
import React from 'react'
import { useMediaQuery } from 'react-responsive'
import './FaqSection.css'
import AccordionCotizar from '../../../components/mainCotizarComponents/AccordionCotizar/AccordionCotizar';
import { faq } from '../../../data';

let isTabletOrMobile = false;
const FaqSection = (props: any) => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 38.5em)' });

  return (
    <div className="faqSection-container" ref={props.questionSection}>
      <h4 className="faqSection-container-title bold" >Me gustaría saber...</h4>
      <div id='#faq' className="faqSection-container-list">
        {faq?.map((a: any, index: number) => (
          <AccordionCotizar
            id={a.name + ' faq'}
            key={index}
            title={a.name}
            text={a.description}
          />
        ))}
      </div>
    </div>
  );
}

export default FaqSection;