import React, { useEffect, useState } from 'react'
import './MotoExito.css'
import Header from '../../../components/generalComponents/header/Header';
import Footer from '../../../components/generalComponents/footer/Footer';
import MainButton from '../../../components/generalComponents/MainButton/MainButton';
import motoLottie from '../../../assets/lotties/moto.json'
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';
import { GetCountryUrl } from '../../../util/GetCountryUrl';
import {store} from '../../../index';
import { useMediaQuery } from 'react-responsive';

let isTabletOrMobile = false;
const MotoExito = () => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 38.5em)' });
  const [scrollPosition, setScrollPosition] = useState(0);

  const countryId = store.getState().general.countryId

  const navigate = useNavigate();

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  }, [])

  return (
    <div className="motoExito-container">
        <Header home colored fixed trans={scrollPosition > 30} special />      
        <div className="motoExito-container-main">
          <div className="motoExito-container-main-top">
            <p className="motoExito-container-main-top-title">Seguro de moto</p>
          </div>
          <div className="motoExito-container-main-content">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: motoLottie
              }}
              height={isTabletOrMobile ? 200 : 250}
              width={isTabletOrMobile ? 200 : 250}
            />
            <p className="motoExito-container-main-content-title">Vamos en camino a cotizar tu seguro ideal</p>
            <p className="motoExito-container-main-content-subTitle">En breve te enviaremos la cotización por email.<br/>¡Muchas gracias!</p>
            <div className="motoExito-container-main-content-buttonWrapper">
              <MainButton
                id='Volver'
                text='VOLVER AL HOME'
                onButtonPressed={() => navigate(GetCountryUrl(countryId) + 'inicio')}
              />
            </div>
          </div>
        </div>
      <Footer />
    </div>
  )
}

export default MotoExito;
