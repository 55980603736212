import React, { useState } from 'react'
import CustomTextInput from '../../../customTextInput/CustomTextInput';
import './FormFieldImei.css'

const FormFieldImei = (props: any) => {

    const [localValue, setLocalValue] = useState('')

    return (
        <div className="FormFieldImei-inputContainer">
            <h1 dangerouslySetInnerHTML={{__html: props.data.display.name}}/>
            <p className="FormFieldImei-inputContainer-description" dangerouslySetInnerHTML={{__html: props.data.display.description}}/>
            <div className="FormFieldImei-inputContainer-wrapper">
                <CustomTextInput
                    label=''
                    value={localValue}
                    placeholder={props.data.display.placeholder}
                    changeText={(e: string) => {
                        e = e.replace(/[^\d+$]/g, '');
                        setLocalValue(e)
                        props.onUpdate(e)
                    }}
                    success={localValue.length === 15}
                    maxLength={15}
                />
            </div>
        </div>
    );
}

export default FormFieldImei;