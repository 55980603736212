import { useMediaQuery } from 'react-responsive'
import generica from '../../../assets/images/GENERICA-FRENTE.png';
import { formatCreditCard } from '../../../paymentMethod/CreditCardAr/CreditCardUtils';

import './CardPayContainer.css'

let isTabletOrMobile = false;

const CardPayContainer = (props: any) => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })



  return (
    <div
      className="cardPayContainer-container"
      style={{
        backgroundImage: props.imagen ? "url(" + props.imagen + ")" : "url(" + generica + ")",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}
    >
      {props.flipForCvv ?
        <div className="cardPayContainer-container-cvvContainer">
          <p className="cardPayContainer-container-cvvContainer-text">{props.cvv}</p>
        </div>
        :
        <div className="cardPayContainer-container-titular-info">
          <p className="numero" style={{ color : props.numero !== undefined ? 'white' : '#6B6B6B'}}>{formatCreditCard(props.numero, props.pattern)}</p>
          <div className="dos">
            <p className="name" style={{ color : props.titular !== 'NOMBRE Y APELLIDO' ? 'white' : '#6b6b6b'}}>{props.titular}</p>
            <p className="fecha" style={{ color: props.vigencia !== 'MM/AA' ? 'white' : '#6b6b6b'}}>{props.vigencia}</p>
          </div>
        </div>
      }
    </div>
  );
}

export default CardPayContainer;