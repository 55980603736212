import React, {useState} from 'react'
import './FormFieldOk.css'

const FormFieldOk = (props: any) => {

    const [accepted, setAccepted] = useState<boolean>(false)

    const handleItemSelected = (a: any) => {
        const data = {
          "questionId": props.data?.id,
          "value": a,
        }
        props.onUpdate(a)
    }

    return (
        <div className="FormFieldOk-container-main-tos">
          <div 
            className="FormFieldOk-container-main-tos-box"
            onClick={() => {
              setAccepted(!accepted)
              handleItemSelected(!accepted)
            }}
          >
            <div 
              className="FormFieldOk-container-main-tos-box-inner"
              style={{transform: accepted ? `scale(${1})` : `scale(${0})`}}
            >
              <span className="material-symbols-outlined" style={{ fontSize: 18 }}>check</span>
            </div>
          </div>
          <p className="FormFieldOk-container-main-tos-text" dangerouslySetInnerHTML={{__html: props.data?.input?.config?.text}} ></p>
        </div>
    );
}

export default FormFieldOk;