import React from 'react'
import './RangeCalendarComponent.css'
import { DateRangePicker } from '@wecover/react-date-range';
import { es } from 'date-fns/locale'
import '@wecover/react-date-range/dist/styles.css'; // main style file
import '@wecover/react-date-range/dist/theme/default.css'; // theme css file
import MainButton from '../../generalComponents/MainButton/MainButton'; 
import { ReactComponent as ButtonExit } from '../../../assets/images/svgs/exit.svg';
import { ReactComponent as Reloj } from '../../../assets/images/svgs/reloj.svg';
import { useMediaQuery } from 'react-responsive';

let isTabletOrMobile;
const RangeCalendarComponent = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

    return (
        <div className="rangeCalendar-container">
            <div 
                id='cerrar calendario'
                className="rangeCalendar-container-close"
                onClick={() => props.onCloseClick()}
            >
                <ButtonExit/>
            </div>
            <Reloj height={isTabletOrMobile ? 20 : 49} width={isTabletOrMobile ? 20 : 49}/>
            <p className="rangeCalendar-container-title" style={{marginTop: 10}}>SELECCIONÁ  EL DÍA O RANGO DE DÍAS A CUBRIR</p>
            <div className="rangeCalendar-container-main">
                <DateRangePicker
                    minDate={new Date()}
                    ranges={[props.selectionRange]}
                    onChange={props.handleCalendarSelection}
                    showMonthAndYearPickers={false}
                    showSelectionPreview={false}
                    showDateDisplay={false}
                    rangeColors='var(--primary500)'
                    color='var(--primary500)'
                    locale={es}
                /> 
                <div className="rangeCalendar-container-main-comparison">
                    <p className="rangeCalendar-container-main-comparison-titleDia">PAGÁS POR DÍA</p>
                    <p className="rangeCalendar-container-main-comparison-dataDia">{props.offerData && props.offerData.renew.term === "MONTHLY" ? '$--' : props.offerData && props.offerData.packages[0].coverageGroups[0].coverages[0].formattedValue}</p>
                    <div className="rangeCalendar-container-main-comparison-line"></div>
                    <p className="rangeCalendar-container-main-comparison-titleMes">PAGARÍAS POR MES</p>
                    <p className="rangeCalendar-container-main-comparison-dataMes">{props.monthlyPrice}</p>
                </div>
                <div className="rangeCalendar-container-main-comparisonResponsive">
                    <div className="rangeCalendar-container-main-comparisonResponsive-dia">
                        <p className="rangeCalendar-container-main-comparisonResponsive-dia-titleDia">PAGÁS POR DÍA</p>
                        <p className="rangeCalendar-container-main-comparisonResponsive-dia-dataDia">{props.offerData && props.offerData.renew.term === "MONTHLY" ? '$--' : props.offerData && props.offerData.packages[0].coverageGroups[0].coverages[0].formattedValue}</p>
                    </div>
                    <div className="rangeCalendar-container-main-comparisonResponsive-line"></div>
                    <div className="rangeCalendar-container-main-comparisonResponsive-mes">
                        <p className="rangeCalendar-container-main-comparisonResponsive-mes-titleMes">PAGARÍAS POR MES</p>
                        <p className="rangeCalendar-container-main-comparisonResponsive-mes-dataMes">{props.monthlyPrice}</p>
                    </div>
                </div>
            </div>
            <div className="rangeCalendar-container-firstButton">
                <MainButton
                    id='Continuar Boton'
                    disabled={false}
                    text='ACEPTAR'
                    onButtonPressed={() => props.setShowCalendar(false)}
                />
            </div>
            <div className="rangeCalendar-container-secondButton">
                <MainButton
                    id='Continuar Boton'
                    disabled={false}
                    text='PREFIERO COTIZAR POR MES'
                    onButtonPressed={() => props.onCloseClick()}
                    empty
color='var(--secondary500)'
                />
            </div>
        </div>
    )
}

export default RangeCalendarComponent;