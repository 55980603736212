import { useEffect, useState } from 'react';
import './FormFieldQuestionnaireItem.css';

const FormFieldQuestionnaireItem = (props: any) => {

  const [localAnswer, setLocalAnswer] = useState<boolean>(false);

  useEffect(() => {
    setLocalAnswer(props.answer)
  }, [props.answer])

  return (
    <div 
      className="formFieldQuestionnaireItem-container"
      style={{ borderBottomWidth: props.questionNumber === 5 ? 0 : 1 }}
    >
      <div className="formFieldQuestionnaireItem-containe-question">
        <p className="formFieldQuestionnaireItem-container-question-number regular" >{props.questionNumber}.</p>
        <p className="formFieldQuestionnaireItem-container-question-question regular" >&nbsp;{props.question}</p>
      </div>
      <div className="formFieldQuestionnaireItem-containe-answer">
        <div 
          className="formFieldQuestionnaireItem-containe-answer-choice"
          onClick={() => props.setAnswer(true)}
        >
          <p className="formFieldQuestionnaireItem-containe-answer-choice-text regular">Si</p>
          <div className="formFieldQuestionnaireItem-containe-answer-choice-circle">
            <div 
              className="formFieldQuestionnaireItem-containe-answer-choice-circle-inner"
              style={{transform: localAnswer ? `scale(${1})` : `scale(${0})`}}
            ></div>
          </div>
        </div>
        <div 
          className="formFieldQuestionnaireItem-containe-answer-choice"
          onClick={() => props.setAnswer(false)}
        >
          <p className="formFieldQuestionnaireItem-containe-answer-choice-text regular">No</p>
          <div className="formFieldQuestionnaireItem-containe-answer-choice-circle">
            <div 
              className="formFieldQuestionnaireItem-containe-answer-choice-circle-inner"
              style={{transform: !localAnswer ? `scale(${1})` : `scale(${0})`}}
            ></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormFieldQuestionnaireItem;