import React, {useState} from 'react'
import './FormFieldOption.css'
import Modal from 'react-modal';
import { useMediaQuery } from 'react-responsive';
import DenyYesModal from '../../../../modalComponents/DenyYesModal/DenyYesModal';

let isTabletOrMobile = false;
const FormFieldOption = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' });

    const [selected, setSelected] = useState<any>(null)
    const [denyYes, setDenyYes] = useState<boolean>(false)

    const handleItemSelected = (a: any) => {
        if(props.parent === 'Cotizar') {
            const data = {
                "questionId": props.data?.id,
                "value": parseInt(a.value, 0),
            }
            props.onUpdateAlt(data)
        } else {
            props.onUpdate(parseInt(a.value, 0))
        }
    }

    const denyYesModal = () => {
        return (
          <Modal
            isOpen={denyYes}
            onRequestClose={() => setDenyYes(false)}
            style={{
              content: {
                top: '50%', left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)', borderRadius: '25px',
                width: isTabletOrMobile ? '80%' : '30%',
                padding: isTabletOrMobile ? '10px' : '20px',
              },
              overlay: {
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                zIndex: '999'
              }
            }}
          >
            <DenyYesModal 
                desactivarAlert={()=> setDenyYes(false)}
            />
          </Modal>
        )
    }

    const checkValidation = (a: any) => {
        if(a.value === "1") {
            if(props.checkOutData?.offer?.product?.categoryId === 18 || props.checkOutData?.offer?.product?.categoryId === 19 || props.checkOutData?.offer?.product?.categoryId === 20) {
                return true
            }
        }
        return false
    }

    return (
        <div 
            className="FormFieldOption-container"
        >
            <p className="FormFieldOption-container-text regular">{props.data?.order + '. ' + props.data?.input?.title}</p>
            <div className="FormFieldOption-container-list">
                {props.data?.input?.values?.map((a: any, index: any) => (
                    <div className="FormFieldOption-container-list-item" key={index}>
                        <div 
                            id={a.label}
                            onClick={() => {
                                if(checkValidation(a)) {
                                    setDenyYes(true)
                                } else {
                                    setSelected(a)
                                    handleItemSelected(a)
                                }
                            }}
                            className="FormFieldOption-container-list-item-circle"
                        >
                            <div
                                className="FormFieldOption-container-list-item-circle-inner"
                                style={{transform: selected?.value == a.value ? `scale(${1})` : `scale(${0})`}}
                            >
                            </div>
                        </div>
                        <p className="FormFieldOption-container-list-item-text regular">{a.label}</p>
                    </div>
                ))}
            </div>
            {denyYesModal()}
        </div>
    );
}

export default FormFieldOption;