import React from 'react'
import './CheckOutResumePaymentComponent.css'
import { useMediaQuery } from 'react-responsive'

import { ReactComponent as CheckCard } from '../../../assets/images/svgs/checkCard.svg';

let isTabletOrMobile = false;
const CheckOutResumePaymentComponent = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

    return (
        <div className="checkOutResumePaymentComponent-container">
            {props.data && 
                <React.Fragment>
                    <img src={props.data.brand.logotype} className="checkOutResumePaymentComponent-container-img"/>
                    {props.data.bank && <p className="checkOutResumePaymentComponent-container-text">{props.data.bank.descripcion}</p>}
                    <p className="checkOutResumePaymentComponent-container-text">{' **** ' + props.data.lastDigits}</p>
                    <div className="checkOutResumePaymentComponent-container-check">
                        <CheckCard/>
                    </div>
                </React.Fragment>
            }
        </div>
    );
}

export default CheckOutResumePaymentComponent;