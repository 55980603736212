import React from 'react'
import './FormFieldLabel.css'
import { Markup } from 'interweave';

const FormFieldLabel = (props: any) => {

    return (
        <div className="FormFieldLabel-container">
            <p className="FormFieldLabel-container-text" dangerouslySetInnerHTML={{__html: props.data.input.config.text}}/>
        </div>
    );
}

export default FormFieldLabel;