import React from 'react'
import './CheckOutDataComponent.css'
import { useMediaQuery } from 'react-responsive'

let isTabletOrMobile = false;
const CheckOutDataComponent = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

    return (
        <div className="checkOutDataComponent-container">
            <p className="checkOutDataComponent-container-title">{props.title}</p>
            <p className="checkOutDataComponent-container-description">{props.description}</p>
        </div>
    );
}
  
export default CheckOutDataComponent;