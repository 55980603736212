import {useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import './ChangePassword.css'
import { connect, useDispatch } from 'react-redux';
import Header from '../../components/generalComponents/header/Header';
import Footer from '../../components/generalComponents/footer/Footer';
import CustomTextInput from '../../components/generalComponents/customTextInput/CustomTextInput';
import MainButton from '../../components/generalComponents/MainButton/MainButton';
import { useNavigate } from 'react-router-dom'
import { LOADING } from '../../actions/types';
import axios from '../../actions/instanceActions';
import { netChangePassword } from '../../net/Connector';
import { genericErrorHandler } from '../../util/GenericErrorHandler';
import { GetGlobalParams } from '../../util/GetGlobalParams';
import { GetCountryUrl } from '../../util/GetCountryUrl';

let isTabletOrMobile = false;
const ChangePassword = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })
    const [contraseña, setContraseña] = useState<string>('')
    const [newContraseña, setNewContraseña] = useState<string>('')
    const [repeatNewContraseña, setRepeatNewContraseña] = useState<string>('')
    const dispatch = useDispatch();

    const navigate = useNavigate()

    const changePassword = async () => {
          dispatch({type: LOADING, payload: true});
          try {
            await axios({
              url: netChangePassword(),
              method: 'put', 
              headers: {
                  'accept': '*/*',
                  'Content-Type': 'application/json',
              },
              data: { currentPassword: contraseña, newPassword: newContraseña}
            })
            navigate(GetCountryUrl(props.countryId) + 'change-password-success?' + GetGlobalParams())
          } catch (error) {
            genericErrorHandler(error)
          } finally {
            dispatch({ type: LOADING, payload: false });
          }
  };

    const validatePassword = (e: string) => {
        if (e.length >= 8) {
          if (/\d/.test(e) && /[a-zA-Z]/.test(e)) {
            if (/[A-Z].*\d|\d.*[A-Z]/.test(e)) {
              return true
            }
          }
        }
        return false
    }

    const isDisabled = () => {
      if(props.userData?.hasPassword) {
        if (contraseña !== '' && newContraseña !== '' && repeatNewContraseña !== '' && repeatNewContraseña === newContraseña) {
            return (!validatePassword(contraseña) || !validatePassword(newContraseña) || !validatePassword(repeatNewContraseña))
        } return true
      } else {
        if (newContraseña !== '' && repeatNewContraseña !== '' && repeatNewContraseña === newContraseña) {
          return (!validatePassword(newContraseña) || !validatePassword(repeatNewContraseña))
      } return true
      }
    }
    
    return (
      <div className="changePassword-container">
              <Header backgroundColor={isTabletOrMobile ? "var(--white)" : null}/>
              <div className="changePassword-container-top">
                  <p className="changePassword-container-top-title">Cambiar contraseña</p>
              </div>
              <div className="changePassword-container-mask">
                  <div className="changePassword-container-mask-inner"></div>
              </div>
              <div className="changePassword-container-main">
                  {props.userData?.hasPassword && <CustomTextInput
                      label='Contraseña'
                      placeholder='*********'
                      required
                      changeText={(e: string) => setContraseña(e)}
                      value={contraseña}
                      password
                      success={validatePassword(contraseña)}
                      error={contraseña !== '' ? !validatePassword(contraseña) : false}
                  />}
                  <CustomTextInput
                      label='Contraseña nueva'
                      placeholder='*********'
                      required
                      changeText={(e: string) => setNewContraseña(e)}
                      value={newContraseña}
                      password
                      success={validatePassword(newContraseña)}
                      error={newContraseña !== '' ? !validatePassword(newContraseña) : false}
                  />
                  <CustomTextInput
                      label='Repetir contraseña nueva'
                      placeholder='*********'
                      required
                      changeText={(e: string) => setRepeatNewContraseña(e)}
                      value={repeatNewContraseña}
                      password
                      success={validatePassword(repeatNewContraseña)}
                      error={repeatNewContraseña !== '' ? repeatNewContraseña !== newContraseña || !validatePassword(repeatNewContraseña) : false}
                  />
                  <p className="changePassword-container-main-noticeText" style={{marginTop: 20, marginBottom: 20}}>Tu contraseña nueva debe incluir: </p>
                  <p className="changePassword-container-main-noticeText">&nbsp;&nbsp;&nbsp;&nbsp;&bull; Mínimo 8 caracteres</p>
                  <p className="changePassword-container-main-noticeText">&nbsp;&nbsp;&nbsp;&nbsp;&bull; Letras y números</p>
                  <p className="changePassword-container-main-noticeText">&nbsp;&nbsp;&nbsp;&nbsp;&bull; 1 letra mayúscula</p>
                  <div style={{marginTop: 20, width: '100%'}}>
                      <MainButton
                          id='Continuar Boton' 
                          disabled={isDisabled()}
                          text='ACTUALIZAR CONTRASEÑA'
                          onButtonPressed={() => changePassword()}
                      />
                  </div>
              </div>
              <Footer/>
      </div>
    );
}

const mapStateToProps = (state: any) => {
  return {
    countryId: state.general.countryId,
    userData: state.user.userData
  };
};


export default connect(mapStateToProps, null)(ChangePassword);