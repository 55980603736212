

import React from 'react'
import './DesactivarPolizaAlert.css'
import { ReactSVG } from 'react-svg';
import CaraTriste from '../../../assets/images/svgs/caraTriste.svg'
import Exit from '../../../assets/images/svgs/exit.svg'
import MainButton from '../../generalComponents/MainButton/MainButton';

const DesactivarPolizaAlert = (props: any) => {
  return (
    <div className="desactivarPolizaAlert-container">
        <ReactSVG
            src={Exit}
            style={{
                position: 'absolute',
                top: 20,
                right: 20,
                cursor: 'pointer'
            }}
            onClick={() => props.desactivarAlert()}
        />
        {props.reDeactivate ? 
            <span className="material-symbols-outlined" style={{ color: 'var(--secondary200)', fontSize: 80 }}>schedule</span>
            :
            <ReactSVG 
                src={CaraTriste}
                style={{marginTop: 40}}
            />
        }
        {props.reDeactivate ? 
            <p className="desactivarPolizaAlert-container-text bold">Ya hay una solicitud de baja en proceso.<br/>En breve nos comunicaremos para gestionarla.</p>
            :
            <p className="desactivarPolizaAlert-container-text bold">¿Confirmás que querés desactivar tu cobertura?</p>
        }
        {props.reDeactivate ? 
            <MainButton
                id='ACEPTAR'
                fill
                color='var(--secondary500)'
                text='ACEPTAR'
                onButtonPressed={() => props.desactivarAlert()}
            />
            :
            <>
                <MainButton
                    id='DESACTIVAR'
                    fill
                    color='var(--secondary500)'
                    text='DESACTIVAR'
                    onButtonPressed={() => props.desactivarPressed()}
                />
                <MainButton
                    id='VOLVER'
                    empty
                    color='var(--secondary500)'
                    text='VOLVER'
                    onButtonPressed={() => props.desactivarAlert()}
                />
                </>
        }
    </div>
  );
}

export default DesactivarPolizaAlert;