import React from 'react'
import './OfferPackage.css'
import MainButton from '../../generalComponents/MainButton/MainButton'; 
import EstrellaVacio from '../../../assets/images/svgs/estrellaVacio.svg';
import EstrellaLleno from '../../../assets/images/svgs/estrellaLleno.svg';
import { ReactSVG } from 'react-svg';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as ButtonMinus } from '../../../assets/images/svgs/minus.svg';
import { ReactComponent as ButtonPlus } from '../../../assets/images/svgs/plus.svg';
import Moment from 'moment/moment'

let isTabletOrMobile = false;
const OfferPackage = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

    const size = isTabletOrMobile ? "22" : "24"

    const estrellaVacioSvg = <ReactSVG
        src={EstrellaVacio}
        beforeInjection={svg => {
            svg.setAttribute('style', 'height: ' + size);
            svg.setAttribute('style', 'width: ' + size);
        }}
    />

    const estrellaLlenoSvg = <ReactSVG
        src={EstrellaLleno}
        beforeInjection={svg => {
            svg.setAttribute('style', 'height: ' + size);
            svg.setAttribute('style', 'width: ' + size);
        }}
    />
    
    const options = props.data?.coverageGroups[0]?.coverages[0]?.options?.split(',')
    let index: any
    let valueM: any
    let valueL: any
    if(options) {
        index = options?.indexOf(props.data?.coverageGroups[0]?.coverages[0]?.value?.toString())
        valueM = options[index + 1] ? options[index + 1] : null
        valueL = options[index - 1] ? options[index - 1] : null
    }
    
    const showStars = () => {
        if (props.data?.assessment === 5) {
            return (
                <>
                    {estrellaLlenoSvg}{estrellaLlenoSvg}{estrellaLlenoSvg}{estrellaLlenoSvg}{estrellaLlenoSvg}
                </>
            )
        } else if (props.data?.assessment === 4) { 
            return (
                <>
                    {estrellaLlenoSvg}{estrellaLlenoSvg}{estrellaLlenoSvg}{estrellaLlenoSvg}{estrellaVacioSvg}
                </>
            )
        } else if (props.data?.assessment === 3) { 
            return (
                <>
                    {estrellaLlenoSvg}{estrellaLlenoSvg}{estrellaLlenoSvg}{estrellaVacioSvg}{estrellaVacioSvg}
                </>
            )
        } else if (props.data?.assessment === 2) {
            return (
                <>
                    {estrellaLlenoSvg}{estrellaLlenoSvg}{estrellaVacioSvg}{estrellaVacioSvg}{estrellaVacioSvg}
                </>
            )
        } else if (props.data?.assessment === 1) {
            return (
                <>
                    {estrellaLlenoSvg}{estrellaVacioSvg}{estrellaVacioSvg}{estrellaVacioSvg}{estrellaVacioSvg}
                </>
            )
        } else {
            return (
                <>
                    {estrellaVacioSvg}{estrellaVacioSvg}{estrellaVacioSvg}{estrellaVacioSvg}{estrellaVacioSvg}
                </>
            )
        }
    }

    return (
        <div 
            className="offerPackage-container"
            style={{ 
                borderWidth: props.data?.favorite ? 1 : 0,
                borderStyle: 'solid',
                borderColor: props.data?.favorite ? 'var(--primary500)' : 'transparent'
            }}
        >
            <div className="offerPackage-container-plan"> {/* Cabecera del packete */}
                <p className="offerPackage-container-plan-text">{props.data?.name}</p>
                {props.showInsurerLogo && <img src={props.data?.insurer?.image} className="offerPackage-container-plan-img"/>}
            </div>
            <div className="offerPackage-container-line"></div>
            <div className="offerPackage-container-stars"> {/* Primera parte del packete */}
                {showStars()}
            </div>
            <p className="offerPackage-container-pagasText">{props.data?.installmentConfig?.cycle?.displayName ? 'PAGAS POR ' + props.data?.installmentConfig?.cycle?.displayName : 'PAGAS POR AÑO'}</p>
            <p className="offerPackage-container-value">{props.data?.installmentConfig?.price?.filter((a: any) => a.currency === props.selectedCurrency)[0]?.formattedValue}</p>
            <div className="offerPackage-container-options">
                <button
                    id='boton -'
                    disabled={!valueL}
                    onClick={() => props.modifyOfferCoverage(props.data?.id, props.data?.coverageGroups[0]?.coverages[0]?.id, valueL)}
                    style={{
                        opacity: valueL ? 1 : 0.4, cursor:
                        valueL ? 'pointer' : 'inherit',
                    }}
                    className="offerPackage-container-options-minus"
                >
                    <ButtonMinus />
                </button>
                <p className="offerPackage-container-options-text">{props.data?.coverageGroups[0]?.coverages[0]?.value === 0 ? 'SIN DEDUCIBLE' : props.data?.coverageGroups[0]?.coverages[0]?.title + ' ' + props.data?.coverageGroups[0]?.coverages[0]?.value + ' ' + props.data?.coverageGroups[0]?.coverages[0]?.unit}</p>
                <button
                    id='boton +'
                    disabled={!valueM}
                    onClick={() => props.modifyOfferCoverage(props.data?.id, props.data?.coverageGroups[0]?.coverages[0]?.id, valueM)}
                    style={{
                        opacity: valueM ? 1 : 0.4,
                        cursor: valueM ? 'pointer' : 'inherit',
                    }}
                    className="offerPackage-container-options-plus"
                >
                    <ButtonPlus />
                </button>
            </div>
            <p className="offerPackage-container-queEsText" onClick={() => props.showQueEs(props.data?.coverageGroups[0]?.coverages[0]?.description, props.data?.coverageGroups[0]?.coverages[0]?.title)}>¿Qué es?</p>
            <div className="offerPackage-container-line"></div>
            <div className="offerPackage-container-dataCoverage"> {/* Segunada parte del packete */}
                {props.data?.coverageGroups[1]?.coverages?.map((a: any, index: any) => (
                    <React.Fragment key={index}>
                        <p className="offerPackage-container-dataCoverage-label">{a.title.toUpperCase()}</p>
                        <p className="offerPackage-container-dataCoverage-valueByLabel">{a.value}</p>
                    </React.Fragment>
                ))}
                <p className="offerPackage-container-dataCoverage-label">VIGENCIA DESDE</p>
                <div className="offerPackage-container-dataCoverage-vigencia" onClick={() => props.openCalendar()}>
                    <p className="offerPackage-container-dataCoverage-vigencia-text">{Moment(props.date).utc().format('DD/MM/YY')}</p>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.75 12.25H2.8L9.26875 5.78125L8.21875 4.73125L1.75 11.2V12.25ZM12.475 4.69375L9.2875 1.54375L10.3375 0.49375C10.625 0.20625 10.9783 0.0625 11.3973 0.0625C11.8158 0.0625 12.1687 0.20625 12.4562 0.49375L13.5062 1.54375C13.7937 1.83125 13.9437 2.17825 13.9562 2.58475C13.9688 2.99075 13.8313 3.3375 13.5438 3.625L12.475 4.69375ZM1 13.75C0.7875 13.75 0.6095 13.678 0.466 13.534C0.322 13.3905 0.25 13.2125 0.25 13V10.8812C0.25 10.7812 0.26875 10.6845 0.30625 10.591C0.34375 10.497 0.4 10.4125 0.475 10.3375L8.2 2.6125L11.3875 5.8L3.6625 13.525C3.5875 13.6 3.50325 13.6562 3.40975 13.6937C3.31575 13.7312 3.21875 13.75 3.11875 13.75H1Z" fill="var(--secondary500)"/>
                    </svg>
                </div>
            </div>
            <div className="offerPackage-container-firstButton">
                <MainButton
                    id='QUIERO SABER MÁS'
                    disabled={false}
                    text='QUIERO SABER MÁS'
                    onButtonPressed={() => props.swornCheck()}
                    altStyle2
                />
            </div>
            <div className="offerPackage-container-secondButton">
                <MainButton
                    id='QUIERO MI SEGURO'
                    disabled={false}
                    text='QUIERO MI SEGURO'
                    onButtonPressed={() => props.checkOutPackage(props.data?.id)}
                />
            </div>
        </div>
    )
}

export default OfferPackage;