import React, {useEffect, useState} from 'react'
import CustomTextInput from '../../generalComponents/customTextInput/CustomTextInput';
import MainButton from '../../generalComponents/MainButton/MainButton';
import './CheckOutProfile.css'
import {
    createUserProfileArgPardo,
    getDataForNewUserArgPardo,
    getLocalidadesByProvinciaPardo,
    getCodigosPostalesByLocalidadPardo,
    loadDataForLoggedUserArgPardo,
    editUserProfileArgPardo,
    loadDataForAdminSelectedUserArgPardo
} from '../../../actions'
import {connect} from 'react-redux';
import Moment from 'moment/moment'
import { useMediaQuery } from 'react-responsive'
import DiscountDetailsComponent from '../DiscountDetailsComponent/DiscountDetailsComponent';
import SelectorSearcher from '../../generalComponents/selectorSearcher/SelectorSearcher';
import { FormattedMessage } from 'react-intl';
import { useSearchParams, useLocation } from 'react-router-dom';

let isTabletOrMobile = false;
const CheckOutProfile = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

    //Inputs
    const [nombre, setNombre] = useState<string>('');
    const [apellido, setApellido] = useState<string>('');
    const [fechaNac, setFechaNac] = useState<string>('');

    const [tipoDocu, setTipoDocu] = useState<any>(null);
    const [numDocu, setNumDocu] = useState<string>('');
    const [cuit, setCuit] = useState<string>('');

    const [cuitFirst, setCuitFirst] = useState<string>('');
    const [cuitSecond, setCuitSecond] = useState<string>('');
    const [cuitThird, setCuitThird] = useState<string>('');
    
    const [sexo, setSexo] = useState<any>(null);
    const [estadoCivil, setEstadoCivil] = useState<any>(null);
    const [nacionalidad, setNacionalidad] = useState<any>(null);

    const [celular, setCelular] = useState<string>('');
    const [email, setEmail] = useState<string>('');

    const [tipoDomi, setTipoDomi] = useState<any>(null);
    const [calle, setCalle] = useState<string>('');
    const [nro, setNro] = useState<string>('');
    const [piso, setPiso] = useState<string>('');
    const [dpto, setDpto] = useState<string>('');

    const [provincia, setProvincia] = useState<any>(null);
    const [localidad, setLocalidad] = useState<any>(null);
    const [codigoPostal, setCodigoPostal] = useState<any>(null);

    //Listado de Selectors
    const [tipoDocuList, setTipoDocuList] = useState<any>(null);

    const [sexoList, setSexoList] = useState<any>(null);
    const [estadoCivilList, setEstadoCivilList] = useState<any>(null);
    const [nacionalidadList, setNacionalidadList] = useState<any>(null);

    const [tipoDomiList, setTipoDomiList] = useState<any>(null);

    const [provinciaList, setProvicinaList] = useState<any>(null);
    const [localidadList, setLocalidadList] = useState<any>(null);
    const [codigosPostalesList, setCodigosPostalesList] = useState<any>(null);
    
    //OTROS
    const [admin, setAdmin] = useState<boolean>(false);
    const [authUserId, setAuthUserId] = useState<any>(null);
    const [searchParams] = useSearchParams();
    const [appBaseIframe] = useState<any>(process.env.REACT_APP_PARENT);
    const location: any = useLocation();
    const adminSelectedUser: any = location?.state?.adminSelectedUser 

    useEffect(() => {
        props.getDataForNewUserArgPardo(
            setTipoDocuList, setSexoList, setEstadoCivilList, 
            setNacionalidadList, setTipoDomiList, setLocalidadList, setProvicinaList
        );
    }, [])

    useEffect(() => {
        handleUrlParams();
    },[props.userAccessToken])

    useEffect(() => {
        if(numDocu.length === 8) setCuitSecond(numDocu)
    }, [numDocu])

    const handleUrlParams = () => {
        const admin: any = searchParams.get("admin");
        if (admin) {
            setAdmin(true)
        } else {
            if(props.userAccessToken) {
                props.loadDataForLoggedUserArgPardo(
                    setNombre, setApellido, setFechaNac,
                    setTipoDocu, setNumDocu, setCuit,
                    setSexo, setEstadoCivil, setNacionalidad,
                    setCelular, setEmail,
                    setTipoDomi, setCalle, setNro, setPiso, setDpto,
                    setProvincia, setLocalidad, setCodigoPostal,
                    setLocalidadList, setCodigosPostalesList, setAuthUserId
                );
            }
        }
        if (adminSelectedUser) {
            props.loadDataForAdminSelectedUserArgPardo(
                setNombre, setApellido, setFechaNac,
                setTipoDocu, setNumDocu, setCuit,
                setSexo, setEstadoCivil, setNacionalidad,
                setCelular, setEmail,
                setTipoDomi, setCalle, setNro, setPiso, setDpto,
                setProvincia, setLocalidad, setCodigoPostal,
                setLocalidadList, setCodigosPostalesList, setAuthUserId, props.userData
            );
        }
      };

    const isValidName = (e: string) => {
        if(!(/^\s/.test(e))) {
            return (/^[a-zA-Z\u00C0-\u00FF _]*$/.test(e))
        } return false
    }

    const validateEmail = (e: string) => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(e).toLowerCase())) {
            return false;
        } return true
    }

    const isValidDate = (e: string) => {
        if(e.length === 10) {
            switch (props.checkOutData?.offer?.product?.categoryId) {
                case 18:
                    if(Moment(e, 'YYYY-MM-DD').isValid()) {
                        if (Moment(e, 'YYYY-MM-DD').isBefore(Moment(new Date()).add(-18, 'years'))) {
                            if (Moment(e, 'YYYY-MM-DD').isAfter(Moment(new Date()).add(-65, 'years'))) {
                                return true
                            }
                        }
                    }
                    break;
                case 19:
                    if(Moment(e, 'YYYY-MM-DD').isValid()) {
                        if (Moment(e, 'YYYY-MM-DD').isBefore(Moment(new Date()).add(-18, 'years'))) {
                            if (Moment(e, 'YYYY-MM-DD').isAfter(Moment(new Date()).add(-65, 'years'))) {
                                return true
                            }
                        }
                    }
                    break;
                case 20:
                    if(Moment(e, 'YYYY-MM-DD').isValid()) {
                        if (Moment(e, 'YYYY-MM-DD').isBefore(Moment(new Date()).add(-50, 'years'))) {
                            if (Moment(e, 'YYYY-MM-DD').isAfter(Moment(new Date()).add(-70, 'years'))) {
                                return true
                            }
                        }
                    }
                    break;
                default:
                    return false;
            }
        }
        return false
    } 

    const isDisabled = () => {
        if(nombre === '' || apellido === '' || !isValidDate(fechaNac) || 
            !tipoDocu || numDocu === '' || (cuitFirst.length !== 2 || cuitSecond.length !== 8 || cuitThird.length !== 1) || 
            !sexo || !estadoCivil || !nacionalidad ||
            celular === '' || !validateEmail(email) ||
            !tipoDomi || calle === '' || nro === '' ||
            !localidad || !codigoPostal || !provincia) {
                return true
        } return false
    }

    return (
        <div className="checkOutProfile-container">
            <div className="checkOutProfile-container-main">
                <div 
                    className="checkOutProfile-container-main-volver"
                    onClick={() => window.history.back()}
                >
                    <span className="material-symbols-outlined" style={{ fontSize: 18 }}>arrow_back_ios_new</span>
                    <b className="checkOutProfile-container-main-volver-text bold medium">Volver</b>
                </div>
                <h6 className="checkOutProfile-container-main-titulo bold">Datos personales</h6>
                {!props.userAccessToken && <p className="checkOutProfile-container-main-reLogText"><FormattedMessage id="page.checkOut.checkOutProfile.reLogin" defaultMessage="" /><p onClick={() => props.reLog()}>&nbsp;Iniciá sesión</p></p>}
                <div className="checkOutProfile-container-main-list">
                    <CustomTextInput
                        label='Nombre'
                        required
                        changeText={(e: string) => setNombre(e)}
                        value={nombre}
                        error={!isValidName(nombre)}
                        success={nombre !== '' ? isValidName(nombre) : false}
                    />
                    <CustomTextInput
                        label='Apellido'
                        required
                        changeText={(e: string) => setApellido(e)}
                        value={apellido}
                        error={!isValidName(apellido)}
                        success={apellido !== '' ? isValidName(apellido) : false}
                    />
                    <CustomTextInput
                        label='Fecha de nacimiento'
                        required
                        changeText={(e: string) => setFechaNac(e)}
                        value={fechaNac}
                        error={fechaNac.length === 0 ? false : !isValidDate(fechaNac)}
                        errorMessage={props.checkOutData?.offer?.product?.categoryId == 18 || props.checkOutData?.offer?.product?.categoryId == 19 ? 'Para afiliarte debes tener entre 18 y 65 años' : 'Para afiliarte debes tener entre 50 y 70 años'}
                        success={isValidDate(fechaNac)}
                        maxLength={10}
                        placeholder="DD/MM/AAAA"
                        type="date"
                        max='2999-12-31'
                    />
                    <div className="checkOutProfile-container-main-list-item-selectorWrapper" style={{ opacity: !tipoDocuList ? 0.5 : 1 }}>
                        <h6 className="checkOutProfile-container-main-list-item-selectorWrapper-title bold">Tipo de documento&nbsp;<ul>*</ul></h6>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                            id='Selector Tipo de documento'
                            list={tipoDocuList}
                            onValueSelected={(a: any) => setTipoDocu(a.codigo)}
                            selectedItem={tipoDocuList && tipoDocu ? tipoDocuList?.filter((a: any) => a.codigo == tipoDocu)[0]?.descripcion : ''}
                            placeholder='Seleccioná tu tipo de documento'
                            deleteSelectedItem={() => setTipoDocu(null)}
                            disabled={!tipoDocuList}
                        /> 
                    </div>
                    <CustomTextInput
                        label='Nro de documento'
                        required
                        changeText={(e: string) => setNumDocu(e.replace(/[^0-9]+/g, ''))}
                        value={numDocu}
                        success={numDocu.length === 8}
                        placeholder='Ingresá sólo números sin puntos'
                        error={numDocu.length !== 8 && numDocu !== ''}
                        maxLength={8}
                    />
                    <div className="checkOutProfile-container-main-list-item-specialInput" style={{ opacity: numDocu.length === 8 ? 1 : 0.6}}>
                        <div className="checkOutProfile-container-main-list-item-specialInput-label">
                            <p className="checkOutProfile-container-main-list-item-specialInput-label-text bold">Nro de CUIT</p>
                            <p className="checkOutProfile-container-main-list-item-specialInput-label-requiered">&nbsp;*</p>
                        </div>
                        <div className="checkOutProfile-container-main-list-item-specialInput-main">
                            <input
                                className="checkOutProfile-container-main-list-item-specialInput-main-input"
                                style={{ width: '15%' }}
                                maxLength={2}
                                disabled={numDocu.length !== 8}
                                onChange={(e) => setCuitFirst(e.target.value.trimStart().replace(/[^0-9]+/g, ''))}
                                value={cuitFirst}
                                placeholder='00'
                            />
                            <b className="checkOutProfile-container-main-list-item-specialInput-main-line light large">-</b>
                            <input
                                className="checkOutProfile-container-main-list-item-specialInput-main-input"
                                style={{ width: '70%' }}
                                maxLength={8}
                                disabled
                                value={cuitSecond}
                                placeholder='00000000'
                            />
                            <b className="checkOutProfile-container-main-list-item-specialInput-main-line light large">-</b>
                            <input
                                className="checkOutProfile-container-main-list-item-specialInput-main-input"
                                style={{ width: '15%' }}
                                maxLength={1}
                                disabled={numDocu.length !== 8}
                                onChange={(e) => setCuitThird(e.target.value.trimStart().replace(/[^0-9]+/g, ''))}
                                value={cuitThird}
                                placeholder='0'
                            />
                            <div className="checkOutProfile-container-main-list-item-specialInput-main-status">
                                {cuitFirst.length === 2 && cuitSecond.length === 8 && cuitThird.length === 1 &&
                                    <span className="material-symbols-outlined" style={{ color: 'var(--statusSuccessPrimary)', fontSize: 20}}>done</span>
                                }
                                {cuitSecond.length === 8 && (cuitFirst.length !== 2 || cuitThird.length !== 1) &&
                                    <span className="material-symbols-outlined" style={{ color: 'var(--statusErrorPrimary)', fontSize: 20}}>error</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="checkOutProfile-container-main-list-item-selectorWrapper" style={{ opacity: !sexoList ? 0.5 : 1 }}>
                        <h6 className="checkOutProfile-container-main-list-item-selectorWrapper-title bold">Sexo&nbsp;<ul>*</ul></h6>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                            id='Selector Sexo'
                            list={sexoList}
                            onValueSelected={(a: any) => setSexo(a.codigo)}
                            selectedItem={sexoList && sexo ? sexoList?.filter((a: any) => a.codigo == sexo)[0]?.descripcion : ''}
                            placeholder='Seleccioná tu sexo'
                            deleteSelectedItem={() => setSexo(null)}
                            disabled={!sexoList}
                        /> 
                    </div>
                    <div className="checkOutProfile-container-main-list-item-selectorWrapper" style={{ opacity: !estadoCivilList ? 0.5 : 1 }}>
                        <h6 className="checkOutProfile-container-main-list-item-selectorWrapper-title bold">Estado civil&nbsp;<ul>*</ul></h6>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'} 
                            id='Selector Estado Civil' 
                            list={estadoCivilList}
                            onValueSelected={(a: any) => setEstadoCivil(a.codigo)}
                            selectedItem={estadoCivilList && estadoCivil ? estadoCivilList?.filter((a: any) => a.codigo == estadoCivil)[0]?.descripcion : ''} 
                            placeholder='Seleccioná tu estado civil' 
                            deleteSelectedItem={() => setEstadoCivil(null)}
                            disabled={!estadoCivilList}
                        />
                    </div>
                    <div className="checkOutProfile-container-main-list-item-selectorWrapper" style={{ opacity: !nacionalidadList ? 0.5 : 1 }}>
                        <h6 className="checkOutProfile-container-main-list-item-selectorWrapper-title bold">Nacionalidad&nbsp;<ul>*</ul></h6>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                            id='Selector Nacionalidad'
                            list={nacionalidadList}
                            onValueSelected={(a: any) => setNacionalidad(a.codigo)}
                            selectedItem={nacionalidadList && nacionalidad ? nacionalidadList?.filter((a: any) => a.codigo == nacionalidad)[0]?.descripcion : ''}
                            placeholder='Seleccioná tu nacionalidad'
                            deleteSelectedItem={() => setNacionalidad(null)}
                            disabled={!nacionalidadList}
                        /> 
                    </div>
                    <CustomTextInput
                        label='Celular'
                        required
                        maxLength={10}
                        changeText={(e: string) => setCelular(e.replace(/[^0-9]+/g, ''))}
                        value={celular}
                        success={celular !== ''}
                    />
                    <CustomTextInput
                        label='E-mail'
                        required
                        value={email}
                        changeText={(e: string) => setEmail(e)}
                        success={validateEmail(email)}
                        error={email.length > 0 ? !validateEmail(email) : false}
                        errorMessage='E-mail inválido'
                        placeholder='nombre@ejemplo.com'
                        disabled={props.userAccessToken && (!admin || adminSelectedUser)}
                    />
                    <div className="checkOutProfile-container-main-list-invis"></div>
                </div>
                <h6 className="checkOutProfile-container-main-titulo bold" style={{marginBottom: 24}}>Datos de domicilio</h6>
                <div className="checkOutProfile-container-main-list">
                    <div className="checkOutProfile-container-main-list-item-selectorWrapper" style={{ opacity: !tipoDomiList ? 0.5 : 1 }}>
                        <h6 className="checkOutProfile-container-main-list-item-selectorWrapper-title bold">Tipo de domicilio&nbsp;<ul>*</ul></h6>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                            id='Selector Tipo de domicilio'
                            list={tipoDomiList}
                            onValueSelected={(a: any) => setTipoDomi(a.codigo)}
                            selectedItem={tipoDomiList && tipoDomi ? tipoDomiList?.filter((a: any) => a.codigo == tipoDomi)[0]?.descripcion : ''}
                            placeholder='Seleccioná tu tipo de domicilio'
                            deleteSelectedItem={() => setTipoDomi(null)}
                            disabled={!tipoDomiList}
                        /> 
                    </div>
                    <CustomTextInput
                        label='Calle'
                        required
                        value={calle}
                        success={calle.length > 0}
                        changeText={(e: string) => setCalle(e)}
                    />
                    <div className="checkOutProfile-container-main-list-list">
                        <CustomTextInput
                            label='Nro'
                            required
                            changeText={(e: string) => setNro(e.replace(/[^0-9]+/g, ''))}
                            value={nro}
                            success={nro !== ''}
                        />
                        <CustomTextInput
                            label='Piso'
                            changeText={(e: string) => setPiso(e)}
                            value={piso}
                            success={piso !== ''}
                        />
                        <CustomTextInput
                            label='Dpto.'
                            changeText={(e: string) => setDpto(e)}
                            value={dpto}
                            success={dpto !== ''}
                        />
                    </div>
                    <div className="checkOutProfile-container-main-list-item-selectorWrapper" style={{ opacity: !provinciaList ? 0.5 : 1 }}>
                        <h6 className="checkOutProfile-container-main-list-item-selectorWrapper-title bold">Provincia&nbsp;<ul>*</ul></h6>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                            id='Selector Provincia'
                            list={provinciaList}
                            onValueSelected={(a: any) => {
                                setProvincia(a)
                                props.getLocalidadesByProvinciaPardo(setLocalidadList, a.codigo)
                            }}
                            selectedItem={provinciaList && provincia ? provinciaList?.filter((a: any) => a.codigo == provincia?.codigo)[0]?.descripcion : ''}
                            placeholder='Seleccioná tu provincia'
                            deleteSelectedItem={() => {
                                setProvincia(null)
                                setLocalidad(null)
                                setLocalidadList(null)
                                setCodigoPostal(null)
                                setCodigosPostalesList(null)
                            }}
                            disabled={!provinciaList}
                        /> 
                    </div>
                    <div className="checkOutProfile-container-main-list-item-selectorWrapper" style={{ opacity: !localidadList ? 0.5 : 1 }}>
                        <h6 className="checkOutProfile-container-main-list-item-selectorWrapper-title bold">Localidad&nbsp;<ul>*</ul></h6>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                            id='Selector Localidad'
                            list={localidadList}
                            onValueSelected={(a: any) => {
                                setLocalidad(a)
                                props.getCodigosPostalesByLocalidadPardo(setCodigosPostalesList, a.codigo)
                            }}
                            selectedItem={localidadList && localidad ? localidadList?.filter((a: any) => a.codigo == localidad?.codigo)[0]?.descripcion : ''}
                            placeholder='Seleccioná tu localidad'
                            deleteSelectedItem={() => {
                                setLocalidad(null)
                                setCodigoPostal(null)
                                setCodigosPostalesList(null)
                            }}
                            disabled={!localidadList}
                        /> 
                    </div>
                    <div className="checkOutProfile-container-main-list-item-selectorWrapper" style={{ opacity: !codigosPostalesList ? 0.5 : 1 }}>
                        <h6 className="checkOutProfile-container-main-list-item-selectorWrapper-title bold">Código postal&nbsp;<ul>*</ul></h6>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                            id='Selector Codigo Postal'
                            list={codigosPostalesList}
                            onValueSelected={(a: any) => setCodigoPostal(a.codigo)}
                            selectedItem={codigosPostalesList && codigoPostal ? codigosPostalesList?.filter((a: any) => a.codigo == codigoPostal)[0]?.descripcion : ''}
                            placeholder='Seleccioná tu codigo postal'
                            deleteSelectedItem={() => setCodigoPostal(null)}
                            disabled={!codigosPostalesList}
                        /> 
                    </div>
                </div>
            </div>
            <div className="checkOutProfile-container-endWrapper">
                <div className="checkOutProfile-container-endWrapper-main">
                    <DiscountDetailsComponent/>
                    <MainButton
                        id='Continuar Boton' 
                        fill
                        color='var(--secondary500)'
                        disabled={isDisabled()}
                        text='CONTINUAR'
                        onButtonPressed={() => {
                            const dateGoodFormat = Moment(fechaNac, 'YYYY-MM-DD').format('YYYY-MM-DD')
                            if(props.userAccessToken && (!admin || adminSelectedUser)) {
                                props.editUserProfileArgPardo(
                                    nombre, apellido, dateGoodFormat,
                                    tipoDocu, numDocu, cuitFirst + cuitSecond + cuitThird,
                                    sexo, estadoCivil, nacionalidad,
                                    celular, email,
                                    tipoDomi, calle, nro, piso, dpto,
                                    localidad?.codigo, localidad?.descripcion, codigoPostal, provincia?.codigo, provincia?.descripcion,
                                    props.checkOutData?.id, authUserId, admin, appBaseIframe
                                );
                            } else {
                                props.createUserProfileArgPardo(
                                    nombre, apellido, dateGoodFormat,
                                    tipoDocu, numDocu, cuitFirst + cuitSecond + cuitThird,
                                    sexo, estadoCivil, nacionalidad,
                                    celular, email,
                                    tipoDomi, calle, nro, piso, dpto,
                                    localidad?.codigo, localidad?.descripcion, codigoPostal, provincia?.codigo, provincia?.descripcion,
                                    props.checkOutData?.id, admin,
                                    appBaseIframe
                                );
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state: any) => {
  return {
    userAccessToken: state.general.userAccessToken,
    sexTypes: state.user.sexTypes,
    civilStatusTypes: state.user.civilStatusTypes,
    checkOutData: state.checkOut.checkOutData,
    userData: state.user.userData
  }; 
};

export default connect(mapStateToProps, {
    createUserProfileArgPardo,
    getDataForNewUserArgPardo,
    getLocalidadesByProvinciaPardo,
    getCodigosPostalesByLocalidadPardo,
    loadDataForLoggedUserArgPardo,
    editUserProfileArgPardo,
    loadDataForAdminSelectedUserArgPardo
})(CheckOutProfile);