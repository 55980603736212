import { 
    getUserMine,
    netDeviceUsed 
} from '../net/Connector';
import {
    COUNTRY_ID,
    CHANNEL_ID,
    USER_ACCESS_TOKEN,
    ERROR_MESSAGE,
    CATEGORIA_REF,
    PREGUNTAS_REF,
    RESUMEN_REF,
    PAYMENT_REF,
    PRODUCT_REF,
    PROFILE_REF,
    BURGER_OPEN,
    GLOBAL_PARAMS,
    GLOBAL_FORM_ANSWER,
    USER_DATA
} from './types';
import axios from './instanceActions'

export const emptyGlobalFormAnswer = () => {
    return {
        type: GLOBAL_FORM_ANSWER,
        payload: {}
    };
};

export const changeCountryId = ( value: number ) => {
    return {
        type: COUNTRY_ID,
        payload: value
    };
};

export const channelIdChange = (value: number) => {
    return {
        type: CHANNEL_ID,
        payload: value
    };
};

export const userAccessTokenSet = (value: string) => {
    return {
        type: USER_ACCESS_TOKEN,
        payload: value
    };
};

export const setRefCategory = (value: boolean) => {
    return {
        type: CATEGORIA_REF,
        payload: value
    };
};

export const setRefQuestion = (value: boolean) => {
    return {
        type: PREGUNTAS_REF,
        payload: value
    };
};

export const toggleErrorOff = () => {
    return {
        type: ERROR_MESSAGE,
        payload: null
    };
}

export const setRefResumen = (value: boolean) => {
    return {
        type: RESUMEN_REF,
        payload: value
    };
};

export const setRefPayment = (value: boolean) => {
    return {
        type: PAYMENT_REF,
        payload: value
    };
};

export const setRefProduct = (value: boolean) => {
    return {
        type: PRODUCT_REF,
        payload: value
    };
};

export const setRefProfile = (value: boolean) => {
    return {
        type: PROFILE_REF,
        payload: value
    };
};

export const setBurgerOpen = (value: boolean) => {
    return {
        type: BURGER_OPEN,
        payload: value
    };
};

export const setGlobalParams = (value: string) => {
    return {
        type: GLOBAL_PARAMS,
        payload: value
    };
}

export const getUserData = () => {
    return (dispatch: any) => {
        axios.get(getUserMine())
            .then((response) => {
                dispatch({ type: USER_DATA, payload: response.data });
            })
            .catch((error) => {
            });
    };
};

export const eraseUserData = () => {
    return {
        type: USER_DATA,
        payload: null
    };
}

export const saveDeviceUsed = (device: any, source: any) => {
    return () => {
        axios({
            url: netDeviceUsed(device, source),
            method: 'post',
            headers: {
                'accept': '*/*',
                'Content-Type': 'application/json'
            },
        })
            .then((response) => {
            })
            .catch((error) => {
            });
    };
};