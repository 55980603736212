import React, {useState, useRef, useEffect} from 'react'
import { ReactComponent as ExitQuote } from '../../../assets/images/svgs/exitQuote.svg';
import { ReactComponent as LupaQuote } from '../../../assets/images/svgs/lupaQuote.svg'; 
import './SelectorSearcher.css'

const SelectorSearcher = (props: any) => {

  const [openList, setOpenList] = useState<Boolean>(false)
  const [completeList, setCompleteList] = useState<any>(props.list)
  const [textSearch, setTextSearch] = useState<any>('')
  const [preSelectedItemIndex, setPreSelectedItemIndex] = useState<number>(-1)

  const mainRef: any = useRef()

  useEffect(() => {
    function handleClickOutside(event: any) {
        if (mainRef.current && !mainRef.current.contains(event.target)) {
          setOpenList(false)
        }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [mainRef]);

  const handleItemSelected = (a: any) => {
    setOpenList(false)
    props.onValueSelected(a)
    setPreSelectedItemIndex(-1)
  }

  const filterByText = (e: String) => {
    setTextSearch(e)
    let auxShow = props.list?.filter((a: any) => (a.descripcion).toLowerCase().includes(e.toLowerCase()))
    setCompleteList(auxShow)
  }

  return (
    <div ref={mainRef} className="selectorSearcher-container" >
      <div 
        id={props.id}
        className="selectorSearcher-container-selector" 
        onClick={() => {
          if(!props.selectedItem && !props.disabled) {
            setOpenList(true)
            filterByText('')
          }
        }}
        style={{
          borderBottomLeftRadius: openList ? 0 : 25,
          backgroundColor: props.selectedItem ? 'var(--primary500)' : 'var(--gray100)'
        }}
      >
        {props.selectedItem ? 
          <p className="selectorSearcher-container-selector-value" style={{color: 'var(--white)'}}>{props.selectedItem}</p>
          :  
          <input 
            id="Selector"
            value={textSearch}
            disabled={props.disabled} 
            className="selectorSearcher-container-selector-input" 
            placeholder={props.placeholder}
            onChange={(e) => filterByText(e.target.value)}
            onKeyDown={(e: any) => {
              if(e.keyCode === 40 && completeList?.length - 1 !== preSelectedItemIndex) { // arrow down
                setPreSelectedItemIndex(preSelectedItemIndex + 1)
              } else if (e.keyCode === 38 && preSelectedItemIndex !== 0) { // arrow up
                setPreSelectedItemIndex(preSelectedItemIndex - 1)
              } else if (e.keyCode === 13) {
                handleItemSelected(completeList[preSelectedItemIndex])
              }
            }}
            onFocus={(event) => {
              event.target.setAttribute('autocomplete', 'off');
              setOpenList(true)
              filterByText('')
            }}
            onBlur={() => setOpenList(false)}
          />
        }
        {props.selectedItem ? 
          <div 
            id='Delete item'
            className="selectorSearcher-container-selector-lupa"
            style={{borderBottomRightRadius: openList ? 0 : 25}}
            onClick={() => {
              if(!props.disabled) {
                setOpenList(true)
                props.deleteSelectedItem()
                filterByText('')
              }
            }}
          >
            <ExitQuote/>
          </div>
          :
          <div 
            className="selectorSearcher-container-selector-lupa"
            style={{borderBottomRightRadius: openList ? 0 : 25}}
          >
            <LupaQuote height={21} width={21}/>
          </div>
        }
      </div>
      <div 
        className="selectorSearcher-container-list"
        style={{
          maxHeight: openList ? 250 : 0,
          width: document.getElementById(props.id)?.offsetWidth
        }}
      >
        {completeList?.map((a: any, index: any) => (
          <p
            id={a.label + ' ' + a.subTitle + ' item'}
            key={index}
            className="selectorSearcher-container-list-listItemText"
            onClick={() => handleItemSelected(a)}
            style={{
              backgroundColor: preSelectedItemIndex === index ? 'var(--primary500)' : 'transparent',
              color: preSelectedItemIndex === index ? 'var(--white)' : 'var(--primary500)'
            }}
          >{a.descripcion}</p>
        ))}
      </div>
    </div>
  );
} 

export default SelectorSearcher;