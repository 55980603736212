import React, {useEffect} from 'react'
import './SuccessCard.css'
import { useMediaQuery } from 'react-responsive'
import {connect} from 'react-redux';
import {
    toggleOffCodeResendSuccess,
} from '../../../actions'

import { ReactComponent as Exit } from '../../../assets/images/svgs/exitQuote.svg';
import { ReactComponent as Success } from '../../../assets/images/svgs/successValidate.svg';

let isTabletOrMobile = false;
const SuccessCard = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

    useEffect(() => {
        if(props.successCardText.state) {
            setTimeout(() => {
                props.toggleOffCodeResendSuccess(props.successCardText.text)
            }, 2500)
        }
    }, [props.successCardText])

    return (
        <div 
            className="successCard-container"
            style={{transform: props.successCardText.state ? `translate(${props.translateX}px, ${props.translateY}px)` : `translate(1000px, ${props.translateY}px)`}}
        >
            <div className="successCard-container-left">
                <Success/>
                <p className="successCard-container-left-text">{props.successCardText.text}</p>
            </div>
            <div 
                className="successCard-container-right"
                onClick={() => props.toggleOffCodeResendSuccess(props.successCardText.text)}
            >
                <Exit/>
            </div>
        </div>
    );
}

const mapStateToProps = (state: any) => {
  return {
    countryId: state.general.countryId,
    successCardText: state.general.successCardText
  };
};

export default connect(mapStateToProps, {toggleOffCodeResendSuccess})(SuccessCard);