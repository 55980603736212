import React, { useEffect, useState } from 'react'
import './CheckOutProduct.css'
import { connect, useDispatch } from 'react-redux';
import {
  checkOutProduct,
  checkOutDiscount,
  checkOutDiscountDelete,
  setRefPayment,
  setRefProduct
} from '../../../actions'
import MainButton from '../../generalComponents/MainButton/MainButton';
import DiscountDetailsComponent from '../DiscountDetailsComponent/DiscountDetailsComponent';
import { LOADING } from '../../../actions/types';
import { getCheckOutRequerements, ProductRequirement } from '../../../services/CheckoutService';
import { genericErrorHandler } from '../../../util/GenericErrorHandler';
import GetGlobalForm from '../../generalComponents/GlobalForms/getGlobalForm';

const CheckOutProduct = (props: any) => {
  const [productRequirements, setProductRequirements] = useState<ProductRequirement[]>();
  const [localAnswers, setLocalAnswers] = useState<any>({});
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      if (props.checkOutData?.id) {
        try {
          dispatch({ type: LOADING, payload: true });
          const checkOutRequerements = await getCheckOutRequerements(props.checkOutData.id);
          setProductRequirements(checkOutRequerements);
        } catch (error) {
          genericErrorHandler(error);
        } finally {
          dispatch({ type: LOADING, payload: false });
        }
      }
    }
    fetchData()
  }, [props.checkOutData?.id])

  const isDisabled = () => {
    let control = false
    productRequirements?.map((a: any) => {
      if (a.required) {
        if (localAnswers[a.id] || localAnswers[a.id] === 0) {
          if (Array.isArray(localAnswers[a.id])) {
            if (localAnswers[a.id]?.filter(b => b !== '')?.length < a?.input?.presignedUrl?.length) {
              control = true
            }
          }
        } else {
          control = true
        }
      }
    })
    return control
  }

  const renderForm = () => {
    return (
      <div className="checkOutProduct-container">
        <div className="checkOutProduct-container-main">
          <div 
            className="checkOutProduct-container-main-volver"
            onClick={() => window.history.back()}
          >
            <span className="material-symbols-outlined" style={{ fontSize: 18 }}>arrow_back_ios_new</span>
            <b className="checkOutProduct-container-main-volver-text bold medium">Volver</b>
          </div>
          <h6 className="checkOutProduct-container-main-title bold">Tu declaración jurada</h6>
          <div className="checkOutProduct-container-main-list">
            <p className="checkOutProduct-container-main-list-title bold">Por favor, respondé “si” o “no” a las siguientes preguntas</p>
            {productRequirements?.map((a: any, indexA: number) => (
              <div key={indexA} className="checkOutProduct-container-main-list-item">
                <GetGlobalForm
                  parent='CheckOut'
                  item={a}
                  currentAnswer={localAnswers} 
                  setLocalAnswers={setLocalAnswers} 
                  next={null} 
                  setCurrentAnswer={null}
                  checkOutData={props.checkOutData}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="checkOutProduct-container-endWrapper">
          <div className="checkOutProduct-container-endWrapper-main">
            <DiscountDetailsComponent />
            <div className="checkOutProduct-container-endWrapper-main-detailsWrapper-buttonWrapper">
              <MainButton
                id='Continuar Boton'
                fill
                color='var(--secondary500)'
                disabled={isDisabled()}
                text='CONTINUAR'
                onButtonPressed={() => {
                  props.checkOutProduct(props.checkOutData.id, localAnswers)
                  props.setRefPayment(true)
                }}
                altStyle10
              />
            </div>
          </div>
        </div>
      </div>
    )
  };

  if (productRequirements === undefined) {
    return <p>Cargando...</p>
  }

  return renderForm();
}

const mapStateToProps = (state: any) => {
  return {
    countryId: state.general.countryId,
    checkOutData: state.checkOut.checkOutData,
  };
};

export default connect(mapStateToProps, {
  checkOutProduct,
  checkOutDiscount,
  checkOutDiscountDelete,
  setRefPayment,
  setRefProduct
})(CheckOutProduct);