import React from 'react'
import './HistoryQuestions.css'
import EditButton from '../../../assets/images/svgs/edit.svg';
import { ReactComponent as FirstLinesQuote } from '../../../assets/images/svgs/firstLinesQuote.svg';
import { ReactComponent as CurrentQuestion } from '../../../assets/images/svgs/currentQuestion.svg';
import { ReactComponent as CompletedQuestion } from '../../../assets/images/svgs/completedQuestion.svg';
import { ReactSVG } from 'react-svg'

const HistoryQuestions = (props: any) => {

    const currentQuestionSvg = <CurrentQuestion/>

    const completedQuestionSvg = <CompletedQuestion/>
    
    return (
        <div className="historyQuestion-container">
            {props.firstAnswer ? 
                <div id="Volver_Quote" onClick={() => props.goBackPressed()} className="historyQuestion-container-goBack">
                    <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.5215 13.3761L0.5835 7.48735C0.486834 7.40473 0.4175 7.31927 0.3755 7.23099C0.3335 7.1427 0.3125 7.0513 0.3125 6.95679C0.3125 6.86228 0.3335 6.77088 0.3755 6.6826C0.4175 6.59431 0.486834 6.50886 0.5835 6.42623L7.5425 0.519611C7.7505 0.342474 8.00383 0.253906 8.3025 0.253906C8.60117 0.253906 8.8545 0.348417 9.0625 0.537438C9.28517 0.714008 9.3895 0.931891 9.3755 1.19109C9.3615 1.45085 9.2505 1.66902 9.0425 1.84559L3.0215 6.95679L9.0625 12.0858C9.25717 12.2505 9.3545 12.4656 9.3545 12.731C9.3545 12.9964 9.25717 13.2115 9.0625 13.3761C8.8545 13.5533 8.59783 13.6419 8.2925 13.6419C7.9865 13.6419 7.7295 13.5533 7.5215 13.3761Z" fill="var(--primary500)"/>
                    </svg>
                    <p className="historyQuestion-container-goBack-text">Volver</p>
                </div>
                :
                <div className="historyQuestion-container-lineContainer">
                    <FirstLinesQuote/>
                </div>
            }
            <div className="historyQuestion-container-question">
                <div className="historyQuestion-container-question-textContainer">
                    <p className="historyQuestion-container-question-textContainer-text">{props.firstQuestion}</p>
                    {props.firstAnswer && 
                        <div id={'Editar ¿Qué querés asegurar?'} onClick={() => props.onEditPressed(-1)} className="historyQuestion-container-question-textContainer-edit">
                            <p className="historyQuestion-container-question-textContainer-edit-text">{props.firstAnswer?.charAt(0).toUpperCase() + props.firstAnswer?.slice(1).toLowerCase()}&nbsp;&nbsp;&nbsp;</p>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.16667 13.8327H3.33333L10.5208 6.64518L9.35417 5.47852L2.16667 12.666V13.8327ZM14.0833 5.43685L10.5417 1.93685L11.7083 0.770182C12.0278 0.450738 12.4203 0.291016 12.8858 0.291016C13.3508 0.291016 13.7431 0.450738 14.0625 0.770182L15.2292 1.93685C15.5486 2.25629 15.7153 2.64185 15.7292 3.09352C15.7431 3.54463 15.5903 3.9299 15.2708 4.24935L14.0833 5.43685ZM1.33333 15.4993C1.09722 15.4993 0.899445 15.4193 0.74 15.2593C0.58 15.0999 0.5 14.9021 0.5 14.666V12.3118C0.5 12.2007 0.520833 12.0932 0.5625 11.9893C0.604167 11.8849 0.666667 11.791 0.75 11.7077L9.33333 3.12435L12.875 6.66602L4.29167 15.2493C4.20833 15.3327 4.11472 15.3952 4.01083 15.4368C3.90639 15.4785 3.79861 15.4993 3.6875 15.4993H1.33333Z" fill="var(--primary500)"/>
                            </svg>
                        </div>
                    }
                </div>
                {props.firstAnswer ? completedQuestionSvg : currentQuestionSvg}
            </div>
            {props.questionList && props.questionList.map((a: any, index: any) => (
                <React.Fragment key={index}>
                    <div className="historyQuestion-container-prevLine"></div>
                    <div className="historyQuestion-container-question">
                        <div className="historyQuestion-container-question-textContainer">
                            <p className="historyQuestion-container-question-textContainer-text">{a.questionLabel?.toUpperCase()}</p>
                            {props.firstAnswer && 
                                <div id={'Editar ' + a.questionLabel} onClick={() => props.onEditPressed(a.step)} className="historyQuestion-container-question-textContainer-edit">
                                    <p className="historyQuestion-container-question-textContainer-edit-text">{a.label?.charAt(0).toUpperCase() + a.label?.slice(1).toLowerCase()}&nbsp;&nbsp;&nbsp;</p>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.16667 13.8327H3.33333L10.5208 6.64518L9.35417 5.47852L2.16667 12.666V13.8327ZM14.0833 5.43685L10.5417 1.93685L11.7083 0.770182C12.0278 0.450738 12.4203 0.291016 12.8858 0.291016C13.3508 0.291016 13.7431 0.450738 14.0625 0.770182L15.2292 1.93685C15.5486 2.25629 15.7153 2.64185 15.7292 3.09352C15.7431 3.54463 15.5903 3.9299 15.2708 4.24935L14.0833 5.43685ZM1.33333 15.4993C1.09722 15.4993 0.899445 15.4193 0.74 15.2593C0.58 15.0999 0.5 14.9021 0.5 14.666V12.3118C0.5 12.2007 0.520833 12.0932 0.5625 11.9893C0.604167 11.8849 0.666667 11.791 0.75 11.7077L9.33333 3.12435L12.875 6.66602L4.29167 15.2493C4.20833 15.3327 4.11472 15.3952 4.01083 15.4368C3.90639 15.4785 3.79861 15.4993 3.6875 15.4993H1.33333Z" fill="var(--primary500)"/>
                                    </svg>
                                </div>
                            }
                        </div>
                        {completedQuestionSvg}
                    </div>
                </React.Fragment>
            ))}
            {props.currentQuestion && 
            <>
                <div className="historyQuestion-container-prevLine"></div>
                <div className="historyQuestion-container-question">
                    <div className="historyQuestion-container-question-textContainer">
                        <p className="historyQuestion-container-question-textContainer-text">{props.currentQuestion.display.description}</p>
                    </div>
                    {currentQuestionSvg}
                </div>
            </>
            }
        </div>
    );

} 
 
export default HistoryQuestions;