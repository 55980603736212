import axios from "axios";
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";
import {store} from '../index';

const instance = axios.create({
	baseURL : process.env.REACT_APP_API
});

instance.interceptors.request.use(
	async (config: any) => {
		const token = store.getState().general.userAccessToken
		const countryId = store.getState().general.countryId
		const channelId = store.getState().general.channelId

		if (token) {
			config.headers['Authorization'] = token
		}
		if (countryId) {
			config.headers['CountryId'] = countryId
		}
		if (channelId) {
			config.headers['ChannelId'] = channelId
		} 
		return config
	}
)

instance.interceptors.response.use( 
	function (response: any) {
		return Promise.resolve(response)
	},
	function (error: any) {
		if (error.response && error.response.status === 401) {
			let cookies = new Cookies();
			cookies.remove('userAccessToken');

			const navigate = useNavigate();
			navigate('/');
		}
        return Promise.reject(error);
    }
)

export default instance;