import React from 'react'
import './AccesosRapidos.css'

const AccesosRapidos = (props: any) => {

    return (
        <div 
            className="accesosRapidos-container"
            onClick={() => props.disable ? {} : props.onPressed()}
            style={{ opacity: props.disable ? 0.4 : 1 }}
        >
            <div className="accesosRapidos-conainer-icon">
                <span className="material-symbols-outlined" style={{ color: 'var(--white)', fontSize: 32 }} >{props.icon}</span>
            </div>
            <p className="accesosRapidos-container-text regular medium" >{props.text}</p>
        </div>
    );
}

export default AccesosRapidos;