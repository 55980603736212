
import axios from '../actions/instanceActions'
import { netCheckOutRequerements } from '../net/Connector';

export interface Display {
    description: string;
    name: string;
    placeholder: string;
}

export interface Input {
    type: string;
}

export interface ProductRequirement {
    display: Display;
    id: string;
    input: Input;
    order: number;
    required: boolean;
}

/**
 * Obtiene información de la información requerida para el producto.
 * @param id Identificador del checkout
 * @returns 
 */
export const getCheckOutRequerements = (id: string): Promise<ProductRequirement[]> => {
    return  axios.get(netCheckOutRequerements(id)).then(x => x.data);
}