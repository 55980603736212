import React, {useState, useEffect, useRef} from 'react'
import { ReactComponent as ExitQuote } from '../../../assets/images/svgs/exitQuote.svg';
import { ReactComponent as ArrowQuote } from '../../../assets/images/svgs/arrowQuote.svg';
import './Selector.css'

const Selector = (props: any) => {

  const [openList, setOpenList] = useState<Boolean>(false)

  const mainRef: any = useRef()

  useEffect(() => {
    function handleClickOutside(event: any) {
        if (mainRef.current && !mainRef.current.contains(event.target)) {
          setOpenList(false) 
        }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [mainRef]);

  const handleItemSelected = (a: any) => {
    setOpenList(false)
    props.onValueSelected(a)
  }

  return (
    <div ref={mainRef} className="selector-container">
      <div 
        id={props.id}
        className="selector-container-selector" 
        onClick={() => {
          if(!props.selectedItem && props.list?.length > 0 && !props.disabled) {
            setOpenList(!openList)
          }
        }}
        style={{
            borderBottomLeftRadius: openList ? 0 : 25,
            borderBottomRightRadius: openList ? 0 : 25,
            backgroundColor: openList ? 'var(--gray100)' : props.selectedItem ? 'var(--primary500)' : 'var(--white)',
            borderWidth: openList || props.selectedItem ? 0 : 1
        }}
      >
        <p 
            className="selector-container-selector-value"
            style={{color: props.selectedItem ? 'var(--white)' : 'var(--primary500)'}}
        >{props.selectedItem ? props.selectedItem : props.placeholder}</p>
        {props.selectedItem ? 
          <div
            id='Delete item'
            onClick={() => {
              if(!props.disabled) {
                props.deleteSelectedItem()
                setOpenList(true)
              }
            }} 
          >
            <ExitQuote/>
          </div>
          :
          <div style={{transform: openList ? `rotate(${180}deg)` : `rotate(${0}deg)`}}>
            <ArrowQuote/>
          </div>
        }
      </div>
      <div 
        className="selector-container-list"
        style={{
          maxHeight: openList ? 250 : 0,
          width: document.getElementById(props.id)?.offsetWidth
        }}
      >
        {props.popularList.length !== 0 && <p className="selector-container-list-popularText">Más populares</p>}
        {props.popularList.length !== 0 && props.popularList.map((a: any, index: any) => (
          <p 
            id={a.label + ' item'}
            key={index}
            className="selector-container-list-listItemText"
            onClick={() => handleItemSelected(a)} 
          >{a.label}</p>
        ))}
        {props.popularList.length !== 0 && <div className="selector-container-list-line"></div>}
        {props.list?.length !== 0 && props.list?.map((a: any, index: any) => (
          <p 
            id={a.label + ' item'}
            key={index}
            className="selector-container-list-listItemText"
            onClick={() => handleItemSelected(a)}
          >{a.label ? a.label : a.value ? a.value : a.installment ? a.installment : a.name ? a.name : a.descripcion}</p>
        ))}
      </div>
    </div>
  );
} 

export default Selector;