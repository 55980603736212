import React, {useState} from 'react'
import CustomTextInput from '../../../customTextInput/CustomTextInput';
import './FormFieldPersonChile.css'
import Moment from 'moment/moment'
import Selector from '../../../selector/Selector';
import MainButton from '../../../MainButton/MainButton';
import SelectorSearcher from '../../../selectorSearcher/SelectorSearcher';

const FormFieldPersonChile = (props: any) => {

    const [rut, setRut] = useState<string>('')
    const [dv, setDv] = useState<string>('')
    const [fechaNac, setFechaNac] = useState<string>('')

    const [comuna, setComuna] = useState<any>(null)
    const [genero, setGenero] = useState<any>(null)

    const handleDateInput = (text: string) => {
        let newText = text;
        if (text.length >= 3 && text[2] !== '/') {
            newText = text[0] + text[1] + '/' + text.slice(2);
        } else if (text.length >= 6 && text[5] !== '/') {
            newText = text[0] + text[1] + text[2] + text[3] + text[4] + '/' + text.slice(5);
        }
        setFechaNac(newText)
    }

    const isValidDate = (e: string) => {
        if(e.length === 10) {
            if(Moment(e, 'DD/MM/YYYY').isValid()) {
                if (Moment(e, 'DD/MM/YYYY').isBefore(Moment(new Date()).add(-18, 'years'))) {
                    if (Moment(e, 'DD/MM/YYYY').isAfter(Moment(new Date()).add(-100, 'years'))) {
                        return true
                    }
                }
            }
        }
        return false
    }
    
    var Fn = {
        // Valida el rut con su cadena completa "XXXXXXXX-X"
        validaRut : function (rutCompleto: any) {
            if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
                return false;
            var tmp 	= rutCompleto.split('-');
            var digv	= tmp[1]; 
            var rut 	= tmp[0];
            if ( digv == 'K' ) digv = 'k' ;
            return (Fn.dv(rut) == digv );
        },
        dv : function(T: any){
            var M=0,S=1;
            for(;T;T=Math.floor(T/10))
                S=(S+T%10*(9-M++%6))%11;
            return S?S-1:'k';
        }
    }
    
    const isDisabled = () => {
        if (props.data?.required) {
            return !Fn.validaRut(rut + '-' + dv) || !isValidDate(fechaNac) || !comuna || !genero
        } return false
    }

    const handleItemSelected = (a: any) => {
        const data = {
          "questionId": props.data.id,
          "value": a,
        }
        props.onUpdateAlt(data)
    }

    return (
        <div className="FormFieldPersonChile-container">
            <div className="FormFieldPersonChile-container-main">
                <div className="FormFieldPersonChile-container-main-inputList">
                    <div className="FormFieldPersonChile-container-main-inputList-two">
                        <div className="FormFieldPersonChile-container-main-inputList-two-row">
                            <div className="FormFieldPersonChile-container-main-inputList-two-row-firstWrapper">
                                <CustomTextInput
                                    label='Rut'
                                    required
                                    changeText={(e: string) => setRut(e.replace(/[^0-9]+/g, ''))}
                                    value={rut}
                                    especial
                                    maxLength={8}
                                    error={!Fn.validaRut(rut + '-' + dv) && rut !== '' && dv !== ''}
                                    success={Fn.validaRut(rut + '-' + dv)}
                                />
                            </div>
                            <div className="FormFieldPersonChile-container-main-inputList-two-row-secondWrapper">
                                <CustomTextInput
                                    label='Dv'
                                    required
                                    changeText={(e: string) => setDv(e)}
                                    value={dv}
                                    especial
                                    maxLength={1}
                                    error={!Fn.validaRut(rut + '-' + dv) && rut !== '' && dv !== ''}
                                    success={Fn.validaRut(rut + '-' + dv)}
                                />
                            </div>
                        </div>
                        {!Fn.validaRut(rut + '-' + dv) && rut !== '' && dv !== '' && <p className="FormFieldPersonChile-container-main-inputList-two-errorText">El RUT y/o DV no son válidos. Prueba tipeándolos nuevamente.</p>}
                    </div>
                    <div className="FormFieldPersonChile-container-main-inputList-inputWrapper">
                        <CustomTextInput
                            label='Fecha de nacimiento'
                            required
                            changeText={(e: string) => handleDateInput(e.replace(/[^/0-9]+/g, ''))}
                            value={fechaNac}
                            placeholder='DD/MM/AAAA'
                            error={fechaNac.length === 0 ? false : !isValidDate(fechaNac)}
                            success={isValidDate(fechaNac)}
                            maxLength={10}
                            especial
                        />
                    </div>
                </div>
                <div className="FormFieldPersonChile-container-main-selectorList">
                    <div className="FormFieldPersonChile-container-main-selectorList-item">
                        <div className="FormFieldPersonChile-container-main-selectorList-item-label">
                            <p className="FormFieldPersonChile-container-main-selectorList-item-label-text">Género</p>
                            <p className="FormFieldPersonChile-container-main-selectorList-item-label-requiered">&nbsp;*</p>
                        </div>
                        <div className="FormFieldPersonChile-container-main-selectorList-item-selectorWrapper">
                            <Selector
                                id='Genero'
                                popularList={[]}
                                list={props.data?.input?.config?.genders}
                                onValueSelected={(a: any) => setGenero(a)}
                                selectedItem={genero?.label}
                                placeholder='Selecciona tu género'
                                deleteSelectedItem={() => setGenero(null)}
                            />
                        </div>
                    </div>
                    <div className="FormFieldPersonChile-container-main-selectorList-item">
                        <div className="FormFieldPersonChile-container-main-selectorList-item-label">
                            <p className="FormFieldPersonChile-container-main-selectorList-item-label-text">Comuna</p>
                            <p className="FormFieldPersonChile-container-main-selectorList-item-label-requiered">&nbsp;*</p>
                        </div>
                        <div className="FormFieldPersonChile-container-main-selectorList-item-selectorWrapper">
                            <SelectorSearcher
                                id='Comuna'
                                list={props.data?.input?.config?.places}
                                onValueSelected={(a: any) => setComuna(a)}
                                selectedItem={comuna?.label}
                                placeholder='Selecciona tu comuna'
                                deleteSelectedItem={() => setComuna(null)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="FormFieldPersonChile-container-main-buttonWrapper">
                <MainButton
                    id='Cotizar'
                    text={props.data?.input?.config?.buttom}
                    onButtonPressed={() => {
                        const data = {
                            "rut" : rut + dv,
                            "fechaNac": Moment(fechaNac, 'DD/MM/YYYY').utcOffset('+0000').startOf('day').toISOString(),
                            "ppsex": genero.value,
                            "pcomuna": comuna.value
                        }
                        handleItemSelected(data)
                    }}
                    disabled={isDisabled()}
                />
            </div>
        </div>
    );
}

export default FormFieldPersonChile;