import { connect } from 'react-redux'
import { useMediaQuery } from 'react-responsive';
import Header from '../../components/generalComponents/header/Header'
import CardSuggest from '../../components/suggestComponent/cardSuggestComponent/CardSuggest';
import { useEffect, useState } from 'react';
import { genericErrorHandler } from '../../util/GenericErrorHandler';
import { getUninsuredItems } from '../../net/Connector';
import axios from '../../actions/instanceActions';
import { store } from '../../index';
import { LOADING } from '../../actions/types';
import { SuggestDTO } from '../../models/suggestItem.model';
import Footer from '../../components/generalComponents/footer/Footer';
import MainButton from '../../components/generalComponents/MainButton/MainButton';
import './SugeridosPage.css'
import { useNavigate } from 'react-router-dom';
import x from '../../assets/icons/X.png';
import { FormattedMessage } from 'react-intl';
import { GetGlobalParams } from '../../util/GetGlobalParams';
import { GetCountryUrl } from '../../util/GetCountryUrl';


let isTabletOrMobile = false;
const SugeridosPage = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })
    const [suggestListUser, setSuggestListUser] = useState<SuggestDTO[]>()
    const { dispatch } = store;
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSugeridosListUser = async () => {
            try {
                dispatch({ type: LOADING, payload: true });
                const response = await axios.get(getUninsuredItems())
                setSuggestListUser(response.data)
            } catch (error) {
                genericErrorHandler(error);
            } finally {
                dispatch({ type: LOADING, payload: false });
            }
        }
        fetchSugeridosListUser();
    }, [])

    return (
        <div>
            <Header
                backgroundColor={isTabletOrMobile ? "var(--white)" : null}
            />
            <div className="mainCotizar-container-titleContainer">
                <p className="mainCotizar-container-titleContainer-title">Productos sugeridos</p>
            </div>
            <div className="mainCotizar-container-titleContainerMask" style={{ marginBottom: 20 }}>
                <div className="mainCotizar-container-titleContainerMask-inner"></div>
            </div>
            <div className='container-card-sugeridos'>
                <div style={{ display: 'flex', flexWrap: 'wrap', 
                              gap: 12, overflowY: isTabletOrMobile ? 'scroll' : 'unset',
                              marginBottom: isTabletOrMobile ? 10 : 'unset'
                              }}>
                    {
                        suggestListUser !== undefined && suggestListUser.length > 0 &&
                            suggestListUser.map((item: SuggestDTO) => {
                                return (
                                    <CardSuggest
                                        key={item.id}
                                        item={item}
                                    />
                                )
                            })
                    }
                </div>

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {
                        !isTabletOrMobile &&
                            <div style={{ width: 427, marginTop: '17vh' }}>
                                <MainButton
                                    id='Iniciar sesion Boton'
                                    text={'sugerir un producto'.toUpperCase()}
                                    onButtonPressed={() => navigate(GetCountryUrl(props.countryId) + 'suggest-product?' + GetGlobalParams())}
                                />
                            </div>
                    }

                </div>
                <div style={{ display: isTabletOrMobile ? 'none' :'flex', justifyContent: 'center', marginTop: '3vh'}}>
                    <p className='help-text-suggest'><FormattedMessage id='title.ayuda.whatsapp'/></p>
                    <p className='help-text-suggest' style={{ marginLeft: 10, cursor: 'pointer', color:'var(--primary500)'}}
                        onClick={() => navigate(GetCountryUrl(props.countryId) + 'contacto?' + GetGlobalParams())}
                    ><FormattedMessage id='title.ayuda.whatsappCont'/></p>
                </div>

                {
                    isTabletOrMobile &&
                    <div 
                    onClick={() => navigate(GetCountryUrl(props.countryId) + 'suggest-product?' + GetGlobalParams())}
                    style={{ width: 60 , height: 60, borderRadius: '50%', backgroundColor: 'var(--primary500)', position: 'fixed', right: 10, bottom: 0, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                               <img src={x} style={{ width: 30, height: 30}}/>
                    </div>
                }

            </div>
            {
                isTabletOrMobile ?
                    <div style={{ display: 'none' }}>
                        <Footer />
                    </div>
                    :
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '3vh' }}>
                        <Footer />
                    </div>
            }
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        countryId: state.general.countryId,
        sugeridosListUser: state.sugerir.sugeridosListUser
    };
};

export default connect(mapStateToProps, null)(SugeridosPage)
