// lib
import React from "react";
import { useMediaQuery } from "react-responsive";
import './PaymentCbu.css'


let isTabletOrMobile = false;

const PaymentCbu = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' });

    return (
        <div className="paymentCbu-container">
            <div className="paymentCbu-container-card">
                <span className="material-symbols-outlined" style={{ fontSize: 24, color: 'var(--primary500)' }}>account_balance</span>
                <p className="regular" style={{ color: 'var(--primary500)' }}>CBU ************</p>
            </div>
            <span className="material-symbols-outlined" style={{ fontSize: 24, color: 'var(--statusSuccessPrimary)' }}>done</span>
        </div>
    )
}

export default PaymentCbu;

