import {
    LOADING,
} from './types';
import {
    netGenericForm,
} from '../net/Connector'
import axios from './instanceActions'
import { genericErrorHandler } from '../util/GenericErrorHandler';
import { GetCountryUrl } from '../util/GetCountryUrl';

export const getGenericForm = (type: any, setFormList: any) => {
    return (dispatch: any) => {
        dispatch({ type: LOADING, payload: true });
        axios.get(netGenericForm(type))
            .then((response) => {
                setFormList(response.data)
                dispatch({ type: LOADING, payload: false });
            })
            .catch((error) => {
                dispatch({ type: LOADING, payload: false });
                genericErrorHandler(error)
            })
    }
}

export const postGenericForm = (type: any, data: any, reCaptcha: any, recaptchaRef: any, setReCaptcha: any, navigate: any, navigateTo: string, countryId: number, setContactSuccess: any) => {
    return (dispatch: any) => {
        dispatch({ type: LOADING, payload: true });
        axios({
            url: netGenericForm(type),
            method: 'post',
            headers: { 'captcha': reCaptcha },
            data: data,
        })
            .then((response) => {
                if (setContactSuccess) setContactSuccess(true)
                else navigate(GetCountryUrl(countryId) + navigateTo)
                dispatch({ type: LOADING, payload: false });
            })
            .catch((error) => {
                recaptchaRef.current.reset();
                setReCaptcha(null);
                dispatch({ type: LOADING, payload: false });
                genericErrorHandler(error)
            })
    }
}