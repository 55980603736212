import React, { useEffect, useState } from 'react'
import './Soap.css'
import Header from '../../../components/generalComponents/header/Header';
import Footer from '../../../components/generalComponents/footer/Footer';
import MainButton from '../../../components/generalComponents/MainButton/MainButton';
import hdi from '../../../assets/images/hdiimg.png';
import bci from '../../../assets/images/bciimg.png';
import hdiFrame from '../../../assets/images/hdi_frame.png';
import bciFrame from '../../../assets/images/bci_frame.png';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

let isTabletOrMobile = false;
const Soap = () => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 38.5em)' });
  const [scrollPosition, setScrollPosition] = useState(0);

  const navigate = useNavigate();

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  }, [])

  return (
    <div className="soap-container">
        <Header home colored fixed trans={scrollPosition > 30} special={!isTabletOrMobile} />      
        <div className="soap-container-main">
          <div className="soap-container-main-top">
            <p className="soap-container-main-top-title">Seguro obligatorio 2022</p>
          </div>
          <div className="soap-container-main-content">
            <p className="soap-container-main-content-title">Estás a un click de contratar tu SOAP 2022 100% online y de forma rápida.</p>
            <p className="soap-container-main-content-subTitle">Accede ahora desde tu celular o computadora y asegura tu automóvil, moto o camioneta.</p>
            <div className="soap-container-main-content-list">
              <div className="soap-container-main-content-list-hdi">
                <img src={hdiFrame} className="soap-container-main-content-list-hdi-frameImg"/>
                <div className="soap-container-main-content-list-hdi-main">
                  <img src={hdi} className="soap-container-main-content-list-hdi-main-img"/>
                  <div className="soap-container-main-content-list-hdi-main-content">
                    <div className="soap-container-main-content-list-hdi-main-content-value">
                      <p className="soap-container-main-content-list-hdi-main-content-value-text">Desde</p>
                      <p className="soap-container-main-content-list-hdi-main-content-value-number">$5.290</p>
                    </div>
                    <p 
                      className="soap-container-main-content-list-hdi-main-content-button"
                      onClick={() => window.open('https://soap.hdi.cl/amsa/cotizador-web/soap/home.ashx?key=20220200_77682370_soap32202212471805_3752&intrut=77682370&local=36&venid=0&next=home.aspx', "WindowName", "noopener")}
                    >CONTRATAR</p>
                  </div>
                </div>
              </div>
              <div className="soap-container-main-content-list-bci">
                <img src={bciFrame} className="soap-container-main-content-list-bci-frameImg"/>
                <div className="soap-container-main-content-list-bci-main">
                  <img src={bci} className="soap-container-main-content-list-bci-main-img"/>
                  <div className="soap-container-main-content-list-bci-main-content">
                    <div className="soap-container-main-content-list-bci-main-content-value">
                      <p className="soap-container-main-content-list-bci-main-content-value-text">Desde</p>
                      <p className="soap-container-main-content-list-bci-main-content-value-number">$5.300</p>
                    </div>
                    <p 
                      className="soap-container-main-content-list-bci-main-content-button"
                      onClick={() => window.open('https://soapweb.bciseguros.cl/web/DatosVehiculo?Convenios=872697D16541560DA3F75FA7E8D5A343', "WindowName", "noopener")}
                    >CONTRATAR</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="soap-container-main-content-buttonWrapper">
              <MainButton
                id='Volver'
                text='VOLVER'
                onButtonPressed={() => navigate(-1)}
                altStyle2
              />
            </div>
          </div>
        </div>
      <Footer />
    </div>
  )
}

export default Soap;