import { useSelector } from 'react-redux';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { GetCountryUrl } from '../../util/GetCountryUrl';

const ProtectedRoute = () => {
    const countryId = useSelector((state: any) => state.general.countryId);
    const userEmailValidated = useSelector((state: any) => state.user.userData);
    const location = useLocation();

    return (
        (userEmailValidated && userEmailValidated?.emailValidated) ? 
            (
                <Outlet/>
            )
            :
            !userEmailValidated ?
                (
                    <Navigate to={GetCountryUrl(countryId) + "login?redirectTo=" + location.pathname + location.search} />
                )
                :
                (
                    <Navigate to={GetCountryUrl(countryId) + "verificacion"} />
                )

    )
};

export default (ProtectedRoute);