import { forwardRef, useEffect, useImperativeHandle } from "react";
import { PaymentMethodComponentProps, PaymentMethodComponentRef } from "../PaymentMethodComponent";

const PaymentMethodNoDataConfigComponent = forwardRef<PaymentMethodComponentRef, PaymentMethodComponentProps>((props, ref) => {

    useEffect(() => {
        props.onChangeStatus(true);
    }, []);

    return (
        <></>
    )
});

export { PaymentMethodNoDataConfigComponent };