import React, { useState } from 'react'
import './Insurer.css'
import { useMediaQuery } from 'react-responsive'

let isTabletOrMobile = false;
const Insurer = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

    return (
        <div onClick={() => props.onChange()} className="insurer-container">
            <div className="insurer-container-top">
                {props.favorite && 
                    <div className="insurer-container-top-star">
                        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.5616 4.55671C12.5182 4.42327 12.4029 4.32603 12.2641 4.30588L8.36437 3.73919L6.6203 0.205426C6.55824 0.0796298 6.43011 0 6.28984 0C6.14955 0 6.02144 0.0796298 5.95936 0.205426L4.21522 3.73919L0.315543 4.30588C0.17675 4.32603 0.061396 4.42328 0.0180554 4.55669C-0.0253098 4.69012 0.0108565 4.83658 0.111321 4.93449L2.93308 7.68515L2.26705 11.5692C2.24332 11.7074 2.30017 11.8472 2.41366 11.9296C2.47786 11.9763 2.5539 12 2.63031 12C2.68898 12 2.74785 11.986 2.80176 11.9577L6.28982 10.1238L9.77773 11.9576C9.90193 12.0229 10.0524 12.0121 10.1659 11.9296C10.2793 11.8472 10.3362 11.7074 10.3125 11.5691L9.64626 7.68515L12.4683 4.93447C12.5688 4.83658 12.605 4.69012 12.5616 4.55671Z" fill="#DF6534"/>
                        </svg>
                    </div>
                }
                {props.img ? 
                    <img className="insurer-container-top-img" src={props.img}/>
                    :
                    <p className="insurer-container-top-title">MÁS POPULARES</p> 
                }

            </div>
            <div 
                className="insurer-container-bot" 
                style={{
                    backgroundColor: props.selected ? 'var(--primary500)' : 'var(--gray100)',
                    height: props.selected ? 80 : 60
                }}
            >
                <div className="insurer-container-bot-value">
                    <p className="insurer-container-bot-value-desde" style={{color: props.selected ? 'var(--gray50)' : 'var(--primary500)'}}>desde&nbsp;</p>
                    <p className="insurer-container-bot-value-value" style={{color: props.selected ? 'var(--gray50)' : 'var(--primary500)'}}>{props.value}</p>
                </div>
                {props.selected && <p className="insurer-container-bot-count" style={{color: props.selected ? 'var(--gray50)' : 'var(--primary500)'}}>{props.count === 1 ? props.count + ' COBERTURA' : props.count + ' COBERTURAS'}</p>}
            </div>
        </div>
    )
}

export default Insurer;