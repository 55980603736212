import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { useMediaQuery } from "react-responsive";
import CustomTextInput from "../../components/generalComponents/customTextInput/CustomTextInput";
import Header from "../../components/generalComponents/header/Header";
import MainButton from "../../components/generalComponents/MainButton/MainButton";
import { recoveryPassReciveId } from '../../actions';
import { useState } from "react";
import './PasswordRecovery.css'
import { useNavigate, useLocation } from "react-router-dom";
import Footer from "../../components/generalComponents/footer/Footer";


let isTabletOrMobile = false;
const PasswordRecovery = (props: any) => {
    const [mail, setMail] = useState<string>('');
    const navigate = useNavigate();
    const location: any = useLocation();
    const redirectTo: string = location.state?.link ? location.state.link : '';

    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' });

    const validateEmail = (e: string) => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(e).toLowerCase())) {
          return false;
        } return true
    }

    return (
        <div className="passwordRecovery-container">
            <Header/>
            <div className="passwordRecovery-container-title" style={{ alignItems: 'center' }}>
                <h3 className="passwordRecovery-container-title-text bold"><FormattedMessage id="page.recovery.password.title" defaultMessage="" /></h3>
            </div>
            <div className="passwordRecovery-container-main">
                <h6 className='passwordRecovery-container-main-title bold'><FormattedMessage id="page.recovery.password.container.title" defaultMessage="" /></h6>
                <CustomTextInput
                    isMail
                    width='100%'
                    label="E-mail"
                    value={mail}
                    changeText={(e: string) => setMail(e)}
                    placeholder="nombre@ejemplo.com"
                    testID='email'
                    success={validateEmail(mail)}
                    error={mail.length > 0 ? !validateEmail(mail) : false}
                    errorMessage='E-mail inválido'
                />
                <div className="passwordRecovery-container-main-button">
                    <MainButton
                        disabled={!validateEmail(mail)}
                        onButtonPressed={() => props.recoveryPassReciveId(mail, props.countryId, redirectTo, navigate)}
                        text={"continuar".toUpperCase()}
                        width='100%'
                        altStyle10
                    />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state:any) => {
    return {
        countryId: state.general.countryId,
        recoverySuccess: state.login.recoverySuccess
    };
};

export default connect(mapStateToProps, {
    recoveryPassReciveId
})(PasswordRecovery);
