import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';
import Header from '../../components/generalComponents/header/Header';
import Footer from '../../components/generalComponents/footer/Footer';
import CategoriaButton from '../../components/cotizarComponents/CategoriaButton/CategoriaButton';
import {
  fetchCategoriaList,
  changeCountryId,
  categoriaSelectedChange,
  categoriaQuestion,
  answerQuestion,
  getQuoteQuestions,
  answerChanged,
  emptyQuestions,
  deleteQuestion
} from '../../actions'
import { useNavigate, useSearchParams, Link } from 'react-router-dom'
import './Cotizar.css'
import { FormattedMessage, useIntl } from 'react-intl';
import HistoryQuestions from '../../components/cotizarComponents/historyQuestions/HistoryQuestions';
import { useMediaQuery } from 'react-responsive'
import { GetGlobalParams } from '../../util/GetGlobalParams';
import { GetCountryUrl } from '../../util/GetCountryUrl';
import {Helmet} from "react-helmet";
import GetGlobalForm from '../../components/generalComponents/GlobalForms/getGlobalForm';
import automovilLottie from '../../assets/lotties/automovil.json'
import Lottie from 'react-lottie';
let isTabletOrMobile = false;

const Cotizar = (props: any) => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' });

  const [formAnswer, setFormAnswer] = useState<any>(null);

  const [loadingOffer, setLoadingOffer] = useState<boolean>(false);

  const [originalParams, setOriginalParams] = useState<any>(null);
  const [admin, setAdmin] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate()

  useEffect(() => {
    if (props.cotizarQuestion?.incompleteQuestions.length === 0) {
      props.deleteQuestion(props.categoriaSelected, props.cotizarQuestion.id, props.cotizarQuestion.previousQuestions[props.cotizarQuestion.previousQuestions.length - 1].step)
    }
  }, [props.cotizarQuestion])

  useEffect(() => {
    props.fetchCategoriaList()
  }, [props.countryId])

  useEffect(() => {
    handleUrlParams()
    setOriginalParams(searchParams.toString())
  }, [])

  const handleUrlParams = () => {
    let categoryId: any = searchParams.get("cId")
    let quote: any = searchParams.get("quote")
    let admin: any = searchParams.get("admin")
    categoryId = parseInt(categoryId)
    if (admin) {
      setAdmin(true)
    }
    if (categoryId && quote) {
      props.categoriaSelectedChange(categoryId)
      props.getQuoteQuestions(categoryId, quote)
    } else {
      props.emptyQuestions()
      props.categoriaSelectedChange(-1)
    }
  }

  const handleContinuarButton = (data: any) => {
    if (props.cotizarQuestion) {
      props.answerQuestion(props.categoriaSelected, props.cotizarQuestion.id, data, originalParams, navigate, props.countryId, admin, setLoadingOffer)
    } else {
      props.categoriaQuestion(data, navigate, props.countryId, admin)
    }
  }

  return (
    <div className="cotizar-container" >
      <Helmet>
        <html lang={useIntl().formatMessage({ id: 'meta.cotizar.html' })}/>
        <title>{useIntl().formatMessage({ id: 'meta.cotizar.title'}, { name: props.categorias?.filter((a: any) => a.category.id === props.categoriaSelected)[0]?.category.name } )}</title>
        <meta name="description" content={useIntl().formatMessage({ id: 'meta.cotizar.description' })} />
        <meta property='keywords' content={useIntl().formatMessage({ id: 'meta.cotizar.keywords' })} />
      </Helmet>
      {!admin && <Header colored />}
      {loadingOffer ? 
        <div className="cotizar-container-mainLoading">
          <div className="cotizar-container-mainLoading-lottieWrapper">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: automovilLottie
              }}
              height={isTabletOrMobile ? 300 : 400}
              width={isTabletOrMobile ? 300 : 400}
            />
          </div>
          <p className="cotizar-container-mainLoading-text">Vamos en camino a cotizar tu seguro ideal</p>
          <p className="cotizar-container-mainLoading-subText">Puede tardar un minuto...</p>
        </div>
        :
        <>
          <div style={{ minHeight: props.cotizarQuestion?.incompleteQuestions[0]?.input?.type === 'select' || props.cotizarQuestion?.incompleteQuestions[0]?.input?.type === 'product-list' || props.cotizarQuestion?.incompleteQuestions[0]?.input?.type === 'carLicence' ? 550 : 'unset' }} className="cotizar-container-main">
            <div className="cotizar-container-main-left">
              <HistoryQuestions
                firstQuestion={<FormattedMessage id="page.cotizar.asegurarQuestion" defaultMessage="" />}
                firstAnswer={props.cotizarQuestion && props.categorias && props.categoriaSelected !== -1 ? props.categorias.filter((a: any) => a.category.id === props.categoriaSelected)[0].category.name : null}
                currentQuestion={props.cotizarQuestion ? props.cotizarQuestion.incompleteQuestions[0] : null}
                questionList={props.cotizarQuestion ? props.cotizarQuestion.previousQuestions : null}
                onEditPressed={(a: any) => {
                  if (a === -1) {
                    if(admin) {
                      const adminN = admin ? '&admin=True' : ''
                      navigate(GetCountryUrl(props.countryId) + 'cotizar?cId=' + props.categoriaSelected + adminN + GetGlobalParams())
                      window.location.reload();
                    } else {
                      navigate(GetCountryUrl(props.countryId) + 'inicio')
                    }
                  } else {
                    props.deleteQuestion(props.categoriaSelected, props.cotizarQuestion.id, a)
                  }
                }}
                goBackPressed={() => {
                  if (props.cotizarQuestion?.previousQuestions?.length === 0 || !props.cotizarQuestion?.previousQuestions) {
                    if(admin) {
                      const adminN = admin ? '&admin=True' : ''
                      navigate(GetCountryUrl(props.countryId) + 'cotizar?' + adminN + GetGlobalParams())
                      window.location.reload();
                    } else {
                      navigate(GetCountryUrl(props.countryId) + 'inicio')
                    }
                  } else {
                    props.deleteQuestion(props.categoriaSelected, props.cotizarQuestion?.id, props.cotizarQuestion?.previousQuestions[props.cotizarQuestion?.previousQuestions?.length - 1]?.step)
                  }
                }}
              />
            </div>
            {props.cotizarQuestion ?
              <div className="cotizar-container-main-right">
                {props.cotizarQuestion.incompleteQuestions.length !== 0 &&
                  <div>
                    {isTabletOrMobile && 
                      <div 
                        onClick={() => {
                          if (props.cotizarQuestion?.previousQuestions?.length === 0 || !props.cotizarQuestion?.previousQuestions) {
                            const adminN = admin ? '&admin=True' : ''
                            navigate(GetCountryUrl(props.countryId) + 'cotizar?' + adminN + GetGlobalParams())
                            window.location.reload();
                          } else {
                            props.deleteQuestion(props.categoriaSelected, props.cotizarQuestion?.id, props.cotizarQuestion?.previousQuestions[props.cotizarQuestion?.previousQuestions?.length - 1]?.step)
                          }
                        }} 
                        className="historyQuestion-container-goBack"
                        style={{marginLeft: 20}}
                      >
                        <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.5215 13.3761L0.5835 7.48735C0.486834 7.40473 0.4175 7.31927 0.3755 7.23099C0.3335 7.1427 0.3125 7.0513 0.3125 6.95679C0.3125 6.86228 0.3335 6.77088 0.3755 6.6826C0.4175 6.59431 0.486834 6.50886 0.5835 6.42623L7.5425 0.519611C7.7505 0.342474 8.00383 0.253906 8.3025 0.253906C8.60117 0.253906 8.8545 0.348417 9.0625 0.537438C9.28517 0.714008 9.3895 0.931891 9.3755 1.19109C9.3615 1.45085 9.2505 1.66902 9.0425 1.84559L3.0215 6.95679L9.0625 12.0858C9.25717 12.2505 9.3545 12.4656 9.3545 12.731C9.3545 12.9964 9.25717 13.2115 9.0625 13.3761C8.8545 13.5533 8.59783 13.6419 8.2925 13.6419C7.9865 13.6419 7.7295 13.5533 7.5215 13.3761Z" fill="var(--primary500)"/>
                        </svg>
                        <p className="historyQuestion-container-goBack-text">Volver</p>
                      </div>
                    }
                    <p className="cotizar-container-main-right-questionText">{props.cotizarQuestion.incompleteQuestions[0].display.name}</p>
                    {props.cotizarQuestion?.incompleteQuestions?.map((a: any, index: any) => (
                      <GetGlobalForm
                        parent='Cotizar'
                        key={a?.id}
                        item={a}
                        currentAnswer={formAnswer} 
                        next={handleContinuarButton} 
                        setLocalAnswers={setFormAnswer}
                      />
                    ))}
                  </div>
                }
              </div>
              :
              <div className="cotizar-container-main-right">
                <div>
                  <p className="cotizar-container-main-right-questionText"><FormattedMessage id="page.cotizar.asegurarQuestion" defaultMessage="" /></p>
                  <div className="cotizar-container-main-right-categoryList">
                    {props.categorias && props.categorias.map((a: any, index: Number) => (
                      <CategoriaButton
                        key={index}
                        id={a.name + ' boton'}
                        commingSoon={a.coming_soon}
                        name={a.name}
                        image={a.category.imageIcon}
                        selected={a.category.id === props.categoriaSelected}
                        categoryPressed={() => {
                          props.categoriaSelectedChange(a.category.id)
                          handleContinuarButton(a.category.id)
                        }}
                      />
                    ))}
                  </div>
                </div>
              </div>
            }
          </div>
          {!admin && <p className="cotizar-container-helpText" dangerouslySetInnerHTML={{__html: props.cotizarQuestion?.incompleteQuestions[0]?.helpText}}></p>}
        </>
      }
      {!admin && <Footer
        rif={props.questionRef}
        categoryReff={props.categoryRef}
      />}
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    categorias: state.cotizar.categorias,
    categoriaSelected: state.cotizar.categoriaSelected,
    cotizarQuestion: state.cotizar.cotizarQuestion,
    countryId: state.general.countryId,
    categoryRef: state.general.categoryRef,
    questionRef: state.general.questionRef
  };
};

export default connect(mapStateToProps, {
  fetchCategoriaList,
  changeCountryId,
  categoriaSelectedChange,
  categoriaQuestion,
  answerQuestion,
  getQuoteQuestions,
  answerChanged,
  emptyQuestions,
  deleteQuestion
})(Cotizar);