
import React from 'react'
import { useMediaQuery } from 'react-responsive'
import './OtherSection.css'
import MainButton from '../../../components/generalComponents/MainButton/MainButton';
import bannerTerciarioDesk from '../../../assets/images/bannerTerciarioDesk.png';
import bannerTerciarioMobi from '../../../assets/images/bannerTerciarioMobi.png';

let isTabletOrMobile = false;
const OtherSection = (props: any) => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 38.5em)' });

  return (
    <div className='otherSection-container'>
      <img className="otherSection-container-img" src={isTabletOrMobile ? bannerTerciarioMobi : bannerTerciarioDesk} />
      <div className='otherSection-container-content'>
        <div className='otherSection-container-content-text'>
          <h2 className='otherSection-container-content-text-title bold'>Estás en buenas manos</h2>
          <h4 className='otherSection-container-content-text-subtitle regular'>Juntos crearemos la cobertura perfecta. ¿Comenzamos?</h4>
        </div>
        <div className='otherSection-container-content-botton'>
          <MainButton
            id='VER COBERTURAS'
            fill
            color='var(--secondary500)'
            text='VER COBERTURAS'
            onButtonPressed={() => props.buttonPress()}
          />
        </div>
      </div>
    </div>
  );
}

export default OtherSection;