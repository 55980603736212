import { useIntl } from "react-intl";
import { Helmet } from "react-helmet";

const GetMetaTags = (props: any) => {

    const intl = useIntl();
    
    const metaHtmlExists: any = intl.messages['meta.'+ props.page + '.html'];
    const metaTitleExists: any = intl.messages['meta.'+ props.page + '.title'];
    const metaDescriptionExists: any = intl.messages['meta.'+ props.page + '.description'];
    const metaKeywordsExists: any = intl.messages['meta.'+ props.page + '.keywords'];

    return (
        <Helmet>
            <html lang={metaHtmlExists}/>
            <title>{metaTitleExists}</title>
            <meta name="description" content={metaDescriptionExists} />
            <meta property='keywords' content={metaKeywordsExists} />
        </Helmet>
    )
}; 

export default GetMetaTags;