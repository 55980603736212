import React from 'react'
import './Headers.css'

const Headers = (props: any) => {

    return (
        <div className='headers-container'>
            <div className='headers-container-list'>
                {props.list?.map((a: any, index: any) => (
                    <div 
                        key={index}
                        className='headers-container-list-item'
                        onClick={() => a.click()}
                    >
                        <p className='headers-container-list-item-text bold large' style={{color: a.name === props.selected ? 'var(--gray700)' : 'var(--gray300)'}}>{a.name}</p>
                        <div 
                            className='headers-container-list-item-underline'
                            style={{backgroundColor: a.name === props.selected ? 'var(--primary500)' : 'transparent'}}
                        />
                    </div>
                ))}
            </div>
            <div className='headers-container-underline'></div>
        </div>
    );

}

export default Headers;