import React, {useEffect, useState} from 'react'
import './AdicionalComponent.css'

const AdicionalComponent = (props: any) => {

    return (
        <div 
            className="adicionalComponent-container"
            style={{
                backgroundColor: props.selected ? 'var(--primary500)' : 'var(--white)',
            }}
        >
            <div className="adicionalComponent-container-left">
                <div 
                    id={props.id}
                    onClick={() => props.adicionalClicked()}
                    className="adicionalComponent-container-left-switch"
                    style={{
                        backgroundColor: props.selected ? 'var(--primary700)' : '#ECECEC',
                    }}
                >
                    <div 
                        className="adicionalComponent-container-left-switch-circle"
                        style={{transform: props.selected  ? `translateX(${34}px)` : `translateX(${4}px)`}}
                    ></div>
                </div>
                <p 
                    className="adicionalComponent-container-left-price"
                    style={{
                        color: props.selected ? 'var(--white)' : 'var(--primary500)',
                    }}
                >{props.price}</p>
            </div>
            <div className="adicionalComponent-container-right">
                <p 
                    className="adicionalComponent-container-right-titulo"
                    style={{
                        color: props.selected ? 'var(--white)' : 'var(--primary500)',
                    }}
                >{props.titulo}</p>
                <p 
                    className="adicionalComponent-container-right-description"
                    style={{
                        color: props.selected ? 'var(--white)' : 'var(--gray300)',
                    }}
                >{props.description}</p>
            </div> 
        </div>
    );
}

export default AdicionalComponent;