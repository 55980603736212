import React, {useState, useEffect} from 'react'
import './PaymentCardComponent.css'
import { useMediaQuery } from 'react-responsive'
import { ReactComponent as PayPal } from '../../../assets/images/svgs/paypal.svg';
import { ReactComponent as CreditCard } from '../../../assets/images/svgs/creditCard.svg';

let isTabletOrMobile = false;
const PaymentCardComponent = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

    return (
        <div 
            className="paymentCardComponent-container"
            style={{
                opacity: props.data.comingSoon ? 0.6 : 1
            }}
            onClick={() => props.data.comingSoon ? {} : props.paymentSelected()}
        >
            <img src={props.data?.image}/>
            <p className="paymentCardComponent-container-name">{props.data.name}</p>
        </div>
    );
}

export default PaymentCardComponent;