import React, { useEffect, useState } from 'react'
import './AutomovilExito.css'
import Header from '../../../components/generalComponents/header/Header';
import Footer from '../../../components/generalComponents/footer/Footer';
import MainButton from '../../../components/generalComponents/MainButton/MainButton';
import automovilLottie from '../../../assets/lotties/automovil.json'
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';
import { GetCountryUrl } from '../../../util/GetCountryUrl';
import {store} from '../../../index';

const AutomovilExito = () => {

  const [scrollPosition, setScrollPosition] = useState(0);

  const countryId = store.getState().general.countryId

  const navigate = useNavigate();

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  }, [])

  return (
    <div className="automovilExito-container">
        <Header home colored fixed trans={scrollPosition > 30} special />      
        <div className="automovilExito-container-main">
          <div className="automovilExito-container-main-top">
            <p className="automovilExito-container-main-top-title">Seguro de automóvil</p>
          </div>
          <div className="automovilExito-container-main-content">
            <div className="automovilExito-container-main-content-lottieWrapper">
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: automovilLottie
                }}
                height={400}
                width={400}
              />
            </div>
            <p className="automovilExito-container-main-content-title">Vamos en camino a cotizar tu seguro ideal</p>
            <p className="automovilExito-container-main-content-subTitle">En breve te enviaremos la cotización por email.<br/>¡Muchas gracias!</p>
            <div className="automovilExito-container-main-content-buttonWrapper">
              <MainButton
                id='Volver'
                text='VOLVER AL HOME'
                onButtonPressed={() => navigate(GetCountryUrl(countryId) + 'inicio')}
              />
            </div>
          </div>
        </div>
      <Footer />
    </div>
  )
}

export default AutomovilExito;
