import React from 'react'
import './CategoriaButton.css'
import { FormattedMessage } from 'react-intl';
import Skeleton from 'react-loading-skeleton';

const CategoriaButton = (props: any) => {
 
  return (
    <div 
      className="categoriaButton-container"
      onClick={() => {if(!props.commingSoon) props.categoryPressed()}}
      id={props.id}
    > 
      <div className="categoriaButton-container-imageContainer">
        <img className="categoriaButton-container-imageContainer-image" src={props.image} alt=""/>
      </div>
      <h6 className='container-container-text bold'>{props.name.toUpperCase()}</h6>
    </div>
  );

} 
 
export default CategoriaButton;