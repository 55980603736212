
import React from 'react'
import './DemoTipografiaSelector.css'

const DemoTipografiaSelector = (props: any) => {

  return (
    <div className="demoTipografiaSelector-container">
        <p>Seleccione la tipografia</p>
        <div className="demoTipografiaSelector-container-list">
            {props.tipografiaList?.map((a: any) => (
                <div className="demoTipografiaSelector-container-list-item">
                    <p 
                        className="demoTipografiaSelector-container-list-item-text" 
                        onClick={() => props.setTipografia(a.id)}
                        style={{ fontFamily: a.tipo}}
                    >{a.tipo}</p>
                    {props.tipografiaSelected === a.id && <span className="material-symbols-outlined" style={{ fontSize: 20 }}>done</span>}
                </div>
            ))}
        </div>
    </div >
  );
}

export default DemoTipografiaSelector;