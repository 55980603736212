import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux';
import './Checkout.css'
import Header from '../../components/generalComponents/header/Header';
import {
  changeCountryId,
  checkOutOfferById,
  loginUser,
  registerUser,
  getUserAuth,
  checkOutModifyStep,
  userAccessTokenSet,
  changeStep,
  setAccessTokenAux,
  emptyGlobalFormAnswer
} from '../../actions'
import { useMediaQuery } from 'react-responsive'
import CheckOutLogin from '../../components/checkoutComponents/CheckOutLogin/CheckOutLogin';
import CheckOutProfile from '../../components/checkoutComponents/CheckOutProfile/CheckOutProfile';
import CheckOutPayment from '../../components/checkoutComponents/CheckOutPayment/CheckOutPayment';
import CheckOutProduct from '../../components/checkoutComponents/CheckOutProduct/CheckOutProduct';
import Cookies from 'universal-cookie';
import CheckOutResume from '../../components/checkoutComponents/CheckOutResume/CheckOutResume';
import { FormattedMessage, useIntl } from 'react-intl';
import { GetGlobalParams } from '../../util/GetGlobalParams';
import { GetCountryUrl } from '../../util/GetCountryUrl';

let isTabletOrMobile = false;
const Checkout = (props: any) => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

  const location: any = useLocation();

  const [originalParams, setOriginalParams] = useState<any>(null)
  const [admin, setAdmin] = useState<boolean>(false)
  const [currentStepString, setCurrentStepString] = useState<any>(null)
  const [hasToLogin, setHasToLogin] = useState<boolean>(false)
  const [checkoutId, setCheckoutId] = useState<string>();
  const [presetEmail, setPresetEmail] = useState<string>('');
  const [newUser, setNewUser] = useState<any>(location.state?.newUser)

  const dispatch = useDispatch();
  const intl = useIntl();

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    handleUrlParams()
    setOriginalParams(searchParams.toString())
  }, [])

  useEffect(() => {
    if (props.checkOutData) {
      const filteredSteps = props.checkOutData.steps.filter(a => a.status === "PENDING")
      if (filteredSteps) {
        setCurrentStepString(filteredSteps[0])
      }
    }
  }, [props.checkOutData])

  useEffect(() => {
    scrollToTop()
  }, [currentStepString])

  const handleUrlParams = () => {
    let cookiesUA = new Cookies();
    cookiesUA = cookiesUA.get('userAccessToken')
    let checkoutId: any = searchParams.get("checkoutId")
    let presetEmailParam: any = searchParams.get("email")
    let admin: any = searchParams.get("admin")
    if (admin) {
      setAdmin(true)
    }
    if (presetEmailParam) {
      setPresetEmail(atob(decodeURIComponent(presetEmailParam)))
    }
    if (cookiesUA) {
      props.userAccessTokenSet(cookiesUA)
      if (props.userData?.emailValidated) {
        props.getUserAuth();
      }
    }
    if (props.step === '') {
      if (checkoutId) {
        props.checkOutOfferById(checkoutId)
        setCheckoutId(checkoutId);
      }
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };



  const renderCheckOutStep = () => {
    if (hasToLogin) {
      return (
        <CheckOutLogin
          presetEmail={presetEmail}
          originalParams={originalParams}
          checkoutId={checkoutId}
          loginUser={(emailLogin: string, contraseñaLogin: string) => props.loginUser(emailLogin, contraseñaLogin, false, props.countryId, '', checkoutId, navigate, admin, setHasToLogin)}
          registerUser={(nombre: string, apellido: string, emailRegister: string, contraseñaRegister: string) => props.registerUser(nombre, apellido, emailRegister, contraseñaRegister, props.countryId, originalParams, navigate)}
          backToRegister={() => setHasToLogin(false)}
          setHasToLogin={setHasToLogin}
        />
      )
    } else {
      switch (currentStepString?.type) {
        case "personalInfo":
          return (
            <CheckOutProfile
              checkOutData={props.checkOutData}
              reLog={() => setHasToLogin(true)}
              newUser={newUser}
              navigate={navigate}
            />
          )
        case "customerProduct":
            return (
              <CheckOutProduct
                checkOutData={props.checkOutData}
                promoCodeError={props.promoCodeError}
                isTabletOrMobile={isTabletOrMobile}
              />
            )
        case "paymentInfo":
          return (
            <CheckOutPayment admin={admin}/>
          )
        case "resume":
          return (
            <CheckOutResume
              otroMedioPagoPressed={() => {
                props.checkOutModifyStep(props.checkOutData, 'paymentInfo', 'PENDING')
              }}
              originalParams={originalParams}
            />
          )
        default:
          return null;
      }
    }
  }

  var index = props.checkOutData?.steps.findIndex(function(person) {
    return person.type === currentStepString?.type;
  });

  const renderCheckOutTitle = () => {
    if(hasToLogin) {
      return (
        <div className="checkout-container–topContainer-left">
          <b className="checkout-container–topContainer-left-pasoText medium regular">Paso {index + 1} de {props.checkOutData?.steps?.length}</b>
          <h3 className="checkout-container–topContainer-left-titleText bold" dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: 'page.checkOut.titlePersonaleInfoLogin' })}}></h3>
        </div>
      )
    }
    switch (currentStepString?.type) {
      case "personalInfo":
        return (
          <div className="checkout-container–topContainer-left">
            <b className="checkout-container–topContainer-left-pasoText medium regular">PASO {index + 1} DE {props.checkOutData?.steps?.length}</b>
            <h3 className="checkout-container–topContainer-left-titleText bold" dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: 'page.checkOut.titlePersonaleInfo' })}}></h3>
          </div>
        )
      case "customerProduct":
        return (
          <div className="checkout-container–topContainer-left">
            <b className="checkout-container–topContainer-left-pasoText medium regular">PASO {index + 1} DE {props.checkOutData?.steps?.length}</b>
            <h3 className="checkout-container–topContainer-left-titleText"><FormattedMessage id="page.checkOut.titleCustomerProduct" defaultMessage="" /></h3>
          </div>
        )
      case "paymentInfo":
        return (
          <div className="checkout-container–topContainer-left">
            <b className="checkout-container–topContainer-left-pasoText medium regular">PASO {index + 1} DE {props.checkOutData?.steps?.length}</b>
            <h3 className="checkout-container–topContainer-left-titleText"><FormattedMessage id="page.checkOut.titlePaymentInfo" defaultMessage="" /></h3>
          </div>
        )
      case "resume":
        return (
          <div className="checkout-container–topContainer-left">
            <b className="checkout-container–topContainer-left-pasoText  medium regular"><FormattedMessage id="page.checkOut.pasoResume" defaultMessage="" /></b>
            <h3 className="checkout-container–topContainer-left-titleText"><FormattedMessage id="page.checkOut.titleResume" defaultMessage="" /></h3>
          </div>
        )
      default:
        return null;
    }
  }

  return (
    <div className="checkout-container">
      {!admin && <Header
        logout={props.userAccessToken}
        logoutPresse={() => {
          props.userAccessTokenSet(null);
          dispatch(setAccessTokenAux(null));
          dispatch({ type: 'USER_DATA', payload: null });
          navigate(GetCountryUrl(props.countryId) + 'inicio?' + GetGlobalParams())
        }}
      />}
      <div className="checkout-container–topContainer"> {/* Titulo y pasos */}
        {renderCheckOutTitle()}
      </div>
      <div className="checkout-container–main"> {/* Componente del paso */}
        {renderCheckOutStep()}
      </div>
      {props.checkOutConfirmStart && <div className="checkout-container-blockScreen"></div>}
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    countryId: state.general.countryId,
    checkOutData: state.checkOut.checkOutData,
    checkOutProductRequerements: state.checkOut.checkOutProductRequerements,
    userData: state.user.userData,
    cvvTextInput: state.checkOut.cvvTextInput,
    promoCodeError: state.checkOut.promoCodeError,
    userAccessToken: state.general.userAccessToken,
    step: state.checkOut.step,
    categoryRef: state.general.categoryRef,
    questionRef: state.general.questionRef,
    checkOutConfirmStart: state.checkOut.checkOutConfirmStart,
  };
};

export default connect(mapStateToProps, {
  changeCountryId,
  checkOutOfferById,
  loginUser,
  registerUser,
  getUserAuth,
  checkOutModifyStep,
  userAccessTokenSet,
  changeStep,
  emptyGlobalFormAnswer
})(Checkout);