import { Slider } from '@mui/material'
import React, {useState, useEffect} from 'react'
import './SliderOffer.css'
import { styled } from '@mui/material/styles';

const IOSSlider = styled(Slider)(({ theme }) => ({
    '& .MuiSlider-valueLabel': {
      backgroundColor: 'var(--accent500)',
      borderRadius: '5px',
    },
}))

const SliderOffer = (props) => {

    const handleChange = (event, newValue) => {
      props.onUpdate(newValue)
    };

    const formatedValueText = (value) => {
        const auxValue = isNaN(value) ? props.value : value
        function format(amount, config) {
            const result = new Intl.NumberFormat(config.locale, config.formatter).format(amount);
            return config.format.replace("VALUE", result);
        }
        
        const config = props.data?.input?.sliderConfig?.format
        
        const formattedValue = format(auxValue, config)
        
        return formattedValue
    }

    return (
        <div className="SliderOffer-inputContainer">
            <p className="SliderOffer-inputContainer-title" >{props.data?.display?.description?.toUpperCase()}</p>
            <div className="SliderOffer-inputContainer-sliderContainer">
                <IOSSlider 
                    aria-label="Default" 
                    valueLabelDisplay="on"
                    step={props.data?.input?.sliderConfig?.step}
                    min={props.data?.input?.sliderConfig?.min}
                    max={props.data?.input?.sliderConfig?.max}
                    onChange={handleChange}
                    valueLabelFormat={formatedValueText}
                    defaultValue={parseInt(props.value)}
                    sx={{color: 'var(--primary500)'}}
                />
                <div className="SliderOffer-inputContainer-sliderContainer-minMaxContainer">
                    <p className="SliderOffer-inputContainer-sliderContainer-minMaxContainer-text">{formatedValueText(props.data?.input?.sliderConfig?.min)}</p>
                    <p className="SliderOffer-inputContainer-sliderContainer-minMaxContainer-text">{formatedValueText(props.data?.input?.sliderConfig?.max)}</p>
                </div>
            </div>
        </div>
    );
}

export default SliderOffer;