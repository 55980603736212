import './FormFieldDate.css';

const FormFieldDate = (props:any) => {
    return (
        <div 
        className="customDateInput-container"
        id={props.data.display.name}
       >
        {props.data.display.name && 
          <div className="customDateInput-container-label">
            <h1>{props.data.display.name}</h1>
            {props.data.required && <p className="customDateInput-container-label-requiered">&nbsp;*</p>}
          </div>
        }
        <div className="customDateInput-container-inputContainer">
          <input
            id={props.data.display.name + ' input'}
            className="customTextInput-container-inputContainer-input"
            placeholder={props.data.display.placeholder}
            type={'date'}
            onChange={(e: any) => props.onUpdate(e.target.value)}
            style={{color: 'unset', backgroundColor: 'transparent'}}
          />
        </div>
        <div className="customTextInput-container-line"></div>
      </div>
    )
}

export default FormFieldDate;