

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux';
import Header from '../../components/generalComponents/header/Header';
import { useMediaQuery } from 'react-responsive'
import './MisReclamos.css'
import Footer from '../../components/generalComponents/footer/Footer';
import {
  fetchDataForMisReclamos,
  resetPolicy,
  offerProductById,
  removeInsuredArticle,
  getClaimTypes,
  toggleClaimTypes,
  postClaimProcess,
  getContactMethods
} from '../../actions'
import HomeSeguro from '../../components/homeComponents/HomeSeguro/HomeSeguro';
import Modal from 'react-modal';
import DesactivarPolizaAlert from '../../components/alertComponents/DesactivarPolizaAlert/DesactivarPolizaAlert';
import ReclamarPolizaAlert from '../../components/alertComponents/ReclamarPolizaAlert/ReclamarPolizaAlert';
import { ReactSVG } from 'react-svg';
import Exit from '../../assets/images/svgs/exit.svg'
import EmptyListReclamos from '../../assets/images/svgs/emptListReclamos.svg'
import MainButton from '../../components/generalComponents/MainButton/MainButton';
import { FormattedMessage, useIntl } from 'react-intl';
import { GetGlobalParams } from '../../util/GetGlobalParams';
import { GetCountryUrl } from '../../util/GetCountryUrl';

let isTabletOrMobile = false;
const MisReclamos = (props: any) => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' });
  const navigate = useNavigate()
  const [desactivarAlertId, setDesactivarAlertId] = useState<number>(-1);
  const [originalParams, setOriginalParams] = useState<any>(null)
  const [reclamoProductData, setReclamoProductData] = useState<any>(null)
  const [notFoundAlert, setNotFoundAlert] = useState<boolean>(false)
  const [listToClaim, setListToClaim] = useState<any>(null)
  const [appId] = useState<any>(process.env.REACT_APP_ID)

  const emptyListReclamosSize = isTabletOrMobile ? 80 : 150

  const text = useIntl().formatMessage({ id: 'page.misReclamos.alert.notFound', defaultMessage: 'Si no ves tu seguro en la lista recordá que <strong> solo se puede realizar un reclamo sobre un seguro activo. </strong> Si aún así no aparece, contactanos y te ayudaremos a la brevedad.' });

  useEffect(() => {
    props.fetchDataForMisReclamos()
    props.getContactMethods()
  }, [])

  useEffect(() => {
    if(props.userProductsActive) {
      const aux = [...props.userProductsActive].filter(a => !a.claimStatus)
      setListToClaim(aux)
    }
  }, [props.userProductsActive])

  const desactivarModal = () => {
    return (
      <Modal
        isOpen={desactivarAlertId !== -1}
        onRequestClose={() => setDesactivarAlertId(-1)}
        style={{
          content: {
            top: '50%', left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)', borderRadius: '25px',
            width: isTabletOrMobile ? '80%' : '30%',
            padding: isTabletOrMobile ? '10px' : '20px',
          },
          overlay: {
            backgroundColor: 'rgba(255, 255, 255, 0.5)'
          }
        }}
      >
        <DesactivarPolizaAlert desactivarAlert={()=> setDesactivarAlertId(-1)}
          desactivarPressed={() => {
            props.removeInsuredArticle(desactivarAlertId)
            setDesactivarAlertId(-1)
          }}
        />
      </Modal>
    )
  }

  const reclamoAlertModal = () => {
    return (
        <Modal 
            isOpen={props.claimTypes}
            onRequestClose={() => props.toggleClaimTypes()}
            style={{content: {
                    top: '50%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%',
                    transform: 'translate(-50%, -50%)', borderRadius: '20px', width: isTabletOrMobile ? '80%' : '30%', padding: isTabletOrMobile ? '10px' : '20px',
                }, overlay: {backgroundColor: 'rgba(255, 255, 255, 0.5)'}
            }}
        >
          <ReclamarPolizaAlert desactivarAlert={() => props.toggleClaimTypes()} reclamoProductData={reclamoProductData}
            postClaimProcess={(data: any) => props.postClaimProcess(data, props.countryId, navigate, props.countryId)}
            claimTypes={props.claimTypes}
            id={reclamoProductData?.id}
          />
        </Modal>
    )
  }

  const notFoundAlertModal = () => {
    return (
      <Modal 
          isOpen={notFoundAlert}
          onRequestClose={() => setNotFoundAlert(false)}
          style={{content: {
                  top: '50%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%',
                  transform: 'translate(-50%, -50%)', borderRadius: '20px', width: isTabletOrMobile ? '80%' : '30%', padding: isTabletOrMobile ? '10px' : '20px',
              }, overlay: {backgroundColor: 'rgba(255, 255, 255, 0.5)'}
          }}
      >
        <div className="misReclamos-container-notFoundAlertCon">
          <ReactSVG
            src={Exit}
            style={{ position: 'absolute', top: 22, right: 22, cursor: 'pointer' }}
            onClick={() => setNotFoundAlert(false)}
          />
          <p 
            className="misReclamos-container-notFoundAlertCon-text" 
            dangerouslySetInnerHTML={{__html: text}}
          />
          <MainButton
            id='Continuar Boton'
            text='CONTACTARME POR WHATSAPP'
            onButtonPressed={() => {
              window.open('https://wa.me/' + props.contactMethods?.filter(a => a.id === "whatsapp")[0].config.telephone)
              setNotFoundAlert(false)
            }}
          />
          <MainButton
            id='Continuar Boton'
            text='volver'
            onButtonPressed={() => setNotFoundAlert(false)}
            empty
color='var(--secondary500)'
          />
        </div>
      </Modal>
    )
  }

  return (
    <div className="misReclamos-container">
      <Header backgroundColor={isTabletOrMobile ? "var(--white)" : null}/>
      <div className="misReclamos-container-topContainer">
        <p className="misReclamos-container-topContainer-text"><FormattedMessage id = "page.misReclamos.title" defaultMessage="Mis Reclamos"/></p>
      </div>
      <div className="misReclamos-container-mask">
        <div className="misReclamos-container-mask-inner"></div>
      </div>
      {listToClaim && listToClaim?.length > 0 && <div className="misReclamos-container-listContainer">
        <p className="misReclamos-container-listContainer-title"><FormattedMessage id = "page.misReclamos.subTitle1" defaultMessage="¿Qué seguro querés reclamar?"/></p>
        <div className="misReclamos-container-listContainer-listSeguros">
          {listToClaim?.map((a: any, index: any) => (
            <HomeSeguro key={index} data={a}
              goToDetails={() => {
                props.resetPolicy(false)
                navigate(GetCountryUrl(props.countryId) + `policy/${a.id}` + '?' + GetGlobalParams())
              }}
              verPoliza={() => {
                props.resetPolicy(true)
                navigate(GetCountryUrl(props.countryId) + `policy/${a.id}` + '?' + GetGlobalParams())
              }} desactivar={(id: number) => setDesactivarAlertId(id)}
              activarPoliza={(id: number, categoryId: number) => props.offerProductById(id, categoryId, originalParams, navigate, props.countryId)}
              reclamarPoliza={() => {
                props.getClaimTypes(a.id)
                setReclamoProductData({
                  id: a.id,
                  name: a.name,
                  model: a.model
                })
              }}
            />
          ))}
        </div>
      </div>}
      <div className="misReclamos-container-listContainer" style={{marginTop: 20}}>
        <p className="misReclamos-container-listContainer-title"><FormattedMessage id = "page.misReclamos.subTitle2" defaultMessage="Reclamos realizados"/></p>
        {props.userReclamoList?.length > 0 ?
          <div className="misReclamos-container-listContainer-listReclamos">
            {props.userReclamoList && props.userReclamoList.map((a: any, index: any) => (
              <div className="misReclamos-container-listContainer-listReclamos-item" key={index}>
                <HomeSeguro 
                  data={{
                    categoryId: 0,
                    date: "",
                    enabled: a.status?.type === "ACTIVE",
                    formattedValue: "",
                    id: 0,
                    image: a.imagen,
                    insurer: "",
                    insurerImage: a.insurerImage,
                    model: a.modelo,
                    name: a.nombre,
                    term: "",
                    status: {type: a.status?.type}
                  }}
                  goToDetails={() => {
                    props.resetPolicy(false)
                    navigate(GetCountryUrl(props.countryId) + `policy/${a.productInsuredUserId}` + '?' + GetGlobalParams())
                  }}
                  verPoliza={() => {
                    props.resetPolicy(true)
                    navigate(GetCountryUrl(props.countryId) + `policy/${a.productInsuredUserId}` + '?' + GetGlobalParams())
                  }} 
                  desactivar={(id: number) => setDesactivarAlertId(id)}
                  activarPoliza={(id: number, categoryId: number) => props.offerProductById(id, categoryId, originalParams, navigate, props.countryId)}
                  reclamo={a.estado}
                  reclamoDisable
                />
              </div>
            ))}
          </div>
          :
          <div className="misReclamos-container-listContainer-listReclamosEmpty">
            <ReactSVG 
              src={EmptyListReclamos}
              beforeInjection={svg => {
                svg.setAttribute('style', 'height: ' + emptyListReclamosSize);
                svg.setAttribute('style', 'width: ' + emptyListReclamosSize);
              }}
            />
            <p className="misReclamos-container-listContainer-listReclamosEmpty-text"><FormattedMessage id = "page.misReclamos.emptyList"/></p>
          </div>
        }
      </div>
      <p 
        onClick={() => {
          if(appId == 3) navigate(GetCountryUrl(props.countryId) + 'contacto?' + GetGlobalParams())
          else setNotFoundAlert(true)
        }}
        className="misReclamos-container-lastText"><FormattedMessage id = "page.misReclamos.notFound" defaultMessage="¿Tu seguro no está en la lista?"/></p>
      {desactivarModal()}
      {reclamoAlertModal()}
      {notFoundAlertModal()}
      <Footer/>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    countryId: state.general.countryId,
    userReclamoList: state.reclamo.userReclamoList,
    userProductsActive: state.home.userProductsActive,
    claimTypes: state.reclamo.claimTypes,
    contactMethods: state.mainCotizar.contactMethods,
  };
};

export default connect(mapStateToProps, {
  fetchDataForMisReclamos,
  resetPolicy,
  offerProductById,
  removeInsuredArticle,
  getClaimTypes,
  toggleClaimTypes,
  postClaimProcess,
  getContactMethods
})(MisReclamos);