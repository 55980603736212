import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { GetCountryUrl } from '../../util/GetCountryUrl';

const PrivateRoute = () => {
    const countryId = useSelector((state: any) => state.general.countryId);
    const userEmailValidated = useSelector((state: any) => state.user.userData);

    return (
        (userEmailValidated && !userEmailValidated?.emailValidated) ?
            (
                <Outlet/>
            )
            :
            userEmailValidated?.emailValidated ?
                (
                    <Navigate to={GetCountryUrl(countryId) + "home"} />
                )
                :
                (
                    <Navigate to={GetCountryUrl(countryId) + "login"} />
                )

    )
};

export default PrivateRoute;

