import React, { useEffect, useState, useRef } from 'react'
import './Automovil.css'
import Header from '../../../components/generalComponents/header/Header';
import Footer from '../../../components/generalComponents/footer/Footer';
import MainButton from '../../../components/generalComponents/MainButton/MainButton';
import CustomTextInput from '../../../components/generalComponents/customTextInput/CustomTextInput';
import Moment from 'moment/moment'
import { useNavigate } from 'react-router-dom';
import {store} from '../../../index';
import { connect } from 'react-redux';
import {
  getGenericForm,
  postGenericForm
} from '../../../actions'
import ReCAPTCHA from "react-google-recaptcha";
import { useMediaQuery } from 'react-responsive';

let isTabletOrMobile = false;
const Automovil = (props: any) => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 38.5em)' });

  const [scrollPosition, setScrollPosition] = useState(0);

  const [tipoSelected, setTipoSelected] = useState<number>(-1);
  const [marca, setMarca] = useState<string>('');
  const [modelo, setModelo] = useState<string>('');
  const [año, setAño] = useState<string>('');
  const [patente, setPatente] = useState<string>('');

  const [rut, setRut] = useState<string>('');
  const [dv, setDv] = useState<string>('');
  const [nombre, setNombre] = useState<string>('');
  const [fechaNac, setFechaNac] = useState<string>('');
  const [comuna, setComuna] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [celular, setCelular] = useState<string>('');

  const [reCaptcha, setReCaptcha] = useState(null)
  const recaptchaRef = useRef();
  const [formList, setFormList] = useState<any>([]);

  const navigate = useNavigate();

  const countryId = store.getState().general.countryId

  const tipo = [
    {
      id: 1,
      name: 'Automóvil'
    },
    {
      id: 2,
      name: 'Station wagon'
    },
    {
      id: 3,
      name: 'Camioneta'
    },
    {
      id: 4,
      name: 'Furgón'
    },
    {
      id: 5,
      name: 'Jeep'
    },
  ]

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    props.getGenericForm('car', setFormList)
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  }, [])

  const validateEmail = (e: string) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(e).toLowerCase())) {
        return false;
    } return true
  }

  const isValidDate = (e: string) => {
    if(e.length === 10) {
        if(Moment(e, 'DD/MM/YYYY').isValid()) {
            if (Moment(e, 'DD/MM/YYYY').isBefore(Moment(new Date()).add(-18, 'years'))) {
                if (Moment(e, 'DD/MM/YYYY').isAfter(Moment(new Date()).add(-100, 'years'))) {
                    return true
                }
            }
        }
    }
    return false
  }

  const handleDateInput = (text: string) => {
    let newText = text;
    if (text.length >= 3 && text[2] !== '/') {
        newText = text[0] + text[1] + '/' + text.slice(2);
    } else if (text.length >= 6 && text[5] !== '/') {
        newText = text[0] + text[1] + text[2] + text[3] + text[4] + '/' + text.slice(5);
    }
    setFechaNac(newText)
  }

  const isDisabled = () => {
    return tipoSelected === -1 || marca === '' || modelo === '' || año === '' || 
    rut === '' || dv === '' || nombre === '' || !isValidDate(fechaNac) || comuna === '' || !validateEmail(email) || celular === '' || !reCaptcha
  }

  const reCaptchaCapture = (value: any) => {
    setReCaptcha(value)
  }

  return (
    <div className="automovil-container">
        <Header home colored fixed trans={scrollPosition > 30} special={!isTabletOrMobile} />      
        <div className="automovil-container-main">
          <div className="automovil-container-main-top">
            <p className="automovil-container-main-top-title">Seguro de automóvil</p>
          </div>
          <p className="automovil-container-main-title">Para cotizar tu auto, vamos a pedirte que completes los siguientes datos. Una vez enviados, te haremos llegar la cotización.</p>
          <div className="automovil-container-main-inputs">
            <div className="automovil-container-main-inputs-list">
              <p className="automovil-container-main-inputs-list-title">Datos del vehículo</p>
              <p className="automovil-container-main-inputs-list-label">Selecciona el tipo de vehículo</p>
              <div className="automovil-container-main-inputs-list-typeList">
                {tipo.map((a: any) => (
                  <p 
                    onClick={() => {
                      if(a.id === tipoSelected) setTipoSelected(-1) 
                      else setTipoSelected(a.id)
                    }} 
                    className={tipoSelected === a.id ? "automovil-container-main-inputs-list-typeList-textSelected" : "automovil-container-main-inputs-list-typeList-text"}
                  >
                    {tipoSelected === a.id ? a.name + ' x' : a.name}
                  </p>
                ))}
              </div>
              <div className="automovil-container-main-inputs-list-inputWrapper">
                <CustomTextInput
                  label='Marca'
                  required
                  maxLength="50"
                  changeText={(e: string) => setMarca(e)}
                  value={marca}
                  especial
                />
              </div>
              <div className="automovil-container-main-inputs-list-inputWrapper">
                <CustomTextInput
                  label='Modelo y versión'
                  required
                  maxLength="50"
                  changeText={(e: string) => setModelo(e)}
                  value={modelo}
                  especial
                />
              </div>
              <div className="automovil-container-main-inputs-list-inputWrapper">
                <CustomTextInput
                  label='Año'
                  required
                  changeText={(e: string) => setAño(e.replace(/[^0-9]+/g, ''))}
                  value={año}
                  especial
                  maxLength={4}
                />
              </div>
              <div className="automovil-container-main-inputs-list-inputWrapper">
                <CustomTextInput
                  label='Patente'
                  maxLength="10"
                  changeText={(e: string) => setPatente(e)}
                  value={patente}
                  placeholder='No obligatorio'
                  especial
                />
              </div>
            </div>
            <div className="automovil-container-main-inputs-list">
              <p className="automovil-container-main-inputs-list-title">Datos personales</p>
              <div className="automovil-container-main-inputs-list-row">
                <div className="automovil-container-main-inputs-list-row-inputWrapper">
                  <CustomTextInput
                    label='Rut'
                    maxLength="8"
                    required
                    changeText={(e: string) => setRut(e.replace(/[^0-9]+/g, ''))}
                    value={rut}
                    especial
                  />
                </div>
                <div className="automovil-container-main-inputs-list-row-inputWrapper">
                  <CustomTextInput
                    label='Dv'
                    required
                    changeText={(e: string) => setDv(e.replace(/[^0-9]+/g, ''))}
                    value={dv}
                    especial
                    maxLength={1}
                  />
                </div>
              </div>
              <div className="automovil-container-main-inputs-list-inputWrapper">
                <CustomTextInput
                  label='Nombre y apellido'
                  maxLength="50"
                  required
                  changeText={(e: string) => setNombre(e)}
                  value={nombre}
                  especial
                />
              </div>
              <div className="automovil-container-main-inputs-list-row">
                <div className="automovil-container-main-inputs-list-row-inputWrapper">
                  <CustomTextInput
                    label='Fecha de nacimiento'
                    required
                    changeText={(e: string) => handleDateInput(e.replace(/[^/0-9]+/g, ''))}
                    value={fechaNac}
                    placeholder='DD/MM/AAAA'
                    error={fechaNac.length === 0 ? false : !isValidDate(fechaNac)}
                    success={isValidDate(fechaNac)}
                    maxLength={10}
                    especial
                  />
                </div>
                <div className="automovil-container-main-inputs-list-row-inputWrapper">
                  <CustomTextInput
                    label='Comuna'
                    required
                    maxLength="50"
                    changeText={(e: string) => setComuna(e)}
                    value={comuna}
                    especial
                  />
                </div>
              </div>
              <div className="automovil-container-main-inputs-list-inputWrapper">
                <CustomTextInput
                  label='E-mail'
                  required
                  changeText={(e: string) => setEmail(e)}
                  value={email}
                  success={validateEmail(email)}
                  error={email.length > 0 ? !validateEmail(email) : false}
                  errorMessage='E-mail inválido'
                  placeholder='nombre@ejemplo.com'
                  especial
                />
              </div>
              <div className="automovil-container-main-inputs-list-inputWrapper">
                <CustomTextInput
                  label='Celular'
                  required
                  changeText={(e: string) => setCelular(e.replace(/[^0-9]+/g, ''))}
                  value={celular}
                  preValue='+56 '
                  especial
                  maxLength={9}
                />
              </div>
            </div>
          </div>
          <div className="automovil-container-main-captcha">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6LdFokQfAAAAAMQ3MMEbQmaltswGk50-MKQoaS8m"
              onChange={reCaptchaCapture}
            />
          </div>
          <div className="automovil-container-main-buttons">
            <div className="automovil-container-main-buttons-buttonWrapper">
              <MainButton
                id='Volver'
                text='VOLVER'
                onButtonPressed={() => navigate(-1)}
                altStyle2
              />
            </div>
            <div className="automovil-container-main-buttons-buttonWrapper">
              <MainButton
                id='Enviar'
                text='ENVIAR'
                onButtonPressed={() => {
                  const data = {
                    plate: patente,
                    comuna: comuna,
                    cellphone: celular,
                    dv: dv,
                    brand: marca,
                    type: tipo.filter((a) => tipoSelected === a.id)[0].name,
                    year: año,
                    birth_date: Moment(fechaNac, 'DD/MM/YYYY').utcOffset('+0000').startOf('day').toISOString(),
                    model_version: modelo,
                    rut: rut,
                    mail: email,
                    name_lastname: nombre
                  }
                  props.postGenericForm('car', data, reCaptcha, recaptchaRef, setReCaptcha, navigate, 'automovil-exito', countryId)
                }}
                disabled={isDisabled()}
              />
            </div>
          </div>
        </div>
      <Footer />
    </div>
  )
}

export default connect(null, {
  getGenericForm,
  postGenericForm
})(Automovil);