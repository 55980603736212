import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import CustomTextInput from "../../components/generalComponents/customTextInput/CustomTextInput"
import { connect, useDispatch } from 'react-redux';
import { FormattedMessage } from "react-intl";
import EmisoraType from "../../components/checkoutComponents/CheckOutPayment/EmisoraType";
import { useMediaQuery } from "react-responsive";
import { genericErrorHandler } from "../../util/GenericErrorHandler";
import { getEmisoras } from "../../net/Connector";
import { 
    checkOutPayment, 
    cvvTextInputChange, 
    fetchUserCardsBrands, 
    resetDataOnEnter,
    changeNumeroTarjetaText,
    changeTipoText,
    changeEmisoras
} from "../../actions";
import axios from '../../actions/instanceActions';
import { formatCreditCard } from "./CreditCardUtils";
import { LOADING, SUCCESS_CARD_TEXT } from "../../actions/types";

let isTabletOrMobile = false;

interface Emisora {
    name: string;
    code: string;
    pattern: string;
    imagePathFront: string;
    numberOfDigits: number;
}

const SimpleCreditCardConfigComponent = forwardRef((props: any, ref) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

    const [tipoId, setTipoId] = useState<number | undefined>(undefined);

    const [numeroTarjeta, setNumeroTarjeta] = useState<string>("");

    const [emisorasType, setEmisorasType] = useState<Emisora[]>([])

    const mounted = useRef<boolean>(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const ready = !isDisabled();
        props.onChangeStatus(ready);
    })

    useEffect(() => {
        getEmisorBanks();
        if (!mounted.current) {
            props.resetDataOnEnter()
            mounted.current = true;
        }
    }, []);

    const getEmisorBanks = async () => {
        try {
            const response = await axios.get(getEmisoras());
            setEmisorasType(response.data);
            props.changeEmisoras(response.data)
        } catch (error) {
            genericErrorHandler(error)
        }
    }


    const validateNumeroTarjeta = () => {
        // Emisora todavia no seleccionada?
        if (emisorasType == null || tipoId === undefined) {
            return false;
        }
        if (emisorasType[tipoId].pattern.replaceAll(" ", "").length !== numeroTarjeta.length) {
            return false
        }

        return valid_credit_card(numeroTarjeta);

    }

    const inValidateNumeroTarjeta = () => {
        return !validateNumeroTarjeta();
    }

    const valid_credit_card = (value) => {
        if (/[^0-9-\s]+/.test(value)) return false;

        var nCheck = 0, nDigit = 0, bEven = false;
        value = value.replace(/\D/g, "");

        for (var n = value.length - 1; n >= 0; n--) {
            var cDigit = value.charAt(n);
            nDigit = parseInt(cDigit, 10);
            if (bEven) {
                if ((nDigit *= 2) > 9) nDigit -= 9;
            }
            nCheck += nDigit;
            bEven = !bEven;
        }
        return (nCheck % 10) === 0;
    }

    const isDisabled = () => {
        if (
            tipoId === undefined ||
            !validateNumeroTarjeta()
        ) {
            return true;
        }
    }

    const selected = (item) => {
        if (emisorasType !== null && tipoId) {
            return (emisorasType[tipoId].code === item.code)
        }
        return false
    }

    return (
        <div className="checkOutPayment-container-main-infoCard">
            <h6 className='formulario-title bold'>¿Qué tarjeta es?</h6>
            <div className='emisorOne'>
                {emisorasType.map(((emisora, index) => (
                    <EmisoraType
                        selected={tipoId != null && emisorasType[tipoId].code === emisora.code}
                        name={emisora.name}
                        onSelected={
                            () => {
                                if (selected(emisora)) {
                                    setTipoId(undefined);
                                    props.changeTipoText(undefined);
                                    setNumeroTarjeta('');
                                    props.changeNumeroTarjetaText('');
                                } else {
                                    setTipoId(index);
                                    props.changeTipoText(index);
                                    setNumeroTarjeta('');
                                    props.changeNumeroTarjetaText('');
                                }
                            }
                        }
                    />
                )))}
            </div>
            <div className="checkOutPayment-container-main-infoCard-input">
                <CustomTextInput
                    disabled={tipoId === undefined}
                    label={<FormattedMessage id="component.checkOut.payment.numeroTarjeta" defaultMessage="" />}
                    required
                    changeText={(text) => {
                        text = text.replace(/[^\d+$]+/g, '');
                        setNumeroTarjeta(text)
                        props.changeNumeroTarjetaText(text);
                    }}
                    value={tipoId !== undefined ? formatCreditCard(numeroTarjeta.replaceAll(" ", ""), emisorasType[tipoId].pattern) : ""}
                    maxLength={props.bankTypes !== null && tipoId !== undefined ? emisorasType[tipoId].pattern.length : 0}
                    success={validateNumeroTarjeta()}
                    returnKeyType='done'
                    error={numeroTarjeta !== "" ? inValidateNumeroTarjeta() : false}
                    errorMessage={"Numero de tarjeta invalida"}
                />
            </div>
        </div>
    );

});

const mapStateToProps = (state) => {
    return {
        countryId: state.general.countryId,
        checkOutData: state.checkOut.checkOutData,
        bankTypes: state.checkOut.bankTypes
    }
};

export default connect(mapStateToProps, {
    checkOutPayment,
    fetchUserCardsBrands,
    resetDataOnEnter,
    cvvTextInputChange,
    changeNumeroTarjetaText,
    changeTipoText,
    changeEmisoras
}, null, { forwardRef: true })(SimpleCreditCardConfigComponent);

