

import { netGetUserCreditCardInfo } from '../net/Connector';
import axios from '../actions/instanceActions'
import { CreditCard } from './CreditCard';

const creditCardCache: Record<string, CreditCard>  = {};

export const getCreditCard = (creditCardId: number, userId: number) => {
    const cacheCode = "C" + creditCardId + "U" + userId;
    if (creditCardCache[cacheCode]) {
        return creditCardCache[cacheCode];
    }

    return axios(netGetUserCreditCardInfo(creditCardId, userId)).then(response => {
        creditCardCache[cacheCode] = response.data;
        return response.data
    });
}