// lib
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import './PaymentCard.css'
import { getUserCreditCardInfo } from "../../../actions";


let isTabletOrMobile = false;

const PaymentCard = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' });

    const [paymentData, setPaymentData] = useState<any>(null);

    useEffect(() => {
        getUserCreditCardInfo(props.policy?.paymentInfoResponseDTO?.id, props.userData?.id, setPaymentData) 
    }, [])

    return (
        <div className="paymentCard-container">
            <div className="paymentCard-container-card">
                <span className="material-symbols-outlined" style={{ fontSize: 24, color: 'var(--primary500)' }}>credit_card</span>
                <p className="regular" style={{ color: 'var(--primary500)' }}>{paymentData?.brand?.name?.toUpperCase()} **** **** **** {paymentData?.lastDigits}</p>
            </div>
            <span className="material-symbols-outlined" style={{ fontSize: 24, color: 'var(--statusSuccessPrimary)' }}>done</span>
        </div>
    )
}

export default PaymentCard;

