import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { getPaymentOption } from "../../services/PaymentOptionService";

const PaymentMethodResumeBasicComponent = (props: any) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [paymentOptionDetails, setPaymentOptionDetails] = useState<any>();

    useEffect(() => {
        async function fetchData() {
            if (props.data) {
                setLoading(true)
                try {
                    const paymentOption = await getPaymentOption(props.data.paymentInfo.paymentType);
                    setPaymentOptionDetails(paymentOption);
                } catch (error) {
                    // No hago nada, componente secundario.
                    console.error(error);
                } finally {
                    setLoading(false)
                }
            }
        }
        fetchData();
    }, [props.data])

    return (
        <div className="checkOutResume-container-mainDesktop-details-itemWrapper">
            <div className="checkOutResume-container-mainDesktop-details-itemWrapper-medioContainer">
                <p className="checkOutResume-container-mainDesktop-details-itemWrapper-medioContainer-medioText"><FormattedMessage id = "component.checkOut.resume.medioDePago" defaultMessage=""/></p>
                {paymentOptionDetails &&
                    <p className="checkOutResume-container-mainDesktop-details-itemWrapper-medioContainer-tarjetaText">{paymentOptionDetails.name}</p>
                }
            </div>
            <p 
                onClick={() => props.otroMedioPagoPressed()}
                className="checkOutResume-container-mainDesktop-details-itemWrapper-otroText"
            ><FormattedMessage id = "component.checkOut.resume.elegirOtroMedioDePago" defaultMessage=""/></p>
        </div>
    )
}

export { PaymentMethodResumeBasicComponent };