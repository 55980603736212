

import axios from '../actions/instanceActions'

const paymentOptionCache: Record<string, any>  = {};

export const getPaymentOption = (paymentOptionId: number) => {
    const cacheCode = paymentOptionId;
    if (paymentOptionCache[cacheCode]) {
        return paymentOptionCache[cacheCode];
    }

    return axios(`/payment_options?code=${paymentOptionId}`).then(response => {
        paymentOptionCache[cacheCode] = response.data;
        return response.data
    });
}