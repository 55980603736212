import {
    CLAIM_TYPES,
    CLAIM_PROCESS_DATA,
    USER_RECLAMO_LIST
} from '../actions/types';
import Cookies from 'universal-cookie';

const INITIAL_STATE = {
    claimTypes: null,
    claimProcessData: null,
    userReclamoList: null,
};

export default (state = INITIAL_STATE, action: any) => {
    const cookies = new Cookies();
    switch (action.type) {
        case CLAIM_TYPES:
            return { ...state, claimTypes: action.payload };
        case CLAIM_PROCESS_DATA:
            return { ...state, claimProcessData: action.payload };
        case USER_RECLAMO_LIST:
            return { ...state, userReclamoList: action.payload };
        default:
            return state;
    }
};
