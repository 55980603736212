import { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import './SocialLinks.css';
import { socialLinks } from '../../../data';
import { ReactSVG } from 'react-svg';

let isTabletOrMobile = false;

const SocialLinks = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })
    const [scrollPosition, setScrollPosition] = useState<number>(0);
    const [open, setOpen] = useState<boolean>(false);

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])

    const calculateDistance = (index: any) => {
        if (isTabletOrMobile) return index * 80
        else return index * 60
    }

    const iconSize = isTabletOrMobile ? 30 : 20

    return (
        <div 
            className='socialLinks-container'
            style={{opacity: scrollPosition < 1500 ? 1 : 0}}
        >
            <div className='socialLinks-container-list'>
                {socialLinks.map((a: any, index: any) => (
                    <a
                        href={a.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        key={index}
                        className='socialLinks-container-list-item'
                        style={{transform: open ? `translate(0px, -${calculateDistance(index + 1)}px)` : `translate(3px, 3px)`}}
                    >
                        <ReactSVG 
                            src={a.svgC}
                            style={{marginTop: 3}}
                            beforeInjection={svg => {
                                svg.setAttribute('style', 'height: ' + iconSize);
                                svg.setAttribute('style', 'width: ' + iconSize);
                            }}
                        />
                    </a>
                ))}
            </div>
            <div 
                className='socialLinks-container-menu'
                onClick={() => setOpen(!open)}
                style={{backgroundColor: open ? 'var(--secondary700)' : 'var(--secondary500)'}}
            >
                {!open ? 
                    <svg width="32" height="6" viewBox="0 0 40 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.62909 9.7904C4.31178 9.7904 3.18448 9.32096 2.24719 8.38207C1.30831 7.44479 0.838867 6.31749 0.838867 5.00018C0.838867 3.68287 1.30831 2.55477 2.24719 1.61589C3.18448 0.678604 4.31178 0.209961 5.62909 0.209961C6.9464 0.209961 8.07369 0.678604 9.01098 1.61589C9.94986 2.55477 10.4193 3.68287 10.4193 5.00018C10.4193 6.31749 9.94986 7.44479 9.01098 8.38207C8.07369 9.32096 6.9464 9.7904 5.62909 9.7904ZM19.9997 9.7904C18.6824 9.7904 17.5551 9.32096 16.6178 8.38207C15.679 7.44479 15.2095 6.31749 15.2095 5.00018C15.2095 3.68287 15.679 2.55477 16.6178 1.61589C17.5551 0.678604 18.6824 0.209961 19.9997 0.209961C21.3171 0.209961 22.4452 0.678604 23.384 1.61589C24.3213 2.55477 24.79 3.68287 24.79 5.00018C24.79 6.31749 24.3213 7.44479 23.384 8.38207C22.4452 9.32096 21.3171 9.7904 19.9997 9.7904ZM34.3704 9.7904C33.0531 9.7904 31.925 9.32096 30.9861 8.38207C30.0488 7.44479 29.5802 6.31749 29.5802 5.00018C29.5802 3.68287 30.0488 2.55477 30.9861 1.61589C31.925 0.678604 33.0531 0.209961 34.3704 0.209961C35.6877 0.209961 36.8158 0.678604 37.7547 1.61589C38.692 2.55477 39.1606 3.68287 39.1606 5.00018C39.1606 6.31749 38.692 7.44479 37.7547 8.38207C36.8158 9.32096 35.6877 9.7904 34.3704 9.7904Z" fill="white"/>
                    </svg>
                    :
                    <svg width="20" height="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.9998 19.4728L4.26376 31.2089C3.82466 31.648 3.2658 31.8675 2.58718 31.8675C1.90857 31.8675 1.34971 31.648 0.910607 31.2089C0.471504 30.7698 0.251953 30.2109 0.251953 29.5323C0.251953 28.8537 0.471504 28.2948 0.910607 27.8557L12.6466 16.1197L0.910607 4.38363C0.471504 3.94453 0.251953 3.38567 0.251953 2.70706C0.251953 2.02844 0.471504 1.46958 0.910607 1.03048C1.34971 0.591377 1.90857 0.371826 2.58718 0.371826C3.2658 0.371826 3.82466 0.591377 4.26376 1.03048L15.9998 12.7665L27.7358 1.03048C28.1749 0.591377 28.7338 0.371826 29.4124 0.371826C30.091 0.371826 30.6499 0.591377 31.089 1.03048C31.5281 1.46958 31.7476 2.02844 31.7476 2.70706C31.7476 3.38567 31.5281 3.94453 31.089 4.38363L19.353 16.1197L31.089 27.8557C31.5281 28.2948 31.7476 28.8537 31.7476 29.5323C31.7476 30.2109 31.5281 30.7698 31.089 31.2089C30.6499 31.648 30.091 31.8675 29.4124 31.8675C28.7338 31.8675 28.1749 31.648 27.7358 31.2089L15.9998 19.4728Z" fill="white"/>
                    </svg>
                }
            </div>
        </div>
    );
}

export default SocialLinks;