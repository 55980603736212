import React, {useState, useEffect, useRef} from 'react'
import './CheckOutResume.css'
import { useMediaQuery } from 'react-responsive'
import { connect } from 'react-redux';
import CheckOutDataComponent from '../CheckOutDataComponent/CheckOutDataComponent';

import {
    checkOutDiscount,
    checkOutDiscountDelete,
    promoCoceErrorToggle,
    setRefResumen
} from '../../../actions'
import { FormattedMessage, useIntl } from 'react-intl';
import DiscountDetailsComponent from '../DiscountDetailsComponent/DiscountDetailsComponent';
import { ReactComponent as TosCheck } from '../../../assets/images/svgs/tosCheck.svg';
import { ReactComponent as DiscountTicketResumeResponsive } from '../../../assets/images/svgs/discountTicketResumeResponsive.svg';
import { ReactComponent as DiscountApply } from '../../../assets/images/svgs/discountApply.svg';
import Moment from 'moment/moment'
import { PaymentMethods } from "../../../services/PaymentMethods"

let isTabletOrMobile = false;
const CheckOutResume = (props) => { 
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

    const [tosAccepted, setTosAccepted] = useState(false)
    const [discountIsFocus, setDiscountIsFocus] = useState(false)
    const [localPromo, setLocalPromo] = useState('')
    const refResumen = useRef(null);

    const showDuration = !process.env.REACT_APP_HIDE_DURATION;

    const paymentMethods = PaymentMethods;

    const isDisabled = () => {
        return (props.checkOutData?.offer?.packages?.filter((a) => a?.id === props.checkOutData?.offer?.currentPackage)[0]?.swornDeclaration ? !tosAccepted : false)
    }

    useEffect(() => {
        if(props.resumenRef) {
          scrollToResumen()
          props.setRefResumen(false)
        }
      }, [props.resumenRef])

    const scrollToResumen = () => {
        refResumen.current?.scrollIntoView({behavior: 'smooth'})
    }

    const getRangeDates = () => {
        if(props.checkOutData.offer.renew.term === "MONTHLY") {
            return 'Renovación Mensual automática'
        } else if (props.checkOutData.offer.renew.term === "ANUAL") {
            return 'Renovación Anual automática'
        }
        if(Moment(props.checkOutData.offer.endDate).subtract(1, "days").format('DD/MM/YY') === Moment(props.checkOutData.offer.startDate).format('DD/MM/YY')) {
            return ('EL ' + Moment(props.checkOutData.offer.startDate).format('DD/MM/YY'))  
          }
          return ('DEL ' + Moment(props.checkOutData.offer.startDate).format('DD/MM/YY') + ' AL ' + Moment(props.checkOutData.offer.endDate).subtract(1, "days").format('DD/MM/YY'))
    }

    const renderResumePayment = () => {
        const PaymentResumeComponent =  paymentMethods[props.checkOutData.paymentInfo.paymentType].resumeComponente;
        return <PaymentResumeComponent data={props.checkOutData} otroMedioPagoPressed={() => props.otroMedioPagoPressed()}/>
    }

    const renderFinalPaymentButton = () => {
        const PaymentComponent =  paymentMethods[props.checkOutData.paymentInfo.paymentType].paymentComponent;
        return <PaymentComponent disabled={isDisabled()} originalParams={props.originalParams}/>
    }

    const pagasText = () => {
        if (props.checkOutData?.offer?.packages?.filter((a) => a?.id === props.checkOutData?.offer?.currentPackage)[0]?.installmentConfig) {
            return 'Pagas por ' + props.checkOutData?.offer?.packages?.filter((a) => a?.id === props.checkOutData?.offer?.currentPackage)[0]?.name?.toLowerCase() + ' por ' + props.checkOutData?.offer?.packages?.filter((a) => a?.id === props.checkOutData?.offer?.currentPackage)[0]?.installmentConfig?.cycle?.displayName?.toLowerCase() + ' durante ' + props.checkOutData?.offer?.packages?.filter((a) => a?.id === props.checkOutData?.offer?.currentPackage)[0]?.installmentConfig?.installments + ' ' + props.checkOutData?.offer?.packages?.filter((a) => a?.id === props.checkOutData?.offer?.currentPackage)[0]?.installmentConfig?.cycle?.displayPluralName?.toLowerCase()
        } return 'Pagas por ' + props.checkOutData?.offer?.packages?.filter((a) => a?.id === props.checkOutData?.offer?.currentPackage)[0]?.name?.toLowerCase() + ' ' + props.checkOutData?.offer?.periods[props.checkOutData?.offer?.renew?.term]?.description
    }

    const priceText = () => {
        if (props.checkOutData?.offer?.packages?.filter((a) => a?.id === props.checkOutData?.offer?.currentPackage)[0]?.installmentConfig) {
            return props.checkOutData?.offer?.packages?.filter((a) => a?.id === props.checkOutData?.offer?.currentPackage)[0]?.installmentConfig?.price[0]?.formattedValue
        } return props.checkOutData?.offer?.packages?.filter((a) => a?.id === props.checkOutData?.offer?.currentPackage)[0]?.discountedPrice ? props.checkOutData?.offer?.packages?.filter((a) => a?.id === props.checkOutData?.offer?.currentPackage)[0]?.discountedPrice?.formattedValue : props.checkOutData?.offer?.packages?.filter((a) => a?.id === props.checkOutData?.offer?.currentPackage)[0]?.price?.formattedValue
    }

    return (
        <div className="checkOutResume-container" >
            <div className="checkOutResume-container-mainDesktop" ref={refResumen}>
                <p className="checkOutResume-container-mainDesktop-title"><FormattedMessage id = "component.checkOut.resume.title" defaultMessage=""/></p>
                <p className="checkOutResume-container-mainDesktop-subTitle"><FormattedMessage id = "component.checkOut.resume.especificaciones" defaultMessage=""/></p>
                <div className="checkOutResume-container-mainDesktop-details">
                    {props.checkOutData?.offer?.packages?.filter((a) => a?.id === props.checkOutData?.offer?.currentPackage)[0]?.coverageGroups?.map((a) => (
                        a?.coverages?.map((b, index) => (
                            <React.Fragment key={index}>
                                {b?.listed &&
                                    <div className="checkOutResume-container-mainDesktop-details-itemWrapper">
                                        <CheckOutDataComponent
                                            title={b?.title.charAt(0).toUpperCase() + b?.title.slice(1).toLowerCase()}
                                            description={b?.formattedValue ? b?.formattedValue : b?.value + b?.unit}
                                        />    
                                    </div>
                                }
                            </React.Fragment>
                        ))
                    ))}
                    {showDuration && <div className="checkOutResume-container-mainDesktop-details-itemWrapper">
                        <CheckOutDataComponent
                            title='Duración'
                            description={getRangeDates()}
                        />    
                    </div>}
                </div>
                <div className="checkOutResume-container-mainDesktop-details">
                    {renderResumePayment()}
                    {props.checkOutData?.offer?.packages?.filter((a) => a?.id === props.checkOutData?.offer?.currentPackage)[0]?.swornDeclaration && 
                        <div className="checkOutResume-container-mainDesktop-details-itemWrapper">
                            <div className="checkOutResume-container-mainDesktop-details-itemWrapper-tos">
                                <div 
                                    onClick={() => {
                                        if(!props.checkOutConfirmStart) {
                                            setTosAccepted(!tosAccepted)
                                        }
                                    }}
                                    id="accept_tos_buttom"
                                    className="checkOutResume-container-mainDesktop-details-itemWrapper-tos-checkContainer"
                                >
                                    <div 
                                        className="checkOutResume-container-mainDesktop-details-itemWrapper-tos-checkContainer-inner"
                                        style={{
                                            transform: tosAccepted ? `scale(${1})` : `scale(${0})`
                                        }}
                                    >
                                        <TosCheck/>
                                    </div>
                                </div>
                                <p className="checkOutResume-container-mainDesktop-details-itemWrapper-tos-text" dangerouslySetInnerHTML={{__html: props.checkOutData?.offer?.packages?.filter((a) => a?.id === props.checkOutData?.offer?.currentPackage)[0]?.swornDeclaration}}></p>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="checkOutResume-container-detailsWrapper">
                <DiscountDetailsComponent final/>
                {isTabletOrMobile ? null : renderFinalPaymentButton()}
            </div>
            <div className="checkOutResume-container-responsivePayContainer">
                <div className="checkOutResume-container-responsivePayContainerMask">
                    <div className="checkOutResume-container-responsivePayContainerMask-inner"></div>
                </div>
                <div className="checkOutResume-container-responsivePayContainer-main">
                    <div className="checkOutResume-container-responsivePayContainer-main-codeContainer">
                        <DiscountTicketResumeResponsive/>
                        <input onFocus={() => setDiscountIsFocus(true)}
                            placeholder={useIntl().formatMessage({id: 'component.checkOut.discount.placeholder'})}
                            value={props.checkOutData?.offer?.promo ? props.checkOutData?.offer?.promo?.code + ' aplicado' : localPromo}
                            className="checkOutResume-container-responsivePayContainer-main-codeContainer-input"
                            onBlur={() => setDiscountIsFocus(false)}
                            onChange={(e) => {
                                setLocalPromo(e.target.value)
                                if(e.target.value.length === 0) {
                                    props.promoCoceErrorToggle('')
                                }
                            }}
                            style={{color: props.checkOutData?.offer?.promo ? 'var(--fifthTextColor)' : 'var(--white)'}}
                            disabled={props.checkOutData?.offer?.promo}
                        />
                        {props.checkOutData?.offer?.promo ? <p className="checkOutResume-container-responsivePayContainer-main-codeContainer-deleteCodeText"
                                onClick={() => {
                                    setLocalPromo('')
                                    props.checkOutDiscountDelete(props.checkOutData?.id)
                                }}
                            ><FormattedMessage id = "page.checkOut.codeDeshacer" defaultMessage=""/></p>
                            : (discountIsFocus || localPromo !== '') && 
                            <div onClick={() => props.checkOutDiscount(localPromo, props.checkOutData?.id)}>
                                <DiscountApply/>
                            </div>
                        }
                    </div>
                    {props.promoCodeError && <p className="checkOutResume-container-responsivePayContainer-main-codeError">{props.promoCodeError}</p>}
                    {props.checkOutData.offer.promo && <p className="checkOutResume-container-responsivePayContainer-main-codeSuccess">{props.checkOutData?.offer?.promo?.description}</p>}
                    {props.checkOutData && 
                        <div className="checkOutResume-container-responsivePayContainer-main-price">
                            <p className="checkOutResume-container-responsivePayContainer-main-price-text">{pagasText()}</p>
                            <p className="checkOutResume-container-responsivePayContainer-main-price-price">{priceText()}</p>
                        </div>
                    }
                    {isTabletOrMobile ? renderFinalPaymentButton() : null}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        checkOutData: state.checkOut.checkOutData,
        countryId: state.general.countryId,
        promoCodeError: state.checkOut.promoCodeError,
        checkOutConfirmStart: state.checkOut.checkOutConfirmStart,
        checkOutDataPaymentInfo: state.checkOut.checkOutDataPaymentInfo,
        resumenRef: state.general.resumenRef
    };
  };
  
  export default connect(mapStateToProps, {
    checkOutDiscount,
    checkOutDiscountDelete,
    promoCoceErrorToggle,
    setRefResumen
  })(CheckOutResume);