import React from 'react'
import './PaymentMethodPatChile.css'
import { ReactComponent as Error } from '../../../assets/images/svgs/error.svg';
import { ReactComponent as Success } from '../../../assets/images/svgs/success.svg';
import { ReactComponent as CandadoPay } from '../../../assets/images/svgs/candadoPay.svg';
import { useMediaQuery } from 'react-responsive'
import { connect } from 'react-redux';
import {
    cvvTextInputChange,
    checkOutConfirm
} from '../../../actions'
import { useNavigate } from 'react-router-dom';

let isTabletOrMobile = false;
const PaymentMethodPatChile = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

    const navigate = useNavigate()

    const handlePayState = () => {
        if(props.checkOutConfirmStart) {
            return "PaymentMethodCreditCard-containerStart"
        }
        if(props.checkOutConfirmError) {
            return "PaymentMethodCreditCard-containerError"
        } 
        if(props.checkOutConfirmSuccess) {
            return "PaymentMethodCreditCard-containerSuccess"
        }
        return "PaymentMethodCreditCard-container"
    }

    const handleInnerPayState = () => {
        if(props.checkOutConfirmError) {
            return <Error/>;
        }
        if(props.checkOutConfirmSuccess) {
            return <Success/>;
        }
        return (
            <React.Fragment> 
                <CandadoPay/>
                <p className="PaymentMethodCreditCard-container-text">{props.checkOutConfirmStart ? 'PAGANDO...' : 'PAGAR'}</p>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div 
                className={handlePayState()} 
                id="payment_buttom"
                style={{
                    opacity: props.disabled ? 0.4 : 1,
                    cursor: props.disabled ? 'inherit' : 'pointer'
                }}
                onClick={() => {
                    if(props.disabled || props.checkOutConfirmStart) {
                        return
                    } else {
                        props.checkOutConfirm(props.checkOutData.id, null, props.checkOutData.offer.product.title, props.userData.name, props.originalParams, navigate, props.countryId)
                    }
                }}
            >
                {handleInnerPayState()}
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state: any) => {
    return {
        checkOutData: state.checkOut.checkOutData,
        cvvTextInput: state.checkOut.cvvTextInput,
        checkOutConfirmSuccess: state.checkOut.checkOutConfirmSuccess,
        checkOutConfirmError: state.checkOut.checkOutConfirmError,
        checkOutConfirmStart: state.checkOut.checkOutConfirmStart,
        checkOutDataPaymentInfo: state.checkOut.checkOutDataPaymentInfo,
        userData: state.user.userData,
        countryId: state.general.countryId
    };
};

export default connect(mapStateToProps, {
    cvvTextInputChange,
    checkOutConfirm
})(PaymentMethodPatChile);