
import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import './CategoryListSection.css'
import CategoriaButton from '../../../components/cotizarComponents/CategoriaButton/CategoriaButton';
import { useNavigate } from 'react-router-dom';

let isTabletOrMobile = false;
const CategoryListSection = (props: any) => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 38.5em)' });

  const navigate = useNavigate();

  const getProductRegionId = (categoryId: any) => {
    switch (categoryId) {
      case 18:
        return 9242;
      case 19:
        return 9243;
      case 20:
        return 9244;
      default:
        return 9242;
    }

  }

  return (
    <div className="categoryListSection-container" ref={props.refCategory}>
      {props.categoriaList?.length !== 0 && 
        <div className='categoryListSection-container-text'>
          <h4 className='categoryListSection-container-text-title bold'>¿Qué cobertura de salud estás buscando?</h4>
          <b className='categoryListSection-container-text-subtitle regular large'>Descubrí las mejores opciones que tenemos para vos</b>
        </div>
      }
      <div className='categoryListSection-container-list'>
        {props.categoriaList?.map((categoryDB: any, index: Number) => (
          <CategoriaButton
            key={index}
            id={categoryDB.name + ' boton'}
            name={categoryDB.name} 
            image={categoryDB.category.imageIcon}
            categoryPressed={() => {
              props.categoriaSelectedChange(categoryDB.category.id)
              const adminRoute = props.admin ? '&admin=True' : ''
              navigate('/oferta?productId=' + getProductRegionId(categoryDB.category.id) + adminRoute)
            }}
          />
        ))}
      </div>
    </div>
  );
}

export default CategoryListSection;