import React, { useState, useEffect, useRef } from 'react'
import { ReactComponent as ExitQuote } from '../../../../../assets/images/svgs/exitQuote.svg';
import { ReactComponent as ArrowQuote } from '../../../../../assets/images/svgs/arrowQuote.svg';
import './FormFieldSelector.css'

const FormFieldSelector = (props: any) => {

  const [openList, setOpenList] = useState<Boolean>(false)
  const [selectedItem, setSelectedItem] = useState<any>(null)

  const mainRef: any = useRef()

  const handleItemSelected = (a: any) => {
    setOpenList(false)
    const data = {
      "questionId": props.data.id,
      "value": a.value,
      "label": a.label
    }
    setSelectedItem(data.label)
    props.onUpdateAlt(data)
  }

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (mainRef.current && !mainRef.current.contains(event.target)) {
        setOpenList(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [mainRef]);


  return (
    <div ref={mainRef} className="FormFieldSelector-container">
      <div 
        id={`select_${props.data.id}`}
        className="FormFieldSelector-container-selector" 
        onClick={() => {
          if(!selectedItem && props.data.input.values.length > 1) {
            setOpenList(!openList)
          }
        }}
        style={{
            borderBottomLeftRadius: openList ? 0 : 25,
            borderBottomRightRadius: openList ? 0 : 25,
            backgroundColor: openList ? 'var(--gray100)' : selectedItem ? 'var(--accent500)' : 'var(--white)',
            borderWidth: openList || selectedItem ? 0 : 1
        }}
      >
        <p 
            className="FormFieldSelector-container-FormFieldSelector-value"
            style={{color: selectedItem ? 'var(--white)' : 'var(--accent500)'}}
        >{selectedItem ? selectedItem : props.data.display.placeholder}</p>
        {selectedItem ? 
          <div
            id='Delete item'
            onClick={() => {
              props.onUpdateAlt({})
              setSelectedItem(null)
              setOpenList(true)
            }} 
          >
            <ExitQuote/>
          </div>
          :
          <ArrowQuote/>
        }
      </div>
      <div 
        className="FormFieldSelector-container-list"
        style={{
          maxHeight: openList ? 250 : 0,
          width: document.getElementById(`select_${props.data.id}`)?.offsetWidth
        }}
      >
        {props.data.input.popular.length !== 0 && <p className="FormFieldSelector-container-list-popularText">Más populares</p>}
        {props.data.input.popular.length !== 0 && props.data.input.popular.map((a: any, index: any) => (
          <p 
            id={a.label + ' item'}
            key={index}
            className="FormFieldSelector-container-list-listItemText"
            onClick={() => handleItemSelected(a)} 
          >{a.label}</p>
        ))}
        {props.data.input.popular.length !== 0 && <div className="FormFieldSelector-container-list-line"></div>}
        {props.data.input.values.length !== 0 && props.data.input.values.map((a: any, index: any) => (
          <p 
            id={a.label + ' item'}
            key={index}
            className="FormFieldSelector-container-list-listItemText"
            onClick={() => handleItemSelected(a)}
          >{a.label ? a.label: a.descripcion}</p>
        ))}
      </div>
    </div>
  );
} 

export default FormFieldSelector;