import { useEffect, useImperativeHandle, useState, forwardRef } from "react"
import './PatChileConfigComponent.css'
import { connect, useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { 
    addPatChile, 
    getPatData 
} from "../../../actions";
import Selector from "../../../components/generalComponents/selector/Selector";
import EmisoraType from "../../../components/checkoutComponents/CheckOutPayment/EmisoraType";
import CustomTextInput from "../../../components/generalComponents/customTextInput/CustomTextInput";
import { formatCreditCard } from "../../CreditCardAr/CreditCardUtils";
import { LOADING } from "../../../actions/types";
import { genericErrorHandler } from "../../../util/GenericErrorHandler";
import moment from 'moment/moment'

let isTabletOrMobile = false;
const PatChileConfigComponent = forwardRef((props: any, ref) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

    const [patChileData, setPatChileData] = useState<any>(null);
    const [banco, setBanco] = useState<any>(null);
    const [emisora, setEmisora] = useState<any>(null);
    const [nroTarjeta, setNroTarjeta] = useState<string>('');
    const [fechaTarjeta, setFechaTarjeta] = useState<string>('');

    useEffect(() => {
        props.getPatData(props.checkOutData.id, setPatChileData)
    }, []);

    const validateNumeroTarjeta = () => {
        // Emisora todavia no seleccionada?
        if (patChileData?.banks == null || emisora === null) {
            return false;
        }
        if (patChileData?.creditCards?.filter((a: any) => a.id === emisora?.id)[0]?.pattern.replaceAll(" ", "").length !== nroTarjeta.length) {
            return false;
        }

        return valid_credit_card(nroTarjeta);

    }

    const validateFechaTarjeta = () => {
        if (fechaTarjeta.length === 5) {
            if (
                moment(moment(fechaTarjeta, 'MM/YY').toDate()).isAfter(moment(new Date()).add(-1, 'M'),)
                && moment(moment(fechaTarjeta, 'MM/YY').toDate()).isBefore(moment(new Date()).add(50, 'y'),)
            ) {
                return true;
            }
        }
        return false;
    }

    const valid_credit_card = (value) => {
        if (/[^0-9-\s]+/.test(value)) return false;

        var nCheck = 0, nDigit = 0, bEven = false;
        value = value.replace(/\D/g, "");

        for (var n = value.length - 1; n >= 0; n--) {
            var cDigit = value.charAt(n);
            nDigit = parseInt(cDigit, 10);
            if (bEven) {
                if ((nDigit *= 2) > 9) nDigit -= 9;
            }
            nCheck += nDigit;
            bEven = !bEven;
        }
        return (nCheck % 10) === 0;
    }

    const isDisabled = () => {
        if (!props.cuotas || !banco || !emisora || !validateNumeroTarjeta() || !validateFechaTarjeta()) return true
        return false
    }

    useEffect(() => {
        const ready = !isDisabled();
        props.onChangeStatus(ready);
    })

    const dispatch = useDispatch();

    const writeFechaVencimiento = (text: string) => {
        let newText = text;
        if (text.length >= 3 && text[2] !== '/') {
            newText = text[0] + text[1] + '/' + text.slice(2);
        }
        setFechaTarjeta(newText);
    }

    return (
        <div className="PatChileConfigComponent-container">
            <div className="PatChileConfigComponent-container-row">
                <div className="PatChileConfigComponent-container-row-item">
                    <div className="PatChileConfigComponent-container-row-item-label">
                        <p className="PatChileConfigComponent-container-row-item-label-text">Nro de cuotas</p>
                        <p className="PatChileConfigComponent-container-row-item-label-requiered">&nbsp;*</p>
                    </div>
                    <Selector
                        id='Cuotas'
                        popularList={[]}
                        list={patChileData?.installments}
                        onValueSelected={(a: any) => props.setCuotas(a)}
                        selectedItem={props.cuotas?.installment}
                        placeholder='Selecciona en nro de cuotas'
                        deleteSelectedItem={() => props.setCuotas(null)}
                    /> 
                </div>
                <div className="PatChileConfigComponent-container-row-item">
                    <div className="PatChileConfigComponent-container-row-item-label">
                        <p className="PatChileConfigComponent-container-row-item-label-text">Monto de cuota </p>
                    </div>
                    <p className="PatChileConfigComponent-container-row-item-cuota">{props.cuotas ? props.cuotas?.prices[0]?.formattedValue : '...'}</p>
                </div>
            </div>
            <div className="PatChileConfigComponent-container-row">
                <div className="PatChileConfigComponent-container-row-item">
                    <div className="PatChileConfigComponent-container-row-item-label">
                        <p className="PatChileConfigComponent-container-row-item-label-text">Banco</p>
                        <p className="PatChileConfigComponent-container-row-item-label-requiered">&nbsp;*</p>
                    </div>
                    <Selector
                        id='Banco'
                        popularList={[]}
                        list={patChileData?.banks}
                        onValueSelected={(a: any) => setBanco(a)}
                        selectedItem={banco?.descripcion}
                        placeholder='Selecciona el banco'
                        deleteSelectedItem={() => setBanco(null)}
                    /> 
                </div>
                <div className="PatChileConfigComponent-container-row-item"></div>
            </div>
            <div className="PatChileConfigComponent-container-row">
                <div className="PatChileConfigComponent-container-row-item">
                    <div className="PatChileConfigComponent-container-row-item-label">
                        <p className="PatChileConfigComponent-container-row-item-label-text">Selecciona el emisor</p>
                        <p className="PatChileConfigComponent-container-row-item-label-requiered">&nbsp;*</p>
                    </div>
                    <div className="PatChileConfigComponent-container-row-item-emisoraList">
                        {patChileData?.creditCards?.map((emisoraItem: any, index: any) => (
                            <EmisoraType
                                selected={emisora?.name === emisoraItem.name}
                                name={emisoraItem.name}
                                onSelected={() => {
                                    setNroTarjeta('')
                                    if(emisora?.name === emisoraItem.name) setEmisora(null) 
                                    else setEmisora(emisoraItem)
                                }}
                            />
                        ))}
                    </div>
                </div>
                <div className="PatChileConfigComponent-container-row-item"></div>
            </div>
            <div className="PatChileConfigComponent-container-row">
                <div className="PatChileConfigComponent-container-row-item">
                    <div className="PatChileConfigComponent-container-row-item-input">
                        <div style={{width: '65%'}}>
                            <CustomTextInput
                                label='Nro de tarjeta'
                                required
                                changeText={(e: string) => setNroTarjeta(e.replace(/[^\d+$]+/g, ''))}
                                value={emisora ? formatCreditCard(nroTarjeta.replaceAll(" ", ""), patChileData?.creditCards?.filter((a: any) => a.id === emisora?.id)[0]?.pattern) : ''}
                                success={validateNumeroTarjeta()}
                                returnKeyType='done'
                                error={nroTarjeta !== "" ? !validateNumeroTarjeta() : false}
                                maxLength={patChileData?.creditCards?.filter((a: any) => a.id === emisora?.id)[0]?.pattern?.length}
                                disabled={!emisora}
                            />
                        </div>
                        <div style={{width: '30%'}}>
                            <CustomTextInput
                                disabled={nroTarjeta.length === 0}
                                label='Fecha de venc.'
                                required
                                placeholder='MM/AA'
                                success={validateFechaTarjeta()}
                                error={fechaTarjeta.length !== 0 && !validateFechaTarjeta()}
                                errorMessage="Fecha inválida"
                                maxLength={5}
                                value={fechaTarjeta}
                                changeText={writeFechaVencimiento}
                            />
                        </div>
                    </div>
                </div>
                <div className="PatChileConfigComponent-container-row-item"></div>
            </div>
        </div>
    );

});

const mapStateToProps = (state) => {
    return {
        countryId: state.general.countryId,
        checkOutData: state.checkOut.checkOutData,
    }
};

export default connect(mapStateToProps, {
    getPatData,
    addPatChile
}, null, { forwardRef: true })(PatChileConfigComponent);

