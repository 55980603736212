import { useSelector} from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { GetCountryUrl } from '../../util/GetCountryUrl';

const PublicRoute = () => {
    const userEmailValidated = useSelector((state: any) => state.user.userData);
    const userAccessToken = useSelector((state: any) => state.general.userAccessToken);
    const countryId = useSelector((state: any) => state.general.countryId);

    return !userAccessToken || !userEmailValidated ? <Outlet/> : <Navigate to={GetCountryUrl(countryId) + "home"}/>
};
  
export default PublicRoute;