import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import './PaymentMethodUbiiPagos.css'
import {
    sendUbiPagosData
} from '../../../actions'
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

declare var UbiiBoton: any;
let isTabletOrMobile = false;
const PaymentMethodUbiiPagos = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

    const navigate = useNavigate()

    useEffect(() => {
        UbiiBoton.initUbii(
            'ubiiboton',
            props.checkOutData.steps.filter((a: any) => a.type === "resume")[0].config.payment.config,
            handlePayConfirm,
            {
                text: "Pagar con UbiiPagos",
                textColor: "var(--white)",
                buttonColor: "#5C7F71",
                borderColor: "var(--white)"
            }
        );
        UbiiBoton.initUbii(
            'ubiibotonRes',
            props.checkOutData.steps.filter((a: any) => a.type === "resume")[0].config.payment.config,
            handlePayConfirm,
            {
                text: "Pagar con UbiiPagos",
                textColor: "var(--white)",
                buttonColor: "#466A5C",
                borderColor: "var(--white)"
            }
        );
    }, [props.checkoutData])

    const handlePayConfirm = (response: any) => {
        if (response.error) {

        } else {
            props.sendUbiPagosData(props.checkOutData.id, props.checkOutData.offer.product.title, props.userData.name, response.data, props.originalParams, navigate, props.countryId, false)
        }
    }

    return (
        <React.Fragment>
            {isTabletOrMobile ?
                <button id="ubiibotonRes" disabled={props.disabled} style={{opacity: props.disabled ? 0.4 : 1, width: '90%'}}></button>
                :
                <button id="ubiiboton" disabled={props.disabled} style={{opacity: props.disabled ? 0.4 : 1}}></button>
            }
        </React.Fragment>
    )
}

const mapStateToProps = (state: any) => {
    return {
        checkOutData: state.checkOut.checkOutData,
        userData: state.user.userData,
        countryId: state.general.countryId
    };
};

export default connect(mapStateToProps, {
    sendUbiPagosData
})(PaymentMethodUbiiPagos);
