import { FormattedMessage, useIntl } from "react-intl"
import CustomTextInput from "../../../components/generalComponents/customTextInput/CustomTextInput";
import '../ProductSuggestPage.css'



interface Props {
    setUserName: (name: string) => void
    setUserEmail: (email: string) => void
    onlyCharacters: (event: string) => boolean
    userName: string
    validateEmail: (email: string) => boolean
    userEmail: string
}

export const FormUserData = ({ setUserName, setUserEmail, onlyCharacters, userName, validateEmail, userEmail}: Props) => {
    return (
        <>
            <h3 className='suggest-title-data-personal'><FormattedMessage id='page.product.suggest.title.sinLogueo' defaultMessage='' /></h3>
            <p className='title-suggest-product'><FormattedMessage id='page.product.suggest.subtitle.sinLogueo' defaultMessage='' /></p>
            <div className='container-form-suggest-product' style={{ width: '90%' }}>
                <CustomTextInput
                    label={<FormattedMessage id='page.product.suggest.name' defaultMessage='' />}
                    placeholder={useIntl().formatMessage({ id: 'page.product.suggest.name.placeholder' })}
                    required
                    changeText={(e: string) => setUserName(e)}
                    error={userName.length > 0 ? !onlyCharacters(userName) : false}
                    value={userName}
                    suggest
                />
                <CustomTextInput
                    label={<FormattedMessage id='page.product.suggest.email' defaultMessage='' />}
                    placeholder={useIntl().formatMessage({ id: 'component.checkOut.login.email.placeholder' })}
                    required
                    changeText={(e: string) => setUserEmail(e)}
                    error={userEmail.length > 0 ? !validateEmail(userEmail) : false}
                    value={userEmail}
                    suggest
                />
            </div>
        </>
    )
}
