import { Slider } from '@mui/material'
import React, {useState, useEffect} from 'react'
import './FormFieldSlider.css'
import { styled } from '@mui/material/styles';

const IOSSlider = styled(Slider)(({ theme }) => ({
    '& .MuiSlider-valueLabel': {
      backgroundColor: 'var(--primary500)',
      borderRadius: '5px',
    },
}))

const FormFieldSlider = (props) => {

    const [min, setMin] = useState(props.data?.input?.config?.min);
    const [max, setMax] = useState(props.data?.input?.config?.max);

    useEffect(() => {
        if(props.data?.input?.config?.format?.type === 'unitnumber') {
            if(props.data?.input?.config?.min === 1) {
                setMin(props.data?.input?.config?.min + ' ' + props.data?.input?.config?.format?.config?.singular)
            } else {
                setMin(props.data?.input?.config?.min + ' ' + props.data?.input?.config?.format?.config?.plural)
            }
            if(props.data?.input?.config?.max === 1) {
                setMax(props.data?.input?.config?.max + ' ' + props.data?.input?.config?.format?.config?.singular)
            } else {
                setMax(props.data?.input?.config?.max + ' ' + props.data?.input?.config?.format?.config?.plural)
            }
        } else {
            setMin(props.data?.input?.config?.format?.config?.currency + Intl.NumberFormat(props.data?.input?.config?.format?.config?.locale)?.format(props.data?.input?.config?.min))
            setMax(props.data?.input?.config?.format?.config?.currency + Intl.NumberFormat(props.data?.input?.config?.format?.config?.locale)?.format(props.data?.input?.config?.max))
        }
    }, [])

    const handleChange = (event, newValue) => {
      props.onUpdate(newValue)
    };

    const valuetext = (value) => {
        if(props.data?.input?.config?.format?.type === 'unitnumber') {
            if(value === 1) {
                return value + ' ' + props.data?.input?.config?.format?.config?.singular
            } else {
                return value + ' ' + props.data?.input?.config?.format?.config?.plural
            }
        }
        return props.data?.input?.config?.format?.config?.currency + Intl.NumberFormat(props.data?.input?.config?.format?.config?.locale)?.format(value)
    }

    return (
        <div className="FormFieldNumber-inputContainer">
            <h1 dangerouslySetInnerHTML={{__html: props.data?.display?.name}}/>
            <p className="FormFieldNumber-inputContainer-description" dangerouslySetInnerHTML={{__html: props.data?.display?.description}}/>
            <div className="FormFieldNumber-inputContainer-sliderContainer">
                <IOSSlider 
                    defaultValue={50} 
                    aria-label="Default" 
                    valueLabelDisplay="auto" 
                    step={props.data?.input?.config?.step}
                    min={props.data?.input?.config?.min}
                    max={props.data?.input?.config?.max}
                    onChange={handleChange}
                    valueLabelFormat={valuetext}
                    sx={{color: 'var(--primary500)'}}
                />
                <div className="FormFieldNumber-inputContainer-sliderContainer-minMaxContainer">
                    <p className="FormFieldNumber-inputContainer-sliderContainer-minMaxContainer-text">{min}</p>
                    <p className="FormFieldNumber-inputContainer-sliderContainer-minMaxContainer-text">{max}</p>
                </div>
            </div>
        </div>
    );
}

export default FormFieldSlider;