import React from 'react'
import CustomTextInput from '../../../customTextInput/CustomTextInput';
import './FormFieldDefault.css'

const FormFieldDefault = (props: any) => {

    return (
        <div className="FormFieldDefault-inputContainer">
            <h1 dangerouslySetInnerHTML={{__html: props.data?.display?.name}}/>
            <p className="FormFieldDefault-inputContainer-description" dangerouslySetInnerHTML={{__html: props.data?.display?.description}}/>
            <div className="FormFieldDefault-inputContainer-wrapper">
                <CustomTextInput
                    label=''
                    placeholder={props.data?.display?.placeholder}
                    changeText={(e: string) => props.onUpdate(e)}
                />
            </div>
        </div>
    );
}

export default FormFieldDefault;