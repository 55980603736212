import { connect, useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import CustomTextInput from '../../components/generalComponents/customTextInput/CustomTextInput';
import Footer from '../../components/generalComponents/footer/Footer'
import Header from '../../components/generalComponents/header/Header'
import MainButton from '../../components/generalComponents/MainButton/MainButton'
import './ProductSuggestPage.css'
import Modal from 'react-modal';
import exitoPago1 from '../../assets/lotties/exitoSuggest.json'
import Lottie from 'react-lottie';
import { cargarItem, getUrlSuggest, getUserAuth, resetRecoveryPassword } from '../../actions'
import ImagePickerSuggestComponent from '../../components/suggestComponent/ImagePickerSuggestComponent/ImagePickerSuggestComponent';
import { useIntl, FormattedMessage } from 'react-intl';
import Cookies from 'universal-cookie';
import { GetGlobalParams } from '../../util/GetGlobalParams';
import { GetCountryUrl } from '../../util/GetCountryUrl';
import { validateEmail } from '../../util/ValidateEmail';
import { FormUserData } from './componentProductSuggest/FormUserData';

export interface IUrlPersing {
    loading: boolean;
    presignedUrl: string;
    defaultValue: string;
}

let isTabletOrMobile = false;
let urlNew = '';
const ProductSuggestPage = (props: any) => {

    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' });
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [productName, setProductName] = useState<string>('');
    const [brand, setBrand] = useState<string>('');
    const [model, setModel] = useState<string>('');
    const [manufacturingYear, setManufacturingYear] = useState<string>('');
    const [value, setValue] = useState<string>('');
    const [userName, setUserName] = useState<string>('');
    const [userEmail, setUserEmail] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [desactivarAlertId, setDesactivarAlertId] = useState<number>(-1);
    const recoverySuccess = useSelector((state: any) => state.login.recoverySuccess);
    const [urlObj, setUrlObj] = useState<IUrlPersing[]>([]);
    let [urlObjAux, setUrlObjAux] = useState<IUrlPersing[]>([]);
    const userLogin = useSelector((state: any) => state.user.userData);
    const [refresh, setRefresh] = useState<boolean>(false);

    useEffect(() => {
        let cookiesUA = new Cookies();
        cookiesUA = cookiesUA.get('userAccessToken')
        if (cookiesUA !== null && cookiesUA !== undefined) {
            dispatch(getUserAuth());
        }
        dispatch(getUrlSuggest());
    }, []);

    useEffect(() => {
    }, [urlObj, urlObjAux])

    useEffect(() => {
        if (recoverySuccess) {
            setDesactivarAlertId(1);
            dispatch(resetRecoveryPassword());
        }
    }, [recoverySuccess]);

    const disabled = () => {
        return (
            productName === '' ||
            brand === '' ||
            model === '' ||
            description === '' ||
            value === ''
        );
    };

    const disabledLogin = () => {
        return (
            productName === '' ||
            brand === '' ||
            model === '' ||
            description === '' ||
            value === '' ||
            userName === '' ||
            userEmail === '' ||
            !validateEmail(userEmail) ||
            !onlyCharacters(userName)
        );
    };

    const putImageS3 = (fileData: string, data: any, presignUrl: string, index: number) => {
        if (props.arraySugeridosUrl[index].presignedUrl === presignUrl) {
            props.arraySugeridosUrl[index].loading = true;
        }
        setUrlObjAux(props.arraySugeridosUrl[index])
        setRefresh(!refresh)
        fetch(fileData)
            .then((response) => {
                return response.blob();
            })
            .then((content) => {
                return fetch(presignUrl, {
                    method: 'PUT',
                    body: content,
                    headers: {
                        'Content-Type': data.type
                    }
                })
            })
            .then(() => {
                if (props.arraySugeridosUrl[index].presignedUrl === presignUrl) {
                    props.arraySugeridosUrl[index].defaultValue = fileData;
                    props.arraySugeridosUrl[index].loading = false;
                    setRefresh(!refresh)
                    if (index === 0) {
                        urlNew = presignUrl;
                    }
                }
                setUrlObj(props.arraySugeridosUrl[index])
            })
            .catch(() => {
                putImageS3(fileData, data, presignUrl, index);
            });
    };


    const desactivarModal = () => {
        return (
            <Modal
                isOpen={desactivarAlertId !== -1}
                onRequestClose={() => setDesactivarAlertId(-1)}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '25px',
                        width: isTabletOrMobile ? '80%' : '30%',
                        padding: isTabletOrMobile ? '10px' : '20px',
                    },
                    overlay: {
                        backgroundColor: 'rgba(255, 255, 255, 0.5)'
                    }
                }}
            >
                <div className="home-container-desactivarModal">
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: exitoPago1,
                            rendererSettings: {
                                preserveAspectRatio: 'xMidYMid slice'
                            }
                        }}
                        height={isTabletOrMobile ? 70 : 80}
                        width={isTabletOrMobile ? 70 : 80}
                        isStopped={false}
                        isPaused={false}
                    />
                    <p className="modal-text-title">{props.userData ? `¡GENIAL ${props.userData?.name.toUpperCase()} ${props.userData?.lastName.toUpperCase()}!` : userName !== '' ?
                        `¡GENIAL ${userName.toUpperCase()}!` : '!GENIAL!'}</p>
                    <p className='modal-text-title modal-text-subtitle'><FormattedMessage id='page.product.suggest.modal.title' defaultMessage='' /></p>
                    {userLogin ?
                        <p className="modal-text-subtitle-title"> <FormattedMessage id='page.product.suggest.modal.subtitle' defaultMessage='' /></p>
                        :
                        <p className="modal-text-subtitle-title"> <FormattedMessage id='page.product.suggest.modal.subtitle.nologueado' defaultMessage='' /></p>
                    }
                    {
                        !userLogin && <p className="modal-text-subtitle-title"><FormattedMessage id='page.product.suggest.modal.subtitle.two.nologueado' defaultMessage='' /></p>
                    }
                    {
                        userLogin && <MainButton
                            id='boton_sugerir'
                            text={'ver sugeridos'.toUpperCase()}
                            onButtonPressed={() => {
                                navigate(GetCountryUrl(props.countryId) + 'suggest?' + GetGlobalParams())
                                setDesactivarAlertId(-1)
                            }}
                        />}
                    {userLogin && <MainButton
                        id='boton_home'
                        empty
color='var(--secondary500)'
                        text={'volver al admin'.toUpperCase()}
                        onButtonPressed={() => {
                            setDesactivarAlertId(-1)
                            navigate(GetCountryUrl(props.countryId) + 'home?' + GetGlobalParams())
                        }}
                    />}
                    {!userLogin && <MainButton
                        id='boton_inicio'
                        text={'volver a la home'.toUpperCase()}
                        onButtonPressed={() => {
                            setDesactivarAlertId(-1)
                            navigate(GetCountryUrl(props.countryId) + 'inicio?' + GetGlobalParams())
                        }}
                    />}
                </div>
            </Modal>
        )
    }

    const onlyCharacters = (name: string): boolean => {
        let isValid = false;
        const pattern = new RegExp('^[A-ZÁÉÍÓÚÑ ]+$', 'i');
        if (pattern.test(name)){
          isValid = true;
        }
        return isValid;
    }

    return (
        <div>
            <Header
                backgroundColor={isTabletOrMobile ? "var(--white)" : null}
            />
            <div className="mainCotizar-container-titleContainer">
                <p className="mainCotizar-container-titleContainer-title">{useIntl().formatMessage({ id: 'page.product.suggest.title.main' })}</p>
            </div>
            <div className="mainCotizar-container-titleContainerMask" style={{ marginBottom: 20 }}>
                <div className="mainCotizar-container-titleContainerMask-inner"></div>
            </div>
            <div className='container-card-suggest'>
                <p className='title-suggest-product'><FormattedMessage id='page.product.suggest.title' defaultMessage='' /></p>
                <div style={{ display: 'flex', flexDirection: isTabletOrMobile ? 'column' : 'row', justifyContent: 'space-around', flex: 1 }}>

                    <div className='container-form-suggest-product'>

                        <CustomTextInput
                            label={<FormattedMessage id='page.product.suggest.productName' defaultMessage='' />}
                            placeholder={useIntl().formatMessage({ id: 'page.product.suggest.productName.placeholder' })}
                            required
                            changeText={(e: string) => setProductName(e)}
                            value={productName}
                            suggest
                            maxLength={250}
                        />

                        <CustomTextInput
                            label={<FormattedMessage id='page.product.suggest.brand' defaultMessage='' />}
                            placeholder={useIntl().formatMessage({ id: 'page.product.suggest.brand.placeholder' })}
                            required
                            changeText={(e: string) => setBrand(e)}
                            value={brand}
                            suggest
                            maxLength={250}
                        />

                        <CustomTextInput
                            label={useIntl().formatMessage({ id: 'page.product.suggest.model' })}
                            placeholder={useIntl().formatMessage({ id: 'page.product.suggest.model.placeholder' })}
                            required
                            changeText={(e: string) => setModel(e)}
                            value={model}
                            suggest
                            maxLength={250}
                        />

                        <CustomTextInput
                            label={useIntl().formatMessage({ id: 'page.product.suggest.year' })}
                            placeholder={useIntl().formatMessage({ id: 'page.product.suggest.year.placeholder' })}
                            changeText={(e: string) => {
                                e = e.replace(/[^\d+$]/g, '');
                                setManufacturingYear(e)
                            }}
                            value={manufacturingYear}
                            maxLength={4}
                            suggest
                        />
                        <CustomTextInput
                            label={useIntl().formatMessage({ id: 'page.product.suggest.value' })}
                            placeholder={useIntl().formatMessage({ id: 'page.product.suggest.value.placeholder' })}
                            required
                            changeText={(text: string) => {
                                text = text.replace(/[^\d+$]+/g, '');
                                setValue(text.split('$')[1] || text);
                            }
                            }
                            maxLength={7}
                            value={value.length < 3 ? `${value}` : `$${value}`}
                            suggest
                        />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: isTabletOrMobile ? 10 : 35, flex: 3 }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label className='label-description'>
                                <FormattedMessage id='page.product.suggest.description' defaultMessage='' /><p style={{ marginLeft: 8, color: 'red' }}>*</p>
                            </label>
                            <textarea
                                name="textarea1"
                                className="estilotextarea"
                                placeholder={useIntl().formatMessage({ id: 'page.product.suggest.description.placeholder' })}
                                onChange={(e) => setDescription(e.target.value)}
                                value={description}
                                cols={2}
                                rows={3}
                                maxLength={250}
                            />
                            {(description.length === 301) &&
                                <p style={{ marginBottom: 0, color: 'red', fontSize: 12 }}>Puedes escribir hasta 300 caracteres</p>
                            }
                        </div>

                        <label className='label-description' style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                            {useIntl().formatMessage({ id: 'page.product.suggest.image' })}
                        </label>

                        <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row' }}>
                            {props.arraySugeridosUrl?.map((b: IUrlPersing, indexB: number) => (
                                    <ImagePickerSuggestComponent
                                        key={indexB}
                                        b={b}
                                        onSelectedImage={(response: any, data: any) => putImageS3(response, data, b.presignedUrl, indexB)}
                                        refresh={refresh}
                                    />
                                ))
                            }

                        </div>
                    </div>

                    {!userLogin && <div style={{ flex: 2.6 }}>
                        <FormUserData 
                        setUserName={setUserName}
                        userName={userName}
                        setUserEmail={setUserEmail}
                        userEmail={userEmail}
                        onlyCharacters={onlyCharacters}
                        validateEmail={validateEmail}
                        />
                    </div>

                    }
                </div>

                <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
                    <div style={{ width: isTabletOrMobile ?  '100%': '34%', marginTop: !isTabletOrMobile ? '1vh' : '5vh', position: 'relative', right: 10}}>
                        <MainButton
                            id='sugerir_producto_boton'
                            disabled={userLogin ? disabled() : disabledLogin()}
                            text='SUGERIR PRODUCTO'
                            onButtonPressed={() => {
                                if (!disabled()) {
                                    dispatch(cargarItem(
                                        productName,
                                        brand.concat(' ', model),
                                        description,
                                        parseInt(value),
                                        urlNew,
                                        userEmail,
                                        userName
                                    ))
                                }
                            }
                            }
                        />
                    </div>
                </div>
                <div style={{ display: isTabletOrMobile ? 'none' : 'flex', justifyContent: 'center' }}>
                    <p className='help-text-suggest'><FormattedMessage id='page.product.suggest.ayuda' defaultMessage='' /></p>
                    <p className='help-text-suggest' style={{ marginLeft: 10, cursor: 'pointer', color: 'var(--primary500)' }}
                        onClick={() => navigate(GetCountryUrl(props.countryId) + 'contacto?' + GetGlobalParams())}
                        id='contacto_sugerir_producto'
                    ><FormattedMessage id='page.product.suggest.whatsapp' defaultMessage='' /></p>
                </div>

            </div>
            <Footer />
            {desactivarModal()}
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        countryId: state.general.countryId,
        arraySugeridosUrl: state.sugerir.arraySugeridosUrl,
        userData: state.user.userData,
    };
};

export default connect(mapStateToProps)(ProductSuggestPage)
