
import React from 'react'
import { useMediaQuery } from 'react-responsive'
import './InfoSection.css'
import landcirc2 from '../../../assets/images/land-circ-2.png';
import landcirc3 from '../../../assets/images/land-circ-3.png';
import landcirc4 from '../../../assets/images/land-circ-4.png';
import landcirc1 from '../../../assets/images/land-circ-1.png';

let isTabletOrMobile = false;
const InfoSection = (props: any) => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 38.5em)' });

  const beneficios = [
    {
      img: landcirc1,
      text: 'Elegí la cobertura más adecuada para vos.'
    },
    {
      img: landcirc2,
      text: 'Completá los datos requeridos para su activación.'
    },
    {
      img: landcirc3,
      text: '¡Listo! Ya podés aprovechar tu cobertura de salud.'
    },
    {
      img: landcirc4,
      text: 'Gestionala 100% desde la comodidad de tu celular.'
    },
  ]

  return (
    <div className="infoSection-container">
      <h4 className='infoSection-container-title bold'>Afiliate así de fácil</h4>
      <div className='infoSection-container-list'>
        {beneficios?.map((a, index) => (
          <div key={index} className='infoSection-container-list-item'>
            <img className='infoSection-container-list-item-img' src={a.img} />
            {isTabletOrMobile ? 
              <p className='infoSection-container-list-item-text regular'>{a.text}</p>
              :
              <b className='infoSection-container-list-item-text large regular'>{a.text}</b>
            }
          </div>
        ))}
      </div>
    </div>
  );
}

export default InfoSection;