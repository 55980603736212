import switchOff from '../../../assets/images/switchOff.png'
import { SuggestDTO } from '../../../models/suggestItem.model';
import './CardSuggest.css'

const styles = {
    itemImage: {
        resizeMode: 'contain',
        height: 100,
        width: 100,
        marginTop: 17,
        marginLeft: 18,
        marginBottom: 17
    },
    itemTextContainer: {
        marginLeft: 10,
    }
};

interface Props {
    item: SuggestDTO;
    key: number;
}

function CardSuggest( { item, key }: Props) {
    return (<div style={{
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: 'var(--white)',
        borderRadius: 15,
        height: 134,
        width: 426,
        position: 'relative',
    }}
    key={key}
    >
        <img
            style={styles.itemImage}
            src={item.image}
        />
        <div style={styles.itemTextContainer}>
            <p className='card-item-name'>{item.name}</p>
            <p className='card-item-model'>{item.model}</p>
            <p className='card-item-value'>Valor: $ {item.value}</p>
        </div>
        <img
            style={{
                width: '10%',
                marginLeft: 'auto',
                marginTop: 'auto',
                position: 'absolute',
                top: 10,
                right: 20           
            }}
            src={switchOff}
        />
        {/* <div style={{
            backgroundColor: 'rgba(190, 190, 190, 0.3)',
            //  backgroundColor: 'red',
             left: 0,
             right: 0,
             top: 0,
             bottom: 0,
             position: 'absolute',
             alignSelf: 'stretch',
             borderRadius: 15,
        }} /> */}
    </div>
)
}

export default CardSuggest
