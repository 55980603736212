import React, {useEffect, useRef} from 'react'
import { ReactComponent as ButtonExit } from '../../../assets/images/svgs/exit.svg';
import './ErrorComponent.css'

const ErrorComponent = (props: any) => {

    const mainRef: any = useRef()

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (mainRef.current && !mainRef.current.contains(event.target)) {
                props.toggleErrorOff()
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [mainRef]);
    return (
        <div className="errorComponent-container" ref={mainRef}>
            <div 
                className="errorComponent-container-close"
                onClick={() => props.toggleErrorOff()}
            >
                <ButtonExit/>
            </div>
            {props.errorMessage && 
                <React.Fragment>
                    <p className="errorComponent-container-title">{props.errorMessage.title}</p>
                    <p className="errorComponent-container-description">{props.errorMessage.description}</p>
                </React.Fragment>
            }
        </div>
    );
}

export default ErrorComponent;