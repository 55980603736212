import React, { useState } from 'react'
import './OfferType.css'
import { useMediaQuery } from 'react-responsive'

let isTabletOrMobile = false;
const OfferType = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

    return (
        <div 
            className="offerType-container"
            style={{
                width: isTabletOrMobile ? '100%' : (50 / props.data?.length.toString()) + '%' /* Contruye el ancho de cada seccion en base a la cantidad que hay */
            }}
        >
        {props.data?.map((a: any, index: any) => (
            <div
                id={a.currency}
                key={a.currency}
                className={a.currency === props.selected ? "offerType-container-itemSel" : "offerType-container-item"}
                onClick={() => props.onChange(a.currency)}
            >
                <p className={a.currency === props.selected ? "offerType-container-item-textSel" : "offerType-container-item-text"}>{'VER EN ' + a.currency}</p>
            </div>
        ))}
        </div>
    )
}

export default OfferType;