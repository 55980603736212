import React, {useState} from 'react'
import './NoFinePrintComponent.css'
import { Markup } from 'interweave';
import MainButton from '../../generalComponents/MainButton/MainButton';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as ButtonExit } from '../../../assets/images/svgs/exit.svg';

const NoFinePrintComponent = (props: any) => {

    const [showExclusions, setShowExclusions] = useState<boolean>(false)

    return (
        <div className="noFinePrintComponent-container">
            <div 
                id='restricciones cerrar'
                className="noFinePrintComponent-container-close"
                onClick={() => props.onCloseClick()}
            >
                <ButtonExit/>
            </div>
            <p className="noFinePrintComponent-container-title"><FormattedMessage id = "component.noFinePrint.title" defaultMessage=""/></p>
            <p className="noFinePrintComponent-container-subTitle">{showExclusions ? <FormattedMessage id = "component.noFinePrint.subTitle1" defaultMessage=""/> : <FormattedMessage id = "component.noFinePrint.subTitle2" defaultMessage=""/>}</p>
            <div className="noFinePrintComponent-container-text">
                <Markup content={showExclusions ? props.text?.exclusions : props.text?.inclusions} />
            </div>
            <div className="noFinePrintComponent-container-buttonWrappers">
                <MainButton
                    id='Continuar Boton'
                    disabled={false}
                    text='ACEPTAR'
                    onButtonPressed={() => props.onCloseClick()}

                />
                {props.text?.exclusions && 
                    <React.Fragment>
                        <div style={{height: 10}}></div>
                        <MainButton
                            id='Continuar Boton'
                            disabled={false}
                            text={showExclusions ? '¿Que cubrimos?'.toUpperCase() : '¿Que no cubrimos?'.toUpperCase()}
                            onButtonPressed={() => setShowExclusions(!showExclusions)}
                            altStyle2
                        />
                    </React.Fragment>
                }
            </div>
        </div>
    )
}

export default NoFinePrintComponent;