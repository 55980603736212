import React, {useState} from 'react'
import './FormFieldGeo.css'
import GoogleMapReact from 'google-map-react';
import mapMarker from '../../../../../assets/images/pinMap.png'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from 'react-places-autocomplete';
import { ReactComponent as Lupa } from '../../../../../assets/images/svgs/lupa.svg';
import { ReactComponent as AddressIcon } from '../../../../../assets/images/svgs/addressIcon.svg';

const FormFieldGeo = (props) => {

    const [localDireccion, setLocalDireccion] = useState('');
    const [mapCoordinates, setMapCoordinates] = useState({
        center: {
            lat: parseFloat(props.data.input.config.startPos[0]),
            lng: parseFloat(props.data.input.config.startPos[1])
        }, 
        zoom: props.data.input.config.zoom
    });

    const handleSelect = (address) => {
        setLocalDireccion(address)
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                let aux = {center: {lat: latLng.lat, lng: latLng.lng}, zoom: 11}
                const data = {
                    "street": address,
                    "position": latLng.lat + ', ' + latLng.lng
                }
                props.onUpdate(data)
                setMapCoordinates(aux)
            })
            .catch(error => {});
    };


    return (
        <div className="FormFieldGeo-container">
            <div className="FormFieldGeo-container-addresses">
                <PlacesAutocomplete
                    value={localDireccion}
                    onChange={setLocalDireccion}
                    onSelect={handleSelect}
                >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <React.Fragment>
                    <div className='FormFieldGeo-container-addresses-inputContainer'>
                        <Lupa/>
                        <input
                            {...getInputProps({
                                className:'FormFieldGeo-container-addresses-inputContainer-input',
                                placeholder:'Escribe tu dirección',
                            })}
                        />
                    </div>
                    <div className="FormFieldGeo-container-addresses-list">
                        {loading && <div>Buscando...</div>}
                        {suggestions.map(suggestion => {
                            return (
                                <div {...getSuggestionItemProps(suggestion)} className="FormFieldGeo-container-addresses-list-item">
                                    <AddressIcon/>
                                    <p className="FormFieldGeo-container-addresses-list-item-text">{suggestion.description}</p>
                                </div>
                            );
                        })}
                    </div>
                    </React.Fragment>
                )}
                </PlacesAutocomplete>
            </div>
            <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyDH19rVbhD4pIfNCp70KBxfWUjVJFONdGY" }}
                center={mapCoordinates.center}
                zoom={mapCoordinates.zoom}
                options={{
                    zoomControl: false,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false
                }}
            > 

                <div 
                    className="FormFieldGeo-container-markerContainer"
                    lat={mapCoordinates.center.lat}
                    lng={mapCoordinates.center.lng}
                >
                    <img src={mapMarker} className="FormFieldGeo-container-markerContainer-img"/>
                </div>
            </GoogleMapReact>
        </div>
    );
}

export default FormFieldGeo;