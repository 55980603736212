import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl';
import CheckOutResumePaymentComponent from '../../../components/checkoutComponents/CheckOutResumePaymentComponent/CheckOutResumePaymentComponent';
import { getPatChile } from '../../../services/PatChileService';
import { genericErrorHandler } from '../../../util/GenericErrorHandler';
import './PatChileResumeCreditCard.css'

const PatChileResumeCreditCard = (props: any) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [creditCard, setCreditCard] = useState<any>();

    useEffect(() => {
        async function fetchData() {
            if (props.data) {
                setLoading(true)

                try {
                    const creditCardInfo = await getPatChile(props.data?.policyHolder?.id, props.data?.paymentInfo?.id)
                    setCreditCard(creditCardInfo);
                } catch (error) {
                    genericErrorHandler(error);
                } finally {
                    setLoading(false)
                }

            }
        }
        fetchData();
    }, [props.data])

    return (
        <div className="checkOutResume-container-mainDesktop-details-itemWrapper">
            <div className="checkOutResume-container-mainDesktop-details-itemWrapper-medioContainer">
                <p className="checkOutResume-container-mainDesktop-details-itemWrapper-medioContainer-medioText"><FormattedMessage id="component.checkOut.resume.medioDePago" defaultMessage="" /></p>
                <p className="checkOutResume-container-mainDesktop-details-itemWrapper-medioContainer-tarjetaText">PAT</p>
            </div>
            {!loading && <CheckOutResumePaymentComponent
                data={creditCard}
            />}
            <p
                onClick={() => props.otroMedioPagoPressed()}
                className="checkOutResume-container-mainDesktop-details-itemWrapper-otroText"
            ><FormattedMessage id="component.checkOut.resume.elegirOtroMedioDePago" defaultMessage="" /></p>
        </div>
    )
}

export default PatChileResumeCreditCard;