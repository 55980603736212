import React from 'react'
import './SimpleCreditCardDisplay.css'
import { CreditCard } from '../../../services/CreditCard';

export interface  SimpleCreditCardDisplayProps {
    card: CreditCard
}

const SimpleCreditCardDisplay = ({card}: SimpleCreditCardDisplayProps ) => {
    return (
        <React.Fragment>
            <img src={card.brand.logotype} alt={card.brand.name} className="brand-img" />
            {card.bank && <p className="simple-text">{card.bank.descripcion}</p>}
            <p className="simple-text">{' **** ' + card.lastDigits}</p>
        </React.Fragment>
    );
}

export default SimpleCreditCardDisplay;