import {
    EMAIL_VALIDATE,
    USER_ACCESS_TOKEN_AUX,
    RESEND_VALID_CODE_SUCCESS,
    RECOVERY_PASSWORD_SUCCESS,
    RECOVERY_PASSWORD_ID
} from '../actions/types';
import Cookies from 'universal-cookie';

const INITIAL_STATE = {
    emailValidate: '',
    userAccessTokenAux: '',
    resendValidCodeSuccess: false,
    recoverySuccess: false,
    recoveryPasswordId: ''
};

export default (state = INITIAL_STATE, action: any) => {
    const cookies = new Cookies();
    switch (action.type) {
        case EMAIL_VALIDATE:
            return { ...state, emailValidate: action.payload };
        case USER_ACCESS_TOKEN_AUX:
            if (action.payload) {
                cookies.set('userAccessTokenAux', action.payload);
            } else {
                cookies.remove('userAccessTokenAux');
            }
            return { ...state, userAccessTokenAux: action.payload }; 
        case RESEND_VALID_CODE_SUCCESS:
            return { ...state, resendValidCodeSuccess: action.payload };
        case RECOVERY_PASSWORD_SUCCESS:
            return { ...state, recoverySuccess: action.payload };
        case RECOVERY_PASSWORD_ID:
            return { ...state, recoveryPasswordId: action.payload };
        default:
            return state;
    }
};