import { useEffect, useState } from 'react';
import { CreditCard } from '../../../services/CreditCard';
import { getCreditCard } from '../../../services/CreditCardService';
import { getPatChile } from '../../../services/PatChileService';
import { genericErrorHandler } from '../../../util/GenericErrorHandler';
import SimpleCreditCardDisplay from '../../CreditCardAr/SimpleCreditCardDisplay/SimpleCreditCardDisplay';

const PaymentPolicyResumePatChile = (props: any) => {
    
    const [loading, setLoading] = useState<boolean>(true);
    const [creditCard, setCreditCard] = useState<CreditCard>();

    const fetchData = async (creditCardId: number, userId: number) => {
        if (props.idx !== undefined && props.userId !== undefined) {
            setLoading(true)

            try {
                const creditCardInfo = await getPatChile(userId, creditCardId)
                setCreditCard(creditCardInfo);
            } catch (error) {
                genericErrorHandler(error);
            } finally {
                setLoading(false)
            }

        }
    }
    useEffect(() => {
        fetchData(props.idx, props.userId);
    }, [props.idx, props.userId])

    return (
        <>
            {creditCard && <SimpleCreditCardDisplay
                card={creditCard}
            />}
        </>
    )
}

export default PaymentPolicyResumePatChile;