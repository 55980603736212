import React, {useState} from 'react'
import './DiscountDetailsComponent.css'
import { useMediaQuery } from 'react-responsive'
import {connect} from 'react-redux';
import CheckoutDetailsProduct from '../CheckoutDetailsProduct/CheckoutDetailsProduct';
import { useIntl } from 'react-intl';
import {
    checkOutDiscount,
    checkOutDiscountDelete,
    promoCoceErrorToggle,
    changeReferer,
    changeRefererApplied,
    checkRefererAvailability
} from '../../../actions'

let isTabletOrMobile = false;
const DiscountDetailsComponent = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

    const [localPromo, setLocalPromo] = useState<string>('')
    const [localAsist, setLocalAsist] = useState<string>('')

    const pagasText = () => {
        if (props.checkOutData?.offer?.packages?.filter((a: any) => a?.id === props.checkOutData?.offer?.currentPackage)[0]?.installmentConfig) {
            return 'Pagas por ' + props.checkOutData?.offer?.packages?.filter((a: any) => a?.id === props.checkOutData?.offer?.currentPackage)[0]?.name?.toLowerCase() + ' por ' + props.checkOutData?.offer?.packages?.filter((a: any) => a?.id === props.checkOutData?.offer?.currentPackage)[0]?.installmentConfig?.cycle?.displayName?.toLowerCase() + ' durante ' + props.checkOutData?.offer?.packages?.filter((a: any) => a?.id === props.checkOutData?.offer?.currentPackage)[0]?.installmentConfig?.installments + ' ' + props.checkOutData?.offer?.packages?.filter((a: any) => a?.id === props.checkOutData?.offer?.currentPackage)[0]?.installmentConfig?.cycle?.displayPluralName?.toLowerCase()
        } return 'Pagas por ' + props.checkOutData?.offer?.packages?.filter((a: any) => a?.id === props.checkOutData?.offer?.currentPackage)[0]?.name?.toUpperCase()
    }

    const priceText = () => {
        if (props.checkOutData?.offer?.packages?.filter((a: any) => a?.id === props.checkOutData?.offer?.currentPackage)[0]?.installmentConfig) {
            return props.checkOutData?.offer?.packages?.filter((a: any) => a?.id === props.checkOutData?.offer?.currentPackage)[0]?.installmentConfig?.price[0]?.formattedValue
        } return props.checkOutData?.offer?.packages?.filter((a: any) => a?.id === props.checkOutData?.offer?.currentPackage)[0]?.discountedPrice ? props.checkOutData?.offer?.packages?.filter((a: any) => a?.id === props.checkOutData?.offer?.currentPackage)[0]?.discountedPrice?.formattedValue : props.checkOutData?.offer?.packages?.filter((a: any) => a?.id === props.checkOutData?.offer?.currentPackage)[0]?.price?.formattedValue
    }

    return (
        <div className="discountDetailsComponent-container">
            <CheckoutDetailsProduct
                plan='Vigencia Anual'
                checkOutData={props.checkOutData}
                wierd={props.wierd}
            />
            <div className="discountDetailsComponent-container-code">
                <span className="material-symbols-outlined" style={{ fontSize: 24, color: props.refererApplied ? 'var(--statusSuccessPrimary)' : 'var(--primary500)' }}>person_pin_circle</span>
                <input
                    className="discountDetailsComponent-container-code-input"
                    placeholder='¿Un vendedor te asiste? Escribí su DNI'
                    value={props.refererApplied ? props.referer : localAsist}
                    onChange={(e: any) => setLocalAsist(e.target.value.replace(/\D/g, ''))}
                    maxLength={8}
                    style={{ color: props.refererApplied ? 'var(--statusSuccessPrimary)' : 'var(--primary500)'}}
                />
                {props.refererApplied ? 
                    <div 
                        className="discountDetailsComponent-container-code-misapply"
                        onClick={() => {
                            setLocalAsist('')
                            props.changeReferer('')
                            props.changeRefererApplied(false)
                        }}
                    >
                        <p className="discountDetailsComponent-container-code-misapply-text bold">DESHACER</p>
                    </div>
                    :
                    <div 
                        className="discountDetailsComponent-container-code-apply" 
                        style={{ opacity: localAsist !== '' ? 1 : 0.4, cursor: localAsist !== '' ? 'pointer' : 'auto'}}
                        onClick={() => {
                            if(localAsist !== '') {
                                props.checkRefererAvailability(localAsist)
                                if(props.setRefererError){
                                    props.setRefererError(false)
                                }
                            }
                        }}
                    >
                        <span className="material-symbols-outlined" style={{ color: 'var(--white)' }}>chevron_right</span>
                    </div>
                }
            </div>
            {props.refererError && <p className="discountDetailsComponent-container-codeError">El DNI no corresponde a un vendedor activo</p>}
            {props.refererErrorEmpty && <p className="discountDetailsComponent-container-codeError">Este campo es obligatorio para finalizar la compra.</p>}
            <div className={"discountDetailsComponent-container-code"}>
                <span className="material-symbols-outlined" style={{ fontSize: 24, color: 'var(--primary500)' }}>sell</span>
                <input
                    className="discountDetailsComponent-container-code-input"
                    placeholder={useIntl().formatMessage({ id: 'component.checkOut.discount.placeholder' })}
                    value={props.checkOutData?.offer?.promo ? props.checkOutData?.offer?.promo?.code : localPromo}
                    onChange={(e: any) => {
                        setLocalPromo(e.target.value)
                        if(e.target.value.length === 0) {
                            props.promoCoceErrorToggle('')
                        }
                    }}
                    disabled={props.checkOutData?.offer?.promo}
                    style={{
                        color: props.checkOutData?.offer?.promo ? 'var(--fifthTextColor)' : 'var(--primary500)'
                    }}
                />
                {props.checkOutData?.offer?.promo ? 
                    <div 
                        className="discountDetailsComponent-container-code-misapply"
                        onClick={() => {
                            setLocalPromo('')
                            props.checkOutDiscountDelete(props.checkOutData?.id)
                        }}
                    >
                        <p className="discountDetailsComponent-container-code-misapply-text bold">DESHACER</p>
                    </div>
                    :
                    <div 
                        className="discountDetailsComponent-container-code-apply" 
                        style={{ opacity: localPromo !== '' ? 1 : 0.4, cursor: localPromo !== '' ? 'pointer' : 'auto'}}
                        onClick={() => {
                            if(localPromo !== '') {
                                props.checkOutDiscount(localPromo, props.checkOutData?.id)
                            }
                        }}
                    >
                        <span className="material-symbols-outlined" style={{ color: 'var(--white)' }}>chevron_right</span>
                    </div>
                }
            </div>
            {props.promoCodeError && <p className="discountDetailsComponent-container-codeError">{props.promoCodeError}</p>}
            {props.checkOutData?.offer?.promo && <p className="discountDetailsComponent-container-codeSuccess">{props.checkOutData?.offer?.promo?.description}</p>}
            {props.checkOutData && 
                <div className="discountDetailsComponent-container-price">
                    <p className="discountDetailsComponent-container-price-text" >{pagasText()}</p>
                    <p className="discountDetailsComponent-container-price-price" >{priceText()}</p>
                </div>
            }
        </div>
    );
}

const mapStateToProps = (state: any) => {
    return {
        userData: state.user.userData,
        checkOutData: state.checkOut.checkOutData,
        countryId: state.general.countryId,
        promoCodeError: state.checkOut.promoCodeError,
        referer: state.checkOut.referer,
        refererApplied: state.checkOut.refererApplied,
        refererError: state.checkOut.refererError
    }; 
};

export default connect(mapStateToProps, {
    checkOutDiscountDelete,
    checkOutDiscount,
    promoCoceErrorToggle,
    changeReferer,
    changeRefererApplied,
    checkRefererAvailability
})(DiscountDetailsComponent);