import React, { useEffect, useState, useRef } from 'react'
import './Moto.css'
import Header from '../../../components/generalComponents/header/Header';
import Footer from '../../../components/generalComponents/footer/Footer';
import CustomTextInput from '../../../components/generalComponents/customTextInput/CustomTextInput';
import MainButton from '../../../components/generalComponents/MainButton/MainButton';
import { useNavigate } from 'react-router-dom';
import {store} from '../../../index';
import { connect } from 'react-redux';
import {
  getGenericForm,
  postGenericForm
} from '../../../actions'
import ReCAPTCHA from "react-google-recaptcha";
import { useMediaQuery } from 'react-responsive';

let isTabletOrMobile = false;
const Moto = (props: any) => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 38.5em)' });
  const [scrollPosition, setScrollPosition] = useState(0);

  const [marca, setMarca] = useState<string>('');
  const [modelo, setModelo] = useState<string>('');
  const [año, setAño] = useState<string>('');

  const [nombre, setNombre] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [celular, setCelular] = useState<string>('');

  const [reCaptcha, setReCaptcha] = useState(null)
  const recaptchaRef = useRef();
  const [formList, setFormList] = useState<any>([]);

  const countryId = store.getState().general.countryId

  const navigate = useNavigate();

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    props.getGenericForm('motorbike', setFormList)
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  }, [])

  const validateEmail = (e: string) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(e).toLowerCase())) {
        return false;
    } return true
  }

  const isDisabled = () => {
    return marca === '' || modelo === '' || año === '' || nombre === '' || !validateEmail(email) || celular === '' || !reCaptcha;
  }

  const reCaptchaCapture = (value: any) => {
    setReCaptcha(value)
  }

  return (
    <div className="moto-container">
        <Header home colored fixed trans={scrollPosition > 30} special={!isTabletOrMobile} />      
        <div className="moto-container-main">
          <div className="moto-container-main-top">
            <p className="moto-container-main-top-title">Seguro de moto</p>
          </div>
          <p className="moto-container-main-title">{formList?.genericFormDTO?.description}</p>
          <div className="moto-container-main-inputs">
            <div className="moto-container-main-inputs-list">
              <p className="moto-container-main-inputs-list-title">Datos de la moto</p>
              <div className="moto-container-main-inputs-list-inputWrapper">
                <CustomTextInput
                  label='Marca'
                  required
                  changeText={(e: string) => setMarca(e)}
                  value={marca}
                  especial
                />
              </div>
              <div className="moto-container-main-inputs-list-inputWrapper">
                <CustomTextInput
                  label='Modelo'
                  required
                  changeText={(e: string) => setModelo(e)}
                  value={modelo}
                  especial
                />
              </div>
              <div className="moto-container-main-inputs-list-inputWrapper">
                <CustomTextInput
                  label='Año'
                  required
                  changeText={(e: string) => setAño(e.replace(/[^0-9]+/g, ''))}
                  value={año}
                  especial
                  maxLength={4}
                />
              </div>
            </div>
            <div className="moto-container-main-inputs-list">
              <p className="moto-container-main-inputs-list-title">Datos personales</p>
              <div className="moto-container-main-inputs-list-inputWrapper">
                <CustomTextInput
                  label='Nombre y apellido'
                  required
                  changeText={(e: string) => setNombre(e)}
                  value={nombre}
                  especial
                />
              </div>
              <div className="moto-container-main-inputs-list-inputWrapper">
                <CustomTextInput
                  label='E-mail'
                  required
                  changeText={(e: string) => setEmail(e)}
                  value={email}
                  success={validateEmail(email)}
                  error={email.length > 0 ? !validateEmail(email) : false}
                  errorMessage='E-mail inválido'
                  especial
                />
              </div>
              <div className="moto-container-main-inputs-list-inputWrapper">
                <CustomTextInput
                  label='Celular'
                  required
                  changeText={(e: string) => setCelular(e.replace(/[^0-9]+/g, ''))}
                  value={celular}
                  preValue='+56 '
                  especial
                  maxLength={9}
                />
              </div>
            </div>
          </div>
          <div className="moto-container-main-captcha">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6LdFokQfAAAAAMQ3MMEbQmaltswGk50-MKQoaS8m"
              onChange={reCaptchaCapture}
            />
          </div>
          <div className="moto-container-main-buttons">
            <div className="moto-container-main-buttons-buttonWrapper">
              <MainButton
                id='Volver'
                text='VOLVER'
                onButtonPressed={() => navigate(-1)}
                altStyle2
              />
            </div>
            <div className="moto-container-main-buttons-buttonWrapper">
              <MainButton
                id='Enviar'
                text='ENVIAR'
                onButtonPressed={() => {
                  const data = {
                    brand: marca,
                    cellphone: celular,
                    model: modelo,
                    name_lastname: nombre,
                    year: año,
                    mail: email
                  }
                  props.postGenericForm('motorbike', data, reCaptcha, recaptchaRef, setReCaptcha, navigate, 'moto-exito', countryId)
                }}
                disabled={isDisabled()}
              />
            </div>
          </div>
        </div>
      <Footer />
    </div>
  )
}

export default connect(null, {
  getGenericForm,
  postGenericForm
})(Moto);