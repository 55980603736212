import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom'
import './CheckoutFin.css'
import success from '../../assets/lotties/success.json'
import error from '../../assets/lotties/errorPago.json'
import Lottie from 'react-lottie';
import logo from '../../assets/images/svgs/logoColor.svg'
import MainButton from '../../components/generalComponents/MainButton/MainButton';
import { useMediaQuery } from 'react-responsive';

let isTabletOrMobile = false;
const CheckoutFin = (props: any) => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

  const navigate = useNavigate();
  const [estado, setEstado] = useState<any>(null)
  const [searchParams] = useSearchParams();

  useEffect(() => {
    handleUrlParams()
  }, [])

  const handleUrlParams = () => {
    setEstado(searchParams.get("estado"))
  }

  const estadoColor = () => {
    if(estado === 'Exito') return 'var(--statusSuccessPrimary)'
    return 'var(--statusErrorPrimary)'
  }

  const lottieSize = estado === 'Exito' ? 200 : 150

  return (
    <div className="checkoutFin-container">
      <div className="checkoutFin-container-main">
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: estado === 'Exito' ? success : error,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
          }}
          height={lottieSize}
          width={lottieSize}
          isStopped={false}
          isPaused={false}
        />
        <img src={logo} className="checkoutFin-container-main-img"/>
        <h5 className="checkoutFin-container-main-title light">{estado === 'Exito' ? '¡Felicidades!' : '¡Algo salió mal!'}</h5>
        <h5 className="checkoutFin-container-main-subTitle regular">{estado === 'Exito' ? 'Tu cobertura fue solicitada exitosamente' : 'No pudimos completar el proceso de la cobertura'}</h5>
        <b className="checkoutFin-container-main-description regular large">{estado === 'Exito' ? 'Entrará en vigencia 24 h después de realizada la contratación. Mientras tanto, podés ver su estado ingresando a tu portal de administración.' : props.checkOutConfirmErrorMessage}</b>
        <div className="checkoutFin-container-main-bot">
          <MainButton
            id='VOLVER AL HOME'
            fill
            color='var(--secondary500)'
            text='VOLVER AL HOME'
            onButtonPressed={() => navigate('/inicio')}
          />
        </div>
        <div className="checkoutFin-container-main-bot">
          <MainButton
            id='INGRESAR A MI ADMIN'
            border
            color='var(--secondary500)'
            text='INGRESAR A MI ADMIN'
            onButtonPressed={() => navigate('/home')}
          />
        </div>
      </div>
      <div className="checkoutFin-container-wave">
        {isTabletOrMobile ? 
          <svg width="430" height="256" viewBox="0 0 430 256" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M233 86.5C141 54.1372 91 14 0 51.4989V256H431.5V0C431.5 0 325 118.863 233 86.5Z" fill={estadoColor()}/>
          </svg>        
          :
          <svg width="1440" height="265" viewBox="0 0 1440 265" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M703 167.5C296 55.2686 158 49 0 127V264.501L1440 264.5V0C1440 0 1110 279.731 703 167.5Z" fill={estadoColor()}/>
          </svg>
        }
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    checkOutConfirmErrorMessage: state.checkOut.checkOutConfirmErrorMessage,
  };
};

export default connect(mapStateToProps, {})(CheckoutFin);