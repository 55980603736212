import React from 'react'
import './TitleTextAlert.css'
import { Markup } from 'interweave';
import MainButton from '../../generalComponents/MainButton/MainButton';
import { ReactComponent as ButtonExit } from '../../../assets/images/svgs/exit.svg';

const TitleTextAlert = (props: any) => {

    return (
        <div className="titleTextAlert-container">
            <div 
                id='restricciones cerrar'
                className="titleTextAlert-container-close"
                onClick={() => props.onCloseClick()}
            >
                <ButtonExit/>
            </div>
            <p className="titleTextAlert-container-title">{props.title}</p>
            <div className="titleTextAlert-container-text">
                <Markup content={props.text} />
            </div>
            <div className="titleTextAlert-container-buttonWrappers">
                <MainButton
                    id='Continuar Boton'
                    disabled={false}
                    text='ACEPTAR'
                    onButtonPressed={() => props.onCloseClick()}

                />
            </div>
        </div>
    )
}

export default TitleTextAlert;