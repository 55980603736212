import React, {useEffect, useState} from 'react'
import './PaymentMethodCreditCard.css'
import { ReactComponent as Error } from '../../../assets/images/svgs/error.svg';
import { ReactComponent as Success } from '../../../assets/images/svgs/success.svg';
import { ReactComponent as CandadoPay } from '../../../assets/images/svgs/candadoPay.svg';
import { useMediaQuery } from 'react-responsive'
import { connect } from 'react-redux';
import {
    cvvTextInputChange,
    checkOutConfirm,
    checkOutAddCbuAndConfirm,
    checkOutAddCreditCardAndConfirm,
    checkOutPaymentAndConfirm
} from '../../../actions'
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import EmptyRefererModal from '../../modalComponents/EmptyRefererModal/EmptyRefererModal';

let isTabletOrMobile = false;
const PaymentMethodCreditCard = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

    const navigate = useNavigate()
    const [refererAlert, setRefererAlert] = useState<boolean>(false);

    const handlePayState = () => {
        if(props.checkOutConfirmStart) {
            return "PaymentMethodCreditCard-containerStart"
        }
        if(props.checkOutConfirmError) {
            return "PaymentMethodCreditCard-containerError"
        } 
        if(props.checkOutConfirmSuccess) {
            return "PaymentMethodCreditCard-containerSuccess"
        }
        return "PaymentMethodCreditCard-container"
    }

    const handleInnerPayState = () => {
        if(props.checkOutConfirmError) {
            return <Error/>;
        }
        if(props.checkOutConfirmSuccess) {
            return <Success/>;
        }
        return (
            <React.Fragment> 
                <CandadoPay/>
                <p className="PaymentMethodCreditCard-container-text">{props.checkOutConfirmStart ? 'PAGANDO...' : 'PAGAR'}</p>
            </React.Fragment>
        )
    }

    const refererModal = () => {
        return (
          <Modal
            isOpen={refererAlert}
            onRequestClose={() => setRefererAlert(false)}
            style={{
              content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '25px',
                width: isTabletOrMobile ? '80%' : '30%',
                padding: isTabletOrMobile ? '10px' : '20px',
              },
              overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.50)'
              }
            }}
          >
            <EmptyRefererModal 
                cancel={() => setRefererAlert(false)}
                buttonPress={() => {
                    setRefererAlert(false)
                    props.setRefererError(false)
                    const assist = props.referer !== '' ? props.referer : ' ';
                    if(props.cbu) {
                        props.checkOutAddCbuAndConfirm(props.cbu, props.userData?.id, props.paymentType, assist, props.checkOutData?.id, null, props.checkOutData?.offer?.product?.title, props.userData?.name, props.originalParams, navigate, props.countryId, props.admin)
                    } else if (props.numeroTarjeta && props.tipo) {
                        const card: any = {
                            brandCardId: props.tipo !== undefined ? props.emisorasType[props.tipo]?.code : '',
                            nroTarjeta: props.numeroTarjeta
                        }
                        props.checkOutAddCreditCardAndConfirm(card, props.userData?.id, props.paymentType, assist, props.checkOutData?.id, null, props.checkOutData?.offer?.product?.title, props.userData?.name, props.originalParams, navigate, props.countryId, props.admin)
                    } else {
                        props.checkOutPaymentAndConfirm(props.paymentType, assist, props.checkOutData?.id, props.checkOutData?.offer?.product?.title, props.userData?.name, navigate, props.countryId, props.admin)
                    }
                }}
            />
          </Modal>
        )
    }

    return (
        <React.Fragment>
            <div 
                className={handlePayState()} 
                id="payment_buttom"
                style={{
                    opacity: props.disabled ? 0.4 : 1,
                    cursor: props.disabled ? 'inherit' : 'pointer'
                }}
                onClick={() => {
                    if(props.disabled || props.checkOutConfirmStart) {
                        return
                    } else if (props.refererErrorEmpty) {
                        setRefererAlert(true)
                    } else {
                        if(props.isAdminSucursal && props.referer?.length !== 8) {
                            props.setRefererError(true)
                        } else {
                            props.setRefererError(false)
                            const assist = props.referer !== '' ? props.referer : ' ';
                            if(props.cbu) {
                                props.checkOutAddCbuAndConfirm(props.cbu, props.userData?.id, props.paymentType, assist, props.checkOutData?.id, null, props.checkOutData?.offer?.product?.title, props.userData?.name, props.originalParams, navigate, props.countryId, props.admin)
                            } else if (props.numeroTarjeta && props.tipo) {
                                const card: any = {
                                    brandCardId: props.tipo !== undefined ? props.emisorasType[props.tipo]?.code : '',
                                    nroTarjeta: props.numeroTarjeta
                                }
                                props.checkOutAddCreditCardAndConfirm(card, props.userData?.id, props.paymentType, assist, props.checkOutData?.id, null, props.checkOutData?.offer?.product?.title, props.userData?.name, props.originalParams, navigate, props.countryId, props.admin)
                            } else {
                                props.checkOutPaymentAndConfirm(props.paymentType, assist, props.checkOutData?.id, props.checkOutData?.offer?.product?.title, props.userData?.name, navigate, props.countryId, props.admin)
                            }
                        }
                    }
                }}
            >
                {handleInnerPayState()}
            </div>
            {refererModal()}
        </React.Fragment>
    )
}

const mapStateToProps = (state: any) => {
    return {
        checkOutData: state.checkOut.checkOutData,
        cvvTextInput: state.checkOut.cvvTextInput,
        checkOutConfirmSuccess: state.checkOut.checkOutConfirmSuccess,
        checkOutConfirmError: state.checkOut.checkOutConfirmError,
        checkOutConfirmStart: state.checkOut.checkOutConfirmStart,
        checkOutDataPaymentInfo: state.checkOut.checkOutDataPaymentInfo,
        userData: state.user.userData,
        countryId: state.general.countryId,
        cbu: state.checkOut.cbu,
        numeroTarjeta: state.checkOut.numeroTarjeta,
        tipo: state.checkOut.tipo,
        emisorasType: state.checkOut.emisorasType,
        referer: state.checkOut.referer
    };
};

export default connect(mapStateToProps, {
    cvvTextInputChange,
    checkOutConfirm,
    checkOutAddCbuAndConfirm,
    checkOutAddCreditCardAndConfirm,
    checkOutPaymentAndConfirm
})(PaymentMethodCreditCard);