import './ImagePickerSuggestComponent.css'
import { useMediaQuery } from 'react-responsive'
import Lottie from 'react-lottie';
import animationData from '../../../assets/lotties/loading.json'
import { ReactComponent as EditButton } from '../../../assets/images/svgs/edit.svg';
import { ReactComponent as ButtonPlus } from '../../../assets/images/svgs/plus.svg';
import renderImageSuggest from '../renderImageSuggest';

let isTabletOrMobile = false;
const ImagePickerSuggestComponent = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })
    const size = isTabletOrMobile ? 50 : 70;
    return (
        <label className="imagePickerComponent-container">
            {props.b?.defaultValue !== "" ?
                <div className="imagePickerComponent-container-editButton"> 
                    <EditButton height={size / 2.2} width={size / 2.2} />
                </div>
                :
                <div className="imagePickerComponent-container-editButton">
                    <ButtonPlus height={size / 4} width={size / 4} />
                </div>
            }
            {props.b?.loading ?
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: animationData,
                        rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice'
                        }
                    }}
                    height={size}
                    width={size}
                    isStopped={false}
                    isPaused={false}
                />
                :
                renderImageSuggest(props.b, size)
            }
            <input
                className="imagePickerComponent-container-input"
                type="file" accept=".png, .jpg, .jpeg"
                onChange={(event: any) => {
                    if (event.target.files && event.target.files[0]) {
                        props.onSelectedImage(URL.createObjectURL(event.target.files[0]), event.target.files[0])
                    }
                }}
            />
        </label>
    );
}

export default ImagePickerSuggestComponent