import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import reducers from './reducers';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import Cookies from 'universal-cookie';

export const store = createStore(reducers, {}, applyMiddleware(ReduxThunk));

const production: boolean = process.env.NODE_ENV === 'production';

if (production) {
  Sentry.init({
    dsn: "https://659d8491b7b242739858658f3282a377@o995441.ingest.sentry.io/5954338",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const cookies = new Cookies();
cookies.set('channelId', process.env.REACT_APP_ID);

ReactDOM.render(
  <Provider store={store}>
    <App/>
  </Provider>, 
document.getElementById('root'))
 