import {
    FETCH_CATEGORIAS,
    CATEGORIA_SELECTED,
    COTIZAR_QUESTION,
    ANSWER,
    LOADING,
    OFFER_DATA,
    RESQUEST_SUCCESS_POLICY,
    GLOBAL_FORM_ANSWER
} from './types';
import {
    getAllCategories,
    netQuoteCotizaCategoria,
    netAnswerCotizar,
    netGetQuoteQuestions,
    netDeleteQuoteQuestions,
    getOffersNet
} from '../net/Connector'
import axios from './instanceActions'
import { genericErrorHandler } from '../util/GenericErrorHandler';
import { GetGlobalParams } from '../util/GetGlobalParams';
import { GetCountryUrl } from '../util/GetCountryUrl';

export const fetchCategoriaList = () => {
    return (dispatch: any) => {
        dispatch({type: LOADING, payload: true});
        axios.get(getAllCategories())
            .then((response: any) => {
                let aux = response.data.filter((a: any) => a.menu)
                aux.sort((a:any,b:any) => {
                    if(a.order == b.order) return 0
                    if(a.order === null ) return 1;
                    if(b.order === null ) return -1;
                    return a.order - b.order;
                })
                dispatch({type: FETCH_CATEGORIAS, payload: aux});
                dispatch({type: LOADING, payload: false});
            })
            .catch((error: any) => {
                dispatch({type: LOADING, payload: false});
                genericErrorHandler(error)
            });
    }
};

export const categoriaSelectedChange = (categoryId: Number) => {
    return {
        type: CATEGORIA_SELECTED, 
        payload: categoryId
    };
}

export const answerChanged = (value: any) => {
    return {
        type: ANSWER, 
        payload: value
    };
}

export const emptyQuestions = () => {
    return {
        type: COTIZAR_QUESTION, 
        payload: null
    };
}

export const categoriaQuestion = (categoryId: Number, navigate: any, countryId: any, admin: false) => {
    return (dispatch: any) => {
        dispatch({type: LOADING, payload: true});
        dispatch({type: ANSWER, payload: null});
        axios({
            url: netQuoteCotizaCategoria(categoryId),
            method: 'post', 
            headers: {'Context': btoa(GetGlobalParams())}
        })
            .then((response) => {
                dispatch({type: COTIZAR_QUESTION, payload: response.data});
                const adminN = admin ? '&admin=True' : ''
                navigate(GetCountryUrl(countryId) + 'cotizar?cId=' + categoryId + '&quote=' + response.data.id + adminN + '&' + GetGlobalParams())
                dispatch({type: LOADING, payload: false});
            })
            .catch((error) => {
                dispatch({type: LOADING, payload: false}); 
                genericErrorHandler(error)
            })
    };
}

export const categoriaToOffer = (productInsuredId: any) => {
    return (dispatch: any) => {
        dispatch({ type: LOADING, payload: true });
        axios({
            url: getOffersNet(),
            method: 'post', 
            headers: {
                accept: "*/*",
                "Content-Type": "application/json",
            },
            data: {
                productInsuredId: productInsuredId,
                productInsuredUserId: null,
            }
        })
            .then((response) => {
                dispatch({ type: OFFER_DATA, payload: response.data });
                dispatch({ type: LOADING, payload: false });
            })
            .catch((error) => {
                dispatch({ type: LOADING, payload: false });
                genericErrorHandler(error);
            });
    };
}

export const getQuoteQuestions = (categoryId: Number, quote: String) => {
    return (dispatch: any) => {
        dispatch({type: LOADING, payload: true});
        axios.get(netGetQuoteQuestions(categoryId, quote))
            .then((response) => {
                dispatch({type: COTIZAR_QUESTION, payload: response.data});
                dispatch({type: LOADING, payload: false});
            })
            .catch((error) => {
                dispatch({type: LOADING, payload: false});
                genericErrorHandler(error)
            })
    }
}

export const answerQuestion = (categoryId: Number, quoteId: String, answer: any, originalParams: String, navigate: any, countryId: any, admin: boolean, setLoadingOffer: any) => {
    return (dispatch: any) => {
        const data = {
            "questionId": answer.questionId,
            "value": answer.value
        }
        dispatch({type: LOADING, payload: true});
        axios({
            url: netAnswerCotizar(categoryId, quoteId),
            method: 'post', 
            headers: {
                'accept': '*/*',
                'Content-Type': 'application/json',
                'Context': btoa(GetGlobalParams())
            },
            data
        })
            .then((responseCot) => {
                dispatch({type: GLOBAL_FORM_ANSWER, payload: {}});
                if(responseCot?.data?.next) { //Ultima Pregunta
                    axios.get(responseCot?.data?.next?.link)
                        .then((responseOff) => {
                            if (responseOff?.data?.status === "CREATING") {
                                dispatch({type: LOADING, payload: false});
                                setLoadingOffer(true)
                                const offerStatus = () => {
                                    axios.get(responseCot?.data?.next?.link)
                                        .then((response) => {
                                            if (response?.data?.status !== "CREATING") {
                                                dispatch({type: OFFER_DATA, payload: response?.data});
                                                const adminN = admin ? '&admin=True' : ''
                                                navigate(GetCountryUrl(countryId) + 'oferta?cId=' + categoryId + '&offer=' + responseOff?.data?.id + adminN + '&' + GetGlobalParams())
                                                setLoadingOffer(false)
                                                clearInterval(refreshIntervalId);
                                            }
                                        })
                                        .catch((error) => {
                                            genericErrorHandler(error)
                                            clearInterval(refreshIntervalId);
                                            setLoadingOffer(false)
                                        })
                                    }
                                var refreshIntervalId = setInterval(offerStatus, 3000);
                            } else {
                                dispatch({type: LOADING, payload: false});
                                dispatch({type: OFFER_DATA, payload: responseOff?.data});
                                const adminN = admin ? '&admin=True' : ''
                                navigate(GetCountryUrl(countryId) + 'oferta?cId=' + categoryId + '&offer=' + responseOff?.data?.id + adminN + '&' + GetGlobalParams())
                            }
                        })
                        .catch((error) => { 
                            dispatch({type: LOADING, payload: false});
                            setLoadingOffer(false)
                            genericErrorHandler(error)
                        })
                } else {
                    dispatch({type: COTIZAR_QUESTION, payload: responseCot.data});
                    dispatch({type: ANSWER, payload: null});
                    dispatch({type: LOADING, payload: false});
                }
            })
            .catch((error) => {
                dispatch({type: LOADING, payload: false});
                genericErrorHandler(error)
            })
    }
}

export const deleteQuestion = (categoryId: Number, quoteId: String, stepId: any) => {
    return (dispatch: any) => {
        dispatch({type: LOADING, payload: true});
        axios({
            url: netDeleteQuoteQuestions(categoryId, quoteId, stepId),
            method: 'delete', 
            headers: {
              'accept': '*/*',
              'Content-Type': 'application/json'
            }
          })
            .then((response) => {
                dispatch({type: GLOBAL_FORM_ANSWER, payload: {}});
                dispatch({type: COTIZAR_QUESTION, payload: response.data});
                dispatch({type: ANSWER, payload: null});
                dispatch({type: LOADING, payload: false});
            })
            .catch((error) => {
                dispatch({type: LOADING, payload: false});
                genericErrorHandler(error)
            })
    }
}

export const resetPolicy = (arg: boolean) => {
    return (dispatch: any) => {
        dispatch({ type: RESQUEST_SUCCESS_POLICY, payload: arg });
    };
};