import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../components/generalComponents/header/Header"
import Lottie from 'react-lottie';
import candado from '../../assets/lotties/candado.json';
import load from '../../assets/lotties/loading.json';
import './PasswordRecoveryFinish.css'
import { GetGlobalParams } from "../../util/GetGlobalParams";
import { GetCountryUrl } from "../../util/GetCountryUrl";


const PasswordRecoveryFinish = () => {

    const navigate = useNavigate();
    const countryId = useSelector((state: any) => state.general.countryId);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const query = new URLSearchParams(location?.search);
    const path: string = query.get('redirectTo') || '';

    useEffect(() => {
        const timer1 = setTimeout(() => {
            setLoading(true);
        }, 3200);
        return () => clearTimeout(timer1);
    }, [loading]);

    useEffect(() => {
        const timer2 = setTimeout(() => {
            if (path !== '') {
                navigate(path + '&' + GetGlobalParams(), { state: { login: true } })
            } else {
                navigate(GetCountryUrl(countryId) + 'login?' + GetGlobalParams())
            }
        }, 4600);
        return () => clearTimeout(timer2);
    },[])

    return (
        <div className="passwordRecoveryFinish-container">
            <Header />
            {loading ?
                <div className="passwordRecoveryFinish-container-lottie">
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: load
                        }}
                        height={120}
                        width={120}
                    />
                </div>
                :
                <div className="passwordRecoveryFinish-container-main">
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: candado,
                            rendererSettings: {
                                preserveAspectRatio: 'xMidYMid slice'
                            }
                        }}
                        height={120}
                        width={140}
                    />
                    <h3 className='passwordRecoveryFinish-container-main-title'>¡Listo!</h3>
                    <p className='passwordRecoveryFinish-container-main-text'>Tu contraseña se cambió correctamente. Te redirigiremos al inicio de sesión para ingresar con tu nueva contraseña.</p>
                </div>
            }
        </div>
    )
}

export default PasswordRecoveryFinish;