import { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux'
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../../components/generalComponents/header/Header'
import SuccessCard from '../../components/generalComponents/SuccessCard/SuccessCard'
import Lottie from 'react-lottie';

import emailSent from '../../assets/lotties/emailVerificar.json'
import { FormattedMessage } from 'react-intl';
import MainButton from '../../components/generalComponents/MainButton/MainButton';
import Footer from '../../components/generalComponents/footer/Footer';
import { recoverySuccessCode, resendEmailCode } from '../../actions'
import './PasswordRecoveryCode.css'


let isTabletOrMobile = false;
const PasswordRecoveryCode = (props: any) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location: any = useLocation();
    const email: string = location.state?.userName;
    const redirectTo: string = location.state?.link ? location.state.link : '';
    const [code1, setCode1] = useState('');
    const [code2, setCode2] = useState('');
    const [code3, setCode3] = useState('');
    const [code4, setCode4] = useState('');
    const [code5, setCode5] = useState('');
    const [code6, setCode6] = useState('');

    const input2 = useRef<HTMLInputElement>(null);
    const input3 = useRef<HTMLInputElement | null>(null);
    const input4 = useRef<HTMLInputElement | null>(null);
    const input5 = useRef<HTMLInputElement | null>(null);
    const input6 = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        document.addEventListener('paste', function (event: any) {
            var clipText = event.clipboardData.getData('Text');
            if (clipText.charAt(0)) {
                setCode1(clipText.charAt(0))
            }
            if (clipText.charAt(1)) {
                setCode2(clipText.charAt(1))
            }
            if (clipText.charAt(2)) {
                setCode3(clipText.charAt(2))
            }
            if (clipText.charAt(3)) {
                setCode4(clipText.charAt(3))
            }
            if (clipText.charAt(4)) {
                setCode5(clipText.charAt(4))
            }
            if (clipText.charAt(5)) {
                setCode6(clipText.charAt(5))
            }
        });
    }, []);

    useEffect(() => {
    }, [props.recoveryPasswordId])


    const isDisabled = () => {
        if (code1.length === 1 &&
            code2.length === 1 &&
            code3.length === 1 &&
            code4.length === 1 &&
            code5.length === 1 &&
            code6.length === 1) {
            return false
        }
        return true
    }

    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' });
    return (
        <div className="mainCotizar-container">
            <Header/>
            <div className="passwordRecovery-container-title" style={{ alignItems: 'center' }}>
                <h3 className="passwordRecovery-container-title-text bold"><FormattedMessage id="page.recovery.password.title" defaultMessage="" /></h3>
            </div>
            <div className="verificacionEmail-container-main">
                <div style={{ width: '100%', position: 'relative', left: 13 }}>
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: emailSent,
                            rendererSettings: {
                                preserveAspectRatio: 'xMidYMid slice'
                            }
                        }}
                        height={100}
                        width={120}
                        isStopped={false}
                        isPaused={false}
                    />
                </div>
                <p className="verificacionEmail-container-main-title"><FormattedMessage id="page.verificacionEmail.title" defaultMessage="" /></p>
                <p className="verificacionEmail-container-main-subTitle"><FormattedMessage id="page.verificacionEmail.subTitle" defaultMessage="" values={{ email: email }} /></p>
                <div className="verificacionEmail-container-main-codeContainer">
                    <div className="verificacionEmail-container-main-codeContainer-inputContainer">
                        <input
                            id='input1'
                            className="verificacionEmail-container-main-codeContainer-inputContainer-input"
                            value={code1}
                            onChange={(e: any) => {
                                setCode1(e.target.value)
                                if (e.target.value !== '') {
                                    setCode2('')
                                    input2.current!.focus();
                                }
                            }}
                            maxLength={1}
                        />
                    </div>
                    <div className="verificacionEmail-container-main-codeContainer-inputContainer">
                        <input
                            ref={input2}
                            className="verificacionEmail-container-main-codeContainer-inputContainer-input"
                            value={code2}
                            onChange={(e: any) => {
                                setCode2(e.target.value)
                                if (e.target.value !== '') {
                                    setCode3('')
                                    input3.current!.focus();
                                }
                            }}
                            maxLength={1}
                        />
                    </div>
                    <div className="verificacionEmail-container-main-codeContainer-inputContainer">
                        <input
                            ref={input3}
                            className="verificacionEmail-container-main-codeContainer-inputContainer-input"
                            value={code3}
                            onChange={(e: any) => {
                                setCode3(e.target.value)
                                if (e.target.value !== '') {
                                    setCode4('')
                                    input4.current!.focus();
                                }
                            }}
                            maxLength={1}
                        />
                    </div>
                    <div className="verificacionEmail-container-main-codeContainer-inputContainer">
                        <input
                            ref={input4}
                            className="verificacionEmail-container-main-codeContainer-inputContainer-input"
                            value={code4}
                            onChange={(e: any) => {
                                setCode4(e.target.value)
                                if (e.target.value !== '') {
                                    setCode5('')
                                    input5.current!.focus();
                                }
                            }}
                            maxLength={1}

                        />
                    </div>
                    <div className="verificacionEmail-container-main-codeContainer-inputContainer">
                        <input
                            ref={input5}
                            className="verificacionEmail-container-main-codeContainer-inputContainer-input"
                            value={code5}
                            onChange={(e: any) => {
                                setCode5(e.target.value)
                                if (e.target.value !== '') {
                                    setCode6('')
                                    input6.current!.focus();
                                }
                            }}
                            maxLength={1}
                        />
                    </div>
                    <div className="verificacionEmail-container-main-codeContainer-inputContainer">
                        <input
                            ref={input6}
                            className="verificacionEmail-container-main-codeContainer-inputContainer-input"
                            value={code6}
                            onChange={(e: any) => {
                                setCode6(e.target.value)
                            }}
                            maxLength={1}
                        />
                    </div>
                </div>
                <p className="verificacionEmail-container-main-resendText">
                    <FormattedMessage id="page.verificacionEmail.noTeMail" defaultMessage="¿No te llegó el mail?" />
                    <p
                        id='reenviar verificacion'
                        style={{ cursor: 'pointer' }}
                        onClick={() => props.resendEmailCode(email)}
                    ><strong>&nbsp;<FormattedMessage id="page.verificacionEmail.noTeMail2" defaultMessage="Reenviar" /></strong></p>
                </p>
                <div className="verificacionEmail-container-main-buttonWrapper">
                    {!isTabletOrMobile && <SuccessCard translateX={-10} translateY={0} />}
                    <MainButton
                        id='Continuar Boton'
                        disabled={isDisabled()}
                        text={'recuperar contraseña'.toUpperCase()}
                        onButtonPressed={() => dispatch(recoverySuccessCode(props.recoveryPasswordId, props.countryId, (code1 + code2 + code3 + code4 + code5 + code6), redirectTo, navigate) )}
                        altStyle10
                    />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        countryId: state.general.countryId,
        recoveryPasswordId: state.login.recoveryPasswordId,
    };
};

export default connect(mapStateToProps, { recoverySuccessCode, resendEmailCode })(PasswordRecoveryCode);
