import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive';
import './FormFieldList.css'

let isTabletOrMobile = false;
const FormFieldList = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

    const [searchText, setSearchText] = useState<string>('')
    const [valuesList, setValuesList] = useState<any>(props.data?.input?.values)
    const [popularList, setPopularList] = useState<any>(props.data?.input?.popular)
    const [searchedList, setSearchedList] = useState<any>([])
    const [activeInput, setActiveInput] = useState<boolean>(false)

    const filterByText = (e: string) => {
        setSearchText(e)
        const auxValues = props.data?.input?.values?.filter((a: any) => a.label.toLowerCase().includes(e.toLowerCase()))
        const auxPopluar = props.data?.input?.popular?.filter((a: any) => a.label.toLowerCase().includes(e.toLowerCase()))
        setSearchedList(auxPopluar.concat(auxValues))
        setValuesList(auxValues)
        setPopularList(auxPopluar)
    }

    const handleItemSelected = (a: any) => {
        const data = {
          "questionId": props.data.id,
          "value": a,
        }
        props.onUpdateAlt(data)
    }

    return (
        <div className="FormFieldList-container">
            {props.data?.input?.config?.showSearch && 
                <div className={activeInput || searchText !== '' ? "FormFieldList-container-buscadorActive" : "FormFieldList-container-buscador"}>
                    <input
                        className="FormFieldList-container-buscador-input"
                        placeholder={props.data?.display?.placeholder}
                        value={searchText}
                        onChange={(e: any) => filterByText(e.target.value)}
                        onFocus={() => setActiveInput(true)}
                        onBlur={() => setActiveInput(false)}
                        id='buscador'
                    />
                    {searchText === '' && <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.8 18.9746L10.3248 12.5C9.8248 12.9164 9.2416 13.2454 8.5752 13.487C7.9084 13.729 7.2084 13.85 6.4752 13.85C4.6584 13.85 3.125 13.225 1.875 11.975C0.625 10.725 0 9.20821 0 7.42461C0 5.64141 0.625 4.12061 1.875 2.86221C3.125 1.60381 4.65 0.974609 6.45 0.974609C8.2332 0.974609 9.7498 1.60381 10.9998 2.86221C12.2498 4.12061 12.8748 5.64141 12.8748 7.42461C12.8748 8.14141 12.7582 8.82901 12.525 9.48741C12.2918 10.1458 11.9502 10.7582 11.5002 11.3246L18 17.7746L16.8 18.9746ZM6.45 12.1748C7.7668 12.1748 8.8876 11.7122 9.8124 10.787C10.7376 9.86221 11.2002 8.74141 11.2002 7.42461C11.2002 6.10821 10.7376 4.98321 9.8124 4.04961C8.8876 3.11641 7.7668 2.64981 6.45 2.64981C5.1168 2.64981 3.9876 3.11641 3.0624 4.04961C2.1376 4.98321 1.6752 6.10821 1.6752 7.42461C1.6752 8.74141 2.1376 9.86221 3.0624 10.787C3.9876 11.7122 5.1168 12.1748 6.45 12.1748Z" fill={activeInput ? "var(--primary500)" : "var(--gray400)"}/>
                    </svg>}
                </div>
            }
            {valuesList?.length > 0 || popularList?.length > 0 || searchedList.length > 0 ?
                <>
                    {searchedList && searchText !== '' ? 
                        <div className="FormFieldList-container-populares">
                            <div 
                                className="FormFieldList-container-populares-list"
                                style={{columns: 1}}
                            >
                                {searchedList?.map((a: any) => (
                                    <p 
                                        id={a.label} 
                                        key={a.label}
                                        onClick={() => handleItemSelected(a.value)} 
                                        className="FormFieldList-container-populares-list-item"
                                        style={{width: '45%'}}
                                    >
                                        {a.label}
                                    </p>
                                ))}
                            </div>
                        </div>
                        :
                        <>
                            {popularList && popularList.length > 0 && <div className="FormFieldList-container-populares">
                                <p className="FormFieldList-container-populares-title">Mas populares</p>
                                <div 
                                    className="FormFieldList-container-populares-list"
                                    style={{columns: isTabletOrMobile ? 1 : props.data?.input?.config?.columns}}
                                >
                                    {popularList?.map((a: any) => (
                                        <p 
                                            id={a.label} 
                                            key={a.label}
                                            onClick={() => handleItemSelected(a.value)} 
                                            className="FormFieldList-container-populares-list-item"
                                            style={{width: props.data?.input?.config?.columns >= 2 ? '95%' : '45%'}}
                                        >
                                            {a.label}
                                        </p>
                                    ))}
                                </div>
                            </div>}
                            {valuesList && valuesList.length > 0 && <div className="FormFieldList-container-values">
                                {props.data?.input?.config?.title && <p className="FormFieldList-container-values-title">{props.data?.input?.config?.title}</p>}
                                <div 
                                    className="FormFieldList-container-values-list"
                                    style={{columns: isTabletOrMobile ? 1 : props.data?.input?.config?.columns}}
                                >
                                    {valuesList?.map((a: any) => (
                                        <p 
                                            id={a.label} 
                                            key={a.label}
                                            onClick={() => handleItemSelected(a.value)} 
                                            className="FormFieldList-container-values-list-item"
                                            style={{width: isTabletOrMobile || props.data?.input?.config?.columns >= 2 ? '95%' : '45%'}}
                                        >
                                            {a.label}
                                        </p>
                                    ))}
                                </div>
                            </div>}
                        </>
                    }
                </>
                :
                <div className="FormFieldList-container-empty">
                    <div className="FormFieldList-container-empty-mask">
                        <div className="FormFieldList-container-empty-mask-svg">
                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M25 0C11.215 0 0 11.215 0 25C0 38.785 11.215 50 25 50C38.785 50 50 38.785 50 25C50 11.215 38.7852 0 25 0ZM25 47.2973C18.8754 47.2973 13.3203 44.8148 9.28644 40.8039C7.67561 39.2022 6.30819 37.3562 5.24348 35.3275C3.62201 32.2381 2.70274 28.7245 2.70274 25C2.70274 12.7052 12.7052 2.70274 25 2.70274C30.8317 2.70274 36.1466 4.95412 40.124 8.63221C42.1862 10.539 43.8891 12.8289 45.117 15.3884C46.514 18.3004 47.2973 21.5604 47.2973 25C47.2973 37.2948 37.2948 47.2973 25 47.2973Z" fill="#999999"/>
                                <path d="M16.6383 22.2973C18.1309 22.2973 19.341 21.0872 19.341 19.5945C19.341 18.1018 18.1309 16.8918 16.6383 16.8918C15.1456 16.8918 13.9355 18.1018 13.9355 19.5945C13.9355 21.0872 15.1456 22.2973 16.6383 22.2973Z" fill="#999999"/>
                                <path d="M33.3615 22.2973C34.8541 22.2973 36.0642 21.0872 36.0642 19.5945C36.0642 18.1018 34.8541 16.8918 33.3615 16.8918C31.8688 16.8918 30.6587 18.1018 30.6587 19.5945C30.6587 21.0872 31.8688 22.2973 33.3615 22.2973Z" fill="#999999"/>
                                <path d="M39.7921 12.8436L31.2329 9.13667L30.1588 11.6167L38.718 15.3236L39.7921 12.8436Z" fill="#999999"/>
                                <path d="M11.281 15.3246L19.8401 11.6178L18.766 9.13776L10.2069 12.8446L11.281 15.3246Z" fill="#999999"/>
                                <path d="M36.1909 34.2543C36.3983 34.6219 36.5892 35.004 36.7589 35.3902L39.2333 34.303C39.0275 33.8349 38.796 33.3717 38.5449 32.9266C35.6696 27.8271 30.0301 24.7269 24.1843 25.0306C18.3763 25.3311 13.1093 28.9707 10.766 34.303L13.2404 35.3905C15.1764 30.9851 19.5268 27.9781 24.3238 27.7298C29.1526 27.4793 33.8148 30.0404 36.1909 34.2543Z" fill="#999999"/>
                            </svg>
                        </div>
                        <p className="FormFieldList-container-empty-mask-title">¡Lo sentimos mucho!</p>
                        <p className="FormFieldList-container-empty-mask-text">No encontramos resultados que coincidan con tu búsqueda.<br/>Por favor, intenta nuevamente.</p>
                    </div>
                </div>
            }
        </div>
    );
}

export default FormFieldList;