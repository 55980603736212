import React, {useState} from 'react'
import './FormFieldPhotoItem.css'
import { ReactSVG } from 'react-svg';
import ReclamoPhoto from '../../../../../../assets/images/svgs/reclamoPhoto.svg';
import ReclamoExit from '../../../../../../assets/images/svgs/reclamoExit.svg'
import { useMediaQuery } from 'react-responsive'
import Lottie from 'react-lottie';
import animationData from '../../../../../../assets/lotties/loading.json'
import { ReactComponent as EditButton } from '../../../../../../assets/images/svgs/edit.svg';
import { ReactComponent as ButtonPlus } from '../../../../../../assets/images/svgs/plus.svg';
import { ReactComponent as ImageDefaultPicker } from '../../../../../../assets/images/svgs/imageDefaultPicker.svg';

let isTabletOrMobile = false;
const FormFieldPhotoItem = (props) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' });

    const [image, setImage] = useState('')
    const [ghostImage, setGhostImage] = useState(null)
    const [imageRes, setImageRes] = useState(null)
    const [loading, setLoading] = useState(false)

    const putImageS3 = (fileData, data, presignUrl, index) => {
        setLoading(true);
        setImage(data.name);
        setImageRes(fileData);
        
        fetch(fileData)
            .then((response) => {
                return response.blob();
            })
            .then((content) => {
                return fetch(presignUrl, {
                    method: 'PUT',
                    body: content,
                    headers: {
                        'Content-Type': data.type
                    }
                })
            })
            .then((response) => {
                setGhostImage('')
                props.onUpdate(presignUrl, index);
                setLoading(false)
            })
            .catch((error) => {
                putImageS3(fileData, data, presignUrl, index);
            });
    }

    const getInputType = (a, index) => {
        switch(props.data.input.type) {
            case 'camera':
                return (
                    <input 
                        disabled={loading}
                        className="FormFieldPhotoItem-container-imgContainer-input" 
                        type="file" accept="image/*" capture
                        onChange={(event) => {
                            props.onUpdate('', index);
                            if (event.target.files && event.target.files[0]) {
                                putImageS3(URL.createObjectURL(event.target.files[0]), event.target.files[0], a.presignedUrl, index)
                            }
                        }}
                        value={ghostImage}
                    />
                )
            case 'photo':
                return (
                    <input 
                        disabled={loading}
                        className="FormFieldPhotoItem-container-imgContainer-input" 
                        type="file" accept=".png, .jpg, .jpeg"
                        onChange={(event) => {
                            props.onUpdate('', index);
                            if (event.target.files && event.target.files[0]) {
                                putImageS3(URL.createObjectURL(event.target.files[0]), event.target.files[0], a.presignedUrl, index)
                            }
                        }}
                        value={ghostImage}
                    />
                )
            default:
                return (
                    <input 
                        disabled={loading}
                        className="FormFieldPhotoItem-container-imgContainer-input" 
                        type="file" accept="*"
                        onChange={(event) => {
                            props.onUpdate('', index);
                            if (event.target.files && event.target.files[0]) {
                                putImageS3(URL.createObjectURL(event.target.files[0]), event.target.files[0], a.presignedUrl, index)
                            }
                        }}
                        value={ghostImage}
                    />
                )
        }
    }

    return (
        <div className="FormFieldPhotoItem-container">
            {isTabletOrMobile ? 
                <label className="FormFieldPhotoItem-containerRes">
                    {image !== "" ?
                        <div className="FormFieldPhotoItem-containerRes-editButton">
                            <EditButton height={22} width={22}/>
                        </div>
                        :
                        <div className="FormFieldPhotoItem-containerRes-editButton">
                            <ButtonPlus height={12} width={12}/>
                        </div>
                    }
                    <div className="FormFieldPhotoItem-containerRes-imgContainer">
                        {loading ? 
                            <Lottie 
                                options={{
                                    loop: true,
                                    autoplay: true, 
                                    animationData: animationData,
                                    rendererSettings: {
                                        preserveAspectRatio: 'xMidYMid slice'
                                    }
                                }}
                                height={80}
                                width={80}
                                isStopped={false}
                                isPaused={false}
                            />
                            :
                            image === '' ?
                                <ImageDefaultPicker height={50} width={50}/>
                                :
                                <img src={imageRes} alt="imagen" className="FormFieldPhotoItem-containerRes-imgContainer-img"/>
                        }
                        {getInputType(props.a, props.index)}
                    </div>
                </label>
                :
                <label 
                    className="FormFieldPhotoItem-containerDes"
                    style={{justifyContent: image === '' ? 'center' : 'space-between'}}
                >
                    {loading ? 
                        <Lottie 
                            options={{
                                loop: true,
                                autoplay: true, 
                                animationData: animationData,
                                rendererSettings: {
                                    preserveAspectRatio: 'xMidYMid slice'
                                }
                            }}
                            height={45}
                            width={45}
                            isStopped={false}
                            isPaused={false}
                        />
                        :
                        <React.Fragment>
                            <p className="FormFieldPhotoItem-containerDes-text">{image === '' ? 'SELECCIONÁ TU ARCHIVO' : image}</p>
                            {image === '' ? 
                                <ReactSVG src={ReclamoPhoto}/>
                                : 
                                <ReactSVG 
                                    src={ReclamoExit}
                                    onClick={() => {
                                        setImage('')
                                        setImageRes(null)
                                        setGhostImage(null)
                                        props.onUpdate('')
                                    }}
                                />
                            }
                        </React.Fragment>
                    }
                    {getInputType(props.a, props.index)}
                </label>
            }
        </div>
    )
}

export default FormFieldPhotoItem;