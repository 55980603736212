import React from 'react'
import './ReclamoSteps.css'
import { useMediaQuery } from 'react-responsive'
import { ReactComponent as CheckStep } from '../../../assets/images/svgs/checkStep.svg';

let isTabletOrMobile = false;
const ReclamoSteps = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

    return (
        <div className="reclamoSteps-container">
            {props.stepList?.map((a: any, index: any) => (
                <div 
                    className="reclamoSteps-container-item" 
                    key={index}
                    style={{ 
                        width: props.stepList.length - 1 !== index ? '100%' : 'auto'
                    }}
                >
                    <div className="reclamoSteps-container-item-data">
                        <div 
                            className="reclamoSteps-container-item-data-circle"
                            style={{
                                backgroundColor: props.currentStep >= a.order ? isTabletOrMobile ? '#27AE60' : 'var(--white)' : 'var(--primary700)'
                            }}
                        >
                            {props.currentStep > a.order &&
                                <div  
                                    className="reclamoSteps-container-item-data-circle-check"
                                    style={{
                                        height: 14,
                                        width: 14
                                    }}
                                >
                                    <CheckStep/>
                                </div>
                            }
                        </div>
                        {props.stepList.length - 1 !== index && 
                            <div 
                                className="reclamoSteps-container-item-data-line"
                                style={{
                                    backgroundColor: props.currentStep > a.order ? isTabletOrMobile ? '#27AE60' : 'var(--white)' : 'var(--primary700)'
                                }}
                            ></div>
                        }
                    </div>
                    <p 
                        className="reclamoSteps-container-item-pasoText"
                        style={{
                            color: props.currentStep >= a.order ? isTabletOrMobile ? '#27AE60' : 'var(--white)' : 'var(--sixthSecondaryTextColor)'
                        }}
                    >{'Paso ' + a.order}</p>
                    <p 
                        className="reclamoSteps-container-item-nameText"
                        style={{
                            color: props.currentStep >= a.order ? isTabletOrMobile ? '#27AE60' : 'var(--white)' : 'var(--sixthSecondaryTextColor)'
                        }}
                    >{a.name}</p>
                </div>
            ))}
        </div>
    );
}

export default ReclamoSteps;