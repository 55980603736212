import axios from "axios";
import {store} from '../index';

const instance = axios.create({
	baseURL : process.env.REACT_APP_API
});

instance.interceptors.request.use(
	async (config: any) => {
		const countryId = store.getState().general.countryId
		const channelId = store.getState().general.channelId
		
		if (countryId) {
			config.headers['CountryId'] = countryId
		}
		if (channelId) {
			config.headers['ChannelId'] = channelId
		}
		return config
	}
)

instance.interceptors.response.use(
	function (response: any) {
		return Promise.resolve(response)
	},
	function (error: any) {
        return Promise.reject(error);
    }
)

export default instance;