import { 
    LOADING,
    USER_PRODUCTS_ACTIVE,
    USER_PRODUCTS_INACTIVE,
    FETCH_CATEGORIAS,
    OFFER_DATA,
    RESQUEST_SUCCESS_POLICY
} from './types';
import {
    getOffersNet,
    getInsuredUser,
    getAllCategories,
    removeInsuredFromUser,
    netOfferProductById,
    netCheckOutOffer
} from '../net/Connector'
import axios from './instanceActions'
import { genericErrorHandler } from '../util/GenericErrorHandler';
import { GetGlobalParams } from '../util/GetGlobalParams';
import { GetCountryUrl } from '../util/GetCountryUrl';

export const fetchDataForHome = () => {
    return (dispatch: any) => {
        dispatch({ type: LOADING, payload: true });  
            axios.get(getInsuredUser())
                .then((responseIU) => {
                    dispatch({ type: USER_PRODUCTS_ACTIVE, payload: responseIU.data });
                    axios.get(getAllCategories())
                        .then((responseC: any) => {
                            let aux = responseC.data.filter((a: any) => a.menu)
                            aux.sort((a:any,b:any) => {
                                if(a.order == b.order) return 0
                                if(a.order === null ) return 1;
                                if(b.order === null ) return -1;
                                return a.order - b.order;
                            })
                            dispatch({type: FETCH_CATEGORIAS, payload: aux});
                            dispatch({ type: LOADING, payload: false });
                        })
                        .catch((error) => {dispatch({ type: LOADING, payload: false }); genericErrorHandler(error)})
                })
                .catch((error) => {dispatch({ type: LOADING, payload: false }); genericErrorHandler(error)})
    }
}

export const removeInsuredArticle = (productUserId: number, setRefresh: any, refresh: any, setDesactivarAlertSuccess: any) => {
    return (dispatch: any) => {
        dispatch({ type: LOADING, payload: true });
        axios({method: 'post', url: removeInsuredFromUser(), params: { productUserId }})
            .then(response => {
                dispatch({ type: RESQUEST_SUCCESS_POLICY, payload: false });
                axios.get(getInsuredUser())
                    .then(response => {
                        dispatch({ type: USER_PRODUCTS_ACTIVE, payload: response.data });
                        setRefresh(!refresh)
                        setDesactivarAlertSuccess(true)
                        dispatch({ type: LOADING, payload: false });
                    }).catch(error => {
                        dispatch({ type: LOADING, payload: false });
                        genericErrorHandler(error)
                    });
            })
            .catch(error => {
                dispatch({ type: LOADING, payload: false });
                genericErrorHandler(error)
            });
    };
};

export const offerProductById = (id: number, categoryId: number, originalParams: any, navigate: any, countryId: any) => {
    return (dispatch: any) => {
        dispatch({ type: LOADING, payload: true });
        axios({
            url: netOfferProductById(),
            method: 'post',
            headers: {
                'accept': '*/*',
                'Content-Type': 'application/json',
            },
            data: `{ \"productInsuredUserId\": ${id} }`
        })
            .then((response) => {
                dispatch({type: OFFER_DATA, payload: response.data});
                navigate(GetCountryUrl(countryId) + 'oferta?productId=' + categoryId + '&offer=' + response.data.id + '&' + GetGlobalParams())
                dispatch({ type: LOADING, payload: false });
            })
            .catch((error) => {
                dispatch({ type: LOADING, payload: false });
                genericErrorHandler(error)
            })
    }
}