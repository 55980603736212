import React, { useState } from 'react'
import MainButton from '../../../MainButton/MainButton'
import './FormFieldCarLicence.css'
import Cookies from 'universal-cookie';

const FormFieldCarLicence = (props: any) => {

    const cookies = new Cookies();

    const [text, setText] = useState<string>('')
    const [okm, setOkm] = useState<boolean>(false)

    const isDisabled = () => {
        return !text.match(props.data?.input?.config?.regExp?.expr) || text.length < props.data?.input?.config?.min
    }

    const handleItemSelected = (a: any) => {
        if(a === '') cookies.set('0Km', true, {path: '/'});
        else cookies.set('0Km', false, {path: '/'});
        const data = {
          "questionId": props.data.id,
          "value": a,
        }
        props.onUpdateAlt(data)
    }

    const  removeNonAlphanumeric = (str) =>  {
        let finalStr = str.replace(/[^1-9a-z]/gi, '');

        finalStr = finalStr.replace(/[mñnqaeiou]/gi, '');

        return finalStr.toUpperCase();
    }

    return (
        <div className="FormFieldCarLicence-container">
            <div className="FormFieldCarLicence-container-label">
                <p className="FormFieldCarLicence-container-label-title">Patente</p>
                <p className="FormFieldCarLicence-container-label-req">&nbsp;*</p>
            </div>
            <div className="FormFieldCarLicence-container-main">
                <input
                    id='patente input'
                    className="FormFieldCarLicence-container-main-input"
                    maxLength={props.data?.input?.config?.max}
                    placeholder={props.data?.display?.placeholder}
                    onChange={(e: any) => setText(removeNonAlphanumeric(e.target.value))}
                    value={text}
                />
                <div 
                    className="FormFieldCarLicence-container-main-next"
                    onClick={() => isDisabled() ? {} : handleItemSelected(okm ? '' : text)}
                    style={{ opacity: isDisabled() ? 0.4 : 1, cursor: isDisabled() ? 'auto' : 'pointer' }}
                >
                    <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.49996 0.720077L9.23996 7.44008C9.31996 7.52008 9.3765 7.60674 9.40956 7.70008C9.44316 7.79341 9.45996 7.89341 9.45996 8.00008C9.45996 8.10674 9.44316 8.20674 9.40956 8.30008C9.3765 8.39341 9.31996 8.48008 9.23996 8.56008L2.49996 15.3001C2.31329 15.4867 2.07996 15.5801 1.79996 15.5801C1.51996 15.5801 1.27996 15.4801 1.07996 15.2801C0.879961 15.0801 0.779962 14.8467 0.779962 14.5801C0.779962 14.3134 0.879961 14.0801 1.07996 13.8801L6.95996 8.00008L1.07996 2.12008C0.893296 1.93341 0.799962 1.70354 0.799962 1.43048C0.799962 1.15688 0.899962 0.920077 1.09996 0.720077C1.29996 0.520077 1.5333 0.420077 1.79996 0.420077C2.06663 0.420077 2.29996 0.520077 2.49996 0.720077Z" fill="white"/>
                    </svg>
                </div>
                {props.data?.input?.config?.optional && <div className="FormFieldCarLicence-container-main-okm">
                    <div 
                        id='OKM'
                        onClick={() => {
                            setOkm(!okm)
                            if (!okm) {
                                setTimeout(() => {
                                    handleItemSelected('')
                                }, 500)
                            }
                        }} 
                        className="FormFieldCarLicence-container-main-okm-box"
                    >
                        <div
                            className="FormFieldCarLicence-container-main-okm-box-inner"
                            style={{transform: okm ? `scale(${1})` : `scale(${0})`}}
                        >
                            <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.9998 6.60078L9.8998 0.700781C10.0831 0.517448 10.3165 0.425781 10.5998 0.425781C10.8831 0.425781 11.1165 0.517448 11.2998 0.700781C11.4831 0.884114 11.5748 1.11745 11.5748 1.40078C11.5748 1.68411 11.4831 1.91745 11.2998 2.10078L4.6998 8.70078C4.4998 8.90078 4.26647 9.00078 3.9998 9.00078C3.73314 9.00078 3.4998 8.90078 3.2998 8.70078L0.699804 6.10078C0.516471 5.91745 0.424805 5.68411 0.424805 5.40078C0.424805 5.11745 0.516471 4.88411 0.699804 4.70078C0.883138 4.51745 1.11647 4.42578 1.3998 4.42578C1.68314 4.42578 1.91647 4.51745 2.0998 4.70078L3.9998 6.60078Z" fill="white"/>
                            </svg>
                        </div>
                    </div>
                    <p className="FormFieldCarLicence-container-main-okm-text">Es 0KM</p>
                </div>}
            </div>
            {isDisabled() && text.length > 0 && <p className="FormFieldCarLicence-container-error">{props.data?.input?.config?.regExp?.error}</p>}
            <div className="FormFieldCarLicence-container-buttonWrapper">
                <MainButton
                    id='Patente'
                    text={props.data?.input?.config?.buttom}
                    onButtonPressed={() => handleItemSelected(okm ? '' : text)}
                    disabled={isDisabled()}
                />
            </div>
        </div>
    );
}

export default FormFieldCarLicence;