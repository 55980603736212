

import React from 'react'
import './CerrarSesionAlert.css'
import MainButton from '../../generalComponents/MainButton/MainButton';

const CerrarSesionAlert = (props: any) => {
  return (
    <div className="cerrarSesionAlert-container">
        <div className="cerrarSesionAlert-container-close" onClick={() => props.close()}>
            <span className="material-symbols-outlined">close</span>
        </div>
        <span className="material-symbols-outlined" style={{ fontSize: 80, color: 'var(--secondary200)' }}>logout</span>
        <p className="cerrarSesionAlert-container-text bold">¿Querés salir de tu cuenta?</p>
        <div className="cerrarSesionAlert-container-botons">
            <MainButton
                id='VOLVER'
                empty
                color='var(--secondary500)'
                text='VOLVER'
                onButtonPressed={() => props.close()}
            />
            <MainButton
                id='CERRAR SESIÓN'
                fill
                color='var(--secondary500)'
                text='CERRAR SESIÓN'
                onButtonPressed={() => {
                    props.close()
                    props.onPress()
                }}
            />
        </div>
    </div>
  );
}

export default CerrarSesionAlert;