import { combineReducers } from 'redux';
import GeneralReducer from "./GeneralReducer";
import CotizarReducer from "./CotizarReducer";
import MainCotizarReducer from './MainCotizarReducer';
import CheckOutReducer from './CheckOutReducer';
import LoginReducer from './LoginReducer'
import UserReducer from './UserReducer';
import HomeReducer from './HomeReducer';
import SuggestReducer from './SuggestReducer';
import ReclamoReducer from './ReclamoReducer';

export default combineReducers({
    general: GeneralReducer,
    cotizar: CotizarReducer,
    mainCotizar: MainCotizarReducer,
    checkOut: CheckOutReducer,
    login: LoginReducer,
    user: UserReducer,
    home: HomeReducer,
    sugerir: SuggestReducer,
    reclamo: ReclamoReducer
});