import React, {useEffect, useState} from 'react'
import './PerfilHome.css'
import {
    getDataForNewUserArgPardo,
    getLocalidadesByProvinciaPardo,
    getCodigosPostalesByLocalidadPardo,
    loadDataForLoggedUserArgPardo
} from '../../actions'
import {connect} from 'react-redux';
import { useMediaQuery } from 'react-responsive'
import { FormattedMessage } from 'react-intl';
import CustomTextInput from '../../components/generalComponents/customTextInput/CustomTextInput';
import SelectorSearcher from '../../components/generalComponents/selectorSearcher/SelectorSearcher';
import Header from '../../components/generalComponents/header/Header';

let isTabletOrMobile = false;
const PerfilHome = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

    //Inputs
    const [nombre, setNombre] = useState<string>('');
    const [apellido, setApellido] = useState<string>('');
    const [fechaNac, setFechaNac] = useState<string>('');

    const [tipoDocu, setTipoDocu] = useState<any>(null);
    const [numDocu, setNumDocu] = useState<string>('');
    const [cuit, setCuit] = useState<string>('');
    
    const [sexo, setSexo] = useState<any>(null);
    const [estadoCivil, setEstadoCivil] = useState<any>(null);
    const [nacionalidad, setNacionalidad] = useState<any>(null);

    const [celular, setCelular] = useState<string>('');
    const [email, setEmail] = useState<string>('');

    const [tipoDomi, setTipoDomi] = useState<any>(null);
    const [calle, setCalle] = useState<string>('');
    const [nro, setNro] = useState<string>('');
    const [piso, setPiso] = useState<string>('');
    const [dpto, setDpto] = useState<string>('');

    const [provincia, setProvincia] = useState<any>(null);
    const [localidad, setLocalidad] = useState<any>(null);
    const [codigoPostal, setCodigoPostal] = useState<any>(null);

    //Listado de Selectors
    const [tipoDocuList, setTipoDocuList] = useState<any>(null);

    const [sexoList, setSexoList] = useState<any>(null);
    const [estadoCivilList, setEstadoCivilList] = useState<any>(null);
    const [nacionalidadList, setNacionalidadList] = useState<any>(null);

    const [tipoDomiList, setTipoDomiList] = useState<any>(null);

    const [provinciaList, setProvicinaList] = useState<any>(null);
    const [localidadList, setLocalidadList] = useState<any>(null);
    const [codigosPostalesList, setCodigosPostalesList] = useState<any>(null);

    //OTROS
    const [authUserId, setAuthUserId] = useState<any>(null);

    useEffect(() => {
        props.getDataForNewUserArgPardo(
            setTipoDocuList, setSexoList, setEstadoCivilList, 
            setNacionalidadList, setTipoDomiList, setLocalidadList, setProvicinaList
        );
    }, [])

    useEffect(() => {
      if(props.userAccessToken) {
        props.loadDataForLoggedUserArgPardo(
            setNombre, setApellido, setFechaNac,
            setTipoDocu, setNumDocu, setCuit,
            setSexo, setEstadoCivil, setNacionalidad,
            setCelular, setEmail,
            setTipoDomi, setCalle, setNro, setPiso, setDpto,
            setProvincia, setLocalidad, setCodigoPostal,
            setLocalidadList, setCodigosPostalesList, setAuthUserId
        );
      }
    },[props.userAccessToken])

    return (
        <div className="perfilHome-container">
            <Header home colored special />
            <div className="perfilHome-container-main">
                <h4 className="perfilHome-container-main-superTitulo bold">Mi Perfil</h4>
                <h6 className="perfilHome-container-main-titulo bold">Datos personales</h6>
                <div className="perfilHome-container-main-list">
                    <CustomTextInput
                        label='Nombre'
                        required
                        value={nombre}
                        disabled
                    />
                    <CustomTextInput
                        label='Apellido'
                        required
                        value={apellido}
                        disabled
                    />
                    <CustomTextInput
                        label='Fecha de nacimiento'
                        required
                        value={fechaNac}
                        disabled
                        maxLength={10}
                        placeholder="DD/MM/AAAA"
                    />
                    <div className="perfilHome-container-main-list-item-selectorWrapper" style={{ opacity: 0.5  }}>
                        <h6 className="perfilHome-container-main-list-item-selectorWrapper-title bold">Tipo de documento&nbsp;<ul>*</ul></h6>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                            id='Selector Tipo de documento'
                            list={tipoDocuList}
                            selectedItem={tipoDocuList && tipoDocu ? tipoDocuList?.filter((a: any) => a.codigo == tipoDocu)[0]?.descripcion : ''}
                            placeholder='Seleccioná tu tipo de documento'
                            disabled
                        /> 
                    </div>
                    <CustomTextInput
                        label='Nro de documento'
                        required
                        value={numDocu}
                        placeholder='Ingresá sólo números sin puntos'
                        maxLength={8}
                        disabled
                    />
                    <CustomTextInput
                        label='Nro de CUIT'
                        required
                        disabled
                        value={cuit}
                        maxLength={11}
                        placeholder='Ingresá sólo números sin puntos ni barras'
                    />
                    <div className="perfilHome-container-main-list-item-selectorWrapper" style={{ opacity: 0.5 }}>
                        <h6 className="perfilHome-container-main-list-item-selectorWrapper-title bold">Sexo&nbsp;<ul>*</ul></h6>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                            id='Selector Sexo'
                            list={sexoList}
                            selectedItem={sexoList && sexo ? sexoList?.filter((a: any) => a.codigo == sexo)[0]?.descripcion : ''}
                            placeholder='Seleccioná tu sexo'
                            disabled
                        /> 
                    </div>
                    <div className="perfilHome-container-main-list-item-selectorWrapper" style={{ opacity: 0.5 }}>
                        <h6 className="perfilHome-container-main-list-item-selectorWrapper-title bold">Estado civil&nbsp;<ul>*</ul></h6>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'} 
                            id='Selector Estado Civil' 
                            list={estadoCivilList}
                            selectedItem={estadoCivilList && estadoCivil ? estadoCivilList?.filter((a: any) => a.codigo == estadoCivil)[0]?.descripcion : ''} 
                            placeholder='Seleccioná tu estado civil' 
                            disabled
                        />
                    </div>
                    <div className="perfilHome-container-main-list-item-selectorWrapper" style={{ opacity: 0.5 }}>
                        <h6 className="perfilHome-container-main-list-item-selectorWrapper-title bold">Nacionalidad&nbsp;<ul>*</ul></h6>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                            id='Selector Nacionalidad'
                            list={nacionalidadList}
                            selectedItem={nacionalidadList && nacionalidad ? nacionalidadList?.filter((a: any) => a.codigo == nacionalidad)[0]?.descripcion : ''}
                            placeholder='Seleccioná tu nacionalidad'
                            disabled
                        /> 
                    </div>
                    <CustomTextInput
                        label='Celular'
                        required
                        maxLength={10}
                        disabled
                        value={celular}
                    />
                    <CustomTextInput
                        label='E-mail'
                        required
                        value={email}
                        placeholder='nombre@ejemplo.com'
                        disabled
                    />
                    <div className="perfilHome-container-main-list-invis"></div>
                </div>
                <h6 className="perfilHome-container-main-titulo bold" style={{marginBottom: 24}}>Datos de domicilio</h6>
                <div className="perfilHome-container-main-list">
                    <div className="perfilHome-container-main-list-item-selectorWrapper" style={{ opacity: 0.5 }}>
                        <h6 className="perfilHome-container-main-list-item-selectorWrapper-title bold">Tipo de domicilio&nbsp;<ul>*</ul></h6>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                            id='Selector Tipo de domicilio'
                            list={tipoDomiList}
                            selectedItem={tipoDomiList && tipoDomi ? tipoDomiList?.filter((a: any) => a.codigo == tipoDomi)[0]?.descripcion : ''}
                            placeholder='Seleccioná tu tipo de domicilio'
                            disabled
                        /> 
                    </div>
                    <CustomTextInput
                        label='Calle'
                        required
                        value={calle}
                        disabled
                    />
                    <div className="perfilHome-container-main-list-list">
                        <CustomTextInput
                            label='Nro'
                            required
                            disabled
                            value={nro}
                        />
                        <CustomTextInput
                            label='Piso'
                            disabled
                            value={piso}
                        />
                        <CustomTextInput
                            label='Dpto.'
                            disabled
                            value={dpto}
                        />
                    </div>
                    <div className="perfilHome-container-main-list-item-selectorWrapper" style={{ opacity: 0.5 }}>
                        <h6 className="perfilHome-container-main-list-item-selectorWrapper-title bold">Provincia&nbsp;<ul>*</ul></h6>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                            id='Selector Provincia'
                            list={provinciaList}
                            disabled
                            selectedItem={provinciaList && provincia ? provinciaList?.filter((a: any) => a.codigo == provincia?.codigo ? provincia?.codigo : provincia)[0]?.descripcion : ''}
                            placeholder='Seleccioná tu provincia'
                        /> 
                    </div>
                    <div className="perfilHome-container-main-list-item-selectorWrapper" style={{ opacity: 0.5 }}>
                        <h6 className="perfilHome-container-main-list-item-selectorWrapper-title bold">Localidad&nbsp;<ul>*</ul></h6>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                            id='Selector Localidad'
                            list={localidadList}
                            selectedItem={localidadList && localidad ? localidadList?.filter((a: any) => a.codigo == localidad?.codigo ? localidad?.codigo : localidad)[0]?.descripcion : ''}
                            placeholder='Seleccioná tu localidad'
                            disabled
                        /> 
                    </div>
                    <div className="perfilHome-container-main-list-item-selectorWrapper" style={{ opacity: 0.5 }}>
                        <h6 className="perfilHome-container-main-list-item-selectorWrapper-title bold">Código postal&nbsp;<ul>*</ul></h6>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                            id='Selector Codigo Postal'
                            list={codigosPostalesList}
                            selectedItem={codigosPostalesList && codigoPostal ? codigosPostalesList?.filter((a: any) => a.codigo == codigoPostal)[0]?.descripcion : ''}
                            placeholder='Seleccioná tu codigo postal'
                            disabled
                        /> 
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state: any) => {
  return {
    userAccessToken: state.general.userAccessToken
  }; 
};

export default connect(mapStateToProps, {
    getDataForNewUserArgPardo,
    getLocalidadesByProvinciaPardo,
    getCodigosPostalesByLocalidadPardo,
    loadDataForLoggedUserArgPardo
})(PerfilHome);