import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux';
import Header from '../../components/generalComponents/header/Header';
import './VerificacionEmail.css'
import Lottie from 'react-lottie';
import animationData from '../../assets/lotties/emailVerificar.json'
import MainButton from '../../components/generalComponents/MainButton/MainButton';
import { FormattedMessage } from 'react-intl';
import { resendEmailValidate } from '../../actions'
import SuccessCard from '../../components/generalComponents/SuccessCard/SuccessCard';
import { getUserMine, netCheckOutPolicyHolder, sendCodeValidate } from '../../net/Connector';
import axios from '../../actions/instanceActions';
import { genericErrorHandler } from '../../util/GenericErrorHandler';
import Cookies from 'universal-cookie';
import { CHECKOUT_DATA, LOADING, USER_ACCESS_TOKEN, USER_ACCESS_TOKEN_AUX, USER_DATA } from '../../actions/types';
import { store } from '../../index';
import { GetGlobalParams } from '../../util/GetGlobalParams';
import { GetCountryUrl } from '../../util/GetCountryUrl';


const VerificacionEmail = (props: any) => {

  const [code1, setCode1] = useState('');
  const [code2, setCode2] = useState('');
  const [code3, setCode3] = useState('');
  const [code4, setCode4] = useState('');
  const navigate = useNavigate();
  const { dispatch } = store;
  const cookies = new Cookies();
  const tokenAux = props.userAccessTokenAux || cookies.get('userAccessTokenAux');

  useEffect(() => {
    document.addEventListener('paste', function (event: any) {
      var clipText = event.clipboardData.getData('Text');
      if (clipText.charAt(0)) {
        setCode1(clipText.charAt(0))
      }
      if (clipText.charAt(1)) {
        setCode2(clipText.charAt(1))
      }
      if (clipText.charAt(2)) {
        setCode3(clipText.charAt(2))
      }
      if (clipText.charAt(3)) {
        setCode4(clipText.charAt(3))
      }
    });
  }, []);

  const valdateEmail = async () => {
    dispatch({ type: LOADING, payload: true });
    try {
      await axios.post(sendCodeValidate(code1 + code2 + code3 + code4), {}, { headers: { 'Authorization': tokenAux } });
      try {
        if (!props.checkOutData) {
          const response = await axios.get(getUserMine(), { headers: { 'Authorization': tokenAux }, params: { countryId: props.countryId } })
          dispatch({ type: USER_ACCESS_TOKEN, payload: tokenAux })
          dispatch({ type: USER_ACCESS_TOKEN_AUX, payload: null })
          dispatch({ type: USER_DATA, payload: response.data });
          navigate(GetCountryUrl(props.countryId) + 'login?' + GetGlobalParams())
        } else {
          axios.put(netCheckOutPolicyHolder(props.checkOutData?.id, props.userData.id), {}, {
            headers: {
              'accept': '*/*',
              'Content-Type': 'application/json',
              'Authorization': tokenAux
            }
          })
            .then((response) => {
              dispatch({ type: CHECKOUT_DATA, payload: response.data });
              axios.get(getUserMine(), { headers: { 'Authorization': tokenAux }, params: { countryId: props.countryId } })
                .then((response) => {
                  navigate(-1);
                  dispatch({ type: USER_ACCESS_TOKEN_AUX, payload: null })
                  dispatch({ type: USER_DATA, payload: response.data });
                  dispatch({ type: USER_ACCESS_TOKEN, payload: tokenAux })
                })
                .catch((error) => {
                  genericErrorHandler(error)
                })
            })
            .catch((error) => {
              genericErrorHandler(error)
            })
        }
      } catch (error) {
        genericErrorHandler(error);
      } finally {
        dispatch({ type: LOADING, payload: false });
      }
    } catch (error) {
      genericErrorHandler(error);
    } finally {
      dispatch({ type: LOADING, payload: false });
    }
  }

  const input2: any = useRef();
  const input3: any = useRef();
  const input4: any = useRef();

  return (
    <div className="verificacionEmail-container">
      <Header
        color={'var(--primary500)'}
        secondaryColor={'var(--primary400)'}
      />
      <div className="verificacionEmail-container-main">
        <SuccessCard translateX={0} translateY={0} />
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
          }}
          height={100}
          width={120}
          isStopped={false}
          isPaused={false}
        />
        <p className="verificacionEmail-container-main-title"><FormattedMessage id="page.verificacionEmail.title" defaultMessage="" /></p>
        <p className="verificacionEmail-container-main-subTitle"><FormattedMessage id="page.verificacionEmail.subTitle" defaultMessage="" values={{ email: props.emailValidate }} /></p>
        <div className="verificacionEmail-container-main-codeContainer">
          <div className="verificacionEmail-container-main-codeContainer-inputContainer">
            <input
              id='input1'
              className="verificacionEmail-container-main-codeContainer-inputContainer-input"
              value={code1}
              onChange={(e: any) => {
                setCode1(e.target.value)
                if (e.target.value !== '') {
                  setCode2('')
                  input2.current.focus();
                }
              }}
              maxLength={1}
            />
          </div>
          <div className="verificacionEmail-container-main-codeContainer-inputContainer">
            <input
              ref={input2}
              className="verificacionEmail-container-main-codeContainer-inputContainer-input"
              value={code2}
              onChange={(e: any) => {
                setCode2(e.target.value)
                if (e.target.value !== '') {
                  setCode3('')
                  input3.current.focus();
                }
              }}
              maxLength={1}
            />
          </div>
          <div className="verificacionEmail-container-main-codeContainer-inputContainer">
            <input
              ref={input3}
              className="verificacionEmail-container-main-codeContainer-inputContainer-input"
              value={code3}
              onChange={(e: any) => {
                setCode3(e.target.value)
                if (e.target.value !== '') {
                  setCode4('')
                  input4.current.focus();
                }
              }}
              maxLength={1}
            />
          </div>
          <div className="verificacionEmail-container-main-codeContainer-inputContainer">
            <input
              ref={input4}
              className="verificacionEmail-container-main-codeContainer-inputContainer-input"
              value={code4}
              onChange={(e: any) => setCode4(e.target.value)}
              maxLength={1}
            />
          </div>
        </div>
        <p className="verificacionEmail-container-main-resendText">
          <FormattedMessage id="page.verificacionEmail.noTeMail" defaultMessage="¿No te llegó el mail?" />
          <p
            id='reenviar verificacion'
            style={{ cursor: 'pointer' }}
            onClick={() => props.resendEmailValidate(tokenAux)}
          ><strong>&nbsp;<FormattedMessage id="page.verificacionEmail.noTeMail2" defaultMessage="Reenviar" /></strong></p>
        </p>
        <div className="verificacionEmail-container-main-buttonWrapper">
          <MainButton
            id='Continuar Boton'
            disabled={false}
            text={'validar e-mail'.toUpperCase()}
            onButtonPressed={() => { valdateEmail(); }}
            altStyle3
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    emailValidate: state.login.emailValidate,
    userData: state.user.userData,
    checkOutData: state.checkOut.checkOutData,
    countryId: state.general.countryId,
    userAccessTokenAux: state.login.userAccessTokenAux,
    resendValidCodeSuccess: state.login.resendValidCodeSuccess,
  };
};

export default connect(mapStateToProps, {
  resendEmailValidate,
})(VerificacionEmail);