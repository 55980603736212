import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import CustomTextInput from "../../components/generalComponents/customTextInput/CustomTextInput"
import { connect, useDispatch } from 'react-redux';
import CardPayContainer from "../../components/generalComponents/CardPayContainer/CardPayContainer";
import { FormattedMessage } from "react-intl";
import EmisoraType from "../../components/checkoutComponents/CheckOutPayment/EmisoraType";
import Selector from "../../components/generalComponents/selector/Selector";
import { useMediaQuery } from "react-responsive";
import moment from 'moment/moment'
import { genericErrorHandler } from "../../util/GenericErrorHandler";
import { getEmisoras } from "../../net/Connector";
import { addCard2, checkOutPayment, cvvTextInputChange, fetchCardBanks, fetchUserCardsBrands, resetDataOnEnter } from "../../actions";
import axios from '../../actions/instanceActions';
import { formatCreditCard } from "./CreditCardUtils";
import { LOADING, SUCCESS_CARD_TEXT } from "../../actions/types";

let isTabletOrMobile = false;

interface Emisora {
    name: string;
    code: string;
    pattern: string;
    imagePathFront: string;
    numberOfDigits: number;
}

const CreditCardArConfigComponent = forwardRef((props: any, ref) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

    const [tipoId, setTipoId] = useState<number | undefined>(undefined);
    const [cvvTextInput, setCvvTextInput] = useState<number>();

    const [bancoId, setBancoId] = useState<number>();

    const [fechaTarjeta, setFechaTarjeta] = useState<string>("");
    const [numeroTarjeta, setNumeroTarjeta] = useState<string>("");
    const [dniTarjeta, setDniTarjeta] = useState<string>("");
    const [titularTarjeta, setTitularTarjeta] = useState<string>("");

    const [emisorasType, setEmisorasType] = useState<Emisora[]>([])

    const mounted = useRef<boolean>(false);
    const dispatch = useDispatch();

    const writeFechaVencimiento = (text) => {
        let newText = text;
        if (text.length >= 3 && text[2] !== '/') {
            newText = text[0] + text[1] + '/' + text.slice(2);
        }
        setFechaTarjeta(newText);
    }

    useEffect(() => {
        const ready = !isDisabled();
        props.onChangeStatus(ready);
    })

    useEffect(() => {
        getEmisorBanks();
        if (!mounted.current) {
            props.resetDataOnEnter()
            setCvvTextInput(undefined);
            mounted.current = true;
        }
    }, []);

    const getEmisorBanks = async () => {
        try {
            const response = await axios.get(getEmisoras());
            setEmisorasType(response.data);
        } catch (error) {
            genericErrorHandler(error)
        }
    }


    const validateNumeroTarjeta = () => {
        // Emisora todavia no seleccionada?
        if (emisorasType == null || tipoId === undefined) {
            return false;
        }
        if (emisorasType[tipoId].pattern.replaceAll(" ", "").length !== numeroTarjeta.length) {
            return false
        }

        return valid_credit_card(numeroTarjeta);

    }

    const inValidateNumeroTarjeta = () => {
        return !validateNumeroTarjeta();
    }

    const isInvalidDocument = () => {
        if (dniTarjeta.length > 0) {
            if (props.countryId === 2) {
                if (dniTarjeta.length !== 13) {
                    return true;
                }
                if (!(dniTarjeta.toLowerCase().substring(0, 4).match(/^[a-z]+$/) && dniTarjeta.substring(4, 10).match(/^[0-9]+$/))) {
                    return true;
                }
            } else {
                if (dniTarjeta.length !== 8) {
                    return true
                }
                if (!dniTarjeta.match(/^[0-9]+$/)) {
                    return true
                }
            }
        }
        return false
    }

    const isValidDocument = () => {
        if (props.countryId === 1) {
            if (dniTarjeta.length === 8 && dniTarjeta.match(/^[0-9]+$/)) {
                return true
            }
        } else {
            if (dniTarjeta.length === 13) {
                if (dniTarjeta.toLowerCase().substring(0, 4).match(/^[a-z]+$/) && dniTarjeta.substring(4, 10).match(/^[0-9]+$/)) {
                    return true;
                }
            }
        }
        return false
    }

    const validName = (text) => {
        if (!(/^\s/.test(text))) {
            return (/^[a-zA-Z ’]*$/.test(text))
        } return false
    }

    const isInvalidDate = () => {
        if (fechaTarjeta.length === 0) {
            return false
        } else if (fechaTarjeta.length === 5) {
            if (moment(moment(fechaTarjeta, 'MM/YY').toDate()).isAfter(moment(new Date()).add(-1, 'M')) && moment(moment(fechaTarjeta, 'MM/YY').toDate()).isBefore(moment().add(50, 'y'))) {
                return false;
            }
            return true;
        }
        return true
    }

    const validateFechaTarjeta = () => {
        if (fechaTarjeta.length === 5) {
            if (
                moment(moment(fechaTarjeta, 'MM/YY').toDate()).isAfter(moment(new Date()).add(-1, 'M'),)
                && moment(moment(fechaTarjeta, 'MM/YY').toDate()).isBefore(moment(new Date()).add(50, 'y'),)
            ) {
                return true;
            }
        }
        return false;
    }

    const valid_credit_card = (value) => {
        if (/[^0-9-\s]+/.test(value)) return false;

        var nCheck = 0, nDigit = 0, bEven = false;
        value = value.replace(/\D/g, "");

        for (var n = value.length - 1; n >= 0; n--) {
            var cDigit = value.charAt(n);
            nDigit = parseInt(cDigit, 10);
            if (bEven) {
                if ((nDigit *= 2) > 9) nDigit -= 9;
            }
            nCheck += nDigit;
            bEven = !bEven;
        }
        return (nCheck % 10) === 0;
    }

    const isDisabled = () => {
        if (props.countryId === 1) {
            if (
                tipoId === undefined ||
                bancoId === undefined ||
                !validateNumeroTarjeta() ||
                !validateFechaTarjeta() ||
                !validName(titularTarjeta) ||
                !isValidDocument()
                // !valiCVV()
            ) {
                return true;
            }
        } else {
            if (
                tipoId === undefined ||
                !validateNumeroTarjeta() ||
                !validateFechaTarjeta() ||
                !validName(titularTarjeta) ||
                !isValidDocument()
                // !validCVV()
            ) {
                return true;
            }
        }
    }

    const selected = (item) => {
        if (emisorasType !== null && tipoId) {
            return (emisorasType[tipoId].code === item.code)
        }
        return false
    }

    return (
        <div className="checkOutPayment-container-main-infoCard">
            <p className='titulo'>Nueva Tarjeta</p>
            <div className='subContenedor'>
                <CardPayContainer
                    numero={validateNumeroTarjeta() ? numeroTarjeta.replace(/\s/g, " ") : ""}
                    titular={titularTarjeta?.length > 0 ? !validName(titularTarjeta) ? 'NOMBRE Y APELLIDO' : titularTarjeta : 'NOMBRE Y APELLIDO'}
                    vigencia={validateFechaTarjeta() ? fechaTarjeta : 'MM/AA'}
                    emisor={tipoId !== undefined ? emisorasType[tipoId]?.code : -1}
                    imagen={tipoId !== undefined && emisorasType[tipoId]?.imagePathFront}
                    pattern={tipoId !== undefined && emisorasType[tipoId]?.pattern}
                    // flipForCvv={validateFechaTarjeta() && props.dniTarjeta === ''}
                    cvv={''}
                />
                <div className='formulario-container'>
                    <p className='formulario-title'> <FormattedMessage id="component.checkOut.payment.seleccionaTuEmisor" defaultMessage="" /></p>
                    <div className='emisorOne'
                    >
                        {
                            emisorasType.map(((emisora, index) => {
                                return (
                                    <EmisoraType
                                        selected={tipoId != null && emisorasType[tipoId].code === emisora.code}
                                        name={emisora.name}
                                        onSelected={
                                            () => {
                                                if (selected(emisora)) {
                                                    setTipoId(undefined);
                                                    setBancoId(undefined);
                                                    setNumeroTarjeta('');
                                                } else {
                                                    setTipoId(index);
                                                    if (props.countryId === 1) {
                                                        props.fetchCardBanks(emisora.code)
                                                    }
                                                    setBancoId(undefined);
                                                    setNumeroTarjeta('');
                                                }
                                            }
                                        }
                                    />
                                )
                            }))
                        }
                    </div>
                    {props.bankTypes.length > 0 && <p className='subTitle'><FormattedMessage id="component.checkOut.payment.banco" defaultMessage="" /></p>}
                    {
                        (props.bankTypes && props.bankTypes.length > 0) &&
                        <Selector
                            widthList={isTabletOrMobile ? '90%' : '30.64%'}
                            id={'Selector banco'}
                            disabled={tipoId === undefined}
                            popularList={[]}
                            list={props.bankTypes}
                            onValueSelected={(a) => {
                                props.bankTypes.map((b, index) => {
                                    if (b.codigo === a.codigo) {
                                        setBancoId(index)
                                    }
                                })
                            }}
                            selectedItem={bancoId !== undefined ? props.bankTypes[bancoId].descripcion : ''}
                            placeholder={<FormattedMessage id="component.checkOut.payment.seleccionaTuBanco.placeholder" defaultMessage="" />}
                            deleteSelectedItem={() => setBancoId(undefined)}
                        />
                    }

                    <CustomTextInput
                        disabled={props.countryId === 1 ? bancoId === undefined : tipoId === undefined}
                        label={<FormattedMessage id="component.checkOut.payment.numeroTarjeta" defaultMessage="" />}
                        required
                        changeText={(text) => {
                            text = text.replace(/[^\d+$]+/g, '');
                            setNumeroTarjeta(text)
                        }}
                        value={tipoId !== undefined ? formatCreditCard(numeroTarjeta.replaceAll(" ", ""), emisorasType[tipoId].pattern) : ""}
                        maxLength={props.bankTypes !== null && tipoId !== undefined ? emisorasType[tipoId].pattern.length : 0}
                        success={validateNumeroTarjeta()}
                        returnKeyType='done'
                        error={numeroTarjeta !== "" ? inValidateNumeroTarjeta() : false}
                        errorMessage={"Numero de tarjeta invalida"}
                    />
                    <CustomTextInput
                        disabled={!validateNumeroTarjeta()}
                        label={<FormattedMessage id="component.checkOut.payment.nombreTitular" defaultMessage="" />}
                        placeholder={'Como figura en la tarjeta'}
                        success={titularTarjeta.length > 0}
                        error={titularTarjeta === '' ? false : !validName(titularTarjeta)}
                        errorMessage="Nombre inválido"
                        required
                        value={titularTarjeta}
                        changeText={(text) => setTitularTarjeta(text.normalize("NFD").toUpperCase())}
                    />
                    <div className='form-div-uno'>
                        <CustomTextInput
                            disabled={titularTarjeta.length === 0 || !validName(titularTarjeta)}
                            label={<FormattedMessage id="component.checkOut.payment.fechaVencimiento" defaultMessage="" />}
                            placeholder='MM/AA'
                            success={validateFechaTarjeta()}
                            error={isInvalidDate()}
                            errorMessage="Fecha invalida"
                            maxLength={5}
                            value={fechaTarjeta}
                            changeText={writeFechaVencimiento}
                        />
                    </div>
                    <div className='form-div-uno'>
                        <div style={{ marginTop: 40, width: '50%' }}>
                            <Selector
                                widthList={isTabletOrMobile ? '30%' : '7.9%'}
                                id={'Selector dni'}
                                disabled={true}
                                popularList={[]}
                                list={props.countryId === 1 ? ['DNI'] : ['RFC']}
                                placeholder={props.countryId === 1 ? 'DNI' : 'RFC'}
                            />
                        </div>
                        <CustomTextInput
                            disabled={!validateFechaTarjeta()}
                            maxLength={props.countryId == 2 ? 13 : 8}
                            success={isValidDocument()}
                            label={props.countryId == 2 ? "RFC del titular" : "Documento del titular"}
                            value={dniTarjeta}
                            changeText={setDniTarjeta}
                            error={isInvalidDocument()}
                            errorMessage="Documento incompleto"
                        />
                    </div>
                </div>
            </div>


        </div>
    );

});

const mapStateToProps = (state) => {
    return {
        countryId: state.general.countryId,
        checkOutData: state.checkOut.checkOutData,
        bankTypes: state.checkOut.bankTypes
    }
};

export default connect(mapStateToProps, {
    checkOutPayment,
    fetchUserCardsBrands,
    resetDataOnEnter,
    cvvTextInputChange,
    fetchCardBanks
}, null, { forwardRef: true })(CreditCardArConfigComponent);

