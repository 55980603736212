import { useState } from 'react';
import './FormFieldQuestionnaire.css';
import FormFieldQuestionnaireItem from './FormFieldQuestionnaireItem/FormFieldQuestionnaireItem';

const FormFieldQuestionnaire = (props: any) => {

  const [localTos, setLocalTos] = useState<boolean>(false);

  const [list, setList] = useState<any>([
    {
      id: 1,
      question: '¿Padecés o padeciste hipertensión arterial?',
      answer: false
    },
    {
      id: 2,
      question: '¿Sufrís o has sufrido cáncer, accidente cerebrovascular, o infarto agudo de miocardio, insuficiencia renal o de otros órganos, hemorragias, diabetes, hipercolesterolemia, enfermedades neurológicas o de las arterias?',
      answer: false
    },
    {
      id: 3,
      question: '¿Fuiste o prevées ser sometido a by pass o implantes stent ó transplante de órganos como donante o receptor?',
      answer: false
    },
    {
      id: 4,
      question: '¿Te encontrás en la lista de espera como receptor de órganos?',
      answer: false
    },
    {
      id: 5,
      question: '¿Te indicaron la colocación de una prótesis traumatológica, ocular, auditiva o neuroquirúrgica en los últimos 6 meses?',
      answer: false
    },
  ]);

  const handleToggleAnswer = (id: any, answer: any) => {
    const updatedList = list.map((item: any) => {
      if (item.id === id) {
        return {
          ...item,
          answer: answer, // Toggle the answer
        };
      }
      return item;
    });

    setList(updatedList);
  };

  return (
    <div className="formFieldQuestionnaire-container">
      <h6 className="formFieldQuestionnaire-container-title bold" >Tu declaración jurada</h6>
      <div className="formFieldQuestionnaire-container-main">
        <p className="formFieldQuestionnaire-container-main-subtitle bold">Por favor, respondé “si” o “no” a las siguientes preguntas</p>
        <div>
          {list?.map((a) => (
            <FormFieldQuestionnaireItem
              questionNumber={a.id}
              question={a.question}
              answer={a.answer}
              setAnswer={(answer: any) => handleToggleAnswer(a.id, answer)}
            />
          ))}
        </div>
        <div 
          className="formFieldQuestionnaire-container-main-tos"
          onClick={() => setLocalTos(!localTos)}
        >
          <div className="formFieldQuestionnaire-container-main-tos-box">
            <div 
              className="formFieldQuestionnaire-container-main-tos-box-inner"
              style={{transform: localTos ? `scale(${1})` : `scale(${0})`}}
            >
              <span className="material-symbols-outlined" style={{ fontSize: 18 }}>check</span>
            </div>
          </div>
          <p className="formFieldQuestionnaire-container-main-tos-text">Declaro bajo juramento que la información proporcionada es verídica y completa.</p>
        </div>
      </div>
    </div>
  )
}

export default FormFieldQuestionnaire;