import {
    CHECKOUT_DATA,
    CHECK_OUT_PRODUCT_REQUIREMENTS,
    CHECK_OUT_PAYMENT_OPTIONS,
    USER_CREDIT_CARDS,
    CARD_SELECTED_CHECKOUT,
    CVV_TEXT_INPUT,
    PROMO_CODE_ERROR,
    CHECK_OUT_CONFIRM_START,
    CHECK_OUT_CONFIRM_SUCCESS,
    CHECK_OUT_CONFIRM_ERROR,
    CHECK_OUT_CONFIRM_ERROR_MESSAGE,
    CHECKOUT_DATA_PAYMENT_INFO,
    CHECK_OUT_CONFIRM_SUCCESS_PRODUCT,
    CHECK_OUT_CONFIRM_SUCCESS_USER,
    FETCH_TYPES_BANKS_ARRAY,
    BANCO_TARJETA_ID,
    TIPO_TARJETA_ID,
    FETCH_BANK_ARRAY,
    NUMERO_TARJETA,
    DNI_TARJETA,
    TITULAR_TARJETA,
    FECHA_TARJETA,
    STEP_TRES,
    CHECK_OUT_CONFIRM_DATA,
    CHECK_OUT_CBU,
    CHECK_OUT_NUMERO_TARJETA,
    CHECK_OUT_TIPO,
    CHECK_OUT_EMISORA_TYPE,
    CHECK_OUT_REFERER,
    CHECK_OUT_REFERER_APPLIED,
    CHECKOUT_DATA_USER,
    CHECK_OUT_REFERER_ERROR,
    CHECKOUT_DATA_HOME
} from '../actions/types';

const INITIAL_STATE = {
    checkOutData: null,
    checkOutProductRequerements: null,
    checkOutPaymentOptions: null,
    userCreditCards: null,
    cardSelectedCheckout: null,
    cvvTextInput: '',
    promoCodeError: null,
    checkOutConfirmStart: false,
    checkOutConfirmSuccess: false,
    checkOutConfirmError: false,
    checkOutConfirmErrorMessage: '',
    checkOutDataPaymentInfo: null,
    checkOutConfirmSuccessProduct: '',
    checkOutConfirmSuccessUser: '',
    bankTypes: [],
    bancoId: -1,
    tipoId: -1,
    objBank: null,
    dniTarjeta: '',
    titularTarjeta: '',
    fechaTarjeta: '',
    cvv: '',
    step: '',
    cbu: null,
    numeroTarjeta: null,
    tipo: null,
    emisorasType: null,
    referer: '',
    checkoutDataUser: null,
    refererApplied: false,
    refererError: false,
    checkoutDataHome: null
};

export default (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case CHECKOUT_DATA:
            return { ...state, checkOutData: action.payload };
        case CHECK_OUT_PRODUCT_REQUIREMENTS:
            return { ...state, checkOutProductRequerements: action.payload };
        case CHECK_OUT_PAYMENT_OPTIONS:
            return { ...state, checkOutPaymentOptions: action.payload };
        case USER_CREDIT_CARDS:
            return { ...state, userCreditCards: action.payload };
        case CARD_SELECTED_CHECKOUT:
            return { ...state, cardSelectedCheckout: action.payload };
        case CVV_TEXT_INPUT:
            return { ...state, cvvTextInput: action.payload };
        case PROMO_CODE_ERROR:
            return { ...state, promoCodeError: action.payload };
        case CHECK_OUT_CONFIRM_START:
            return { ...state, checkOutConfirmStart: action.payload };
        case CHECK_OUT_CONFIRM_SUCCESS:
            return { ...state, checkOutConfirmSuccess: action.payload };
        case CHECK_OUT_CONFIRM_ERROR:
            return { ...state, checkOutConfirmError: action.payload };
        case CHECK_OUT_CONFIRM_ERROR_MESSAGE:
            return { ...state, checkOutConfirmErrorMessage: action.payload };
        case CHECKOUT_DATA_PAYMENT_INFO:
            return { ...state, checkOutDataPaymentInfo: action.payload };
        case CHECK_OUT_CONFIRM_SUCCESS_PRODUCT:
            return { ...state, checkOutConfirmSuccessProduct: action.payload };
        case CHECK_OUT_CONFIRM_SUCCESS_USER:
            return { ...state, checkOutConfirmSuccessUser: action.payload };
        case FETCH_TYPES_BANKS_ARRAY:
            return { ...state, bankTypes: action.payload };
        case BANCO_TARJETA_ID:
            return { ...state, bancoId: action.payload };
        case TIPO_TARJETA_ID:
            return { ...state, tipoId: action.payload };
        case FETCH_BANK_ARRAY:
            return { ...state, objBank: action.payload };
        case DNI_TARJETA:
            return { ...state, dniTarjeta: action.payload };
        case TITULAR_TARJETA:
            return { ...state, titularTarjeta: action.payload };
        case FECHA_TARJETA:
            return { ...state, fechaTarjeta: action.payload };
        case STEP_TRES:
            return { ...state, step: action.payload };
        case CHECK_OUT_CONFIRM_DATA:
            return { ...state, checkOutConfirmData: action.payload };
        case CHECK_OUT_CBU:
            return { ...state, cbu: action.payload };
        case CHECK_OUT_NUMERO_TARJETA:
            return { ...state, numeroTarjeta: action.payload };
        case CHECK_OUT_TIPO:
            return { ...state, tipo: action.payload };
        case CHECK_OUT_EMISORA_TYPE:
            return { ...state, emisorasType: action.payload };
        case CHECK_OUT_REFERER:
            return { ...state, referer: action.payload };
        case CHECKOUT_DATA_USER:
            return { ...state, checkoutDataUser: action.payload };
        case CHECK_OUT_REFERER_APPLIED:
            return { ...state, refererApplied: action.payload };
        case CHECK_OUT_REFERER_ERROR:
            return { ...state, refererError: action.payload };
        case CHECKOUT_DATA_HOME:
            return { ...state, checkoutDataHome: action.payload };
        default:
            return state;
    }
};