

import React from 'react'
import './SiniestroAlert.css'
import MainButton from '../../generalComponents/MainButton/MainButton';

const SiniestroAlert = (props: any) => {
  return (
    <div className="siniestroAlert-container">
        <div className="siniestroAlert-container-close" onClick={() => props.close()}>
            <span className="material-symbols-outlined">close</span>
        </div>
        <div className="siniestroAlert-container-data">
            <p className="bold" style={{ color: 'var(--primary500)', textAlign: 'center' }}>DENUNCIAR UN SINIESTRO</p>
        </div>
        <div className="siniestroAlert-container-data">
            <b className="regular large" style={{ color: 'var(--gray500)', textAlign: 'center' }}>Por favor, comunicate con tu aseguradora a través de</b>
            <b className="bold large" style={{ color: 'var(--secondary500)', cursor: 'pointer', textAlign: 'center' }} onClick={() => window.open("https://api.whatsapp.com/send?phone=541123646994&text=%C2%A1Hola!%F0%9F%91%8BQuer%C3%ADa%20consultar%20sobre...")}>Whatsapp</b>
            <b className="regular large" style={{ color: 'var(--primary500)', textAlign: 'center' }}>11 2364 6994</b>
        </div>
        <div className="siniestroAlert-container-data">
            <b className="regular large" style={{ color: 'var(--gray500)', textAlign: 'center' }}>o desde del siguiente canal</b>
            <b className="regular large" style={{ color: 'var(--primary500)', textAlign: 'center' }}>0810-666-0066</b>
            <b className="regular large" style={{ color: 'var(--gray500)', textAlign: 'center' }}>de Lunes a Viernes de 8 a 20 h </b>
        </div>
        <div className="siniestroAlert-container-botton">
            <MainButton
                id='ACEPTAR'
                fill
                color='var(--secondary500)'
                text='ACEPTAR'
                onButtonPressed={() => props.close()}
            />
        </div>
    </div>
  );
}

export default SiniestroAlert;