import React, {useState} from 'react'
import CustomTextInput from '../../../customTextInput/CustomTextInput';
import './FormFieldImageOption.css'

const FormFieldImageOption = (props: any) => {

    const [localValue, setLocalValue] = useState<any>(null)

    return (
        <div className="FormFieldImageOption-inputContainer">
            <h1 dangerouslySetInnerHTML={{__html: props.data.display.name}}/>
            <p className="FormFieldImageOption-inputContainer-description" dangerouslySetInnerHTML={{__html: props.data.display.description}}/>
            <div className="FormFieldImageOption-inputContainer-listContainer">
                {props.data.input.config.values.map((a: any) => (
                    <div 
                        className={a.value === localValue ? "FormFieldImageOption-inputContainer-listContainer-item FormFieldImageOption-gradient" : "FormFieldImageOption-inputContainer-listContainer-item"}
                        onClick={() => {
                            setLocalValue(a.value === localValue ? null : a.value)
                            props.onUpdate(a.value === localValue ? null : a.value)
                        }}
                        style={{backgroundColor: localValue === a.value ? 'var(--primary500)' : 'var(--white)'}}
                    >
                        <img 
                            src={a.icon} 
                            className={a.value === localValue ? "FormFieldImageOption-inputContainer-listContainer-item-img FormFieldImageOption-white" : "FormFieldImageOption-inputContainer-listContainer-item-img"}
                        />
                        <p 
                            className="FormFieldImageOption-inputContainer-listContainer-item-text"
                            style={{color: localValue === a.value ? 'var(--white)' : 'var(--primary500)'}}
                        >{a.label}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default FormFieldImageOption;