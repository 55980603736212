import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useLocation } from 'react-router-dom';
import { recoverySuccessNewPassword } from '../../actions';
import { ReactComponent as SuccessInput } from '../../assets/images/svgs/successInput.svg';
import CustomTextInput from '../../components/generalComponents/customTextInput/CustomTextInput';
import Footer from '../../components/generalComponents/footer/Footer';
import Header from '../../components/generalComponents/header/Header'
import MainButton from '../../components/generalComponents/MainButton/MainButton';
import './PasswordRecoveryForm.css'

let isTabletOrMobile = false;
let loading = false;
const PasswordRecoveryForm = (props) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' });

    const [newContraseña, setNewContraseña] = useState<string>('');
    const [repeatNewContraseña, setRepeatNewContraseña] = useState<string>('');
    const countryId: number = useSelector((state: any) => state.general.countryId);
    const recoveryPasswordId: string = useSelector((state: any) => state.login.recoveryPasswordId);
    const location: any = useLocation();
    const redirectTo: string = location.state?.link ? location.state.link : '';
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validatePassword = (e: string) => {
        if (e.length >= 8) {
            if (/\d/.test(e) && /[a-z]/.test(e) && /[A-Z]/.test(e)) {
                loading = true;
                return true;
            }
        }
        loading = false;
        return false;
    }

    const isDisabled = () => {
        if (newContraseña !== '' && repeatNewContraseña !== '' && repeatNewContraseña === newContraseña) {
            return (!validatePassword(newContraseña) || !validatePassword(repeatNewContraseña))
        } return true
    }

    return (
        <div className="mainCotizar-container">
            <Header />
            <div className="passwordRecovery-container-title" style={{ alignItems: 'center' }}>
                <h3 className="passwordRecovery-container-title-text bold"><FormattedMessage id="page.recovery.password.title" defaultMessage="" /></h3>
            </div>
            <div className="passwordRecoveryForm-container-main">
                <CustomTextInput
                    label='Contraseña nueva'
                    placeholder='*********'
                    required
                    changeText={(text: string) => setNewContraseña(text)}
                    value={newContraseña}
                    password
                    success={validatePassword(newContraseña)}
                    error={newContraseña !== '' ? !validatePassword(newContraseña) : false}
                />
                <CustomTextInput
                    label='Repetir contraseña nueva'
                    placeholder='*********'
                    required
                    changeText={(text: string) => setRepeatNewContraseña(text)}
                    value={repeatNewContraseña}
                    password
                    success={validatePassword(repeatNewContraseña)}
                    error={repeatNewContraseña !== '' ? repeatNewContraseña !== newContraseña || !validatePassword(repeatNewContraseña) : false}
                />
                <p className="container-main-noticeText" style={{ marginTop: 20, marginBottom: 20, marginLeft: 10 }}>Tu contraseña nueva debe incluir: </p>
                {loading ?
                    <div>
                       <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 10}}>
                            <SuccessInput/> <p className="container-main-noticeText" style={{ marginLeft: 10}}>Mínimo 8 caracteres</p>
                       </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 10 }}>
                                <SuccessInput/><p className="container-main-noticeText" style={{ marginLeft: 10}}>Letras y números</p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 10 }}>
                            <SuccessInput/> <p className="container-main-noticeText" style={{ marginLeft: 10}}>1 letra mayúscula</p>
                        </div>
                    </div>
                    :
                    <div>
                        <p className="container-main-noticeText" style={{marginLeft: 20}}>&bull; Mínimo 8 caracteres</p>
                        <p className="container-main-noticeText" style={{marginLeft: 20}}>&bull; Letras y números</p>
                        <p className="container-main-noticeText" style={{marginLeft: 20}}>&bull; 1 letra mayúscula</p>
                    </div>

                }
                <div style={{ marginTop: 20, width: '100%' }}>
                    <MainButton
                        id='Continuar Boton'
                        disabled={isDisabled()}
                        text='ACTUALIZAR CONTRASEÑA'
                        onButtonPressed={() =>
                            dispatch(recoverySuccessNewPassword(recoveryPasswordId, newContraseña, countryId, redirectTo, navigate))
                        }
                        altStyle10
                    />
                </div>
            </div>
        </div>
    )
}

export default PasswordRecoveryForm;
