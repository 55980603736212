import "./PaymentPolicyResumeAccountBank.css";

const PaymentPolicyResumeAccountBank = (props: any) => {
    return (
        <div className="info">
            <span className="title"><strong>Debito Bancario</strong></span>
        </div>
    )
}

export { PaymentPolicyResumeAccountBank };