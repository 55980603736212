import { createPersingUrl, getUninsuredItems, uploadUninsuredItem } from "../net/Connector";
import { IUrlPersing } from "../pages/productSuggestPage/ProductSuggestPage";
import { genericErrorHandler } from "../util/GenericErrorHandler";
import axios from './instanceActions'
import {
  LOADING,
  FETCH_SUGERIDOS_LIST_USER,
  FETCH_URL_SUGERIDOS,
  RECOVERY_PASSWORD_SUCCESS
} from "./types";

declare global {
  interface FormDataValue {
    uri: string;
    name: string;
    type: string;
  }

  interface FormData {
    append(name: string, value: FormDataValue, fileName?: string): void;
    set(name: string, value: FormDataValue, fileName?: string): void;
    get(name: string): FormDataEntryValue | null;
  }
}

export const fetchSugeridosListUser = () => {
  return async (dispatch: any) => {
    dispatch({ type: LOADING, payload: true });
    try {
      const response = await axios.get(getUninsuredItems());
      dispatch({ type: FETCH_SUGERIDOS_LIST_USER, payload: response.data });
    } catch (error) {
      genericErrorHandler(error);
    } finally {
      dispatch({ type: LOADING, payload: false });
    }
  }
}

export const getUrlSuggest = () => {
  return async (dispatch: any) => {
    dispatch({ type: LOADING, payload: true });
    try {
      let aux: IUrlPersing[] = [];
      const response = await axios.post(createPersingUrl(), { amount: 2 });
      for (let index = 0; index < response.data.length; index++) {
        let element = {
          loading: false,
          presignedUrl: response.data[index],
          defaultValue: ''
        };
        aux.push(element);
      }
      dispatch({ type: FETCH_URL_SUGERIDOS, payload: aux })
    } catch (error) {
      genericErrorHandler(error);
    } finally {
      dispatch({ type: LOADING, payload: false })
    }
  }
}

export const cargarItem = (name: string, model: string, description: string, value: number, imageFile: string, userEmail: string, userName: string) => {
  return async (dispatch: any) => {
    dispatch({ type: LOADING, payload: true });
    let formDatas: Object = {
      description: description,
      image: imageFile ? imageFile : null,
      model: model,
      name: name,
      pricePerDay: 0,
      value: value,
      userEmail: userEmail ? userEmail : null,
      userName: userName ? userName : null
    }

    try {
      await axios.post(uploadUninsuredItem(), formDatas);
      dispatch({ type: RECOVERY_PASSWORD_SUCCESS, payload: true})
    } catch (error) {
      dispatch({ type: RECOVERY_PASSWORD_SUCCESS, payload: false})
      genericErrorHandler(error);
    } finally {
      dispatch({ type: LOADING, payload: false });
    }
  }
};

export const resetRecoveryPassword = () => {
  return (dispatch: any) => {
    dispatch({ type: RECOVERY_PASSWORD_SUCCESS, payload: false})
  }
}
