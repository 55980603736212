import React from 'react'
import './CalendarComponent.css'
import '@wecover/react-date-range/dist/styles.css'; // main style file
import '@wecover/react-date-range/dist/theme/default.css'; // theme css file
import { useMediaQuery } from 'react-responsive';
import { Calendar } from '@wecover/react-date-range';
import { ReactComponent as ButtonExit } from '../../../assets/images/svgs/exit.svg';
import { es } from 'date-fns/locale'
import MainButton from '../../generalComponents/MainButton/MainButton';

let isTabletOrMobile;
const CalendarComponent = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

    return (
        <div className="calendar-container">
            <div 
                id='restricciones cerrar'
                className="calendar-container-close"
                onClick={() => props.dismissCalendar()}
            >
                <ButtonExit/>
            </div>
            <p className="calendar-container-titulo">Selecciona la fecha de inicio de tu cobertura</p>
            <Calendar
                minDate={new Date()}
                date={props.currentDate}
                onChange={props.setCurrentDate}
                showMonthAndYearPickers={false}
                showSelectionPreview={false}
                showDateDisplay={false}
                rangeColors='var(--primary500)'
                color='var(--primary500)'
                locale={es}
            />
            <div className="calendar-container-button">
                <MainButton
                    id='ACEPTAR'
                    text='ACEPTAR'
                    onButtonPressed={() => props.confirmDate()}
                />
            </div>
        </div>
    )
}

export default CalendarComponent;