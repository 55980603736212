import {
    OFFER_DATA,
    ORIGINAL_OFFER_DATA,
    SHOW_RESTRICTIONS,
    RESTRICTIONS_TEXT,
    CONTACT_METHODS,
    CHECKOUT_DATA,
    CONTACT_WHATSAPP
} from '../actions/types';

const INITIAL_STATE = {
    offerData: null,
    originalOfferData: null,
    showRestrictions: false,
    restrictionsText: null,
    contactMethods: null,
    checkOutData: null,
    contactWhatsapp: null
};

export default (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case OFFER_DATA:
            return { ...state, offerData: action.payload };
        case ORIGINAL_OFFER_DATA:
            return { ...state, originalOfferData: action.payload };
        case SHOW_RESTRICTIONS:
            return { ...state, showRestrictions: action.payload };
        case RESTRICTIONS_TEXT:
            return { ...state, restrictionsText: action.payload };
        case CONTACT_METHODS:
            return { ...state, contactMethods: action.payload };
        case CONTACT_WHATSAPP:
            return { ...state, contactWhatsapp: action.payload };
        case CHECKOUT_DATA:
            return { ...state, checkOutData: action.payload };
        default:
            return state;
    }
};