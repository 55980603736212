import {
    USER_DATA,
    CIVIL_STATUS_TYPES,
    SEX_TYPES,
    STATES,
    STATES_STRINGS,
    LOCALIDAD_EDIT_CHANGED,
    LOCALIDAD_STRING_EDIT_CHANGED,
    ADDRESS_LOCALITY_SELECTED,
    PROVINCIA_EDIT_CHANGED,
    MAP_COORDINATES,
    ADDRESS_SELECTED_PROFILE,
    LOADING_ADDRESS_STREET_PROFILE,
    ADDRESS_STREET_PROFILE,
    ADDRESS_SELECTED_PLACE_ID,
    ADDRESS_STREET_RESULTS_PROFILE,
    ADDRESS_STREET_SHOW_DEFAULT,
    DIRECCION_CHANGE,
    CODIGO_POSTAL_CHANGE,
    GM_ADDRESS_FORMAT_CHANGE,
    PROVINCIA_CHANGE,
    DELEGACION_CHANGE,
    COLONIA_CHANGE,
    FETCH_CPS_BY_CITY,
    NOMBRE,
    APELLIDO,
    EMAIL,
    CELULAR,
    DNI,
    GENERO,
    FECHA_NAC,
    CUIL,
    ESTADO_CIVIL,
    PISO,
    FETCH_CITIES_BY_STATES,
    USER_PLACES,
    USER_PLACE,
    USER_PLACES_MUNICIPIOS,
    USER_PLACE_MUNICIPIO,
    USER_PLACES_LOCALIDADES,
    USER_PLACE_LOCALIDAD,
    PROFILE_ENABLE_EDIT,
    PROFILE_SHOW_MORE,
    USER_LOADER,
    APELLIDO_MATERNO,
    RUT,
    DV
} from '../actions/types';

const INITIAL_STATE = {
    userData: null,
    civilStatusTypes: null,
    sexTypes: null,
    states: null,
    statesStrings: null,
    localidadEdit: null,
    localidadStringEdit: '',
    adressLocalitySelected: null,
    provincia: '',
    mapCoordinates: {
        center: {
            lat: -37.979858,
            lng: -57.589794
        }, 
        zoom: 11
    },
    addressSelected: {},
    addressesResults: [],
    loading: false,
    addressStreetProfile: '',
    addressPlaceId: '',
    addressDefaultShow: false,
    cpsByCity: [],
    direccion: '',
    codigoPostal: '',
    gmAddressFormat: '',
    delegacion: '',
    colonia: '',
    nombre: '',
    apellido: '',
    apellidoMaterno: '',
    rut: '',
    dv: '',
    email: '',
    celular: '',
    dni: '',
    genero: null,
    fechaNac: '',
    cuil: '',
    estadoCivil: null,
    piso: '',
    citiesByStates: [],
    userPlaces: [],
    userPlace: null,
    userPlacesMunicipios: [],
    userPlaceMunicipio: null,
    userPlacesLocalidades: [],
    userPlaceLocalidad: null,
    enableEdit: false,
    showMore: false,
    userLoader: false
};

export default (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case USER_DATA:
            return { ...state, userData: action.payload };
        case USER_LOADER:
            return { ...state, userLoader: action.payload };
        case CIVIL_STATUS_TYPES:
            return { ...state, civilStatusTypes: action.payload };
        case SEX_TYPES:
            return { ...state, sexTypes: action.payload };
        case STATES:
            return { ...state, states: action.payload };
        case STATES_STRINGS:
            return { ...state, statesStrings: action.payload };
        case LOCALIDAD_EDIT_CHANGED:
            return { ...state, localidadEdit: action.payload };
        case LOCALIDAD_STRING_EDIT_CHANGED:
            return { ...state, localidadStringEdit: action.payload };
        case ADDRESS_LOCALITY_SELECTED:
            return { ...state, adressLocalitySelected: action.payload };
        case PROVINCIA_EDIT_CHANGED:
            return { ...state, provincia: action.payload };
        case MAP_COORDINATES:
            return { ...state, mapCoordinates: action.payload };
        case ADDRESS_SELECTED_PROFILE:
            return { ...state, addressSelected: action.payload};
        case ADDRESS_STREET_RESULTS_PROFILE:
            return { ...state, addressesResults: action.payload};
        case LOADING_ADDRESS_STREET_PROFILE:
            return { ...state, loading: action.payload};
        case ADDRESS_STREET_PROFILE:
            return { ...state, addressStreetProfile: action.payload};
        case ADDRESS_SELECTED_PLACE_ID:
            return { ...state, addressPlaceId: action.payload};
        case ADDRESS_STREET_SHOW_DEFAULT:
            return { ...state, addressDefaultShow: action.payload};
        case DIRECCION_CHANGE:
            return { ...state, direccion: action.payload};
        case CODIGO_POSTAL_CHANGE:
            return { ...state, codigoPostal: action.payload};
        case GM_ADDRESS_FORMAT_CHANGE:
            return { ...state, gmAddressFormat: action.payload};
        case PROVINCIA_CHANGE:
            return { ...state, provincia: action.payload};
        case DELEGACION_CHANGE:
            return { ...state, delegacion: action.payload};
        case COLONIA_CHANGE:
            return { ...state, colonia: action.payload};
        case FETCH_CPS_BY_CITY:
            return { ...state, cpsByCity: action.payload};
        case NOMBRE:
            return { ...state, nombre: action.payload};
        case APELLIDO:
            return { ...state, apellido: action.payload};
        case APELLIDO_MATERNO:
            return { ...state, apellidoMaterno: action.payload};
        case RUT:
            return { ...state, rut: action.payload};
        case DV:
            return { ...state, dv: action.payload};
        case EMAIL:
            return { ...state, email: action.payload};
        case CELULAR:
            return { ...state, celular: action.payload};
        case DNI:
            return { ...state, dni: action.payload};
        case GENERO:
            return { ...state, genero: action.payload};
        case FECHA_NAC:
            return { ...state, fechaNac: action.payload};
        case CUIL:
            return { ...state, cuil: action.payload};
        case ESTADO_CIVIL:
            return { ...state, estadoCivil: action.payload};
        case PISO:
            return { ...state, piso: action.payload};
        case FETCH_CITIES_BY_STATES:
            return { ...state, citiesByStates: action.payload};
        case USER_PLACES:
            return { ...state, userPlaces: action.payload};
        case USER_PLACE:
            return { ...state, userPlace: action.payload};
        case USER_PLACES_MUNICIPIOS:
            return { ...state, userPlacesMunicipios: action.payload};
        case USER_PLACE_MUNICIPIO:
            return { ...state, userPlaceMunicipio: action.payload};
        case USER_PLACES_LOCALIDADES:
            return { ...state, userPlacesLocalidades: action.payload};
        case USER_PLACE_LOCALIDAD:
            return { ...state, userPlaceLocalidad: action.payload};
        case PROFILE_ENABLE_EDIT:
            return { ...state, enableEdit: action.payload};
        case PROFILE_SHOW_MORE:
            return { ...state, showMore: action.payload};
        default:
            return state;
    }
};