import { FormattedMessage } from 'react-intl';
import './PaymentResumeAccountBank.css'

const PaymentResumeAccountBank = (props: any) => {
    return (
        <div className="checkOutResume-container-mainDesktop-details-itemWrapper">
            <div className="checkOutResume-container-mainDesktop-details-itemWrapper-medioContainer">
                <p className="checkOutResume-container-mainDesktop-details-itemWrapper-medioContainer-medioText"><FormattedMessage id = "component.checkOut.resume.medioDePago" defaultMessage=""/></p>
                <p className="checkOutResume-container-mainDesktop-details-itemWrapper-medioContainer-tarjetaText"><FormattedMessage id = "component.checkOut.resume.debitoBancario" defaultMessage=""/></p>
            </div>
            <p 
                onClick={() => props.otroMedioPagoPressed()}
                className="checkOutResume-container-mainDesktop-details-itemWrapper-otroText"
            ><FormattedMessage id = "component.checkOut.resume.elegirOtroMedioDePago" defaultMessage=""/></p>
        </div>
    )
}

export { PaymentResumeAccountBank };