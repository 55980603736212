import { PaymentMethod } from "./PaymentMethod"
import PaymentMethodBasic from "../paymentMethod/Generic/PaymentMethodBasic"
import { PaymentMethodNoDataConfigComponent, PaymentMethodResumeBasicComponent } from "../paymentMethod/Generic/"

// Tarjeta de Credito
import PaymentMethodCreditCard from "../components/checkoutComponents/PaymentMethodCreditCard/PaymentMethodCreditCard"
import PaymentResumeCreditCard from "../components/checkoutComponents/PaymentResumeCreditCard/PaymentResumeCreditCard"
import PaymentPolicyResumeCreditCard from "../paymentMethod/CreditCardAr/PaymentPolicyResumeCreditCard/PaymentPolicyResumeCreditCard"
import CreditCardArConfigComponent from "../paymentMethod/CreditCardAr/CreditCardArConfigComponent"

// Banco
import { PaymentResumeAccountBank, PaymentPolicyResumeAccountBank, AccountBankArConfigComponent } from "../paymentMethod/AccountBankAr"

// UbiiPagos
import PaymentMethodUbiiPagos from "../paymentMethod/UbiiPagos/PaymentMethodUbiiPagos/PaymentMethodUbiiPagos"
import { PaymentPolicyUbiiPagos, PaymentResumeUbiiPagos } from "../paymentMethod/UbiiPagos"
import PatChileConfigComponent from "../paymentMethod/PatChile/PatChileConfigComponent/PatChileConfigComponent"
import PatChileResumeCreditCard from "../paymentMethod/PatChile/PaymentResumeCreditCard/PatChileResumeCreditCard"
import PaymentMethodPatChile from "../paymentMethod/PatChile/PaymentMethodPatChile/PaymentMethodPatChile"
import PaymentPolicyResumePatChile from "../paymentMethod/PatChile/PaymentPolicyResumePatChile/PaymentPolicyResumePatChile"
import SimpleCreditCardConfigComponent from "../paymentMethod/SimpleCreditCard/SimpleCreditCardConfigComponent"

const PaymentMethods: Record<string, PaymentMethod> = {
    'ubiipagos': {
        name: "paymentmehtod.ubiipagos",
        paymentComponent: PaymentMethodUbiiPagos,
        resumeComponente: PaymentResumeUbiiPagos,
        policyComponent: PaymentPolicyUbiiPagos,
        configComponent: PaymentMethodNoDataConfigComponent
    }, 
    'creditcard': {
        name: "paymentmehtod.creditcard",
        resumeComponente: PaymentResumeCreditCard,
        paymentComponent: PaymentMethodCreditCard,
        configComponent: CreditCardArConfigComponent,
        policyComponent: PaymentPolicyResumeCreditCard
    },
    'accountbank_ar': {
        name: "paymentmehtod.accountbank_ar",
        resumeComponente: PaymentResumeAccountBank,
        paymentComponent: PaymentMethodBasic,
        configComponent: AccountBankArConfigComponent,
        policyComponent: PaymentPolicyResumeAccountBank
    },
    'cash': {
        name: "paymentmehtod.cash",
        resumeComponente: PaymentMethodResumeBasicComponent,
        paymentComponent: PaymentMethodBasic,
        configComponent: PaymentMethodNoDataConfigComponent
    },
    'pat_chile': {
        name: "paymentmehtod.pat_chile",
        resumeComponente: PatChileResumeCreditCard,
        paymentComponent: PaymentMethodPatChile,
        configComponent: PatChileConfigComponent,
        policyComponent: PaymentPolicyResumePatChile
    },
    'simple_creditcard': {
        name: "paymentmehtod.simple_creditcard",
        resumeComponente: PaymentResumeCreditCard,
        paymentComponent: PaymentMethodCreditCard,
        configComponent: SimpleCreditCardConfigComponent,
        policyComponent: PaymentPolicyResumeCreditCard
    },
    'cbu': {
        name: "paymentmehtod.cbu",
        resumeComponente: PaymentResumeAccountBank,
        paymentComponent: PaymentMethodBasic,
        configComponent: AccountBankArConfigComponent,
        policyComponent: PaymentPolicyResumeAccountBank
    },
}

export {PaymentMethods} 