import Header from "../../components/generalComponents/header/Header"
import Logo from '../../assets/images/svgs/logo.svg';
import LogoColor from '../../assets/images/svgs/logoColor.svg';
import { ReactSVG } from "react-svg";
import { useMediaQuery } from "react-responsive";
import { useState } from "react";
import './AdminInicio.css'
import CustomTextInput from "../../components/generalComponents/customTextInput/CustomTextInput";
import MainButton from "../../components/generalComponents/MainButton/MainButton";
import GoogleLogin from "react-google-login";
import { connect } from 'react-redux';
import {
    loginGoogleUser,
    loginUser
} from '../../actions';
import bandera from '../../assets/icons/bandera.png'
import llamada from '../../assets/icons/llamada.png'
import { useNavigate, useLocation } from 'react-router-dom'
import { FormattedMessage, useIntl } from "react-intl";
import { GetGlobalParams } from "../../util/GetGlobalParams";
import { GetCountryUrl } from "../../util/GetCountryUrl";
import { validateEmail } from "../../util/ValidateEmail";
import GetMetaTags from "../../util/GetMetaTags";

let isTabletOrMobile = false;
let isTablet = false;
let isMobile = false;


const AdminInicio = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })
    isTablet = useMediaQuery({ query: '(max-width: 1300px)' })
    isMobile = useMediaQuery({ query: '(max-width: 340px)' })
    const [emailLogin, setEmailLogin] = useState<string>('');
    const [contraseñaLogin, setContraseñaLogin] = useState<string>('');
    const size = isTabletOrMobile ? 325 : 425;
    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const urlState: string = query.get('redirectTo')!;
    const [appId] = useState<any>(process.env.REACT_APP_ID)
    const logoSize = appId == 3 ? 200 : isTabletOrMobile ? 60 : 80;

    const responseGoogle = (response: any) => {        
        props.loginGoogleUser(response.tokenId, props.countryId, props.checkoutId, true, urlState, navigate, null);
    }

    const disableLogin = () => {
        return !(emailLogin !== '' && contraseñaLogin !== '')
    }

    return (
        <div className="admin-container">
            <GetMetaTags page={'login'} />

            <div className='admin-subcontainer'>

                <div className='admin-container-logo'>
                    <ReactSVG
                        src={appId == 3 ? LogoColor : Logo}
                        beforeInjection={svg => {
                            svg.setAttribute('style', 'height: ' + logoSize);
                            svg.setAttribute('style', 'width: ' + logoSize);
                        }}
                    />
                </div>
                <h4 className="admin-sesion-title bold">Te damos la bienvenida a tu administrador</h4>
                <h4 className="admin-sesion-subtitle regular"><FormattedMessage id="page.login.subtitle" defaultMessage="" /></h4>
                <div className="admin-sesion-container-login">
                    <CustomTextInput
                        color={appId != 3}
                        adminLogin={appId != 3}
                        label='E-mail'
                        placeholder='nombre@ejemplo.com'
                        changeText={(e: string) => setEmailLogin(e)}
                        error={emailLogin.length > 0 ? !validateEmail(emailLogin) : false}
                        value={emailLogin}
                    />
                    <CustomTextInput
                        color={appId != 3}
                        admin={appId != 3}
                        adminLogin={appId != 3}
                        label='Contraseña'
                        placeholder='*********'
                        changeText={(e: string) => setContraseñaLogin(e)}
                        value={contraseñaLogin}
                        password
                        onKeyDown={(e: any) => {
                            if (e.key === 'Enter') {
                                if (!disableLogin()) {
                                    props.loginUser(emailLogin, contraseñaLogin, true, props.countryId, urlState, null, navigate, null, null)
                                }
                            }
                        }}
                    />
                </div>

                <p className="admin-sesion-recuperar-pass" onClick={() => navigate(GetCountryUrl(props.countryId) + 'recovery?' + GetGlobalParams())}>¿Olvidaste tu contraseña?</p>
                
                <div className="admin-sesion-inicio">
                    <div className="admin-sesion-inicio-but">
                        <MainButton
                            id='Iniciar sesion Boton'
                            disabled={disableLogin()}
                            text={useIntl().formatMessage({id: 'page.login.button'})}
                            onButtonPressed={() => props.loginUser(emailLogin, contraseñaLogin, true, props.countryId, urlState, null, navigate, null, null)}
                            fill
                            color='var(--secondary500)'
                        />
                    </div>
                    <div className="admin-sesion-marcadores">
                        <div className="admin-sesion-marcadores-icon">
                            <span className="material-symbols-outlined" style={{fontSize: 48}} >{!isTabletOrMobile ? 'bookmark' : 'add_to_home_screen'}</span>
                        </div>
                        <div className="admin-sesion-marcadores-text">
                            <p className="title-marcadores bold">{isTabletOrMobile ? 'ACCESO DIRECTO' : 'MARCADORES'}</p>
                            <b className="subtitle-marcadores medium regular"> {
                                isTabletOrMobile ? 
                                    'Recordá que desde tu navegador, podés agregar este sitio a la pantalla principal de tu cel y acceder cuando quieras.'
                                    :
                                    'Recordá que desde tu navegador, podés agregar este sitio a marcadores y acceder cuando quieras.'
                            }</b>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        countryId: state.general.countryId,
    };
};

export default connect(mapStateToProps, {
    loginGoogleUser,
    loginUser
})(AdminInicio);