const formatCreditCard = (creditCardNumber: string, pattern: string): string => {
    let result = "";
    let realPos: number = 0;
    let i: number = 0;

    while(i < pattern.length && realPos < creditCardNumber.length) {
        const value = pattern.charAt(i);
        if (value !== " ") {
            result += creditCardNumber.charAt(realPos);
            realPos++;
        } else {
            result += " ";
        }
        i++;
    }

    return result;
}


export { formatCreditCard };