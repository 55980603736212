

import React, { useEffect, useState } from 'react'
import './ReclamarPolizaAlert.css'
import { ReactSVG } from 'react-svg';
import Exit from '../../../assets/images/svgs/exit.svg'
import MainButton from '../../generalComponents/MainButton/MainButton';
import { FormattedMessage } from 'react-intl';

const ReclamarPolizaAlert = (props: any) => {

    const [showExternalUrl, setShowExternalUrl] = useState<any>(null)

    useEffect(() => {
        if(props.claimTypes?.length === 1) setShowExternalUrl(props.claimTypes[0]?.externalInfo)
    }, [props.claimTypes])

    return (
        <div className="reclamarPolizaAlert-container">
            <ReactSVG
                src={Exit}
                style={{ position: 'absolute', top: 22, right: 22, cursor: 'pointer' }}
                onClick={() => props.desactivarAlert()}
            />
            {showExternalUrl ?
                <>
                    <p className="reclamarPolizaAlert-container-title">DECLARAR UN SINIESTRO</p>
                    <div className='reclamarPolizaAlert-container-main' dangerouslySetInnerHTML={{__html: showExternalUrl}}/>
                    <div className='reclamarPolizaAlert-container-buttonWrapper'>
                        <MainButton
                            id='ACEPTAR'
                            text='ACEPTAR'
                            onButtonPressed={() => props.desactivarAlert()}
                        />
                    </div>
                </>
                :
                <>
                    <p className="reclamarPolizaAlert-container-title"><FormattedMessage id = "component.alert.reclamar.title" defaultMessage="¿Cuál es el motivo de tu reclamo?"/></p>
                    <p className="reclamarPolizaAlert-container-text">{props.reclamoProductData?.name + ' ' + props.reclamoProductData?.model}</p>
                    {props.claimTypes?.map((a: any) => (
                        <div style={{marginTop: 5, marginBottom: 5}}>
                            <MainButton
                                id='Continuar Boton'
                                text={a.name.toUpperCase()}
                                onButtonPressed={() => {
                                    if(a.externalInfo) setShowExternalUrl(a.externalInfo)
                                    else {
                                        const data = {
                                            "productInsuredUserId": props.id,
                                            "claimTypeId": a.id
                                        }
                                        props.postClaimProcess(data)
                                    }
                                }}
                            />
                        </div>
                    ))}
                    <MainButton
                        id='Continuar Boton'
                        text='CANCELAR'
                        onButtonPressed={() => props.desactivarAlert()}
                        empty
color='var(--secondary500)'
                    />
                </>
            }
        </div>
    );
}

export default ReclamarPolizaAlert;