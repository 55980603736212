import React from 'react'
import './OfferPlan.css'
import MainButton from '../../../components/generalComponents/MainButton/MainButton'

const OfferPlan = (props: any) => {

  const fullStar = (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clip-path="url(#clip0_360_10343)">
        <path d="M19.5279 7.35279L13.0498 6.75197L10.4765 0.776628C10.2963 0.35823 9.70311 0.35823 9.52295 0.776628L6.94972 6.75201L0.471641 7.35279C0.0180481 7.39486 -0.165272 7.95903 0.176954 8.25966L5.06468 12.5535L3.63429 18.9002C3.53414 19.3446 4.01406 19.6932 4.40574 19.4607L9.99975 16.139L15.5938 19.4607C15.9854 19.6932 16.4654 19.3446 16.3652 18.9002L14.9348 12.5535L19.8225 8.25966C20.1648 7.95903 19.9814 7.39486 19.5279 7.35279Z" fill="#FFC850"/>
      </g>
      <defs>
        <clipPath id="clip0_360_10343">
          <rect width="20" height="20" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
  const emptyStar = (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clip-path="url(#clip0_360_10397)">
        <path d="M19.5279 7.35279L13.0498 6.75197L10.4765 0.776628C10.2963 0.35823 9.70311 0.35823 9.52295 0.776628L6.94972 6.75201L0.471641 7.35279C0.0180481 7.39486 -0.165272 7.95903 0.176954 8.25966L5.06468 12.5535L3.63429 18.9002C3.53414 19.3446 4.01406 19.6932 4.40574 19.4607L9.99975 16.139L15.5938 19.4607C15.9854 19.6932 16.4654 19.3446 16.3652 18.9002L14.9348 12.5535L19.8225 8.25966C20.1648 7.95903 19.9814 7.39486 19.5279 7.35279Z" fill="#E6E6E6"/>
      </g>
      <defs>
        <clipPath id="clip0_360_10397">
          <rect width="20" height="20" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )

  const getScore = () => {
    const stars = Array.from({ length: 5 }, (_, index) => (
      <span key={index} style={{ marginRight: '4px' }}>
        {index < props.score ? fullStar : emptyStar}
      </span>
    ));
  
    return <>{stars}</>;
  };
  

  return (
    <div className="offerPlan-container">
      <div 
        className="offerPlan-container-title"
        style={{ backgroundColor: props.index == 0 ? 'var(--primary200)' : props.index == 1 ? 'var(--primary300)' : 'var(--primary400)'}}
      >
        <b 
          className="offerPlan-container-title-text"
          style={{ color: props.index == 0 ? 'var(--gray600)' : props.index == 1 ? 'var(--gray600)' : 'var(--gray200)'}}
        >{props.title}</b>
      </div>
      <div className="offerPlan-container-score">
        {getScore()}
      </div>
      <b className="offerPlan-container-planType regular small">{props.planType}</b>
      <div className="offerPlan-container-price">
        <b className="offerPlan-container-price-unit bold medium">{props.unit}</b>
        <h1 className="offerPlan-container-price-price bold">{props.price}</h1>
        <b className="offerPlan-container-price-per bold medium">{props.per}</b>
      </div>
      <div className="offerPlan-container-line"/>
      <div className="offerPlan-container-queCubre">
        <b className="offerPlan-container-queCubre-title large">¿QUÉ CUBRE?</b>
        <div className="offerPlan-container-queCubre-list">
          {props.queCubre?.map((a: any, index: any) => (
            <div className="offerPlan-container-queCubre-list-item" key={index}>
              <p className="offerPlan-container-queCubre-list-item-title">{a.title}</p>
              <p className="offerPlan-container-queCubre-list-item-descripcion">{a.value}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="offerPlan-container-boton">
        <MainButton
          id='VER MÁS'
          border
          color='var(--secondary500)'
          text='VER MÁS'
          onButtonPressed={() => props.seeMore()}
        />
      </div>
      <div className="offerPlan-container-boton">
        <MainButton
          id='CONTRATAR'
          fill
          color='var(--secondary500)'
          text='CONTRATAR'
          onButtonPressed={() => props.contratar()}
        />
      </div>
      <div className="offerPlan-container-boton">
        <MainButton
          id='¿QUERÉS QUE TE LLAMEMOS?'
          empty
          color='var(--secondary500)'
          text='¿QUERÉS QUE TE LLAMEMOS?'
          onButtonPressed={() => props.contact()}
        />
      </div>
    </div>
  )
}

export default OfferPlan;