import { ReactComponent as Argentina } from './/assets/images/svgs/argentinaLogo.svg';

import Facebook from "./assets/logo/Facebook.svg";
import FacebookC from "./assets/logo/FacebookC.svg";
import FacebookImg from "./assets/images/facebookC.png";

import Instagram from "./assets/logo/Instagram.svg";
import InstagramC from "./assets/logo/InstagramC.svg";
import InstagramImg from "./assets/images/instagram.png";

import Whatsapp from "./assets/logo/Whatsapp.svg";
import WhatsappC from "./assets/logo/WhatsappC.svg";
import WhatsappImg from "./assets/images/instagram.png";

export const countries = [
  {
    title: "ARGENTINA",
    id: 1,
    img: Argentina,
    url: '/',
    lang: () => import('./assets/lang/es-AR-Pardo.json'),
    htmlMetaInfo: {
      title: 'Crea tu pagina'
    },
  }
];

export const categorias = null;

export const faq = [
  {
    'name': '¿Para qué me sirve el seguro de Salud Integral?',
    'description': 'Para respaldar tu economía ante un imprevisto de salud. Cubre la falta o complementa la prestación del sistema de salud público, tu obra social o medicina prepaga. Ante el primer diagnóstico de cáncer, infarto de miocardio, derrame e infarto cerebral, cirugía o un trasplante te pagamos una suma asegurada y con el dinero podrás hacer frente a todos los gastos adicionales e imprevistos que siempre surgen. <a href="https://pardosalud.wecover.co/oferta?productId=9242">Conocé más sobre Salud Integral</a>'
  },
  {
    'name': '¿Para qué me sirve el seguro de Salud Mujer?',
    'description': 'Para respaldar tu economía ante un imprevisto de salud. Cubre la falta o complementa la prestación del sistema de salud público, tu obra social o medicina prepaga. Ante distintas intervenciones quirúrgicas, el primer diagnóstico de cáncer, gastos para adquirir drogas oncológicas o un trasplante te pagamos una suma asegurada y con el dinero podrás hacer frente a todos los gastos adicionales e imprevistos que siempre surgen. <a href="https://pardosalud.wecover.co/oferta?productId=9243">Conocé más sobre Salud Mujer</a>'
  },
  {
    'name': '¿Para qué me sirve el seguro de Salud Senior?',
    'description': 'Para respaldar tu economía ante un imprevisto de salud. Cubre la falta o complementa la prestación del sistema de salud público, tu obra social o medicina prepaga. Ante intervenciones quirúrgicas, prótesis, cuidados en el hogar o insuficiencia renal crónica te pagamos una suma asegurada y con el dinero podrás hacer frente a todos los gastos adicionales e imprevistos que siempre surgen. <a href="https://pardosalud.wecover.co/oferta?productId=9244">Conocé más sobre Salud Senior</a>'
  }
]

export const socialLinks = [
  {
    svg: Facebook,
    svgC: FacebookC,
    img: FacebookImg,
    link: "https://www.facebook.com/pardo.online/",
  },
  {
    svg: Instagram,
    svgC: InstagramC,
    img: InstagramImg,
    link: "https://www.instagram.com/pardo.online/",
  },
  {
    svg: Whatsapp,
    svgC: WhatsappC,
    img: WhatsappImg,
    link: "https://api.whatsapp.com/send?phone=541123646994&text=%C2%A1Hola!%F0%9F%91%8BQuer%C3%ADa%20consultar%20sobre...",
  }
];