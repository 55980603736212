import {
    COUNTRY_ID,
    USER_ACCESS_TOKEN,
    LOADING,
    ERROR_MESSAGE,
    SUCCESS_CARD_TEXT,
    CATEGORIA_REF,
    PREGUNTAS_REF,
    RESUMEN_REF,
    PAYMENT_REF,
    PRODUCT_REF,
    PROFILE_REF,
    BURGER_OPEN,
    RESQUEST_SUCCESS_POLICY,
    GLOBAL_FORM_ANSWER,
    GLOBAL_PARAMS
} from '../actions/types';
import Cookies from 'universal-cookie';
import { countries } from '../data';

const INITIAL_STATE = {
    channelId: process.env.REACT_APP_ID,
    countryId: countries[0].id,
    userAccessToken: null,
    loading: false,
    errorMessage: null,
    successCardText: { text: '', state: false },
    questionRef: false,
    categoryRef: false,
    resumenRef: false,
    paymentRef: false,
    productRef: false,
    profileRef: false,
    burgerMenuOpen: false,
    satisfactoryStatus: true,
    globalFormAnswer: {},
    globalParams: null
};

export default (state = INITIAL_STATE, action: any) => {
    const cookies = new Cookies();
    switch (action.type) {
        case COUNTRY_ID:
            cookies.set('countryId', action.payload);
            return { ...state, countryId: action.payload };
        case USER_ACCESS_TOKEN:
            if (action.payload) {
                cookies.set('userAccessToken', action.payload, {path: '/'});
            } else {
                cookies.remove('userAccessToken', {path: '/'});
            }
            return { ...state, userAccessToken: action.payload };
        case LOADING:
            return { ...state, loading: action.payload };
        case ERROR_MESSAGE:
            return { ...state, errorMessage: action.payload };
        case SUCCESS_CARD_TEXT:
            return { ...state, successCardText: action.payload };
        case CATEGORIA_REF:
            return { ...state, categoryRef: action.payload };
        case PREGUNTAS_REF:
            return { ...state, questionRef: action.payload };
        case RESUMEN_REF:
            return { ...state, resumenRef: action.payload };
        case PAYMENT_REF:
            return { ...state, paymentRef: action.payload };
        case PRODUCT_REF:
            return { ...state, productRef: action.payload };
        case PROFILE_REF:
            return { ...state, profileRef: action.payload };
        case BURGER_OPEN:
            return { ...state, burgerMenuOpen: action.payload };
        case RESQUEST_SUCCESS_POLICY:
            return { ...state, satisfactoryStatus: action.payload };
        case GLOBAL_FORM_ANSWER:
            return { ...state, globalFormAnswer: action.payload };
        case GLOBAL_PARAMS:
            return { ...state, globalParams: action.payload };
        default:
            return state;
    }
};