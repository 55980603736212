import {
    FETCH_CATEGORIAS,
    CATEGORIA_SELECTED,
    COTIZAR_QUESTION,
    ANSWER
} from '../actions/types';

const INITIAL_STATE = {
    categorias: null,
    categoriaSelected: -1,
    cotizarQuestion: null,
    answer: null
};

export default (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case FETCH_CATEGORIAS:
            return { ...state, categorias: action.payload };
        case CATEGORIA_SELECTED:
            return { ...state, categoriaSelected: action.payload };
        case COTIZAR_QUESTION:
            return { ...state, cotizarQuestion: action.payload };
        case ANSWER:
            return { ...state, answer: action.payload };
        default:
            return state;
    }
};