import {
    LOADING,
    CLAIM_TYPES,
    CLAIM_PROCESS_DATA,
    GLOBAL_FORM_ANSWER,
    USER_RECLAMO_LIST,
    USER_PRODUCTS_ACTIVE
} from './types';
import {
    netGetClaimTypes,
    netPostClaimProcess,
    netGetClaimProcessById,
	netPutAnswerQuestion,
    netGetUserReclamos,
    getInsuredUser
} from '../net/Connector'
import axios from './instanceActions'
import { genericErrorHandler } from '../util/GenericErrorHandler';
import { GetGlobalParams } from '../util/GetGlobalParams';
import { GetCountryUrl } from '../util/GetCountryUrl';

export const getClaimTypes = (id: number) => {
    return (dispatch: any) => {
        dispatch({type: LOADING, payload: true});
        axios.get(netGetClaimTypes(id))
            .then((response) => {
                dispatch({type: CLAIM_TYPES, payload: response.data});
                dispatch({type: LOADING, payload: false});
            })
            .catch((error) => {
                dispatch({type: LOADING, payload: false});
                genericErrorHandler(error)
            })
    }
}

export const postClaimProcess = (data: any, countryId: number, navigate: any) => {
    return (dispatch: any) => {
        dispatch({type: LOADING, payload: true});
        dispatch({type: CLAIM_TYPES, payload: null});
        axios.post(netPostClaimProcess(), data)
            .then((response) => {
                dispatch({type: GLOBAL_FORM_ANSWER, payload: {}})
                dispatch({type: CLAIM_PROCESS_DATA, payload: response.data});
				navigate(GetCountryUrl(countryId) + `claim_process/${response.data.id}` + '?' + GetGlobalParams())
                dispatch({type: LOADING, payload: false});
            })
            .catch((error) => {
                dispatch({type: LOADING, payload: false});
                genericErrorHandler(error)
            })
    }
}

export const toggleClaimTypes = () => {
    return {
        type: CLAIM_TYPES,
        payload: null
    };
}

export const getClaimProcessById = (id: string) => {
    return (dispatch: any) => {
        dispatch({type: LOADING, payload: true});
        axios.get(netGetClaimProcessById(id))
            .then((response) => {
                dispatch({type: GLOBAL_FORM_ANSWER, payload: {}})
                dispatch({type: CLAIM_PROCESS_DATA, payload: response.data});
                dispatch({type: LOADING, payload: false});
            })
            .catch((error) => {
                dispatch({type: LOADING, payload: false});
                genericErrorHandler(error)
            })
    }
}

export const putAnswerQuestion = (answer: any, id: string, incompleteStepId: number, incompleteFormId: number) => {
	return (dispatch: any) => {
        dispatch({type: LOADING, payload: true});
        axios.put(netPutAnswerQuestion(id, incompleteStepId, incompleteFormId), answer)
            .then((response) => {
                window.scrollTo(0, 0);
                dispatch({type: GLOBAL_FORM_ANSWER, payload: {}})
				dispatch({type: CLAIM_PROCESS_DATA, payload: response.data});
                dispatch({type: LOADING, payload: false});
            })
            .catch((error) => {
                dispatch({type: LOADING, payload: false});
                genericErrorHandler(error)
            })
    }
}

export const fetchDataForMisReclamos = () => {
	return (dispatch: any) => {
        dispatch({type: LOADING, payload: true});
        axios.get(netGetUserReclamos())
            .then((response) => {
                dispatch({type: USER_RECLAMO_LIST, payload: response.data});
                axios.get(getInsuredUser())
                    .then((responseIU) => {
                        dispatch({ type: USER_PRODUCTS_ACTIVE, payload: responseIU.data });
                        dispatch({type: LOADING, payload: false});
                    })
                    .catch((error) => {
                        dispatch({type: LOADING, payload: false});
                        genericErrorHandler(error)
                    })
            })
            .catch((error) => {
                dispatch({type: LOADING, payload: false});
                genericErrorHandler(error)
            })
    }
}