import React, { useState } from 'react'
import './EmptyRefererModal.css'
import CustomTextInput from '../../generalComponents/customTextInput/CustomTextInput';
import { connect } from 'react-redux';
import { 
    checkRefererAvailability,
    changeReferer,
    changeRefererApplied
} from '../../../actions'
import MainButton from '../../generalComponents/MainButton/MainButton';

const EmptyRefererModal = (props: any) => {

    const [localReferer, setLocalReferer] = useState<string>('');

    const handleInput = (text: string) => {
        setLocalReferer(text)
        props.changeReferer('')
        props.changeRefererApplied(false)
        if (text?.length === 8) {
            props.checkRefererAvailability(text)
        }
    }

    return (
        <div className="emptyRefererModal-container">
            <span onClick={() => props.cancel()} className="material-symbols-outlined" style={{ fontSize: 20, marginLeft: 'auto', cursor: 'pointer' }}>close</span>
            <span className="material-symbols-outlined" style={{ fontSize: 40, color: 'var(--primary500)' }}>person_pin_circle</span>
            <p className="emptyRefererModal-container-title bold">Ingresá el DNI del vendedor</p>
            <div className="emptyRefererModal-container-input">
                <CustomTextInput
                    changeText={(e: string) => handleInput(e.replace(/[^0-9]+/g, ''))}
                    value={localReferer}
                    success={props.refererApplied}
                    placeholder='Sólo números sin puntos'
                    error={props.refererError}
                    maxLength={8}
                />
                {props.refererError && <b className="emptyRefererModal-container-codeError regular small">El DNI ingresado no corresponde a un vendedor, por favor intentá nuevamente.</b>}
            </div>
            <div className="emptyRefererModal-container-button">
                <MainButton
                    id={props.refererError ? 'FINALIZAR SIN DNI' : 'FINALIZAR'}
                    fill
                    color='var(--primary500)'
                    text={props.refererError ? 'FINALIZAR SIN DNI' : 'FINALIZAR'}
                    onButtonPressed={() => props.buttonPress()}
                    disabled={localReferer.length !== 8}
                />
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        refererApplied: state.checkOut.refererApplied,
        refererError: state.checkOut.refererError
    }; 
};

export default connect(mapStateToProps, {
    checkRefererAvailability,
    changeReferer,
    changeRefererApplied
})(EmptyRefererModal);