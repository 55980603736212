import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { connect } from 'react-redux';
import './MainCotizar.css'
import { FormattedMessage, useIntl } from 'react-intl';

import Header from '../../components/generalComponents/header/Header';
import AccordionCotizar from '../../components/mainCotizarComponents/AccordionCotizar/AccordionCotizar';
import {
  changeCountryId,
  categoriaSelectedChange,
  getOffer,
  modifyOffer,
  modifyOfferDate,
  fadeDataIn,
  fadeDataOut,
  getRestrictions,
  toggleRestrictions,
  checkOutOffer,
  setRefCategory,
  modifyOfferByPackage
} from '../../actions'
import { useMediaQuery } from 'react-responsive'
import MainButton from '../../components/generalComponents/MainButton/MainButton';
import Modal from 'react-modal';
import Moment from 'moment/moment'
import AdicionalComponent from '../../components/mainCotizarComponents/AdicionalComponent/AdicionalComponent';
import PeriodsComponent from '../../components/mainCotizarComponents/PeriodsComponent/PeriodsComponent';
import RangeCalendarComponent from '../../components/mainCotizarComponents/RangeCalendarComponent/RangeCalendarComponent';
import NoFinePrintComponent from '../../components/mainCotizarComponents/NoFinePrintComponent/NoFinePrintComponent';

import { ReactComponent as ButtonMinus } from '../../assets/images/svgs/minus.svg';
import { ReactComponent as ButtonPlus } from '../../assets/images/svgs/plus.svg';
import EstrellaLleno from '../../assets/images/svgs/estrellaLleno.svg';
import EstrellaLlenoPremiun from '../../assets/images/svgs/estrellaLlenoPremiun.svg';
import EstrellaVacio from '../../assets/images/svgs/estrellaVacio.svg';
import { ReactComponent as Calendar } from '../../assets/images/svgs/calendar.svg';
import Candado from '../../assets/images/svgs/candado.svg';
import Footer from '../../components/generalComponents/footer/Footer';
import { ReactSVG } from 'react-svg'
import { GetGlobalParams } from '../../util/GetGlobalParams';
import { GetCountryUrl } from '../../util/GetCountryUrl';
import {Helmet} from "react-helmet";
import GetGlobalForm from '../../components/generalComponents/GlobalForms/getGlobalForm';
import SliderOffer from '../../components/mainCotizarComponents/SliderOffer/SliderOffer';

let isTabletOrMobile = false;
const MainCotizar = (props: any) => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

  const [originalParams, setOriginalParams] = useState<any>(null)
  const [monthlyPrice, setMonthlyPrice] = useState<string>('')
  const [admin, setAdmin] = useState<boolean>(false);
  const [selectionRange, setSelectionRange] = useState<any>({
    startDate: new Date(),
    endDate: new Date(), 
    key: 'selection'
  });
  const [adicionales, setAdicionales] = useState<any>({
    "title": "Adicionales"
  })
  const [packagesAux, setPackagesAux] = useState<any>(null)
  const [appId, setAppId] = useState<any>(process.env.REACT_APP_ID)
  const showDuration: any = !process.env.REACT_APP_HIDE_DURATION;
  const [timeout, timeoutChange] = useState<any>(0);
  const [loadingOffer, setLoadingOffer] = useState<boolean>(false);

  const [showCalendar, setShowCalendar] = useState<boolean>(false)

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate()

  const [formAnswer, setFormAnswer] = useState<any>(null);

  const intl = useIntl()

  useEffect(() => {
    let aux = props.offerData?.packages
    aux?.sort(function (a: any, b: any) {
      if (a.assessment < b.assessment) { return -1; }
      if (a.assessment > b.assessment) { return 1; }
      return 0;
    })
    setPackagesAux(aux)
  }, [props.offerData])

  useEffect(() => {
    handleUrlParams()
    setOriginalParams(searchParams.toString())
  }, [])

  const handleUrlParams = () => {
    let categoryId: any = searchParams.get('cId')
    let offer: string | null = searchParams.get('offer')
    let admin: any = searchParams.get("admin")
    categoryId = parseInt(categoryId)

    if (admin) {
      setAdmin(true)
    }
    if (categoryId && offer) {
      props.categoriaSelectedChange(categoryId)
      props.getOffer(categoryId, offer, setLoadingOffer)
    } else if (categoryId) {
      props.categoriaSelectedChange(categoryId)
    }
  }

  const modifyOfferCoverage = (value: any, b: any, id: Number) => {
    props.modifyOffer(props.categoriaSelected, id, b.id, value)
  }

  const renderCoverageData = (a: any, b: any, id: any) => {
    if (b.icon === 'estrellas') {
      const options = b.options.split(',')
      const index = options.indexOf(b.value.toString())
      const valueM = options[index + 1]
      const valueL = options[index - 1]
      return (
        <div className="mainCotizar-container-cobertura-coverageGroups-coverages-percentagesContainer">
          <div
            id='boton -'
            onClick={() => {
              if (valueL) {
                modifyOfferCoverage(valueL, b, id)
              }
            }}
            style={{
              opacity: valueL ? 1 : 0.4, cursor:
                valueL ? 'pointer' : 'inherit',
              display: valueL || valueM ? 'flex' : 'none'
            }}
            className="mainCotizar-container-cobertura-coverageGroups-coverages-percentagesContainer-minus"
          >
            <ButtonMinus />
          </div>
          <p className="mainCotizar-container-cobertura-coverageGroups-coverages-percentagesContainer-text">{b.value + b.unit}</p>
          <div
            id='boton +'
            onClick={() => {
              if (valueM) {
                modifyOfferCoverage(valueM, b, id)
              }
            }}
            style={{
              opacity: valueM ? 1 : 0.4,
              cursor: valueM ? 'pointer' : 'inherit',
              display: valueL || valueM ? 'flex' : 'none'
            }}
            className="mainCotizar-container-cobertura-coverageGroups-coverages-percentagesContainer-plus"
          >
            <ButtonPlus />
          </div>
        </div>
      )
    } else {
      if (b.icon === 'candado' && props.offerData.packages[0].discountedPrice) {
        return (
          <div className="mainCotizar-container-cobertura-coverageGroups-coverages-percentagesContainer-container">
            <p 
              className="mainCotizar-container-cobertura-coverageGroups-coverages-percentagesContainer-text"
              style={{color: 'var(--seventhTextColor)'}}
            >{props.offerData.packages[0].discountedPrice?.formattedValue}</p>
            <div className="mainCotizar-container-cobertura-coverageGroups-coverages-percentagesContainer-container-discountContainer">
              {b.formattedValue !== props.offerData.packages[0].discountedPrice?.formattedValue && <p className="mainCotizar-container-cobertura-coverageGroups-coverages-percentagesContainer-container-discountContainer-price">{b.formattedValue}</p>}
              {props.offerData.promo?.description && 
                <div className="mainCotizar-container-cobertura-coverageGroups-coverages-percentagesContainer-container-discountContainer-description">{props.offerData.promo?.description}
                  {props.offerData.promo?.fullDescription && <span className="mainCotizar-container-cobertura-coverageGroups-coverages-percentagesContainer-container-discountContainer-description-tooltipText">{props.offerData.promo?.fullDescription}</span>}
                </div>
              }
            </div>
          </div>
        )
      } return <p className="mainCotizar-container-cobertura-coverageGroups-coverages-percentagesContainer-text">{b.formattedValue}</p>
    }
  }

  const size = isTabletOrMobile ? "22" : "32"

  const estrellaLlenoPremiunSvg = <ReactSVG
    src={EstrellaLlenoPremiun}
    beforeInjection={svg => {
      svg.setAttribute('style', 'height: ' + size);
      svg.setAttribute('style', 'width: ' + size);
    }}
  />

  const estrellaLlenoSvg = <ReactSVG
    src={EstrellaLleno}
    beforeInjection={svg => {
      svg.setAttribute('style', 'height: ' + size);
      svg.setAttribute('style', 'width: ' + size);
    }}
  />

  const estrellaVacioSvg = <ReactSVG
    src={EstrellaVacio}
    beforeInjection={svg => {
      svg.setAttribute('style', 'height: ' + size);
      svg.setAttribute('style', 'width: ' + size);
    }}
  />

  const renderCoverageTopImage = (a: any, b: any, z: any) => {
    const options = b.options?.split(',')
    if (b.icon === 'candado') {
      if (z) {
        return (
          <p className="mainCotizar-container-coberturaList-cobertura-coverageGroups-name">{z.name.toUpperCase()}</p>
        )
      }
      return (
        <div className="mainCotizar-container-cobertura-coverageGroups-coverages-estrellas">
          <ReactSVG src={Candado} />
        </div>
      )
    } else if (b.icon === 'estrellas') {
      if (b.value === 90 || b.value === 100 || b.value === '--' || options.length === 1) {
        return (
          <div className="mainCotizar-container-cobertura-coverageGroups-coverages-estrellas">
            {estrellaLlenoPremiunSvg}{estrellaLlenoPremiunSvg}{estrellaLlenoPremiunSvg}{estrellaLlenoPremiunSvg}{estrellaLlenoPremiunSvg}
          </div>
        )
      } else if (b.value === 80) {
        return (
          <div className="mainCotizar-container-cobertura-coverageGroups-coverages-estrellas">
            {estrellaLlenoSvg}{estrellaLlenoSvg}{estrellaLlenoSvg}{estrellaLlenoSvg}{estrellaVacioSvg}
          </div>
        )
      } else if (b.value === 70) {
        return (
          <div className="mainCotizar-container-cobertura-coverageGroups-coverages-estrellas">
            {estrellaLlenoSvg}{estrellaLlenoSvg}{estrellaLlenoSvg}{estrellaVacioSvg}{estrellaVacioSvg}
          </div>
        )
      } else if (b.value === 60) {
        return (
          <div className="mainCotizar-container-cobertura-coverageGroups-coverages-estrellas">
            {estrellaLlenoSvg}{estrellaLlenoSvg}{estrellaVacioSvg}{estrellaVacioSvg}{estrellaVacioSvg}
          </div>
        )
      } else if (b.value === 50) {
        return (
          <div className="mainCotizar-container-cobertura-coverageGroups-coverages-estrellas">
            {estrellaLlenoSvg}{estrellaVacioSvg}{estrellaVacioSvg}{estrellaVacioSvg}{estrellaVacioSvg}
          </div>
        )
      } else {
        return (
          <div className="mainCotizar-container-cobertura-coverageGroups-coverages-estrellas">
            {estrellaLlenoSvg}{estrellaVacioSvg}{estrellaVacioSvg}{estrellaVacioSvg}{estrellaVacioSvg}
          </div>
        )
      }
    }
  }

  const renderExtraCoverageData = (index: Number, z: any) => {
    if (index === 0 && showDuration) {
      return (
        <div className="mainCotizar-container-cobertura-coverageGroups-coverages">
          <p className="mainCotizar-container-cobertura-coverageGroups-coverages-title">{<FormattedMessage id="page.mainCotizar.title.duracion" defaultMessage="" />}</p>
          {props.offerData.renew.term === "CUSTOM" && <p className="mainCotizar-container-cobertura-coverageGroups-coverages-duracionText">{<FormattedMessage id="page.mainCotizar.title.diaria" defaultMessage="" />}</p>}
          <p className="mainCotizar-container-cobertura-coverageGroups-coverages-duracionText">{getRangeDates()}</p>
        </div>
      )
    } else if (index === 1 && appId == 1) {
      return (
        <div className="mainCotizar-container-cobertura-coverageGroups-coverages">
          <p className="mainCotizar-container-cobertura-coverageGroups-coverages-title">{<FormattedMessage id="page.mainCotizar.title.asegura" defaultMessage="" />}</p>
          <img className="mainCotizar-container-cobertura-coverageGroups-coverages-insurerImg" src={z.insurer.image} alt="" />
        </div>
      )
    }
  }

  const localModifyOfferDate = (startDate: string, period: string, endDate: string) => {
    let data = {
      "startDate": startDate,
      "period": period,
      "endDate": endDate
    }
    props.modifyOfferDate(props.categoriaSelected, props.offerData.id, data)
  }

  const handleSelect = (DateRange: any) => {
    setSelectionRange(DateRange.selection)
    const dateAuxStart = Moment(DateRange.selection.startDate).startOf('day').toISOString()
    const dateAuxEnd = Moment(DateRange.selection.endDate).startOf('day').add(1, 'days').toISOString()
    localModifyOfferDate(dateAuxStart, "CUSTOM", dateAuxEnd)
  }

  const getRangeDates = () => {
    if (props.offerData.renew.term === "MONTHLY") {
      return 'RENOVACIÓN MENSUAL AUTOMÁTICA'
    } else if (props.offerData.renew.term === "ANUAL") {
      return 'RENOVACIÓN ANUAL AUTOMÁTICA'
    }
    if(Moment(props.offerData.endDate).add(-1, 'days').format('DD/MM/YY') === Moment(props.offerData.startDate).format('DD/MM/YY')) {
      return ('EL ' + Moment(props.offerData.startDate).format('DD/MM/YY'))
    }
    return ('DEL ' + Moment(props.offerData.startDate).format('DD/MM/YY') + ' AL ' + Moment(props.offerData.endDate).add(-1, 'days').format('DD/MM/YY'))
  }

  const detectAdicionales = () => {
    let control: boolean = false
    props.offerData && props.offerData.packages[0].coverageGroups.map((a: any) => {
      a.coverages.map((b: any) => {
        if (b.canExclude) {
          control = true
        }
      })
    })
    return control
  }

  const getPackageValue = (z) => {
    switch (z.assessment) {
      case 1:
        return (
          <div className="mainCotizar-container-coberturaList-cobertura-coverageGroups-estrellas">
            {estrellaLlenoSvg}{estrellaVacioSvg}{estrellaVacioSvg}{estrellaVacioSvg}{estrellaVacioSvg}
          </div>
        )
      case 2:
        return (
          <div className="mainCotizar-container-coberturaList-cobertura-coverageGroups-estrellas">
            {estrellaLlenoSvg}{estrellaLlenoSvg}{estrellaVacioSvg}{estrellaVacioSvg}{estrellaVacioSvg}
          </div>
        )
      case 3:
        return (
          <div className="mainCotizar-container-coberturaList-cobertura-coverageGroups-estrellas">
            {estrellaLlenoSvg}{estrellaLlenoSvg}{estrellaLlenoSvg}{estrellaVacioSvg}{estrellaVacioSvg}
          </div>
        )
      case 4:
        return (
          <div className="mainCotizar-container-coberturaList-cobertura-coverageGroups-estrellas">
            {estrellaLlenoSvg}{estrellaLlenoSvg}{estrellaLlenoSvg}{estrellaLlenoSvg}{estrellaVacioSvg}
          </div>
        )
      case 5:
        return (
          <div className="mainCotizar-container-coberturaList-cobertura-coverageGroups-estrellas">
            {estrellaLlenoPremiunSvg}{estrellaLlenoPremiunSvg}{estrellaLlenoPremiunSvg}{estrellaLlenoPremiunSvg}{estrellaLlenoPremiunSvg}
          </div>
        )
      default:
        return (
          <div className="mainCotizar-container-coberturaList-cobertura-coverageGroups-estrellas">
            {estrellaLlenoSvg}{estrellaLlenoSvg}{estrellaLlenoSvg}{estrellaVacioSvg}{estrellaVacioSvg}
          </div>
        )
    }
  }

  const handleFormUpdate = (value: any, coverageId: any) => {
    setFormAnswer(value)
    if (timeout) timeoutChange(clearTimeout(timeout));
    timeoutChange(setTimeout(() => {
      props.modifyOfferByPackage(props.categoriaSelected, props.offerData?.id, props.offerData?.currentPackage, coverageId, value)
    }, 1000));
  }

  const renderPackageList = () => {
    if (props.offerData) {
      if (props.offerData.packages.length === 1) {
        return (
          <div className="mainCotizar-container-cobertura">
            {props.offerData.packages[0].coverageGroups.map((a: any, indexA: any) => (
              <React.Fragment key={indexA}>
                <div className="mainCotizar-container-cobertura-coverageGroups">
                  {a.coverages.map((b: any, indexB: any) => renderPackage(a, b, indexB, null))}
                  {renderExtraCoverageData(indexA, props.offerData.packages[0])}
                </div>
                {isTabletOrMobile ?
                  indexA === props.offerData.packages[0].coverageGroups.length - 1 && <div className="mainCotizar-container-cobertura-coverageGroups-line"></div>
                  :
                  <div className="mainCotizar-container-cobertura-coverageGroups-line"></div>
                }
              </React.Fragment>
            ))}
            <div className="mainCotizar-container-main-adicionalesContainer">
              {detectAdicionales() && <p className="mainCotizar-container-main-adicionalesTitulo">{adicionales.title}</p>}
              <div className="mainCotizar-container-main-adicionales">
                {props.offerData.packages[0].coverageGroups.map((a: any, index: any) => (
                  a.coverages.map((b: any, indexB: any) => {
                    return (
                      b.canExclude &&
                      <AdicionalComponent
                        id={b.title + ' adicional'}
                        key={index}
                        selected={b.value}
                        price={'+' + b.additionalPriceFormatted}
                        titulo={b.title}
                        description={b.description}
                        adicionalClicked={() => modifyOfferCoverage(b.value ? 0 : 1, b, props.offerData.id)}
                      />
                    )
                  })
                ))}
              </div>
            </div>
            <div className="mainCotizar-container-main-endButtons">
              {props.offerData && props.offerData.packages[0].hasRestrictions &&
                <div className="mainCotizar-container-main-endButtons-firstButton">
                  <MainButton
                    id='Continuar Boton'
                    disabled={false}
                    text='QUIERO SABER MÁS'
                    onButtonPressed={() => props.getRestrictions(props.categoriaSelected, props.offerData.id, props.offerData.packages[0].id)}
                    altStyle2
                  />
                </div>
              }
              <div className="mainCotizar-container-main-endButtons-secondButton" style={{ marginRight: isTabletOrMobile ? 0 : 20 }}>
                <MainButton
                  id='Continuar Boton'
                  disabled={false}
                  text={<FormattedMessage id="page.mainCotizar.button.seguro" defaultMessage="" />}
                  onButtonPressed={() => props.checkOutOffer(props.offerData.id, props.offerData.product.categoryId, props.offerData.packages[0].id, navigate, props.countryId, props.userData?.id, admin, props.userData)}
                  altStyle6
                />
              </div>
            </div>
            {appId == 2 && <p className="mainCotizar-container-cobertura-disclaimer">* Para asegurar tu producto necesitaremos fotos sacadas en el momento. Por eso, es importante que tengas tu celular a mano para finalizar el proceso.</p>}
          </div>
        )
      } else {
        return (
          <div className="mainCotizar-container-coberturaList">
            {packagesAux && packagesAux.map((z: any, indexZ: any) => (
              <div 
                className="mainCotizar-container-coberturaList-cobertura" 
                key={indexZ}
                style={{width: (100/packagesAux.length).toString() + '%'}}
              >
                {z.coverageGroups.map((a: any, indexA: any) => (
                  <React.Fragment key={indexA}>
                    <div className="mainCotizar-container-coberturaList-cobertura-coverageGroups">
                      {indexA === 0 && getPackageValue(z)}
                      {a.coverages.map((b: any, indexB: any) => renderPackage(a, b, indexB, z))}
                      {indexA === 0 && <div className="mainCotizar-container-cobertura-coverageGroups-line"></div>}
                      {renderExtraCoverageData(indexA, z)}
                      {indexA === z.coverageGroups.length - 1 && <div className="mainCotizar-container-cobertura-coverageGroups-line"></div>}
                    </div>
                  </React.Fragment>
                ))}
                <div className="mainCotizar-container-coberturaList-endButtons">
                  {props.offerData && z.hasRestrictions &&
                    <div className="mainCotizar-container-coberturaList-endButtons-firstButton">
                      <MainButton
                        id='Continuar Boton'
                        disabled={false}
                        text='QUIERO SABER MÁS'
                        onButtonPressed={() => props.getRestrictions(props.categoriaSelected, props.offerData.id, z.id)}
                        altStyle2
                      />
                    </div>
                  }
                  <div className="mainCotizar-container-coberturaList-endButtons-secondButton" style={{ marginRight: isTabletOrMobile ? 0 : 20 }}>
                    <MainButton
                      id='Continuar Boton'
                      disabled={false}
                      text={<FormattedMessage id="page.mainCotizar.button.seguro" defaultMessage="" />}
                      onButtonPressed={() => props.checkOutOffer(props.offerData.id, props.offerData.product.categoryId, z.id, navigate, props.countryId, props.userData?.id, admin, props.userData)}
                      altStyle6
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )
      }
    }
  }

  const renderPackage = (a: any, b: any, indexB: any, z: any) => {
    if (b?.form) {
      return (
        <div key={indexB} className="mainCotizar-container-cobertura-coverageGroups-coverages">
          <div key={indexB} className="mainCotizar-container-cobertura-coverageGroups-coverages-form">
            <SliderOffer
              data={b.form[0]}
              onUpdate={(e: any) => handleFormUpdate(e, b.id)}
              value={b.value}
            />
          </div>
        </div>
      )
    } else {
      return (
        !b.canExclude &&
        <div key={indexB} className="mainCotizar-container-cobertura-coverageGroups-coverages">
          {renderCoverageTopImage(a, b, z)}
          <p className="mainCotizar-container-cobertura-coverageGroups-coverages-title">{b.title}</p>
          {renderCoverageData(a, b, props.offerData.id)}
          <p className="mainCotizar-container-cobertura-coverageGroups-coverages-description">{b.description}</p>
          {a.id === 1 && b.id === 1 && <div className="mainCotizar-container-cobertura-coverageGroups-coverages-percentagesContainer-line"></div>}
        </div>
      )
    }
  }  

  if (props.offerData) {
    const title = props.offerData.periods[props.offerData.renew.term].displayName;
    return (
      <div className="mainCotizar-container">
        <Helmet>
          <html lang={intl.formatMessage({ id: 'meta.oferta.html' })}/>
          <title>{intl.formatMessage({ id: 'meta.oferta.title', defaultMessage: 'WeCover | Contratá tu seguro en segundos 100% online.' }, { name: props.offerData?.product.title + ' ' + props.offerData?.product.modelo, price: props.offerData?.packages[0].insurerPrice[0].formattedValue })}</title>
          <meta name="description" content={intl.formatMessage({ id: 'meta.oferta.description', defaultMessage: 'Tus objetos protegidos en tan sólo unos segundos y 100% online. Personaliza tu cobertura y gestionala totalmente online con nuestra aplicación o nuestra web.' })} />
          <meta property='keywords' content={intl.formatMessage({ id: 'meta.oferta.keywords' })} />
        </Helmet>
        {!admin && <Header backgroundColor={isTabletOrMobile ? "var(--white)" : null}/>}
       <div className={ admin ? "" : "mainCotizar-container-titleContainer"}>
          { !admin && <p className="mainCotizar-container-titleContainer-title"><FormattedMessage id="page.mainCotizar.title1" defaultMessage="" /> <strong><FormattedMessage id="page.mainCotizar.title2" defaultMessage="" /></strong></p> }
          {props.offerData?.packages.length === 1 && <div className="mainCotizar-container-titleContainer-details">
            <div className="mainCotizar-container-titleContainer-details-product">
              <p className="mainCotizar-container-titleContainer-details-product-marca">{props.offerData ? props.offerData.product.marca : ''}</p>
              <p className="mainCotizar-container-titleContainer-details-product-title">{props.offerData ? props.offerData.product.title : ''}</p>
              <p className="mainCotizar-container-titleContainer-details-product-modelo">{props.offerData ? props.offerData.product.modelo : ''}</p>
              <p className="mainCotizar-container-titleContainer-details-product-valor"><FormattedMessage id="page.mainCotizar.valor" defaultMessage="" /></p>
              <p className="mainCotizar-container-titleContainer-details-product-valorValue">{props.offerData ? props.offerData.packages[0].insurerPrice[0].formattedValue : ''}</p>
            </div>
            <img className="mainCotizar-container-titleContainer-details-image" src={props.offerData ? props.offerData.product.image : null} alt="" />
          </div>}
        </div>
        <div className="mainCotizar-container-titleContainerMask" style={{marginBottom: 20}}>
          <div className="mainCotizar-container-titleContainerMask-inner"></div>
        </div>
        <div className="mainCotizar-container-main">
          {props.offerData?.packages[0].insurerPrice[0].value !== null && props.offerData?.packages[0].insurerPrice[0].value !== 0 && <div
            className="mainCotizar-container-main-top"
            style={{ marginTop: props.offerData ? Object.keys(props.offerData.periods).length > 1 ? -10 : 40 : 40 }}
           >
            <div className="mainCotizar-container-main-top-product">
              <p className="mainCotizar-container-main-top-product-marca">{props.offerData ? props.offerData.product.marca : ''}</p>
              <p className="mainCotizar-container-main-top-product-title">{props.offerData ? props.offerData.product.title : ''}</p>
              <p className="mainCotizar-container-main-top-product-modelo">{props.offerData ? props.offerData.product.modelo : ''}</p>
              <p className="mainCotizar-container-main-top-product-valor"><FormattedMessage id="page.mainCotizar.valor" defaultMessage="" /></p>
              <p className="mainCotizar-container-main-top-product-valorValue">{props.offerData ? props.offerData.packages[0].insurerPrice[0].formattedValue : ''}</p>
            </div>
            {Object.keys(props.offerData?.periods).length > 1 &&
              <div className="mainCotizar-container-main-top-periods">
                <p className="mainCotizar-container-main-titleText"><FormattedMessage id="page.mainCotizar.tiempoPregunta" defaultMessage="" /></p>
                {props.offerData &&
                  <PeriodsComponent
                    offerData={props.offerData}
                    periodClicked={(key: string) => {
                      if (key === 'CUSTOM') {
                        props.fadeDataIn(props.offerData)
                        if (monthlyPrice === '') {
                          setMonthlyPrice(props.offerData.packages[0].coverageGroups[0].coverages[0].formattedValue)
                        }
                        setTimeout(() => {
                          setSelectionRange({
                            startDate: new Date(),
                            endDate: new Date(),
                            key: 'selection'
                          })
                          setShowCalendar(true)
                        }, 500)
                      } else {
                        localModifyOfferDate(new Date().toISOString(), key, '')
                      }
                    }}
                  />
                }
                {props.offerData && props.offerData.renew.term === "CUSTOM" &&
                  <>
                    <p className="mainCotizar-container-main-titleText"><FormattedMessage id="page.mainCotizar.diaRango" defaultMessage="" /></p>
                    <div className="mainCotizar-container-main-days">
                      <p className="mainCotizar-container-main-days-text">{props.offerData.renew.term === "CUSTOM" ? getRangeDates() : 'DD/MM/AAAA'}</p>
                      <div
                        id='Calendario icono'
                        className="mainCotizar-container-main-days-calendar"
                        onClick={() => setShowCalendar(true)}
                      >
                        <Calendar />
                      </div>
                    </div>
                  </>
                }
              </div>
            }
          </div>}
          <p
            className="mainCotizar-container-main-titleText"
            style={{ marginTop: props.offerData ? Object.keys(props.offerData?.periods).length > 1 ? 30 : isTabletOrMobile ? 0 : 90 : 90 }}
          ><FormattedMessage id="page.mainCotizar.coberturaTitle" defaultMessage="" /></p>
          {renderPackageList()}
          <p className="mainCotizar-container-main-titleText"><FormattedMessage id="page.mainCotizar.faqTitle" defaultMessage="" /></p>
          {props.offerData && props.offerData.packages[0].conditions.map((a: any, index: any) => (
            <AccordionCotizar
              id={a.name + ' faq'}
              key={index}
              title={a.name}
              text={a.description}
            />
          ))}
          <div className="mainCotizar-container-main-endButtons">
            <div className="mainCotizar-container-main-endButtons-firstButton" style={{ display: isTabletOrMobile ? 'none' : 'initial' }}>
              <MainButton
                id='Continuar Boton'
                disabled={false}
                text='COTIZAR OTRO PRODUCTO'
                onButtonPressed={() => {
                  const adminN = admin ? '&admin=True&' : ''
                  if(props.offerData.packages.length === 1) {
                    navigate(GetCountryUrl(props.countryId) + 'cotizar?' + adminN + GetGlobalParams())
                  } else {
                    navigate(GetCountryUrl(props.countryId) + 'inicio?' + adminN + GetGlobalParams())
                    props.setRefCategory(true)
                  }
                }}
                empty
color='var(--secondary500)'
              />
            </div>
            {!admin && <div className="mainCotizar-container-main-endButtons-secondButton" style={{ width: isTabletOrMobile ? '100%' : '20%'}}>
              <MainButton
                id='Continuar Boton'
                disabled={false}
                text='CONECTARME CON UN ASESOR'
                onButtonPressed={() => {
                  const adminN = admin ? '&admin=True&' : ''
                  navigate(GetCountryUrl(props.countryId) + 'contacto?' + adminN + GetGlobalParams())
                }}
                altStyle7
              />
              {isTabletOrMobile && props.offerData?.packages.length > 1 && <MainButton
                id='Continuar Boton'
                disabled={false}
                text='COTIZAR OTRO PRODUCTO'
                onButtonPressed={() => {
                  const adminN = admin ? '&admin=True&' : ''
                  if(props.offerData.packages.length === 1) {
                    navigate(GetCountryUrl(props.countryId) + 'cotizar?' + adminN + GetGlobalParams())
                  } else {
                    navigate(GetCountryUrl(props.countryId) + 'inicio?' + adminN +  GetGlobalParams())
                    props.setRefCategory(true)
                  }
                }}
                empty
color='var(--secondary500)'
              />}
            </div>}
          </div>

          {props.offerData?.packages.length === 1 && <div className='mainCotizar-container-main-fixedPrice' style={{ display: isTabletOrMobile ? 'flex' : 'none', flexDirection: 'column', width: '100%' }}>
            <div style={{flexDirection: 'row', display:'flex', alignItems: 'center', justifyContent:'space-between', width: '93%'}}>
              <div style={{flexDirection: 'row', display:'flex'}}>
                <p className='title-price-main'>{props.offerData.packages[0].discountedPrice ? props.offerData.packages[0].discountedPrice?.formattedValue : props.offerData.packages[0].price.formattedValue}</p>
                <p className='title-time-main'>/POR {title}</p>
              </div>
              <div style={{ width: '50%' }}>
                <MainButton
                  id='Continuar Boton'
                  disabled={false}
                  text={<FormattedMessage id="page.mainCotizar.button.seguro" defaultMessage="" />}
                  onButtonPressed={() => props.checkOutOffer(props.offerData.id, props.offerData.product.categoryId, props.offerData.packages[0].id, navigate, props.countryId, props.userData?.id, admin, props.userData)}
                  altStyle6
                />
              </div>
            </div>
            <div>
              <p 
                className='cotizar-otro-producto' 
                onClick={() => {
                  const adminN = admin ? '&admin=True&' : ''
                  if(props.offerData.packages.length === 1) {
                    navigate(GetCountryUrl(props.countryId) + 'cotizar?' + adminN + GetGlobalParams())
                  } else {
                    navigate(GetCountryUrl(props.countryId) + 'inicio?' + adminN + GetGlobalParams())
                    props.setRefCategory(true)
                  }
                }}
              >COTIZAR OTRO PRODUCTO</p>
            </div>
          </div>}
        </div>
        <Modal
          isOpen={showCalendar}
          onRequestClose={() => {
            props.fadeDataOut(props.originalOfferData)
            setShowCalendar(false)
            localModifyOfferDate(new Date().toISOString(), 'MONTHLY', '')
          }}
          style={{
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              borderRadius: '25px',
              width: isTabletOrMobile ? '95%' : '50%',
              padding: isTabletOrMobile ? '10px' : '20px',
            },
            overlay: {
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              zIndex: 12
            }
          }}
        >
          <RangeCalendarComponent
            onCloseClick={() => {
              props.fadeDataOut(props.originalOfferData)
              setShowCalendar(false)
              localModifyOfferDate(new Date().toISOString(), 'MONTHLY', '')
            }}
            handleCalendarSelection={handleSelect}
            setShowCalendar={(e: boolean) => setShowCalendar(e)}
            offerData={props.offerData}
            monthlyPrice={monthlyPrice}
            selectionRange={selectionRange}
          />
        </Modal>
        <Modal
          isOpen={props.showRestrictions}
          onRequestClose={() => props.toggleRestrictions(false)}
          style={{
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              borderRadius: '25px',
              width: isTabletOrMobile ? '80%' : '50%',
              padding: '20px'
            },
            overlay: {
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
              zIndex: 12
            }
          }}
        >
          <NoFinePrintComponent
            onCloseClick={() => props.toggleRestrictions(false)}
            text={props.restrictionsText}
          />
        </Modal>
        <div style={{marginBottom: isTabletOrMobile ? 130 : 'unset'}}>
        {!admin && <Footer />}
        </div>
      </div>
    );
  } return null;
}

const mapStateToProps = (state: any) => {
  return {
    countryId: state.general.countryId,
    userData: state.user.userData,
    categoriaSelected: state.cotizar.categoriaSelected,
    offerData: state.mainCotizar.offerData,
    originalOfferData: state.mainCotizar.originalOfferData,
    showRestrictions: state.mainCotizar.showRestrictions,
    restrictionsText: state.mainCotizar.restrictionsText,
  };
};

export default connect(mapStateToProps, {
  changeCountryId,
  categoriaSelectedChange,
  getOffer,
  modifyOffer,
  modifyOfferDate,
  fadeDataIn,
  fadeDataOut,
  getRestrictions,
  toggleRestrictions,
  checkOutOffer,
  setRefCategory,
  modifyOfferByPackage
})(MainCotizar);