import { useEffect, useRef, useState } from 'react';
import './BurgerMenu.css';
import { useMediaQuery } from 'react-responsive';
import { connect, useDispatch } from 'react-redux';
import {
    setBurgerOpen,
    userAccessTokenSet,
    setRefCategory,
    setRefQuestion,
    setAccessTokenAux
} from '../../../actions';
import { ReactSVG } from 'react-svg';
import Exit from '../../../assets/images/svgs/exitQuote.svg'; 
import DefaultAvatar from '../../../assets/images/svgs/defaultAvatar.svg'; 
import Faq from '../../../assets/images/svgs/faqIcon.svg'; 
import { useNavigate, Link } from 'react-router-dom';
import { GetGlobalParams } from '../../../util/GetGlobalParams';
import { GetCountryUrl } from '../../../util/GetCountryUrl';
import Modal from 'react-modal';
import AtencionAlert from '../../alertComponents/AtencionAlert/AtencionAlert';
import CerrarSesionAlert from '../../alertComponents/CerrarSesionAlert/CerrarSesionAlert';
import { CHECKOUT_DATA, USER_DATA } from '../../../actions/types';

let isTabletOrMobile = false;

const BurgerMenu = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })
    const navigate = useNavigate();
    const mainRef: any = useRef();
    const dispatch = useDispatch();
    const [atencionAlert, setAtencionAlert] = useState<boolean>(false);
    const [cerrarSesionAlert, setCerrarSesionAlert] = useState<boolean>(false);
    const [translate, setTranslate] = useState<any>(null);

    const avatarSize = isTabletOrMobile ? 100 : 125;

    const iconSize = isTabletOrMobile ? 20 : 25;

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (mainRef.current && !mainRef.current.contains(event.target)) {
                props.setBurgerOpen(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [mainRef]);

    useEffect(() => {
        setTranslate(window.screen.width-mainRef?.current?.offsetWidth)
    }, [window?.screen?.width, mainRef])

    const atencionModal = () => {
        return (
          <Modal
            isOpen={atencionAlert}
            onRequestClose={() => setAtencionAlert(false)}
            style={{
              content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '25px',
                width: isTabletOrMobile ? '80%' : '30%',
                padding: isTabletOrMobile ? '10px' : '20px',
              },
              overlay: {
                backgroundColor: 'rgba(255, 255, 255, 0.5)'
              }
            }}
          >
            <AtencionAlert
              close={() => setAtencionAlert(false)}
            />
          </Modal>
        )
    }

    const cerrarSesionModal = () => {
        return (
          <Modal
            isOpen={cerrarSesionAlert}
            onRequestClose={() => setCerrarSesionAlert(false)}
            style={{
              content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '25px',
                width: isTabletOrMobile ? '80%' : '30%',
                padding: isTabletOrMobile ? '10px' : '20px',
              },
              overlay: {
                backgroundColor: 'rgba(255, 255, 255, 0.5)'
              }
            }}
          >
            <CerrarSesionAlert
                onPress={() => {
                    props.userAccessTokenSet(null);
                    dispatch(setAccessTokenAux(null));
                    dispatch({ type: USER_DATA, payload: null });
                    dispatch({ type: CHECKOUT_DATA, payload: null });
                    navigate(GetCountryUrl(props.countryId) + 'inicio?' + GetGlobalParams())
                }}
                close={() => setCerrarSesionAlert(false)}
            />
          </Modal>
        )
    }

    return (
        <div
            ref={mainRef}
            className="burgerMenu-container"
            style={{ transform: props.burgerMenuOpen ? `translate(${translate}px, 0px)` : `translate(${window.screen.width}px, 0px)` }}
        >
            <div className="burgerMenu-container-top">
                <ReactSVG
                    src={Exit}
                    style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        top: 20,
                        right: 20
                    }}
                    onClick={() => props.setBurgerOpen(false)}
                />
                {props.userAccessToken && props.userData?.imageProfile ?
                    <img src={props.userData?.imageProfile} className="burgerMenu-container-top-profileImg" />
                    :
                    <ReactSVG
                        src={DefaultAvatar}
                        beforeInjection={svg => {
                            svg.setAttribute('style', 'height: ' + avatarSize);
                            svg.setAttribute('style', 'width: ' + avatarSize);
                        }}
                    />
                }
                {props.userAccessToken && props.userData ?
                    <div className="burgerMenu-container-top-user">
                        <p className="burgerMenu-container-top-user-name">{props.userData.name + ' ' + props.userData.lastName}</p>
                        <p className="burgerMenu-container-top-user-email">{props.userData.username}</p>
                    </div>
                    :
                    <Link
                        className="burgerMenu-container-top-ingresar"
                        onClick={() => props.setBurgerOpen(false)} 
                        to={GetCountryUrl(props.countryId) + 'login?' + GetGlobalParams()} 
                        state={{ login: 'InicioSesion' }} 
                    >
                        <p className="burgerMenu-container-top-ingresar-text">INGRESAR</p>
                    </Link>
                }
            </div>
            <div className="burgerMenu-container-main">
                <div
                    className="burgerMenu-container-main-item"
                    onClick={() => {
                        props.setBurgerOpen(false)
                        if (props.userAccessToken) {
                            navigate(GetCountryUrl(props.countryId) + 'home?' + GetGlobalParams())
                        } else {
                            navigate(GetCountryUrl(props.countryId) + 'inicio?' + GetGlobalParams())
                        }
                    }}
                >
                    <span className="material-symbols-outlined" style={{ fontSize: iconSize, color: 'var(--primary500)' }}>home</span>
                    <p className="burgerMenu-container-main-item-text">Home</p>
                </div>
                {props.userAccessToken && <div
                    className="burgerMenu-container-main-item"
                    onClick={() => {
                        props.setBurgerOpen(false)
                        navigate(GetCountryUrl(props.countryId) + 'perfil?' + GetGlobalParams())
                    }}
                >
                    <span className="material-symbols-outlined" style={{ fontSize: iconSize, color: 'var(--primary500)' }}>account_circle</span>
                    <p className="burgerMenu-container-main-item-text">Mi perfil</p>
                </div>}
                {props.userAccessToken && <div
                    className="burgerMenu-container-main-item"
                    onClick={() => {
                        props.setBurgerOpen(false)
                        navigate(GetCountryUrl(props.countryId) + 'mis_coberturas#Pendiente')
                        props.setRefCategory(true)
                    }}
                >
                    <span className="material-symbols-outlined" style={{ fontSize: iconSize, color: 'var(--primary500)' }}>lock</span>
                    <p className="burgerMenu-container-main-item-text">Mis coberturas</p>
                </div>}
                <div
                    className="burgerMenu-container-main-item"
                    onClick={() => {
                        props.setBurgerOpen(false)
                        if (props.userAccessToken) {
                            navigate(GetCountryUrl(props.countryId) + 'home?' + GetGlobalParams())
                        } else {
                            navigate(GetCountryUrl(props.countryId) + 'inicio?' + GetGlobalParams())
                        }
                        props.setRefCategory(true)
                    }}
                >
                    <span className="material-symbols-outlined" style={{ fontSize: iconSize, color: 'var(--primary500)' }}>verified_user</span>
                    <p className="burgerMenu-container-main-item-text">Cotizá tu cobertura</p>
                </div>
                {!props.userAccessToken && <div
                    className="burgerMenu-container-main-item"
                    onClick={() => {
                        props.setBurgerOpen(false)
                        navigate(GetCountryUrl(props.countryId) + 'inicio?' + GetGlobalParams())
                        props.setRefQuestion(true)
                    }}
                >
                    <ReactSVG
                        src={Faq}
                        beforeInjection={svg => {
                            svg.setAttribute('style', 'height: ' + iconSize);
                            svg.setAttribute('style', 'width: ' + iconSize);
                        }}
                    />
                    <p className="burgerMenu-container-main-item-text">Preguntas frecuentes</p>
                </div>}
                <div
                    className="burgerMenu-container-main-item"
                    onClick={() => {
                        props.setBurgerOpen(false)
                        if (props.userAccessToken) {
                            setAtencionAlert(true)
                        } else {
                            navigate(GetCountryUrl(props.countryId) + 'contacto?' + GetGlobalParams())
                        }
                    }}
                >
                    <span className="material-symbols-outlined" style={{ fontSize: iconSize, color: 'var(--primary500)' }}>contact_support</span>
                    <p className="burgerMenu-container-main-item-text">{props.userAccessToken ? 'Atención al cliente' : 'Contactanos'}</p>
                </div>
            </div>
            {props.userAccessToken && props.userData && <div className="burgerMenu-container-bot">
                <div className="burgerMenu-container-bot-line"></div>
                <p
                    className="burgerMenu-container-main-item-text"
                    onClick={() => {
                        props.setBurgerOpen(false)
                        setCerrarSesionAlert(true)
                    }}
                >Cerrar sesión</p>
            </div>}
            {atencionModal()}
            {cerrarSesionModal()}
        </div>
    );
}

const mapStateToProps = (state: any) => {
    return {
        countryId: state.general.countryId,
        burgerMenuOpen: state.general.burgerMenuOpen,
        userData: state.user.userData,
        userAccessToken: state.general.userAccessToken,
    };
};

export default connect(mapStateToProps, {
    setBurgerOpen,
    userAccessTokenSet,
    setRefCategory,
    setRefQuestion
})(BurgerMenu);