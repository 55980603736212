import React, { useState } from 'react'
import './FormFieldPhoto.css'
import FormFieldPhotoItem from './FormFieldPhotoItem/FormFieldPhotoItem';

const FormFieldPhoto = (props: any) => {
    /**
     * Almacenamos las imagenes subidas hasta el momento.
     */
    const [status, setStatusState] = useState<any>(null)

    const updateIternalStatus = (data: string, index: number) => {
        let res: any = []
        if(status) res = status
        res[index] = data;
        setStatusState(res)
        const urlWithOutQueryParmas: string[] = res?.map((url: any) => url?.split("?")[0]);

        props.onUpdate(urlWithOutQueryParmas);
    };

    return (
        <div className="FormFieldPhoto-container">
            <h1 dangerouslySetInnerHTML={{__html: props.data.display.name}}/>
            <p className="FormFieldPhoto-container-description" dangerouslySetInnerHTML={{__html: props.data.display.description}}/>
            <div className="FormFieldPhoto-container-list">
                {props.data.input.presignedUrl.map((a: any, index: number) => (
                    <FormFieldPhotoItem
                        key={index}
                        a={a}
                        index={index}
                        data={props.data}
                        onUpdate={updateIternalStatus}
                    />
                ))}
            </div>
        </div>
    )
}

export default FormFieldPhoto;