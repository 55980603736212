import React, {useState} from 'react'
import './FormFieldInfo.css'
import { ReactComponent as ArrowQuote } from '../../../../../assets/images/svgs/arrowQuote.svg';

const FormFieldInfo = (props: any) => {

    const [open, setOpen] = useState<boolean>(false)

    return (
        <div className="FormFieldInfo-container" onClick={() => setOpen(!open)}>
            <div className="FormFieldInfo-container-main">
                <div className="FormFieldInfo-container-main-titleContainer">
                    <p className="FormFieldInfo-container-main-titleContainer-text">{props.data.input.config.title}</p>
                    <div style={{transform: open ? `rotate(${180}deg)` : `rotate(${0}deg)`}}>
                        <ArrowQuote/>
                    </div>
                </div>
                <div 
                    className="FormFieldInfo-container-main-textContainer"
                    style={{ display: open ? 'flex' : 'none' }}
                >
                    <div className="FormFieldInfo-container-main-textContainer-line"></div>
                    <p className="FormFieldInfo-container-main-textContainer-text">{props.data.input.config.description}</p>
                </div>
            </div>
        </div>
    );
}

export default FormFieldInfo;