import { useState } from 'react';
import './FormFieldText.css';

const FormFieldText = (props: any) => {

  const [localText, setLocalText] = useState<string>('')

  return (
    <div
      className="customTextInput1-container"
      id={props.data.display.name}
      style={{margin: 0}}
    >
      {props.data.display.name &&
        <div className="customTextInput1-container-label">
          <h1>{props.data.display.name}</h1>
          {props.data.required && <p className="customTextInput1-container-label-requiered">&nbsp;*</p>}
        </div>
      }
      <div className="customTextInput1-container-inputContainer">
        {( props.data.input.config.rows && props.data.input.config.rows > 1 ) ?
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <textarea
              id={props.data.display.name + '-textarea'}
              name="textarea1"
              className="estilotextarea1"
              placeholder={props.data.display.placeholder}
              onChange={(e: any) => props.onUpdate(e.target.value)}
              cols={2}
              rows={props.data.input.config.rows}
              maxLength={props.data.input.config.max}
              minLength={props.data.input.config.min}
            />
          </div>
          :
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <input
              id={props.data.display.name + ' input'}
              maxLength={props.data.input.config.max}
              minLength={props.data.input.config.min}
              className="customTextInput1-container-inputContainer-input"
              placeholder={props.data.display.placeholder}
              type='text'
              onChange={(e: any) => {
                const res = e.target.value.replace(/[^a-z0-9 ]/gi, '')
                setLocalText(res)
                props.onUpdate(res)
              }}
              value={localText}
              style={{ color: 'unset', backgroundColor: 'transparent' }}
            />
             <div className="customTextInput1-container-line"></div>
          </div>
        }
      </div>
    </div>
  )
}

export default FormFieldText;