
import React from 'react'
import './DemoColorSelector.css'

const DemoColorSelector = (props: any) => {

  return (
    <div className="demoColorSelector-container">
        <p>Seleccione el color</p>
        <div className="demoColorSelector-container-list">
            {props.colorList?.map((a: any) => (
                <div 
                    className="demoColorSelector-container-list-colorList"
                    onClick={() => props.setColor(a.id)}
                >
                    <div 
                        className="demoColorSelector-container-list-colorList-box" 
                        style={{ backgroundColor: a["--primary200"]}}
                    />
                    <div 
                        className="demoColorSelector-container-list-colorList-box" 
                        style={{ backgroundColor: a["--primary300"]}}
                    />
                    <div 
                        className="demoColorSelector-container-list-colorList-box" 
                        style={{ backgroundColor: a["--primary400"]}}
                    />
                    <div 
                        className="demoColorSelector-container-list-colorList-box" 
                        style={{ backgroundColor: a["--primary500"]}}
                    />
                    <div 
                        className="demoColorSelector-container-list-colorList-box" 
                        style={{ backgroundColor: a["--primary700"]}}
                    />
                    <div 
                        className="demoColorSelector-container-list-colorList-box" 
                        style={{ backgroundColor: a["--primary800"]}}
                    />
                    {props.colorSelected === a.id && 
                        <div className="demoColorSelector-container-list-colorList-check">
                            <span className="material-symbols-outlined" style={{ fontSize: 40 }}>done</span>
                        </div>
                    }
                </div>
            ))}
        </div>
    </div >
  );
}

export default DemoColorSelector;