import React, { useEffect, useState } from 'react'
import Footer from '../../components/generalComponents/footer/Footer';
import Header from '../../components/generalComponents/header/Header';
import './TerminosPage.css'

const TerminosPage = (props: any) => {

    const TyC = '<ul>I. Aspectos generales:<br/></ul>' +
    'Las presentes Términos y Condiciones de venta por medio del sitio web “SiempreSeguro” (en adelante los “Términos y Condiciones”), tienen como objeto establecer las buenas prácticas corporativas en los procedimientos de SiempreSeguro, facilitar la oferta y contratación vía Internet de Productos, al mismo tiempo que poner a disposición de los Usuarios la información suministrada en el Sitio, así como las transacciones comerciales entre SiempreSeguro y los Usuarios. La adquisición de cualquiera de los productos ofertados en el Sitio por los Usuarios, supone y requiere la aceptación total y sin reservas de los presentes Términos y Condiciones. Estos Términos y Condiciones regulan, se aplican y se entienden formar parte de todos los actos y contratos que se ejecutan o celebran mediante los sistemas de oferta y comercialización comprendidos en este Sitio entre sus usuarios y ARTHUR J. GALLAGHER CORREDORES DE SEGUROS S.A. de conformidad a las leyes y regulaciones chilenas, y a los estándares generalmente aceptados por el comercio electrónico.<br/>' +
    'Estos Términos y Condiciones regulan la relación jurídica que emana de la utilización de los Servicios, así como los procesos de contratación realizados por los Usuarios. Los Usuarios deberán aceptar los Términos y Condiciones para efectos de contratar o adquirir cualquier Producto en el sitio web. Este documento puede ser impreso y almacenado por los Usuarios.<br/>' +
    '<ul>II. Definiciones.<br/></ul>' +
    'Para efecto de los presentes Términos y Condiciones, salvo que se disponga expresamente lo contrario, se entenderá por:<br/>' +
    '“SiempreSeguro”: ARTHUR J. GALLAGHER CORREDORES DE SEGUROS S.A., RUT. Nº 77.682.370-8, sociedad del giro de corretaje de seguros, inscrita como tal ante la Comisión para el Mercado Financiero, con domicilio para estos efectos en Isidora Goyenechea Nº 3477, oficina 61, comuna de Las Condes, Santiago, Chile, quien es titular de la marca “SiempreSeguro”, de la tienda virtual “www.siempreseguro.cl” y del correo de ayuda al cliente “ “siempresegurochile@ajg.com”<br/>' +
    '“Producto” o “Productos”: El o los productos proporcionados por Compañías Aseguradoras y/o de Asistencias, consistentes en Pólizas de seguro o certificado de cobertura que contrata el Usuario por medio del Sitio.<br/>' +
    '“Proveedor” o “Proveedores”: La o las Compañías Aseguradoras y/o de Asistencias que proporcionan los Productos intermediados por SiempreSeguro.<br/>' +
    '“Servicio” o “Servicios”: Aquellos ofrecidos por SiempreSeguro, por intermedio del Sitio, correspondientes a las actividades que para los corredores de seguros establece el Decreto Supremo Nº 1055 de 2012, del Ministerio de Hacienda.<br/>' +
    '“Sitio”: Página web <a href="www.siempreseguro.cl" target="_blank">www.siempreseguro.cl</a><br/>' +
    '“Usuario” o “Usuarios”: La o las Personas naturales o jurídicas que a través del Sitio coticen, comparen, contraten o paguen Productos o Servicios.<br/>' +
    '<ul>III. Intervinientes.<br/></ul>' +
    'a) Cubrirán los riesgos las siguientes compañías aseguradoras, inscritas como tales ante la Comisión para el Mercado Financiero:<br/>' +
    '- MAPFRE COMPAÑIA DE SEGUROS GENERALES DE CHILE S.A.<br/>' +
    '- REALE CHILE SEGUROS GENERALES S.A.<br/>' +
    '- LIBERTY COMPAÑIA DE SEGUROS GENERALES S.A.<br/>' +
    '- HDI SEGUROS S.A.<br/>' +
    'Se hace presente que en el futuro podrán incorporarse al presente listado otras compañías aseguradoras locales, siempre y cuando se encuentren registradas como tales ante la Comisión para el Mercado Financiero, y por tanto se encuentren autorizadas por el regulador local para realizar el negocio de asegurar riesgos en base a primas.<br/>' +
    'b) ARTHUR J. GALLAGHER CORREDORES DE SEGUROS S.A. (en adelante también “SiempreSeguro”), RUT Nº 77.682.370-8, inscrita en el registro de corredores de seguros persona jurídica que lleva la Comisión para el Mercado Financiero bajo el Nº 5654, e inscrita en el Registro de Comercio de Santiago a fojas 45.514, número 24.900 del año 2016, con domicilio en Isidora Goyenechea Nº 3477, oficina 61, comuna de Las Condes, Santiago de Chile, es propietario y dispone de la titularidad de la tienda virtual <a href="www.siempreseguro.cl" target="_blank">www.siempreseguro.cl</a> y del correo de ayuda al cliente, siempresegurochile@ajg.com.<br/>' +
    'c) Las primas por los seguros contratados serán recaudadas directamente por cada Compañía Aseguradora, por medio del sistema de Pago Automático con Tarjeta (PAT).<br/>' +
    '<ul>IV. Aceptación de estos Términos y Condiciones.<br/></ul>' +
    'Mediante la aceptación del presente contrato, el Usuario declara:<br/>' +
    'a. Que es una persona mayor de edad y con capacidad para contratar.<br/>' +
    'b. Que reside en territorio nacional.<br/>' +
    'c. Que ha leído y acepta íntegramente los presentes Términos y Condiciones.<br/>' +
    '<ul>V. Contacto de ayuda al Usuario.<br/></ul>' +
    'SiempreSeguro pone a disposición de los Usuarios la dirección de e-mail siempresegurochile@ajg.com, para que puedan plantear cualquier duda acerca de los Términos y Condiciones. Además, el teléfono +56940810793 para que puedan comunicarse a través de WhatsApp en el mismo Sitio.<br/>' +
    '<ul>VI. Modificación de los Términos y Condiciones.<br/></ul>' +
    'SiempreSeguro podrá modificar los Términos y Condiciones, notificándolo a los Usuarios con el fin de mejorar los Servicios ofrecidos a través del Sitio. La versión actualizada de los Términos y Condiciones estará permanentemente disponible para los Usuarios en el Sitio.<br/>' +
    'En todo caso, los Términos y Condiciones aquí contenidos tienen carácter obligatorio y vinculante, por lo que cualquier persona que no acepte estos términos deberá abstenerse de utilizar el Sitio y/o los Servicios de SiempreSeguro.<br/>' +
    'SiempreSeguro se reserva el derecho de rechazar cualquier solicitud de registro, sin que esté obligado a comunicar o exponer las razones de su decisión y sin que ello genere algún derecho a indemnización o resarcimiento. Sin perjuicio de lo anterior, en cualquier momento el Usuario podrá solicitar que su registro o datos personales sean eliminados del Sitio, solicitud que no supondrá liberarle de las obligaciones que en virtud de los Productos contratados hayan nacido para las partes o el intermediador.<br/>' +
    '<ul>VII. Descripción de los Servicios e Información disponible en el Sitio.<br/></ul>' +
    'Arthur J. Gallagher Corredores de Seguros S.A. es una empresa dedicada al rubro de la prestación de servicios de corretaje de seguros a personas y empresas, que se encuentra registrada como tal ante la Comisión para el Mercado Financiero, y cuya actividad se encuentra regulada por el Reglamento de los Auxiliares del Comercio de Seguros, contenido en el Decreto Supremo Nº 1.055 de 2012 del Ministerio de Hacienda. De esta forma, Arthur J. Gallagher Corredores de Seguros S.A. se encuentra autorizada para intermediar seguros de ambos grupos (generales y de vida), ofreciendo una amplia gama de productos y servicios.<br/>' +
    'Los precios ofrecidos estarán disponibles mientras aparezcan en el Sitio. SiempreSeguro podrá modificar las informaciones dadas en este Sitio, incluyendo las referidas a mercaderías, servicios, precios, existencias y condiciones, en cualquier momento y sin previo aviso, mientras no reciba la solicitud de compra del Usuario de la forma indicada en estos Términos y Condiciones.<br/>' +
    'Para garantizar al cliente la certeza y seguridad del precio de sus productos, en el cual se incluye el Impuesto al Valor Agregado o IVA, éste será el vigente en la publicidad al momento de solicitar el Producto.<br/>' +
    'Salvo prueba en contrario, los datos registrados por SiempreSeguro constituyen la prueba del conjunto de transacciones realizadas entre SiempreSeguro y los Usuarios. SiempreSeguro archivará el documento electrónico en que se formalice el contrato.<br/>' +
    '<ul>VIII. Registro.<br/></ul>' +
    'La sola visita al Sitio, en el cual se ofrecen determinados Productos y el acceso a determinados servicios, no impone al Usuario obligación alguna, a menos que haya expresado en forma inequívoca y mediante actos positivos su voluntad de aceptar una determinada oferta del Proveedor, en la forma indicada en estos Términos y Condiciones.<br/>' +
    'Una vez que el Usuario adquiera un Producto, se creará una cuenta personalizada de acceso (Extranet) de forma totalmente gratuita para acceder a los Servicios. Para la creación de esta cuenta es obligatorio completar el Formulario proporcionado por SiempreSeguro en todos sus campos. La información o datos personales registrados en cada uno de los campos del formulario deben ser exactos, precisos y verdaderos.<br/>' +
    'Adicionalmente, el Usuario asume el compromiso de mantener actualizada su información en el Extranet del Sitio.<br/>' +
    'SiempreSeguro no se responsabiliza por la veracidad de la información entregada por los Usuarios. Los Usuarios son responsables, en cualquier caso, por la veracidad, exactitud, vigencia y autenticidad de los datos personales ingresados. La información y los datos personales serán utilizados exclusivamente por SiempreSeguro para identificar al Usuario y clasificar su perfil con el fin de ofrecer una mejor atención.<br/>' +
    'Una vez registrado el Usuario, accederá al Extranet del Sitio mediante el ingreso de su usuario y contraseña o clave secreta. La cuenta es personal, única e intransferible. El Usuario será responsable por todas las operaciones efectuadas en el Extranet del Sitio, ya que el acceso a la misma está restringido por el uso de su Usuario y Contraseña, de conocimiento exclusivo del Usuario.<br/>' +
    'El usuario es responsable de mantener la confidencialidad de su clave secreta registrada en este Sitio. El Usuario podrá cambiar su clave, siguiendo el procedimiento establecido en el Sitio.<br/>' +
    'El Usuario se compromete a notificar a SiempreSeguro en forma inmediata y por medio idóneo, cualquier uso no autorizado del Extranet del Sitio, así como el ingreso por terceros no autorizados a la misma. Se aclara que está prohibida la venta, cesión o transferencia de la Cuenta bajo ningún título.<br/>' +
    'SiempreSeguro se reserva el derecho de rechazar cualquier solicitud de registro o de cancelar un registro previamente aceptado, sin que esté obligado a comunicar o exponer las razones de su decisión y sin que ello genere algún derecho a indemnización o resarcimiento.<br/>' +
    '<ul>IX. Concursos.<br/></ul>' +
    'SiempreSeguro, puede realizar concursos por medio del Sitio. La participación en estos concursos es completamente voluntaria. Los datos solicitados pueden incluir información personal identificable. Para participar, el Usuario deberá aceptar todos los términos y condiciones que se especificarán clara y oportunamente en las Bases del concurso respectivo.<br/>' +
    '<ul>X. Prestación de los Servicios.<br/></ul>' +
    'Toda aceptación de una oferta de compra quedará sujeta a la condición suspensiva de que SiempreSeguro confirme la transacción, para lo cual verificará:<br/>' +
    'a) Que aún dispone de los Productos en stock.<br/>' +
    'b) Que el medio de pago propuesto por el Usuario está disponible.<br/>' +
    'c) Que los datos registrados por el Usuario en el sitio coinciden con los dados al aceptar la oferta.<br/>' +
    'De ser necesario se comunicará con el Usuario un ejecutivo de SiempreSeguro que se encargará de gestionar de la forma más eficaz el servicio contratado.<br/>' +
    '<ul>XI. Devoluciones y cancelaciones.<br/></ul>' +
    'En caso que el Usuario comunique su intención de poner término a un Producto, cumpliendo con los requisitos que para ello establezca la póliza o certificado respectivo, SiempreSeguro gestionará ante el Proveedor la cancelación de Producto, así como la devolución de las sumas que por dicha terminación deba restituir el Proveedor al Usuario, en caso que ésta sea procedente bajo el contrato y la ley.<br/>' +
    'Cualquier solicitud de cancelación o devolución deberá ser comunicada lo antes posible al contacto entregado por SiempreSeguro al contratar el Producto, y en su defecto al correo siempresegurochile@ajg.com.<br/>' +
    '<ul>XII. Forma de Pago y Seguridad.<br/></ul>' +
    'Las primas por los seguros contratados por los Usuarios serán recaudadas directamente por cada Compañía Aseguradora, por medio del sistema de Pago Automático con Tarjeta (PAT). Por ende, los términos y condiciones para el pago de las primas serán aquellos establecidos por cada compañía aseguradora con que el Usuario contrate el producto.<br/>' +
    'En caso que se informara de la denegación de la tarjeta, se cancelará automáticamente el pedido, dando a conocer online al cliente tal situación.<br/>' +
    '<ul>XIII. Obligaciones de las partes.<br/></ul>' +
    'a. Obligaciones del Usuario: Es obligación del Usuario completar los formularios contenidos en el Sitio con datos reales previamente a comprar y/o contratar Productos.<br/>' +
    'El Usuario puede realizar una o varias solicitudes de compra de Productos ofrecidos en el Sitio. En el momento de hacer una solicitud, el Producto seleccionado por el Usuario podrá ingresar a un “Carro de compras” que registrará todos los Productos seleccionados por el Usuario. Una vez registrado el producto seleccionado, el Usuario deberá confirmar la orden de compra y ejecutar el pago correspondiente.<br/>' +
    'La confirmación de la orden de compra indica que el Usuario acepta las especificaciones y características del Producto. Igualmente, queda obligado por las condiciones de venta incluidas en la descripción del Producto en el Sitio, en la medida en que las mismas no infrinjan las leyes o los Términos y Condiciones y demás políticas de SiempreSeguro.<br/>' +
    'Con carácter general, el usuario se obliga al cumplimiento de estos Términos y Condiciones, así como a obrar siempre conforme a la ley, a las buenas costumbres y a las exigencias de la buena fe, empleando la diligencia debida, y absteniéndose de utilizar el Sitio de cualquier forma que pueda impedir, dañar o deteriorar el normal funcionamiento del mismo, los bienes o derechos de SiempreSeguro, de los Proveedores, del resto de los usuarios o en general de cualquier tercero. Queda prohibido el acceso y uso del portal a los menores de edad sin el consentimiento expreso de sus padres, SiempreSeguro no se responsabiliza de la veracidad y exactitud de los datos proporcionados por el Usuario y por tanto no puede constatar la edad de los mismos.<br/>' +
    'El Usuario se compromete a posibilitar la entrega del Producto proporcionando a un correo electrónico vigente, al que puedan ser enviados los documentos que dan cuenta del Producto contratado en el más breve plazo posible. En caso de incumplimiento por parte del cliente de esta obligación, SiempreSeguro no tendrá ninguna responsabilidad sobre el retraso o imposibilidad de entrega del Producto solicitado por el cliente, reservándose el derecho de anular la compra realizada.<br/>' +
    'b. Obligaciones de SiempreSeguro: Una vez activado el pago por el Usuario del respectivo Producto, SiempreSeguro deberá gestionar la entrega al Usuario de los documentos que dan cuenta de la contratación del Producto.<br/>' +
    'Igualmente, es responsabilidad de SiempreSeguro cumplir con las condiciones de la venta, las características y especificaciones de los Productos ofrecidos, como también de que las garantías sean otorgadas cuando se requieran.<br/>' +
    'SiempreSeguro no se hace responsable de los perjuicios que se pudieran derivar de interferencias, omisiones, interrupciones, virus informáticos, averías y/o desconexiones en el funcionamiento operativo de este sistema electrónico o en los aparatos y equipos informáticos de los Usuarios, motivadas por causas ajenas a SiempreSeguro, que impidan o retrasen la prestación de los Servicios o la navegación por el Sitio, ni de los retrasos o bloqueos en su uso causados por deficiencias o sobrecargas de Internet o en otros sistemas electrónicos debidas al Usuario, a terceros, o a situaciones calificables como caso fortuito o fuerza mayor. SiempreSeguro no controla, con carácter general, la utilización que los Usuarios hacen del Sitio.<br/>' +
    '<ul>XIV. Prohibiciones.<br/></ul>' +
    'Los Usuarios de SiempreSeguro no podrán:<br/>' +
    'a. Estar registrados sin antes haber aceptado los Términos y Condiciones y haber contratado un Producto.' +
    'b. Proporcionar datos maliciosamente falsos o incompletos en cualquiera de los formularios que el uso del Sitio requiera. La infracción de esta prohibición producirá los efectos señalados en estos Términos y Condiciones, así como en la normativa legal y reglamentaria vigente aplicable a la contratación de Seguros.' +
    'c. Hacer uso del Sitio con fines distintos a la cotización y contratación de Productos. Se prohíbe expresamente la apropiación o utilización de información disponible en el Sitio para fines distintos a la cotización y contratación de Productos.<br/>' +
    '<ul>XV. Política de desistimiento, devoluciones y disponibilidad de los servicios y/o productos.<br/></ul>' +
    'Según lo dispuesto en el artículo 3º bis de la ley 19.496, el Usuario podrá poner término unilateralmente al contrato en el plazo de 10 días contados desde la recepción del producto o desde la contratación del servicio y antes de la prestación del mismo.' +
    'Si el Usuario opta, en los casos permitidos por ley, por solicitar la devolución del importe abonado, éste se efectuará a través del medio de pago que el Proveedor disponga en un plazo no superior a sesenta días.' +
    'En el evento que por causas legales o las indicadas precedentemente, se produzca una devolución del dinero, se aplicará el art. 70 de la Ley del IVA, por el cual en los casos en que una venta quede sin efecto por resolución, resciliación, nulidad u otra causa, el Servicio de Impuestos Internos, a petición del interesado, anulará la orden que haya girado, no aplicará el tributo correspondiente o procederá a su devolución, si hubiere sido ya ingresado en arcas fiscales. Lo establecido en el inciso anterior no tendrá aplicación cuando hubieren transcurrido más de tres meses entre la entrega y la devolución de las especies que hayan sido objeto del contrato, salvo en los casos en que la venta quede sin efecto por sentencia judicial. En lo que respecta a SiempreSeguro, la devolución o no aplicación del impuesto deberá solicitarse dentro de los treinta días siguientes a la fecha en que la venta quede sin efecto.<br/>' +
    '<ul>XVI. Privacidad y Protección de Datos Personales.<br/></ul>' +
    'En cumplimiento a las disposiciones legales vigentes, los datos personales del Usuario quedarán incorporados y serán tratados en los ficheros de SiempreSeguro, con el fin de prestar y ofrecer los Servicios, mediante la entrega de la dirección de correo electrónico u otros datos personales, requisito necesario para la contratación de Productos. Los datos personales que entregue el Usuario sólo podrán ser utilizados por SiempreSeguro para perfeccionar contratos, recibir pagos y mejorar la labor de información y comercialización de los Productos y Servicios con el Usuario. Los Usuarios que faciliten sus datos de carácter personal, prestan su consentimiento expreso para que puedan ser comunicados para su utilización a las empresas relacionadas a SiempreSeguro para la realización de actividades propias de su objeto social.<br/>' +
    'Los Usuarios quedan informados que dicha comunicación puede producirse en el mismo momento en que proporcionan los datos a SiempreSeguro.<br/>' +
    'SiempreSeguro pone a disposición de los Usuarios la dirección de correo electrónico siempresegurochile@ajg.com, para que estos revoquen el consentimiento prestado. Así como para el ejercicio de los derechos de acceso, rectificación, cancelación y oposición garantizados por la legislación vigente.' +
    'SiempreSeguro declara que cumple la normativa vigente respecto a la protección de datos personales.<br/>' +
    '<ul>XVII. Propiedad Industrial e Intelectual.<br/></ul>' +
    'Los contenidos suministrados por SiempreSeguro están sujetos a los derechos de propiedad intelectual e industrial y son de titularidad exclusiva de Arthur J. Gallagher Corredores de Seguros S.A. o de las personas físicas o jurídicas que se informe. Mediante la adquisición de un Producto o Servicio, SiempreSeguro no confiere al adquirente ningún derecho de alteración, explotación, reproducción, distribución o comunicación pública sobre el mismo, reservándose SiempreSeguro todos estos derechos. La cesión de los citados derechos precisará el previo consentimiento por escrito por parte del titular de los mismos, de manera que el Usuario no podrá poner a disposición de terceras personas dichos contenidos.<br/>' +
    'SiempreSeguro ha obtenido la Información y los materiales incluidos en la web de fuentes consideradas como fiables y, si bien se han tomado medidas razonables para asegurarse de que la información contenida sea la correcta, SiempreSeguro no puede garantizar que en todo momento y circunstancia dicha información sea exacta, completa, actualizada y, consecuentemente, no debe confiarse en ella como si lo fuera.<br/>' +
    'SiempreSeguro declina expresamente cualquier responsabilidad por error u omisión en la Información contenida en el Sitio.<br/>' +
    'SiempreSeguro se reserva la facultad de modificar, suspender, cancelar o restringir el contenido del Sitio, los vínculos o la información obtenida a través de ella, sin necesidad de previo aviso.<br/>' +
    'SiempreSeguro en ningún caso, asume responsabilidad alguna como consecuencia de la incorrecta utilización del Sitio que pueda llevar a cabo el Usuario, tanto de la información como de los Servicios en él proporcionados.<br/>' +
    'En ningún caso, SiempreSeguro, sus sucursales y/o centros de trabajo, sus directores y/o apoderados, empleados y, en general, el personal autorizado serán responsables de cualquier tipo de perjuicio, pérdidas, reclamaciones o gastos de ningún tipo, tanto si proceden, directa o indirectamente, del uso y/o difusión del Sitio de la información adquirida o accedida por o a través del mismo, o de sus virus informáticos, de fallos operativos o de interrupciones en el servicio o transmisión o de fallos en la línea en el uso del Sitio, tanto por conexión directa como por vínculo u otro medio, constituyendo el presente documento a todos los efectos legales un aviso a cualquier Usuario de que estas posibilidades y eventos pueden ocurrir.<br/>' +
    'Los derechos de Propiedad Intelectual y derechos de explotación y reproducción del Sitio de sus páginas, pantallas, la Información que contienen, su apariencia y diseño, así como los vínculos ("hiperlinks") que se establezcan desde ella a otras páginas web de cualquier sociedad filial y/o relacionada de SiempreSeguro son propiedad exclusiva de ésta, salvo que expresamente se especifique otra cosa. Cualquier uso indebido de las mismas por personas diferentes de su legítimo titular y sin el consentimiento expreso e inequívoco por parte de éste podrá ser denunciado y perseguido a través de todos los medio legales existentes.<br/>' +
    'Los derechos de propiedad intelectual y marcas de terceros están destacados convenientemente y deben ser respetados por todo aquél que acceda al Sitio, no siendo responsabilidad de SiempreSeguro el uso que el Usuario pueda llevar a cabo al respecto, recayendo la responsabilidad exclusiva en su persona.<br/>' +
    'Sólo para uso personal y privado se permite descargar los contenidos, copiar o imprimir cualquier página del Sitio. Queda prohibido reproducir, transmitir, modificar o suprimir la información, contenido o advertencias del Sitio sin la previa autorización por escrito de SiempreSeguro.<br/>' +
    '<ul>XVIII. Cookies.<br/></ul>' +
    'En algunas ocasiones, el Sitio utiliza "Cookies", es decir, pequeños ficheros de datos que se generan en el ordenador del Usuario y que permiten obtener la siguiente Información:<br/>' +
    'a. Fecha y hora de la última vez que el Usuario visitó el Sitio;<br/>' +
    'b. Diseño y contenidos que el Usuario escogió en su primera visita al Sitio;<br/>' +
    'c. Elementos de seguridad que intervienen en el control de acceso a las áreas restringidas, y<br/>' +
    'd. Otras circunstancias análogas.<br/>' +
    'El Usuario tiene la opción de impedir la generación de cookies, mediante la selección de la correspondiente opción en su programa navegador. Sin embargo, SiempreSeguro no se responsabiliza de que la desactivación de las mismas impida el buen funcionamiento del Sitio.<br/>' +
    '<ul>XIX. Nulidad e ineficacia de las Cláusulas.<br/></ul>' +
    'Si cualquier cláusula incluida en estos Términos y Condiciones fuese declarada, total o parcialmente, nula o ineficaz, tal nulidad o ineficacia afectará tan sólo a dicha disposición o a la parte de la misma que resulte nula o ineficaz, subsistiendo los Términos y Condiciones en todo lo demás, teniéndose tal disposición, o la parte de la misma que resultase afectada, por no escrita.<br/>' +
    '<ul>XX. Notificaciones.<br/></ul>' +
    'Todas las notificaciones, requerimientos, peticiones y otras comunicaciones que hayan de efectuarse por las partes en relación con los presentes Términos y Condiciones, deberán realizarse por escrito y se entenderá que han sido debidamente realizadas cuando hayan sido entregadas en mano o bien remitidas por correo ordinario al domicilio informado por medio del Sitio o al correo electrónico proporcionado por medio del Sitio, o bien a cualquier otro domicilio o correo electrónico que a estos efectos cada parte pueda indicar a la otra.<br/>' +
    '<ul>XXI. Legislación aplicable y jurisdicción competente.<br/></ul>' +
    'Los presentes Términos y Condiciones se regirán e interpretarán de acuerdo con las leyes de Chile. En el supuesto de que surja cualquier conflicto o discrepancia en la interpretación o aplicación de las presentes condiciones contractuales, los Juzgados y Tribunales que, en su caso conocerán del asunto, serán los que disponga la normativa legal aplicable.<br/>' +
    'En el caso de que la parte compradora tenga su domicilio fuera de Chile, o que se trate de una compraventa realizada por una empresa, ambas partes se someten, con renuncia expresa a cualquier otro fuero, a los Juzgados y Tribunales de la Ciudad de Santiago de Chile.'

    return (
        <div className="terminosPage-container">
            <Header />
            <div className="terminosPage-container-main">
                <p className="terminosPage-container-main-text1">SIEMPRE SEGURO</p>
                <p className="terminosPage-container-main-text2">Políticas y Uso</p>
                <p className="terminosPage-container-main-text3">2022</p>
                <p className="terminosPage-container-main-text4">Términos y condiciones de venta de productos y servicios por mes de sitio web Siempre Seguro.</p>
                <p className="terminosPage-container-main-text5" dangerouslySetInnerHTML={{__html: TyC}}/>
            </div>
            <Footer/>
        </div>
    );
}

export default TerminosPage;