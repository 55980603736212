import React, {useEffect, useState} from 'react'
import './PeriodsComponent.css'
import { useMediaQuery } from 'react-responsive'

let isTabletOrMobile = false;
const PeriodsComponent = (props: any) => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

  const [periodList, setPeriodList] = useState<any>([])

  useEffect(() => {
    const aux = props.offerData.periods;
    var sortable: any = [];
    for (var vehicle in aux) {
      sortable.push([vehicle, aux[vehicle]]);
    }
    sortable.sort(function (a: any, b: any) {
      if (a[1].quantity < b[1].quantity) { return -1; }
      if (a[1].quantity > b[1].quantity) { return 1; }
      return 0;
    })
    setPeriodList(sortable)
  }, [])

  return (
    <div 
      className="periodsComponent-container"
      style={{
        width: isTabletOrMobile ? '100%' : (100 / periodList.length.toString()) + '%'
      }}
    >
      {periodList.map((a: any, index: any) => (
        <div 
          id={a[1].displayName + ' periodo'}
          key={a[0]}
          className="periodsComponent-container-item"
          onClick={() => props.periodClicked(a[0])}
          style={{backgroundColor: props.offerData.renew.term === a[0] ? 'var(--primary500)' : 'transparent'}}
        >
          <p 
            className="periodsComponent-container-item-text"
            style={{color: props.offerData.renew.term === a[0] ? 'var(--white)' : 'var(--primary500)'}}
          >{a[1].displayName}</p>
          {a[1].discount !== 0 && <p className="periodsComponent-container-item-discount">{a[1].discount + '% OFF'}</p>}
        </div>
      ))}
    </div>
  )
}

export default PeriodsComponent;