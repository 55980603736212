import {
    FETCH_SUGERIDOS_LIST_USER,
    FETCH_URL_SUGERIDOS
} from "../actions/types";


const INITIAL_STATE = {
    sugeridosListUser: [],
    arraySugeridosUrl: []
};

export default (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case FETCH_SUGERIDOS_LIST_USER:
            return { ...state, sugeridosListUser: action.payload };
        case FETCH_URL_SUGERIDOS:
            return { ...state, arraySugeridosUrl: action.payload };
        default:
            return state;
    }
};