import React from 'react'

const EmisoraType = (props) => {
    
    return (
        <div className='emisor-container'
            key={props.name}
            style={{
                backgroundColor: props.selected ? 'var(--primary500)' : 'var(--primary200)',
            }}
            onClick={ props.onSelected}
        >
            <b className="regular small" style={{ color: props.selected ? 'var(--white)' : 'var(--gray700)' }}>{props.name.toUpperCase()}</b>
            {props.selected && <span className="material-symbols-outlined" style={{ fontSize: 16, color: 'var(--white)' }} >close</span>}
        </div>
    )
}

export default EmisoraType;
