import {
    USER_PRODUCTS_ACTIVE,
    USER_PRODUCTS_INACTIVE
} from '../actions/types';

const INITIAL_STATE = {
    userProductsActive: [],
    userProductsInactive: [],
};

export default (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case USER_PRODUCTS_ACTIVE:
            return { ...state, userProductsActive: action.payload };
        case USER_PRODUCTS_INACTIVE:
            return { ...state, userProductsInactive: action.payload };
        default:
            return state;
    }
};